import React, { useEffect, useState, createContext, useRef } from "react";
import Layout from "../components/Layout";
import { LessonsSidebar, Questions, Video } from "../components/lessons/index";
import { getLessonComments, getLesson } from "../api";
import { useUserSignedIn } from "../hooks/useUserSignedIn";
import questionCircle from "../assets/icons/question_circle.svg";
import Popup from "reactjs-popup";
import { QuestionPlayer } from "../components/shared/QuestionPlayer";

export const QnasContext = createContext(null);

const Lessons = (props) => {
  const lessonDetailRef = useRef(null);
  const [lessonDetail, setLessonDetail] = useState(null);
  const qnasRef = useRef(null);
  const [qnas, setQnas] = useState(null);
  const { isUserSignedIn } = useUserSignedIn();

  useEffect(() => {
    getLesson({ lessonId: props?.lessonId })
      .then((response) => {
        lessonDetailRef.current = response?.data;
        setLessonDetail(lessonDetailRef.current);

        dataLayer.push({
          lecture_id: response?.data?.lecture_id,
          lesson_id: response?.data?.id,
        });
      })
      .catch(() => {
        window.location.href = `/lectures/${props?.lectureId}`;
      });
  }, []);

  const [selectedVideoId, setSelectedVideoId] = useState(null);

  //외부 링크 사이트로 바로 이동 & 그게 아니면 qna response
  useEffect(() => {
    if (lessonDetail?.mode === "external") {
      location.href = lessonDetail?.matching_link;
    } else {
      if (!!lessonDetail?.id) {
        getLessonComments({ lessonId: lessonDetail?.id }).then((response) => {
          qnasRef.current = response?.data;
          setQnas(qnasRef.current?.comments);
        });
      }
    }
  }, [lessonDetail]);

  return (
    isUserSignedIn && (
      <Layout>
        <QnasContext.Provider
          value={{
            qnas: qnas,
            setQnas: setQnas,
          }}
        >
          <div className="flex tablet:flex-col mobile:flex-col mx-auto max-w-[1230px] px-[15px]">
            <div className="flex flex-col w-full web:mr-[22px] web:max-w-[820px]">
              <Video
                lessonDetail={lessonDetail}
                setSelectedVideoId={setSelectedVideoId}
              />

              <div className="flex items-center justify-end ml-auto">
                <Popup
                  trigger={
                    <button className="px-2 py-1 text-xs bg-white border rounded shadow text-gray400 hover:bg-gray100">
                      시청에 문제가 있나요?
                    </button>
                  }
                  modal
                  overlayStyle={{
                    background: "rgba(0, 0, 0, 0.75)",
                  }}
                  lockScroll
                >
                  {(close) => (
                    <div className="bg-white web:max-w-[500px] tablet:max-w-[500px] mobile: mx-4 p-4 rounded-[5px] max-h-[80vh] overflow-auto">
                      <QuestionPlayer onClose={close} />
                    </div>
                  )}
                </Popup>
              </div>

              <div className="tablet:hidden mobile:hidden">
                <Questions
                  lessonId={lessonDetail?.id}
                  qnas={qnas}
                  commentableType="Lesson"
                />
              </div>
            </div>
            <LessonsSidebar
              lessonDetail={lessonDetail}
              qnas={qnas}
              lessonId={props?.lessonId}
              selectedVideoId={selectedVideoId}
            />
          </div>
        </QnasContext.Provider>
      </Layout>
    )
  );
};

export default Lessons;
