import questionCircle from "../../assets/icons/question_circle.svg";
import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { VideoPlayer } from "./VideoPlayer";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { patchUserColdCallActive } from "../../api";
import useDeviceCheck from "../../hooks/useDeviceCheck";

const Video = ({ lessonDetail, setSelectedVideoId }) => {
  const [toggle, setToggle] = useState(true);
  const deviceCheck = useDeviceCheck();
  // internal_video => 일반강의
  // internal_download => 교재다운로드페이지
  // external => 외부페이지 redirect
  // internal_video_external_button => 강의 시청 후 외부 페이지 링크 이용
  const { currentUser } = useGetCurrentUser();

  useEffect(() => {
    if (deviceCheck === "web") {
      setToggle(currentUser?.is_cold_call);
    } else {
      setToggle(false);
    }
  }, [currentUser]);

  const coldcallQHover = css`
    &:hover {
      .hoverCard {
        display: flex !important;
      }
    }
  `;

  const changeToggle = () => {
    patchUserColdCallActive({ isColdCall: !toggle }).then((response) => {
      gtag("event", "click_question_toggle", {
        user_id: currentUser?.id,
        toggle_status: response?.data,
      });
      setToggle(!toggle);
    });
  };

  return (
    <div className="flex flex-col web:mb-[25px] tablet:mb-[13px] mobile:mb-[13px]">
      <span className="tablet:text-sm mobile:text-sm">
        {lessonDetail?.lecture_title}
      </span>
      <div className="flex items-center justify-between ">
        <span className="w-full font-bold web:text-xl tablet:text-xl">
          {lessonDetail?.title}
        </span>

        <div
          className="relative flex items-center mr-[14px] cursor-pointer"
          css={coldcallQHover}
        >
          <div className="ml-auto web:text-sm tablet:text-xs mobile:text-xs whitespace-nowrap">
            퀴즈
          </div>

          <img
            src={questionCircle}
            alt="유의사항"
            className="w-[15px] h-[15px] ml-[3px]"
          />
          <div
            className="rounded-[6px] absolute hidden flex-col text-sm max-w-[260px] w-[260px] hoverCard bg-white z-[5] top-[24px] right-0 py-[13px] px-[17px]"
            css={css`
              box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
            `}
          >
            {deviceCheck === "web" ? (
              <span>
                강의 중에 퀴즈가 있어요! <br />
                퀴즈를 풀고 싶다면 'ON', 퀴즈를 풀고 싶지 않다면 클릭하여
                'OFF'로 바꿔주세요.
              </span>
            ) : (
              <span>
                태블릿과 모바일에서는 퀴즈 기능이 동작하지 않을 수 있습니다.
                <br />
                퀴즈 기능을 사용하려면 PC를 이용해주세요!
              </span>
            )}
          </div>
        </div>

        {deviceCheck === "web" && (
          <button
            className={`text-white flex items-center justify-between rounded-full transition max-w-[54px] w-full p-[3px] ${
              toggle ? "bg-yellow700" : "bg-gray100 flex-row-reverse"
            }`}
            onClick={changeToggle}
            css={css`
              box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
            `}
          >
            <span className="mx-auto text-sm">{toggle ? "ON" : "OFF"}</span>
            <div className="rounded-full bg-white w-[17px] h-[17px]"></div>
          </button>
        )}
      </div>

      {lessonDetail && (
        <VideoPlayer
          lessonDetail={lessonDetail}
          isColdCall={toggle}
          setSelectedVideoId={setSelectedVideoId}
        />
      )}

      {/* 예외-폴리수프 */}
      {lessonDetail?.mode === "internal_video_external_button" && (
        <div className="flex w-full mt-4 tablet:items-end mobile:items-end tablet:flex-col mobile:flex-col web:justify-end web:items-center">
          <span className="font-medium tablet:text-sm mobile:text-sm">
            강의를 다 들었다면?
          </span>
          <a
            id="next"
            href={lessonDetail?.matching_link}
            className="text-white tablet:w-full mobile:w-full tablet:mt-[6px] mobile:mt-[6px] bg-yellow700 flex justify-center items-center web:ml-[17px] font-bold text-sm py-[6px] px-[12px] rounded-[5px]"
          >
            미션해결하러 가기 Go!
          </a>
          {lessonDetail?.id === 169 && (
            <span className="ml-4">수강권 코드 : sbc2022</span>
          )}
        </div>
      )}
    </div>
  );
};

export default Video;
