import { css } from "@emotion/react";

import cta1 from "../../assets/images/makeyourdream/cta1.png";
import cta1Dochimom from "../../assets/images/makeyourdream/cta1_dochimom.png";
import image1 from "../../assets/images/makeyourdream/image1.png";
import image2 from "../../assets/images/makeyourdream/image2.png";
import image3 from "../../assets/images/makeyourdream/image3.png";
import image4 from "../../assets/images/makeyourdream/image4.png";
import image5 from "../../assets/images/makeyourdream/image5.png";
import image6 from "../../assets/images/makeyourdream/image6.png";
import image7 from "../../assets/images/makeyourdream/image7.png";
import image7Dochimom from "../../assets/images/makeyourdream/image7_dochimom.png";
import image8 from "../../assets/images/makeyourdream/image8.png";
import image9 from "../../assets/images/makeyourdream/image9.png";
import image10 from "../../assets/images/makeyourdream/image10.png";
import image1bg from "../../assets/images/makeyourdream/image1_bg.png";
import clipLink from "../../assets/images/makeyourdream/clip_link.svg";
import kakaoShare from "../../assets/images/makeyourdream/kakao_share.svg";
import movingWordWeb from "../../assets/images/makeyourdream/moving_word_web.png";
import movingWordMobile from "../../assets/images/makeyourdream/moving_word_mobile.png";
import { doCopy, setReturnSession, setUtm } from "../../utils";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import useDeviceCheck from "../../hooks/useDeviceCheck";

const useKakaoShare = ({ isDochimom }) => {
  const kakaoJsKey = process.env.KAKAO_JS_KEY;

  if (Kakao.isInitialized() === false) {
    Kakao.init(kakaoJsKey);
  }

  const utmObj = {
    utm_source: "externalsharing",
    utm_medium: "kakao",
    utm_campaign: "makeyourdream",
  };
  const url = setUtm(utmObj);

  return Kakao.Link.sendDefault({
    objectType: "feed",
    content: {
      title: isDochimom
        ? "꿈업일치 패키지 42% 할인 진행중!"
        : "꿈업일치 패키지 20% 할인 진행중!",
      imageUrl:
        "https://d3hox5bpjgs2zh.cloudfront.net/uploads/editor/admin_user/4/7c6fd1c6-f2de-496c-aaa2-03fc882e5a01",
      link: {
        mobileWebUrl: url,
        webUrl: url,
      },
      imageWidth: 644,
      imageHeight: 600,
    },
    buttons: [
      {
        title: isDochimom
          ? "꿈업일치 패키지 42% 할인 진행중!"
          : "꿈업일치 패키지 20% 할인 진행중!",
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
    ],
  });
};

const Content = ({ handleSubmit, packageInfo, isDochimom }) => {
  const { isUserSignedIn } = useUserSignedIn();
  const device = useDeviceCheck();

  return (
    <div className="w-full flex flex-col">
      <section
        id="section1"
        css={css`
          background-image: url(${image1bg});
          background-repeat: repeat;
        `}
      >
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image1} alt="image1" />
        </div>
      </section>
      <section
        id="section2"
        className="bg-white flex items-center justify-center py-[46px]"
      >
        <div className="mx-auto max-w-[650px] px-[30px] bg-white">
          <button
            type="button"
            className="w-full"
            id="makeyourdream-cta_top"
            onClick={() => {
              isUserSignedIn ? handleSubmit(packageInfo) : setReturnSession();
            }}
          >
            <img
              className="w-full"
              src={isDochimom ? cta1Dochimom : cta1}
              alt="cta1"
            />
          </button>
        </div>
      </section>

      <section id="section13" className="bg-[#F5F5F5]">
        <div className="max-w-[650px] mx-auto">
          <img
            className="w-full"
            src={isDochimom ? image7Dochimom : image7}
            alt="image7"
          />
        </div>
      </section>

      <section id="section4" className="bg-black">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image3} alt="image3" />
        </div>
      </section>
      <section id="section15" className="bg-black">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image10} alt="image10" />
        </div>
      </section>
      <section id="section11" className="bg-[#F5F5F5]">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image8} alt="image8" />
        </div>
      </section>
      <section
        id="section12"
        className="bg-[#F5F5F5] web:pb-[100px] tablet:pb-[37px] mobile:pb-[37px]"
      >
        <div className="max-w-[650px] mx-auto web:px-[50px] tablet:px-[27px] mobile:px-[27px] flex flex-col justify-center items-center">
          <video
            width="100%"
            height="100%"
            controls
            loop
            muted
            playsInline
            controlsList="nodownload"
          >
            <source
              src="https://d3hox5bpjgs2zh.cloudfront.net/uploads/content_file/lecture/admin_user/4/9311e50d-0723-4879-8c74-9d67d0d8f201"
              type="video/mp4"
            />
          </video>
          <div className="web:text-xl tablet:text-sm mobile:text-sm text-gray99 web:mt-[25px] tablet:mt-[20px] mobile:mt-[20px]">
            *실제 초3 학생이 수행한 꿈업일치 최종 과제입니다.
          </div>
        </div>
      </section>
      <section id="section14">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image9} alt="image9" />
        </div>
      </section>
      <section id="section5">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image4} alt="image4" />
        </div>
      </section>
      <section
        id="section6"
        className="web:mb-[130px] tablet:mb-[65px] mobile:mb-[65px]"
      >
        <div className="mx-auto web:h-[113px] tablet:h-[89px] mobile:h-[89px] overflow-hidden border-b border-t py-[21px]">
          <div
            className="web:h-[72px] tablet:h-[45px] mobile:h-[45px] web:w-[6180px] tablet:w-[3982px] mobile:w-[3982px]"
            css={css`
              @keyframes slide {
                0% {
                  transform: translate(0);
                }
                100% {
                  transform: translate(
                    ${device === "web" ? "-3090px" : "-1991px"}
                  ); /* The image width */
                }
              }

              background: url(${device === "web"
                  ? movingWordWeb
                  : movingWordMobile})
                repeat-x;
              animation: slide 25s linear infinite;
            `}
          ></div>
        </div>
      </section>
      <section id="section7">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image5} alt="image5" />
        </div>
      </section>

      <section id="section8">
        <div className="max-w-[650px] mx-auto">
          <img className="w-full" src={image6} alt="image6" />
        </div>
      </section>

      <section id="section9" className="bg-black">
        <div className="max-w-[650px] mx-auto py-[50px] flex flex-col items-center justify-center">
          <span className="text-white font-[300] web:text-[28px] tablet:text-xl mobile:text-xl">
            특별한 혜택, 같이 나눠요
          </span>
          <div className="flex web:mt-[25px] tablet:mt-[15px] mobile:mt-[15px]">
            <button
              className="flex flex-col items-center justify-center web:mr-[41px] tablet:mr-[30px] mobile:mr-[30px]"
              type="button"
              id="KakaoExternalSharing"
              onClick={() => {
                useKakaoShare({ isDochimom: isDochimom });
              }}
            >
              <img src={kakaoShare} alt="카카오공유하기" className="w-[80px]" />
              <span className="text-[15px] font-[100] text-[#FFFFFF] web:mt-[12px] tablet:mt-2 mobile:mt-2">
                카카오톡 공유
              </span>
            </button>
            <button
              className="flex flex-col items-center justify-center"
              type="button"
              id="LinkExternalSharing"
              onClick={() => {
                const utmObj = {
                  utm_source: "externalsharing",
                  utm_medium: "viral",
                  utm_campaign: "",
                };
                doCopy(setUtm(utmObj));
              }}
            >
              <img src={clipLink} alt="카카오공유하기" className="w-[80px]" />
              <span className="ext-[15px] font-[100] text-[#FFFFFF] web:mt-[12px] tablet:mt-2 mobile:mt-2">
                링크 복사
              </span>
            </button>
          </div>
        </div>
      </section>
      <section id="section10" className="bg-gray33 text-gray99">
        <div className="max-w-[650px] mx-auto flex flex-col py-[50px] px-[39px]">
          <span className="font-bold web:text-[22px]">유의사항</span>
          <span className="web:text-lg tablet:text-sm mobile:text-sm leading-[25px] mt-[25px]">
            {`· 해당 패키지는 <Let's 스타트업 : 꼬마 CEO를 위한 경제경영 클래스> 와 <직업체험 글쓰기 활동과 함께하는 '맛있는 초등 스피치'> 클래스가 포함된 클래스 입니다.`}{" "}
            <br />
            {`· 각 클래스마다 워크북이 포함되어 있으며, 구매 후 마이페이지에서 다운로드 받으실 수 있습니다.`}{" "}
            <br />
            {`· 클래스 수강 기간은 기존보다 6개월 연장 혜택이 적용되며, 두 클래스 모두 12개월간 수강하실 수 있습니다.`}{" "}
            <br />
            {`· 상황에 따라 사전 공지 없이 얼리버드 기간이 조기 마감될 수 있습니다.`}{" "}
            <br />
            {`· 본 상품은 쿠폰 사용이 불가능한 상품입니다.`} <br />
            {`· 패키지의 구성 클래스를 변경할 수 없습니다.`} <br />
          </span>
          <span className="font-bold web:text-[22px] mt-[50px]">환불 규정</span>
          <span className="web:text-lg tablet:text-sm mobile:text-sm leading-[25px] mt-[25px]">
            {`· 이 클래스는 두 개의 클래스가 포함되어 있는 패키지 클래스 입니다. 환불 시 일부 강의만 환불하는 것은 불가능하며 전체 환불만 가능합니다.`}{" "}
            <br />
            {`· 두 개의 클래스 모두 수강하지 않은 경우, 강의 수강 시작일(결제일) 로부터 7일 이내인 경우 전액 환불해드립니다.`}{" "}
            <br />
            {`· 정상 수강 기간 (6개월) 또는 수강 진도 1/3 경과 전 결제 대금에 2/3에 해당하는 금액을 환불해드립니다.`}{" "}
            <br />
            {`· 정상 수강 기간 (6개월) 또는 수강 진도 1/2 경과 전 결제 대금에 1/3에 해당하는 금액을 환불해드립니다.`}{" "}
            <br />
            {`· 정상 수강 기간 (6개월) 또는 수강 진도 1/2 경과 후 환불이 불가합니다.`}{" "}
            <br />
            {`· 반품 및 환불 문의는 채널톡을 통해 접수 가능합니다.`} <br />
          </span>
          <span className="web:text-lg leading-[25px] mt-[25px]">
            ※ 유의사항 미 숙지로 인한 불이익에 대해 책임지지 않습니다
          </span>
        </div>
      </section>
    </div>
  );
};

export default Content;
