import { color } from "../../tailwindStyle";
import { css } from '@emotion/react';
import { MOBILE_MAX_WIDTH } from "../../constant";

//pageBtnStyle로 antd pagination을 감싸기
export const pageBtnStyle = css`
.ant-pagination {
  .reset-component();

  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    font-size: 12px;
  }

  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 14px;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }

  &-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }

  &-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: white;
    border: 1px solid ${color.gray200};
    border-radius: 0;
    outline: 0;
    cursor: pointer;
    user-select: none;

    a {
      display: block;
      padding: 0 6px;
      color: black;
      transition: none;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      border-color: ${color.navy900};
      transition: all 0.3s;

      a {
        color: ${color.navy900};
      }
    }

    &:focus-visible {
      border-color: ${color.navy900};
      transition: all 0.3s;

      a {
        color: ${color.navy900};
      }
    }

    &-active {
      font-weight: bold;
      border-color: ${color.navy900};

      a {
        color: ${color.navy900};
      }
    }
  }

  &-jump-prev,
  &-jump-next {
    outline: 0;
    .ant-pagination-item-container {
      position: relative;

      .ant-pagination-item-link-icon {
        color: ${color.navy900};
        letter-spacing: -1px;
        opacity: 0;
        transition: all 0.2s;

        &-svg {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }

      .ant-pagination-item-ellipsis {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: auto;
        color: ${color.gray600};
        letter-spacing: 2px;
        text-align: center;
        text-indent: 0.13em;
        opacity: 1;
        transition: all 0.2s;
      }
    }

    &:hover {
      .ant-pagination-item-link-icon {
        opacity: 1;
      }
      .ant-pagination-item-ellipsis {
        opacity: 0;
      }
    }

    &:focus-visible {
      .ant-pagination-item-link-icon {
        opacity: 1;
      }
      .ant-pagination-item-ellipsis {
        opacity: 0;
      }
    }
  }

  &-prev,
  &-jump-prev,
  &-jump-next {
    margin-right: 8px;
  }

  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: black;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s;
  }

  &-prev,
  &-next {
    outline: 0;

    button {
      color: black;
      cursor: pointer;
      user-select: none;
    }

    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0;
      text-align: center;
      border: 1px solid ${color.gray200};
      border-radius: 0;e
      outline: none;
      transition: all 0.3s;
    }

    &:focus-visible .ant-pagination-item-link {
      color: ${color.navy900};
      border-color: ${color.navy900};
    }

    &:hover .ant-pagination-item-link {
      color: ${color.navy900};
      border-color: ${color.navy900};
    }
  }

  &-disabled {
    &,
    &:hover {
      cursor: not-allowed;
      .ant-pagination-item-link {
        color: ${color.gray600};
        border-color: ${color.navy900};
        cursor: not-allowed;
      }
    }

    &:focus-visible {
      cursor: not-allowed;
      .ant-pagination-item-link {
        color: ${color.gray600};
        border-color: ${color.navy900};
        cursor: not-allowed;
      }
    }
  }

  &-slash {
    margin: 0 10px 0 5px;
  }

  // ============================ Disabled ============================
  &&-disabled {
    cursor: not-allowed;

    .ant-pagination-item {
      background: @disabled-bg;
      border-color: ${color.navy900};
      cursor: not-allowed;

      a {
        color: ${color.gray600};
        background: transparent;
        border: none;
        cursor: not-allowed;
      }

      &-active {
        background: @pagination-item-disabled-bg-active;

        a {
          color: @pagination-item-disabled-color-active;
        }
      }
    }

    .ant-pagination-item-link {
      color: ${color.gray600};
      background: @disabled-bg;
      border-color: ${color.navy900};
      cursor: not-allowed;
      .ant-pagination-simple& {
        background: transparent;
      }
    }

    .ant-pagination-item-link-icon {
      opacity: 0;
    }

    .ant-pagination-item-ellipsis {
      opacity: 1;
    }

    .ant-pagination-simple-pager {
      color: ${color.gray600};
    }
  }
}

@media only screen and (max-width: 1023px) {
  .ant-pagination-item {
    &-after-jump-prev,
    &-before-jump-next {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-pagination-options {
    display: none;
  }
}`