import { useContext, useRef } from "react";
import { QnasContext } from "../../pages/Lessons";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { findIndex } from "lodash";
import { postLessonReply } from "../../api";
import TextArea from "textarea-autosize-reactjs";

export const ReplyInputForm = ({
  commentable_id,
  commentable_type,
  closeReplyForm,
}) => {
  const textareaRef = useRef(null);
  const { qnas, setQnas } = useContext(QnasContext);

  const formSchema = Yup.object().shape({
    content: Yup.string()
      .required("글을 입력하셔야 합니다.")
      .min(1, "너무 짧습니다!"),
  });

  const formik = useFormik({
    initialValues: {
      content: "",
    },
    validationSchema: formSchema,
    onSubmit: (values, { resetForm }) => {
      postLessonReply({
        commentable_type: commentable_type,
        commentable_id: commentable_id,
        body: values.content,
      }).then((response) => {
        resetForm();
        textareaRef.current.style.height = "52px";
        if (
          commentable_type === "Lesson" ||
          commentable_type === "Assignment"
        ) {
          setQnas([response?.data, ...qnas]);

          Swal.fire("성공적으로 등록 되었어요");
        } else if (commentable_type === "Comment") {
          const editedIndex = findIndex(qnas, { id: commentable_id });

          setQnas([qnas[editedIndex].comments.push(response?.data)]);

          setQnas([...qnas]);

          if (!!closeReplyForm) {
            closeReplyForm();
          }

          Swal.fire("성공적으로 등록 되었어요");
        }
      });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="border rounded-[8px] border-gray100 w-full items-center flex px-[13px] py-2"
    >
      <TextArea
        id=""
        type="text"
        className="w-full pr-5 mt-1 outline-none resize-none"
        name="content"
        ref={textareaRef}
        onChange={formik.handleChange}
        value={formik.values.content}
        placeholder={`${
          commentable_type === "Lesson"
            ? "강의 내용 중에 궁금한 것이 있을 때는? 선생님께 바로 질문해요!"
            : ""
        }`}
      />
      <button
        type="submit"
        className="flex items-center justify-center font-bold text-black web:text-sm tablet:text-xs mobile:text-xs rounded-[21px] flex-shrink-0 px-[11px] h-[28px]"
        disabled={formik.values.content === "" ? true : false}
      >
        등록
      </button>
    </form>
  );
};
