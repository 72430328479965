const PaymentCardNone = () => {
  return (
    <div className="flex flex-col p-3 mt-1 bg-white border rounded-lg card border-gray100">
      <img src={require("main/thumbnail_pencil.jpg").default} alt="" />
      <div className="w-full text-sm font-bold">주문/배송 정보 내역이 없습니다</div>
    </div>
  );
}

export default PaymentCardNone;
