import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { useState, useEffect } from "react";
import StarRankGroup from "../shared/StarRankGroup";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { color } from "../../tailwindStyle";
import TextTruncate from "react-text-truncate";

SwiperCore.use([Pagination]);

const ReviewSwiper = ({ selectReview }) => {
  const [bestReviews, setBestReviews] = useState(null);

  const deviceCheck = useDeviceCheck();

  useEffect(() => {
    setBestReviews(selectReview?.reviews);
  }, []);

  const bestReviewSwiperStyle = css`
    .swiper-wrapper {
      max-width: 500px;
    }

    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      padding-bottom: 40px;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      width: 90vw;
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin: 0 7px;
      }
      .swiper-pagination-bullet-active {
        background-color: ${color.navy900};
      }

      .swiper-pagination-bullet {
        &:not(.swiper-pagination-bullet-active) {
          &:hover {
            background-color: #7a8e87 !important;
          }
        }
      }
    }
  `;

  return (
    <Swiper
      breakpoints={{
        [MOBILE_MAX_WIDTH]: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1.15,
        },
      }}
      spaceBetween={15}
      css={bestReviewSwiperStyle}
      pagination={
        (deviceCheck == "web" || deviceCheck === "tablet") && {
          clickable: true,
        }
      }
    >
      {bestReviews?.map((item) => {
        return (
          <SwiperSlide key={`베스트리뷰${item?.id}`}>
            <div className="flex flex-col border border-gray200 rounded-[20px] p-[24px] max-w-[500px]">
              <div className="flex items-center mb-2 border-b-1 border-gray100">
                <span className="mr-4 font-bold">
                  {item?.user?.nickname || item?.user?.name}
                </span>

                <StarRankGroup
                  webSize="20"
                  mobileSize="20"
                  value={item?.rating}
                />
                <span className="ml-auto text-sm text-gray500">
                  {moment(item?.created_at).format("YYYY-MM-DD")}
                </span>
              </div>
              <TextTruncate
                line={item?.images?.length === 0 ? 8 : 4}
                truncateText="..."
                element="span"
                text={item?.body}
                textTruncateChild={
                  <span
                    style={{
                      textDecoration: "underline",
                      color: color.blue300,
                    }}
                  >
                    더보기
                  </span>
                }
              />
              <div className="flex mt-4">
                {item?.thumb_images?.map((image, index) => {
                  return (
                    <div
                      key={`베스트리뷰${item.id}_${index}`}
                      css={css`
                        width: 100px;
                        height: 100px;
                        background-image: url(${image});
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        margin-right: 1rem;
                      `}
                    ></div>
                  );
                })}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ReviewSwiper;
