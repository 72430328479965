import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Content } from "../components/lecturesParents/index";
import BannerSwiper from "../components/shared/BannerSwiper";
import { getParentBanners, getParentLectures } from "../api";

const LecturesParents = () => {
  const [banners, setBanners] = useState(null);
  useEffect(() => {
    getParentBanners().then((response) => {
      setBanners(response?.data);
    });
  }, []);

  const [lectures, setLectures] = useState(null);
  useEffect(() => {
    getParentLectures().then((response) => {
      setLectures(response?.data);
    });
  }, []);

  return (
    <Layout>
      <BannerSwiper banners={banners} />
      <Content lectures={lectures} />
    </Layout>
  );
};

export default LecturesParents;
