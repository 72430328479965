import { useState } from "react";
import { css } from "@emotion/react";
import { color } from "../../tailwindStyle";
import { EditContent } from "./EditContent";
import { AssignmentLists } from "./AssignmentLists";

const tabBtnsStyle = css`
  &.active {
    color: black !important;
  }

  &.active::after {
    position: absolute;
    content: "";
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 5.5px;
    border-radius: 3.5px;
    background-color: ${color.yellow600};
    border: 1px solid ${color.yellow600};
  }
`;

export const EditContentMobile = ({ lessonId, assignmentId }) => {
  const tab = new URLSearchParams(window.location.search);

  const [tabSelect, setTabSelect] = useState(tab.get("tab") || "editMission"); // otherMission, editMission

  const setPushState = (stateName) => {
    setTabSelect(stateName);
    window.history.replaceState(
      stateName,
      null,
      `/lessons/${lessonId}/assignments/new?tab=${stateName}`
    );
  };

  const TabBtn = ({ name, tabValue, tailwindStyle }) => {
    return (
      <div
        css={tabBtnsStyle}
        className={`text-lg relative w-full cursor-pointer flex items-center justify-center ${
          tabSelect === tabValue
            ? "active font-bold text-black"
            : "font-medium text-gray400"
        } ${tailwindStyle}`}
        onClick={() => {
          setPushState(tabValue);
        }}
      >
        {name}
      </div>
    );
  };

  return (
    <div className="flex flex-col web:w-[354px] tablet:w-full tablet:min-w-[330px] mobile:min-w-[330px] mobile:w-full flex-shrink-0 mt-[9px]">
      <div className="grid grid-cols-2 gap-4 mt-[17px]">
        <TabBtn name="미션 수정하기" tabValue="editMission" />
        <TabBtn name="미션 게시판" tabValue="otherMission" />
      </div>

      {tabSelect === "editMission" && (
        <EditContent lessonId={lessonId} assignmentId={assignmentId} />
      )}
      {tabSelect === "otherMission" && <AssignmentLists lessonId={lessonId} />}
    </div>
  );
};
