import moment from "moment";

const Notice = ({ live_lectures, notice }) => {
  return (
    <div className="max-w-[1200px] web:w-full tablet:w-screen mobile:w-screen bg-black bg-opacity-[0.03] mx-auto web:mt-[60px] tablet:mt-[40px] mobile:mt-[40px] web:mb-[73px] mobile:mb-[40px]">
      <div className="default_margin tablet:max-w-[640px] mobile:max-w-[640px] web:py-[59px] tablet:py-[38px] web:px-[124px] mobile:py-[38px] grid web:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 web:gap-4">
        <div className="flex flex-col">
          <span className="font-bold web:text-xl">
            회차별 라이브 일정 [{live_lectures?.length}회]
          </span>
          <div className="flex flex-col mt-[12px]">
            {live_lectures?.map((lecture, index) => {
              return (
                <span
                  key={lecture?.id}
                  className={`text-sm font-medium mt-[13px] ${
                    lecture.is_purchase ? "" : "text-gray400"
                  }`}
                >
                  {`${index + 1}회차`}. [ {lecture?.teacher_name} ]{" "}
                  <span
                    className={`${lecture.is_purchase ? "text-blue-base" : ""}`}
                  >
                    {" "}
                    {moment(lecture?.start_at)
                      .locale("ko")
                      .format("YY.MM.DD (ddd) HH:mm")}{" "}
                    - {moment(lecture?.end_at).locale("ko").format("HH:mm")}
                  </span>
                </span>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col tablet:mt-[69px] mobile:mt-[69px]">
          <span className="font-bold web:text-xl">공지사항</span>
          <div className="mt-[23px] text-sm">
            <span dangerouslySetInnerHTML={{ __html: notice }}></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
