import congratu from "../../assets/icons/congratu.svg";

const MyDnavLectureCardNone = () => {
  return (
    <div className="mt-[14px] rounded-lg border-2 border-gray100 flex justify-center items-center flex-col w-full h-[212px]">
      <img src={congratu} alt="내리뷰없음" className="w-[50px] h-[49px]" />
      <span className="text-sm mt-[20px] text-gray400">
        구매한 디냅 클래스가 없습니다.
      </span>
    </div>
  );
};

export default MyDnavLectureCardNone;
