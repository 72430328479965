import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Content } from "../components/lecturesKids/index";
import BannerSwiper from "../components/shared/BannerSwiper";
import { getKidBanners } from "../api";

const LecturesKids = () => {
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    getKidBanners().then((response) => {
      setBanners(response?.data);
    });
  }, []);

  return (
    <Layout>
      <BannerSwiper banners={banners} />
      <Content />
    </Layout>
  );
};

export default LecturesKids;
