import moment from "moment";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import { color } from "../../tailwindStyle";
import { deleteAssignment } from "../../api";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import Popup from "reactjs-popup";

const optionStyle = css`
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
`;

const editorBlockStyle = css`
  word-break: break-all;
  a,
  a:link,
  a:visited {
    color: revert;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`;

export const ShowContent = ({ lessonId, assignment }) => {
  const { currentUser, isUserLoading } = useGetCurrentUser();
  const deviceCheck = useDeviceCheck(null);

  const deleteMission = () => {
    Swal.fire({
      title: "미션을 삭제하시겠어요? 삭제하시면 복원 할 수 없습니다.",
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: "삭제",
      denyButtonText: "취소",
      confirmButtonColor: color["red500"],
      denyButtonColor: color["blue500"],
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAssignment({ assignmentId: assignment?.id }).then(() => {
          window.location.href = `/lessons/${lessonId}?tab=assignment`;
        });
      }
    });
  };

  return (
    !isUserLoading && (
      <div className="flex flex-col">
        <div className="mb-[9px]">
          <span className="font-medium tablet:text-sm mobile:text-sm">
            &nbsp;&nbsp;&nbsp;
          </span>

          {deviceCheck === "web" && (
            <div className="flex items-center justify-between">
              {assignment?.user?.id === currentUser?.id ? (
                <span className="font-bold web:text-xl">내가 한 미션</span>
              ) : (
                <span className="font-bold web:text-xl">미션 게시판</span>
              )}

              <a
                href={`/lessons/${lessonId}?tab=assignment`}
                className="text-white web:px-5 tablet:px-[7px] mobile:px-[7px] web:py-[6px] rounded-[5px] font-bold web:text-sm tablet:text-xs mobile:text-xs bg-yellow700 p-[6px]"
              >
                강의로 돌아가기
              </a>
            </div>
          )}
        </div>

        <div className="rounded-[10px] border border-gray100 py-[22px] w-full">
          <div className="flex items-center px-[26px] border-b border-gray100 pb-[22px]">
            <span className="font-bold truncate web:text-lg">
              {assignment?.title}
            </span>
            {deviceCheck === "web" && (
              <span className="ml-auto text-xs font-bold text">
                {assignment?.user?.nickname || assignment?.user?.name}
              </span>
            )}

            <span className="text-xs web:ml-2 tablet:ml-auto mobile:ml-auto text-gray400">
              {moment(assignment?.created_at).format("YYYY-MM-DD")}
            </span>
            {assignment?.user?.id === currentUser?.id && (
              <Popup
                trigger={
                  <span className="pl-4 cursor-pointer text-gray400">
                    &#183;&#183;&#183;
                  </span>
                }
                position="bottom center"
                on={["hover", "click"]}
                closeOnDocumentClick
              >
                <div
                  className="absolute bg-white font-bold w-[180px] rounded-[6px] right-0"
                  css={optionStyle}
                >
                  <a
                    href={`/assignments/${assignment?.id}/edit`}
                    className="h-[55px] flex items-center justify-center tablet:text-sm mobile:text-sm border border-b border-gray100 px-[6px] decoration-none"
                  >
                    수정하기
                  </a>
                  <span
                    className="h-[55px] flex items-center justify-center tablet:text-sm mobile:text-sm px-[6px] decoration-none cursor-pointer"
                    onClick={deleteMission}
                  >
                    삭제하기
                  </span>
                </div>
              </Popup>
            )}
          </div>

          <div className="pt-[22px] px-[26px]">
            <div
              css={editorBlockStyle}
              dangerouslySetInnerHTML={{ __html: assignment?.content }}
            ></div>
          </div>
        </div>
      </div>
    )
  );
};
