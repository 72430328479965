import { useFormik } from "formik";
import { UsersInput } from "../users/UsersInput";
import * as Yup from "yup";
import Swal from "sweetalert2";
import AuthPhoneNumber from "../users/AuthPhoneNumber";
import { useState, useEffect } from "react";
import { UsersInputLabel } from "../users/UsersInputLabel";
import { patchChangePw } from "../../api";
import { useRef } from "react";
import { Auth } from "aws-amplify";

const Content = () => {
  const [encryptedKey, setEncryptedKey] = useState(null);

  const validationSchema = Yup.object().shape({
    newPw: Yup.string()
      .min(6, "비밀번호는 6자 이상이어야 합니다.")
      .required("새 비밀번호를 입력해주세요."),
    newPwConfirm: Yup.string()
      .oneOf(
        [Yup.ref("newPw")],
        "비밀번호가 일치하지 않습니다. 다시 확인해주세요."
      )
      .required("비밀번호 확인을 해주셔야 합니다."),
  });

  const formik = useFormik({
    initialValues: {
      encryptedKey: "",
      newPw: "",
      newPwConfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      patchChangePw({
        encryptedKey: values.encryptedKey,
        password: values.newPw,
        passwordConfirm: values.newPwConfirm,
      })
        .then(() => {
          Swal.fire({
            title: "비밀번호 변경 성공",
            confirmButtonText: "확인",
            willClose: () => {
              Auth.signOut({ global: true }).then(() => {
                location.href = "/users/sign_in";
              });
            },
          });
        })
        .catch((error) => {
          switch (error.response.data.message) {
            case "not_matching":
              Swal.fire({
                icon: "error",
                text: "현재 로그인된 계정과 전화번호가 일치하지 않습니다. 처음부터 다시 시도해주세요.",
                confirmButtonText: "확인",
                willClose: () => (location.href = "/mypage/edit"),
              });
              break;
            default:
              Swal.fire({
                icon: "error",
                title: "잠시 후 다시 시도해주세요.",
                text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
                confirmButtonText: "확인",
              });
              break;
          }

          setSubmitting(false);
        });
    },
  });

  const pwInputRef = useRef(null);

  useEffect(() => {
    if (!!encryptedKey) {
      pwInputRef.current.focus();
      formik.setFieldValue("encryptedKey", encryptedKey);
    }
  }, [encryptedKey]);

  return (
    <div className="max-w-[500px] mx-auto">
      <AuthPhoneNumber setEncryptedKey={setEncryptedKey} />

      {!!encryptedKey && (
        <form onSubmit={formik.handleSubmit}>
          <UsersInputLabel inputId="newPw" labelName="새 비밀번호" />

          <UsersInput
            value={formik.values.newPw}
            onChange={formik.handleChange}
            blur={formik.handleBlur}
            inputId="newPw"
            inputType="password"
            classOption={"mb-3"}
            inputRef={pwInputRef}
          />

          {formik.errors.newPw && formik.touched.newPw && (
            <div className="mb-3 text-xs text-red400">
              {formik.errors.newPw}
            </div>
          )}

          <UsersInputLabel
            inputId="newPwConfirm"
            labelName="새 비밀번호 확인"
          />

          <UsersInput
            value={formik.values.newPwConfirm}
            onChange={formik.handleChange}
            blur={formik.handleBlur}
            inputId="newPwConfirm"
            inputType="password"
            classOption={"mb-3"}
          />

          {formik.errors.newPwConfirm && formik.touched.newPwConfirm && (
            <div className="mb-3 text-xs text-red400">
              {formik.errors.newPwConfirm}
            </div>
          )}

          <button
            type="submit"
            disabled={
              !formik.values.newPw ||
              !formik.values.newPwConfirm ||
              formik.isSubmitting
            }
            className="text-sm flex items-center justify-center text-white bg-black rounded-[5px] h-[45px] mt-4 shrink-0 w-full"
          >
            비밀번호 재설정
          </button>
        </form>
      )}
    </div>
  );
};

export default Content;
