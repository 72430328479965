import closeSm from "../../assets/icons/closeSm.svg";
import { DrawerContext, ModalContext } from "../../pages/SpecialEvent";
import { useContext, useState } from "react";
import moment from "moment";
import { addComma } from "../../utils";
import { find } from "lodash";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { postOrderOption } from "../../api";

const ActionSheet = ({ live_lectures }) => {
  const deviceCheck = useDeviceCheck();
  const { closeSheetDrawer } = useContext(DrawerContext);
  const { closeModal } = useContext(ModalContext);

  const [selectOption, setSelectOption] = useState(null);
  const [selectText, setSelectText] = useState("옵션");

  const findSelectLecture = () => {
    if (!selectOption) return null;

    const result = find(live_lectures, { id: Number(selectOption) });
    return result;
  };

  const getPrice = () => {
    if (findSelectLecture() !== null) {
      return findSelectLecture()?.price;
    } else {
      return 0;
    }
  };

  const submitPost = () => {
    const postObject = {
      order: {
        orderable_type: "LiveLecture",
        orderable_id: findSelectLecture()?.id,
        order_type: "none",
        price: findSelectLecture()?.price,
      },
    };

    postOrderOption(postObject).then((response) => {
      location.href = `/orders/${response?.data?.order_id}`;
    });
  };

  return (
    <div className="flex flex-col web:min-w-[500px]">
      <div className="flex items-center justify-between mx-[15px]">
        <span className="text-lg font-bold">클래스 신청하기</span>
        <img
          src={closeSm}
          alt="닫기"
          className="w-[10px] object-contain"
          onClick={deviceCheck === "web" ? closeModal : closeSheetDrawer}
        />
      </div>

      <span className="text-gray400 font-medium text-sm mt-[3px] mx-[15px]">
        클래스 구매는 한 번에 1개씩 결제 가능합니다.
      </span>

      <div className="flex flex-col">
        <select
          name="selectOption"
          className={`rounded-[7px] border-[0.75px] mx-[15px] py-[7px] mt-[10px] px-[13px] text-sm h-[35px] ${
            selectOption !== null ? "text-blue-base" : ""
          }`}
          value={selectText}
          onChange={(e) => {
            setSelectOption(e.currentTarget.value);
          }}
        >
          <option value="">옵션</option>
          {live_lectures.map((live_lecture, indexNum) => {
            return (
              <option
                value={live_lecture?.id}
                key={live_lecture?.id}
                disabled={!live_lecture?.btnStatus}
              >
                [{indexNum + 1}주차]{" "}
                {moment(live_lecture?.start_at)
                  .locale("ko")
                  .format("MM.DD (ddd) LT")}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {live_lecture?.teacher_name}
              </option>
            );
          })}
        </select>

        {findSelectLecture() !== null && (
          <div className="border-[0.75px] border-gray100 rounded-[7px] flex mx-[15px] mt-[10px] px-[14px] py-[9px]">
            <div className="flex flex-col justify-between w-full">
              <div className="flex items-center justify-between text-sm">
                <span className="font-medium">
                  {findSelectLecture()?.title}
                </span>
                <img
                  onClick={() => setSelectOption(null)}
                  src={closeSm}
                  alt="닫기"
                  className="w-2 h-2 cursor-pointer"
                />
              </div>
              <div className="flex justify-between mt-[10px]">
                <span className="font-bold">
                  {moment(findSelectLecture()?.start_at).format(
                    "YYYY.MM.DD HH:mm"
                  )}{" "}
                  - {moment(findSelectLecture()?.end_at).format("HH:mm")}
                </span>
                <span>{addComma(findSelectLecture()?.price)} 원</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <hr className="w-full mt-[15px]" />

      <div className="flex items-center justify-between mx-[15px] mt-[13px]">
        <span className="text-sm text-gray400">총 상품 금액</span>
        <span className="font-bold text-blue-base">
          {addComma(getPrice())} 원
        </span>
      </div>

      <button
        onClick={submitPost}
        className="mx-[15px] text-white  rounded-[9px] justify-center items-center py-[11px] mt-[14px] bg-blue-base"
        disabled={selectOption === null ? true : false}
      >
        수강 신청하기
      </button>
    </div>
  );
};

export default ActionSheet;
