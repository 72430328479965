import { css } from "@emotion/react";
import { useState } from "react";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { color } from "../../tailwindStyle";

const boxStyle1 = css`
  padding: 22px 24px 18px 21px;
  background-color: ${color.gray25};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  border-radius: 5px;
`;
const boxStyle2 = css`
  padding: 22px 24px 18px 21px;
  background-color: ${color.gray25};
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
`;

const arrowUpCaution = css`
  border: solid #9f9f9f;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  vertical-align: middle;
  margin-top: 7px;
  width: 14px;
`;

const arrowDownCaution = css`
  border: solid #9f9f9f;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  vertical-align: middle;
  margin-bottom: 8px;
  width: 13px;
`;

const buttonFreeLecture = css`
  color: white;
  background-color: ${color["blue-base"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0 13px;
  line-height: 2.11;
  white-space: nowrap;
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    width: 120px;
    height: auto;
  }

  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 14px;
    margin-left: 1rem;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    font-size: 12.5px;
  }
`;

const CautionMobile = ({
  age_range,
  total_time,
  has_kit,
  free_link,
  notice,
}) => {
  const [isCautionSpread, setIsCautionSpread] = useState(true);

  return (
    <>
      <div css={boxStyle1}>
        <div className="flex flex-col text-[12px]">
          <div className="flex">
            <span className="font-bold">권장 연령</span>
            <span className="ml-4">{age_range}</span>
          </div>
          <div className="flex">
            <span className="font-bold">강의 구성</span>
            <span className="ml-4">{total_time}</span>
          </div>
          <div className="flex">
            <span className="font-bold">키트 유무</span>
            <span className="ml-4">{has_kit ? "키트 있음" : "키트 없음"}</span>
          </div>
        </div>
        {!!free_link && (
          <div className="flex items-center justify-center">
            <button css={buttonFreeLecture}>
              <a href={free_link} className="class_free_class">
                1강 무료체험 하기
              </a>
            </button>
          </div>
        )}
      </div>
      <div css={boxStyle2} className="overflow-auto">
        <div
          className="flex justify-between cursor-pointer"
          onClick={() => setIsCautionSpread(!isCautionSpread)}
        >
          <span className="font-bold">유의사항</span>
          <i css={isCautionSpread ? arrowUpCaution : arrowDownCaution}></i>
        </div>
        {isCautionSpread && (
          <div
            className="pt-4"
            css={css`
              p {
                white-space: normal !important;
              }
            `}
            dangerouslySetInnerHTML={{ __html: notice }}
          ></div>
        )}
      </div>
    </>
  );
};

export default CautionMobile;
