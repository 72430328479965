import React, { useEffect, useRef, useState } from "react";
import ReviewNewTitle from "../components/review/ReviewNewTitle";
import ReviewLecture from "../components/review/ReviewLecture";
import ReviewNewForm from "../components/review/ReviewNewForm";
import Swal from "sweetalert2";
import { getEncryptedReview } from "../api";

const ReviewNew = () => {
  const [decryptedInfo, setDecryptedInfo] = useState(null);
  const titleRef = useRef(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    getEncryptedReview({
      userId: urlParams.get("user_id"),
      reviewableId: urlParams.get("reviewable_id"),
      reviewableType: urlParams.get("reviewable_type"),
    })
      .then((response) => {
        setDecryptedInfo(response?.data);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "잘못된 주소로 접근했어요!",
          reverseButtons: true,
          showCancelButton: false,
          confirmButtonText: "메인 페이지로 이동",
        }).then(() => {
          location.href = "/";
        });
      });
  }, []);

  return (
    <>
      {!!decryptedInfo && (
        <div className="max-w-[640px] mx-auto">
          <ReviewNewTitle titleRef={titleRef} />
          <ReviewLecture
            titleRef={titleRef}
            titleText={decryptedInfo?.reviewable?.title}
            titleImg={decryptedInfo?.reviewable?.image?.thumb?.url}
          />
          <ReviewNewForm
            lectureType={decryptedInfo?.lecture_type}
            lectureId={decryptedInfo?.reviewable?.id}
            userId={decryptedInfo?.user_id}
            viewerType={decryptedInfo?.reviewable?.viewer_type}
          />
        </div>
      )}
    </>
  );
};

export default ReviewNew;
