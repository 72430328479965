import Popup from "reactjs-popup"; //전용 컴포넌트
import closeIcon from "../../assets/icons/close.svg";

export const StyledPopup = ({ trigger, content }) => {
  return (
    <Popup
      overlayStyle={{ background: "rgba(0,0,0,0.75)" }}
      contentStyle={{
        background: "white",
        maxWidth: "500px",
        maxHeight: "500px",
        overflow: "auto",
        paddingTop: "25px",
        paddingBottom: "33px",
        paddingLeft: "35px",
        paddingRight: "35px",
        borderRadius: "12px",
      }}
      trigger={trigger}
      modal
      nested
    >
      {(close) => (
        <div className="relative">
          {content}
          <img
            src={closeIcon}
            alt="공유하기끄기버튼"
            className="absolute right-0 w-4 h-4 cursor-pointer top-3"
            onClick={close}
          />
        </div>
      )}
    </Popup>
  );
};
