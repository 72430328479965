import RatingBar from "./RatingBar";
import { css } from "@emotion/react";
import StarRankGroup from "../shared/StarRankGroup";
import { useEffect, useState, useRef } from "react";
import { getReviews } from "../../api";

const ratingBodyStyle = css`
  .smallText {
    font-size: 12px;
    font-weight: 500;
  }
  .bigText {
    font-size: 40px;
    font-weight: 900;
  }
`;

const RatingSection = ({ metaRating, ratingAvg, targetId, targetType }) => {
  const [totalCount, setTotalCount] = useState(null);
  const textGroupRef = useRef(null);

  useEffect(() => {
    getReviews({ targetId: targetId, targetType: targetType }).then(
      (response) => {
        setTotalCount(response?.data?.meta?.total_count);
      }
    );
  }, []);

  return (
    <div
      css={ratingBodyStyle}
      className="flex justify-evenly items-center whitespace-nowrap pt-[42.7px] pb-[44.1px] mb-[30.6px] bg-gray25 rounded-[16px]"
    >
      <div className="flex flex-col items-center w-[114px]">
        <div
          className="flex items-baseline justify-between font-bold w-[114px]"
          ref={textGroupRef}
          css={css`
            font-family: "Roboto";
            line-height: normal;
          `}
        >
          <div className="text-black bigText">
            {!isNaN(ratingAvg?.toFixed(1)) ? ratingAvg?.toFixed(1) : ""}
          </div>
          <div className="text-gray400 smallText">
            총 {totalCount ?? 0}개
          </div>
        </div>
        <div className="w-full">
          {!isNaN(ratingAvg?.toFixed(1)) ? (
            <StarRankGroup
              value={ratingAvg}
              gapRatio="6px"
              webSize="18"
              mobileSize="18"
              widthRatio="100%"
            />
          ) : (
            <StarRankGroup
              value={5}
              gapRatio="6px"
              webSize="18"
              mobileSize="18"
              widthRatio="100%"
            />
          )}
        </div>
      </div>

      <div
        className="flex flex-col justify-between w-[120px]"
        css={css`
          .gapStyle + .gapStyle {
            margin-top: 2px;
          }
        `}
      >
        <RatingBar num={5} value={metaRating["5"] || 0} />
        <RatingBar num={4} value={metaRating["4"] || 0} />
        <RatingBar num={3} value={metaRating["3"] || 0} />
        <RatingBar num={2} value={metaRating["2"] || 0} />
        <RatingBar num={1} value={metaRating["1"] || 0} />
      </div>
    </div>
  );
};

export default RatingSection;
