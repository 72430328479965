import { css } from "@emotion/react";
import { useCookies } from "react-cookie";
import { useContext } from "react";

import { LayoutContext } from "../Layout";
import { CloseSm } from "./CloseSm";
import { useDiffTime } from "../../hooks/useDiffTime";

import closeSm from "../../assets/icons/close_sm.svg";

export const EventBannerTimer = ({ banner }) => {
  const { setEventBannerState: setEventBannerState } =
    useContext(LayoutContext);

  const { days, hours, minutes, seconds, duration, timeIsLoading } =
    useDiffTime({
      endTimeDate: banner?.end_at,
    });

  const [cookies, setCookie] = useCookies();

  const tomorrow = new Date();
  tomorrow.setFullYear(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate() + 1
  );

  const closeEventBanner = () => {
    setCookie("isEventBannerOpen", "false", {
      path: "/",
      expires: tomorrow,
    });

    setEventBannerState(false);
  };

  return !timeIsLoading && duration > 0 ? (
    <div
      className="w-screen h-[52px] flex items-center px-[15px]"
      css={css`
        background-color: ${banner?.background_color};
      `}
    >
      <div className="flex justify-between items-center mx-auto w-full max-h-[52px]">
        <div className="ml-4 w-full h-full max-w-[18px] max-h-[18px]">
          <img
            src={closeSm}
            alt="가상영역"
            className="w-full h-full max-w-[18px] max-h-[18px] min-w-[14px] min-h-[14px] invisible"
          />
        </div>
        <a href={banner?.link} className="mx-auto w-full max-h-[44px]">
          <div
            id={`event_banner_${banner?.title}`}
            className="flex justify-center items-center tablet:flex-col mobile:flex-col mx-auto w-full h-full py-1 object-contain web:text-lg tablet:text-sm mobile:text-sm"
          >
            <span
              className="font-medium"
              css={css`
                color: ${banner?.text_content_color};
              `}
            >
              {banner?.content}&nbsp;
            </span>
            <span
              className="font-bold"
              css={css`
                color: ${banner?.text_timer_color};
              `}
            >
              🔥{hours + days * 24}
              시간 {minutes}분 {seconds}초 후에 종료됩니다.🔥
            </span>
          </div>
        </a>
        <div
          className="ml-4 w-full h-full max-w-[18px] max-h-[18px] cursor-pointer"
          onClick={closeEventBanner}
        >
          <CloseSm color={banner?.close_btn_color} />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="w-screen h-[52px] flex items-center px-[15px]"
      css={css`
        background-color: ${banner?.background_color};
      `}
    >
      <div className="flex justify-between items-center mx-auto w-full max-h-[52px]">
        <div className="ml-4 w-full h-full max-w-[18px] max-h-[18px]">
          <img
            src={closeSm}
            alt="가상영역"
            className="w-full h-full max-w-[18px] max-h-[18px] min-w-[14px] min-h-[14px] invisible"
          />
        </div>
        <a href={banner?.link} className="mx-auto w-full max-h-[44px]">
          <div
            id={`event_banner_${banner?.title}`}
            className="flex justify-center items-center tablet:flex-col mobile:flex-col mx-auto w-full h-full py-1 object-contain web:text-lg tablet:text-sm mobile:text-sm"
          ></div>
        </a>
        <div
          className="ml-4 w-full h-full max-w-[18px] max-h-[18px] cursor-pointer"
          onClick={closeEventBanner}
        >
          <CloseSm color={banner?.close_btn_color} />
        </div>
      </div>
    </div>
  );
};
