import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Content, SubmitForm } from "../components/onedayLecture/index";
import moment from "moment";
import { getOnedayBoard } from "../api";

const OnedayLecture = ({ id }) => {
  const [isOverDate, setIsOverDate] = useState(false);
  const [onedayLecture, setOnedayLecture] = useState();

  useEffect(() => {
    //원데이 클래스 정보 GET
    getOnedayBoard({ id: id }).then((res) => {
      setOnedayLecture(res.data);
    });
  }, []);

  useEffect(() => {
    if (onedayLecture) {
      const { end_at } = onedayLecture;
      const now = moment();
      const endDate = moment(end_at);
      if (now.isAfter(endDate)) {
        setIsOverDate(true);
      }
    }
  }, [onedayLecture]);

  return (
    <Layout>
      <Content onedayLecture={onedayLecture} />
      <SubmitForm isOverDate={isOverDate} onedayLecture={onedayLecture} />
      <div className="mx-auto max-w-[650px] mobile:pl-4">
        <a
          href="/oneday_boards"
          className="flex items-center justify-center bg-[#CCCCCC] text-white rounded-[5px] mt-[50px] w-[80px] h-[36px] font-bold text-sm"
        >
          목록으로
        </a>
      </div>
    </Layout>
  );
};

export default OnedayLecture;
