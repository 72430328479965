import { useState, useContext, useEffect } from "react";
import {
  AssignmentLists,
  ShowContent,
} from "../../components/assignments/index";
import { Reply } from "../lessons/Reply";
import { ReplyInputForm } from "../lessons/ReplyInputForm";
import { QnaTitle } from "../lessons/QnaTitle";
import { QnasContext } from "../../pages/Lessons";
import { missionMobileTabBtnsStyle } from "../../style";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

export const ShowContentMobile = ({ lessonId, assignment }) => {
  const { qnas } = useContext(QnasContext);

  const { currentUser, isUserLoading } = useGetCurrentUser();

  const tab = new URLSearchParams(window.location.search);

  const [tabSelect, setTabSelect] = useState(tab.get("tab") || "mission"); // mission, otherMission

  const setPushState = (stateName) => {
    setTabSelect(stateName);
    window.history.replaceState(
      stateName,
      null,
      `/assignments/${assignment?.id}?tab=${stateName}`
    );
  };

  const TabBtn = ({ name, tabValue, tailwindStyle }) => {
    return (
      <div
        css={missionMobileTabBtnsStyle}
        className={`text-lg relative w-full cursor-pointer flex items-center justify-center ${
          tabSelect === tabValue
            ? "active font-bold text-black"
            : "font-medium text-gray400"
        } ${tailwindStyle}`}
        onClick={() => {
          setPushState(tabValue);
        }}
      >
        {name}
      </div>
    );
  };

  useEffect(() => {
    let subscribed = true;

    return () => {
      subscribed = false;
    };
  }, [tabSelect]);

  return (
    !isUserLoading && (
      <div className="flex flex-col w-full flex-shrink-0 mt-[9px]">
        <div className="grid grid-cols-2 gap- mt-[17px]">
          {assignment?.user?.id === currentUser?.id ? (
            <TabBtn name="내가 한 미션" tabValue="mission" />
          ) : (
            <TabBtn name="친구의 미션" tabValue="mission" />
          )}
          <TabBtn name="미션 게시판" tabValue="otherMission" />
        </div>

        {tabSelect === "mission" && (
          <div className="flex flex-col">
            <ShowContent lessonId={lessonId} assignment={assignment} />
            <QnaTitle
              qnasCount={qnas?.length}
              titleName="와글와글"
              titleContent={`<span class="font-bold">다른 친구의 과제에 대한 칭찬이나 여러분의 의견을 나눌 수 있습니다</span> <br /> <br />
                  <span>
                    ※ 나쁜 말은 안 돼요! \n
                    욕설, 비난 등 다른 친구들에게 상처를 주는 말은 삭제될 수 있어요. <br /> 나쁜 말은 하지 않기, 약속~!
                  </span>`}
            />
            <ReplyInputForm
              commentable_id={assignment?.id}
              commentable_type="Assignment"
            />
            <div className="mt-[18px]">
              <Reply qnas={qnas} replyTitle="다른 친구들의 와글와글" />
            </div>
          </div>
        )}
        {tabSelect === "otherMission" && (
          <AssignmentLists lessonId={lessonId} />
        )}
      </div>
    )
  );
};
