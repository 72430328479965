import { css } from "@emotion/react";
import { MOBILE_MAX_WIDTH } from "../../constant";

const boxStyle = css`
  display: grid;
  grid-template-columns: 30px auto;
  margin: 13px 0;
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    font-size: 14px;
  }
`;

const KitTextItem = ({ item, realIndex }) => {
  return (
    <div css={boxStyle}>
      <span className="col-start-1 col-end-1 row-start-1 row-end-1 font-bold leading-6">
        {realIndex}
      </span>
      <span className="col-start-2 col-end-2 row-start-1 row-end-1 font-bold leading-6">
        {item?.title}
      </span>
      <span className="col-start-1 col-end-1 row-start-2 row-end-2 leading-6"></span>
      <span className="col-start-2 col-end-2 row-start-2 row-end-2 mt-1 leading-6">
        {item?.desc}
      </span>
    </div>
  );
};

export default KitTextItem;
