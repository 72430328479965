import MyDnavLectureCard from "./MyDnavLectureCard";

const MyDnavLectureCardExist = (props) => {
  const lectures = props.value;
  return (
    <div className="grid gap-3 card_group mobile:grid-cols-2 tablet:grid-cols-2 web:grid-cols-3">
      {lectures?.map((lecture) => {
        return <MyDnavLectureCard key={lecture.id} lecture={lecture} />;
      })}
    </div>
  );
};

export default MyDnavLectureCardExist;
