import { useState, useLayoutEffect } from "react";
import { Auth } from "aws-amplify";
import { getUser } from "../api";
import { useUserSignedIn } from "./useUserSignedIn";

export const useGetCurrentUser = () => {
  const { isUserSignedIn } = useUserSignedIn();
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useLayoutEffect(() => {
    isUserSignedIn
      ? getUser()
          .then((response) => {
            setCurrentUser(response?.data);
            setIsUserLoading(false);
          })
          .catch(() => {
            Auth.signOut();
            setIsUserLoading(false);
          })
      : setIsUserLoading(false);
  }, [isUserSignedIn]);

  return { currentUser, setCurrentUser, isUserLoading };
};
