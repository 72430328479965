import Modal from "react-modal";
import StarRankGroup from "../shared/StarRankGroup";
import { css } from "@emotion/react";
import closeIcon from "../../assets/icons/close.svg";
import { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { reviewModalStyle } from "../../style";

const ReviewShow = ({ selectReview, modalIsOpen, setModalIsOpen }) => {
  const [reviewShow, setReviewShow] = useState(null);

  useEffect(() => {
    setReviewShow(selectReview);
  }, [selectReview]);

  return (
    <Modal
      style={reviewModalStyle}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setModalIsOpen(false)}
    >
      <div className="flex flex-col">
        <div className="flex items-center bg-white">
          <span className="font-bold truncate web:mr-4 tablet:mr-3 mobile:mr-2 web:max-w-[200px] tablet:max-w-[200px] mobile:max-w-[100px] web:text-[18px] tablet:text-[18px] mobile:text-[13px] leading-[18px]">
            {reviewShow?.user?.nickname || reviewShow?.user?.name}
          </span>

          <StarRankGroup
            webSize="20"
            mobileSize="14"
            gapRatio="6px"
            value={reviewShow?.rating}
          />
          <button
            className="ml-auto"
            onClick={(e) => {
              setModalIsOpen(!modalIsOpen);
            }}
          >
            <img src={closeIcon} alt="닫기버튼" className="w-[18px] h-[18px]" />
          </button>
        </div>
        <span className="mt-2 mb-4 web:text-[18px] tablet:text-[18px] mobile:text-[13px] whitespace-pre-wrap">
          {reviewShow?.body}
        </span>
        <div className="grid grid-cols-3 gap-2 mb-5">
          {reviewShow?.images?.map((image, index) => {
            return (
              <Zoom key={`리뷰더보기${index}`}>
                <img
                  src={image}
                  alt={`리뷰더보기${index}`}
                  css={css`
                    max-height: 100px;
                  `}
                />
              </Zoom>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ReviewShow;
