import { useState, useEffect } from "react";

const ResultBottomButton = ({ order }) => {
  // null, lecture, item
  const [mode, setMode] = useState(null);

  useEffect(() => {
    if (!!order) {
      const lectureOrderType = ["none", "kit", "textbook"];
      const itemOrderType = ["only_kit", "only_textbook"];
      if (order?.orderable_type === "LiveLecture") {
        setMode("liveLecture");
      } else if (lectureOrderType.includes(order?.order_type)) {
        setMode("lecture");
      } else if (itemOrderType.includes(order?.order_type)) {
        setMode("item");
      }
    }
  }, [order]);

  return (
    <div className="grid grid-cols-2 mobile:gap-[15px] tablet:gap-[30px] web:gap-[30px] h-[46px] mt-[37px]">
      {mode === "liveLecture" ? (
        <a
          href="/special_events/1"
          className="flex flex-col items-center justify-center text-blue-base border border-blue-base rounded-[7px] w-full"
        >
          다른 클래스 구매하기
        </a>
      ) : (
        <a
          href="/lectures/kids"
          className="flex flex-col items-center justify-center text-blue-base border border-blue-base rounded-[7px] w-full"
        >
          초등 클래스 보기
        </a>
      )}

      <a
        href="/mypage/payment"
        className="flex flex-col items-center justify-center text-white bg-blue-base rounded-[7px] w-full"
      >
        주문현황 보기
      </a>
    </div>
  );
};

export default ResultBottomButton;
