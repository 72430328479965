import { css } from "@emotion/react";
import kakaoIcon from "../../assets/icons/kakao.svg";
import messageIcon from "../../assets/icons/message.svg";
import { doCopy, setUtm } from "../../utils";
import useKakaoShare from "../../hooks/useKakaoShare";
import closeSm from "../../assets/icons/closeSm.svg";

const iconTextStyle = css`
  font-size: 12.5px;
`;

const ShareModal = ({ closeShareModal, specialEvent }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between font-bold border-b border-b-gray100 pb-[14px] px-[22px] items-center">
        <span>공유하기</span>
        <img
          onClick={closeShareModal}
          src={closeSm}
          className="w-[10px] h-[10px]"
          alt=""
        />
      </div>

      <div className="flex items-center justify-evenly pt-[15px] pb-[35px]">
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={(e) => {
            useKakaoShare(specialEvent);
          }}
        >
          <img
            src={kakaoIcon}
            alt="카카오톡아이콘"
            style={{ width: "51px", height: "51px" }}
          />
          <span className="text-[12.5px]">카카오톡</span>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            const utmObj = {
              utm_source: "externalsharing",
              utm_medium: "viral",
              utm_campaign: "",
            };
            doCopy(setUtm(utmObj));
          }}
        >
          <img
            src={messageIcon}
            alt="URL복사아이콘"
            style={{ width: "51px", height: "51px" }}
          />
          <span css={iconTextStyle}>URL 복사</span>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
