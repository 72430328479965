import { css } from "@emotion/react";
import kakaoIcon from "../../assets/icons/kakao.svg";
import messageIcon from "../../assets/icons/message.svg";
import { doCopy, setUtm } from "../../utils";
import useKakaoShare from "../../hooks/useKakaoShare";
import { color } from "../../tailwindStyle";

const boxStyle = css`
  padding: 15px 0 35px 0;
`;

const textStyle = css`
  font-size: 15px;
  border-bottom: 1px solid ${color.gray100};
  padding-bottom: 14px;
  font-weight: bold;
  padding-left: 22px;
`;

const iconTextStyle = css`
  font-size: 12.5px;
  color: black;
`;

const DrawerContent = ({ lecture }) => {
  return (
    <div className="flex flex-col">
      <span css={textStyle}>공유하기</span>
      <div className="flex items-center justify-evenly" css={boxStyle}>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={(e) => {
            useKakaoShare(lecture);
          }}
        >
          <img
            src={kakaoIcon}
            alt="카카오톡아이콘"
            style={{ width: "51px", height: "51px" }}
          />
          <span css={iconTextStyle}>카카오톡</span>
        </div>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => {
            const utmObj = {
              utm_source: "externalsharing",
              utm_medium: "viral",
              utm_campaign: "",
            };
            doCopy(setUtm(utmObj));
          }}
        >
          <img
            src={messageIcon}
            alt="URL복사아이콘"
            style={{ width: "51px", height: "51px" }}
          />
          <span css={iconTextStyle}>URL 복사</span>
        </div>
      </div>
    </div>
  );
};

export default DrawerContent;
