export const MOBILE_MAX_WIDTH = 768;
export const TABELT_MAX_WIDTH = 1024;
export const WEB_MAX_WIDTH = 1200;
export const cognitoKeyWithEnv = {
  development: {
    userPoolId: JSON.parse(process.env.DEVELOPMENT).cognito.user_pool_id,
    userPoolWebClientId: JSON.parse(process.env.DEVELOPMENT).cognito
      .user_pool_web_client_id,
  },
  staging: {
    userPoolId: JSON.parse(process.env.STAGING).cognito.user_pool_id,
    userPoolWebClientId: JSON.parse(process.env.STAGING).cognito
      .user_pool_web_client_id,
  },
  production: {
    userPoolId: JSON.parse(process.env.PRODUCTION).cognito.user_pool_id,
    userPoolWebClientId: JSON.parse(process.env.PRODUCTION).cognito
      .user_pool_web_client_id,
  },
};
global.cognitoKeyWithEnv = cognitoKeyWithEnv;
