import shareLg from "../../assets/icons/share_lg.svg";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { useEffect, useState } from "react";
import { forEach, every } from "lodash";
import { setReturnSession } from "../../utils";
import { getMyLiveLectures } from "../../api";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const FooterSticky = ({
  openDrawer,
  openModal,
  openShareModal,
  live_lectures,
  reduceLiveLectures,
  setReduceLiveLectures,
}) => {
  const deviceCheck = useDeviceCheck();
  const [purchaseBtnStatus, setPurchaseBtnStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isUserSignedIn } = useUserSignedIn();

  useEffect(() => {}, [reduceLiveLectures]);

  useEffect(() => {
    if (isUserSignedIn) {
      getMyLiveLectures()
        .then((response) => {
          const userLiveLectures = response?.data;
          const userLiveIds = userLiveLectures?.map((lecture) => {
            return lecture?.id;
          });

          let tempReduceLiveLectures = [];
          forEach(live_lectures, (value) => {
            let previousObject = value;
            let btnStatus = false;
            if (userLiveIds?.includes(value?.id) || !value?.is_purchase) {
              btnStatus = false;
            } else {
              btnStatus = true;
            }

            previousObject["btnStatus"] = btnStatus;
            tempReduceLiveLectures.push(previousObject);
          });

          const isAllFalseBtnStatus = every(tempReduceLiveLectures, [
            "btnStatus",
            false,
          ]);

          if (isUserSignedIn && isAllFalseBtnStatus) {
            setPurchaseBtnStatus(false);
          } else {
            setPurchaseBtnStatus(true);
          }

          setReduceLiveLectures(tempReduceLiveLectures);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [live_lectures]);

  return (
    <>
      {!loading && (
        <div className="fixed w-full bottom-0 flex bg-white web:py-[15px] tablet:py-[12px] mobile:py-[12px] z-50 my-0 web:h-[77px] tablet:h-[71px] mobile:h-[71px]">
          <div className="flex web:max-w-[1200px] tablet:max-w-[640px] mobile:max-w-[640px] default_margin items-center w-full">
            <button
              type="button"
              onClick={openShareModal}
              id="parentClassExternalSharing"
            >
              <img
                src={shareLg}
                alt="공유하기"
                className="web:w-[18px] web:h-[24px] tablet:w-[18px] tablet:h-[24px] mobile:w-[18px] mobile:h-[24px] web:mr-[52px] tablet:mr-[20px] mobile:mr-[20px] web:ml-[15px]"
              />
            </button>

            <button
              disabled={!purchaseBtnStatus}
              type="button"
              onClick={
                isUserSignedIn
                  ? deviceCheck === "web"
                    ? openModal
                    : openDrawer
                  : () => setReturnSession()
              }
              className={`flex items-center justify-center  text-white py-[11px] rounded-[7px] w-full h-full font-bold web:text-xl tablet:text-sm mobile:text-sm web:h-[48px] tablet:h-[46px] mobile:h-[46px] ${
                purchaseBtnStatus ? "bg-blue-base" : "bg-gray400 opacity-70"
              } `}
            >
              {purchaseBtnStatus ? "수강 신청하기" : "LIVE로 곧 만나요!"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FooterSticky;
