import moment from "moment";
import { filter } from "lodash";

const DetailBox = ({ order, orderable }) => {
  return (
    <>
      {/* 구입한 클래스 */}
      {(order?.order_type === "none" || order?.order_type === "delivery") && (
        <div className="flex flex-col">
          <span className="font-bold mt-[31px]">구입한 클래스</span>
          <div className="mt-[14px] border-gray100 border rounded-[12px] py-[23px] px-[8px] flex">
            <img
              src={order?.image_url}
              alt="구매강의썸네일"
              className="max-w-[91px] mr-[10px] shrink-0 w-full"
            />
            <div className="flex flex-col w-full">
              <span className="text-sm">{order?.title}</span>

              {!!orderable &&
                orderable?.viewer_type !== "dnav" &&
                (orderable?.viewer_type !== "write" ? (
                  // 일반 클래스
                  <span className="text-xs text-gray400 my-[3px]">
                    수강 기한 : {orderable?.watching_period}개월
                  </span>
                ) : (
                  // 써볼까?!
                  <span className="text-xs text-gray400 my-[3px]">
                    수강 기한 : 2주
                  </span>
                ))}

              {/* 패키지 클래스 */}
              {!orderable && (
                <span className="text-xs text-gray400 my-[3px]">
                  수강 기한 : 12개월
                </span>
              )}

              {order?.orderable_type === "LiveLecture" && (
                <span className="text-sm font-bold text-red-700">
                  라이브 일시{" "}
                  {moment(orderable?.start_at)
                    .locale("ko")
                    .format("YYYY.MM.DD (ddd) HH:mm")}{" "}
                  - {moment(orderable?.end_at).format("HH:mm")}
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      {/* 기본 제공 옵션 */}
      {filter(order?.order_items, (i) => i?.default_option_quantity > 0).map(
        (item) => (
          <div key={item?.id} className="flex flex-col">
            <span className="font-bold mt-[31px]">옵션</span>
            <div className="mt-[14px] border-gray100 border rounded-[12px] py-[23px] px-[8px] flex">
              <img
                src={item?.item_image_url}
                alt="구매기본제공옵션"
                className="max-w-[91px] mr-[10px] shrink-0 w-full"
              />
              <div className="flex flex-col w-full">
                <span className="text-sm">{item?.item_title}</span>

                <div className="flex mt-2 items-center">
                  <span className="text-xs text-gray400 mr-[10px]">
                    기본 제공 수량
                  </span>
                  <span className="text-xs">
                    {item?.default_option_quantity}
                  </span>
                </div>

                {orderable?.viewer_type !== "dnav" && (
                  <div className="flex mt-2 items-center">
                    <span className="text-xs text-gray400 mr-[10px]">
                      추가 구매 수량
                    </span>
                    <span className="text-xs">{item?.item_quantity}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default DetailBox;
