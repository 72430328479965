import { css } from "@emotion/react";
import moment from "moment";
import noticeDefault from "../../assets/images/notice_default_img.svg";
import { useState } from "react";
import { useEffect } from "react";
import { getEvents } from "../../api";

//공지사항
const Event = () => {
  const [events, setEvents] = useState(null);
  useEffect(() => {
    let subscribed = true;

    getEvents().then((response) => {
      if (subscribed) {
        setEvents(response?.data);
      }
    });

    return () => (subscribed = false);
  }, []);

  const eventStyle = css`
    .item:first-of-type {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .item:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .item:first-of-type.outdated::after {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .item:last-of-type.outdated::after {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .outdated {
      border: 0px solid rgba(0, 0, 0, 0.6);
    }

    .outdated::after {
      position: absolute;
      content: "종료된 이벤트입니다.";
      color: white;
      background-color: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `;

  return (
    <div className="mt-5" css={eventStyle}>
      {events?.map((event) => {
        return (
          <a
            href={`/boards/${event?.id}`}
            key={event?.id}
            className={`pl-4 pr-2 relative py-[10px] item border border-gray100 flex items-center ${
              event?.is_over ? "outdated" : ""
            }`}
          >
            <div
              className="w-[90px] h-[73px] flex mr-[9px] justify-center items-center shrink-0 bg-cover"
              css={css`
                background-image: url(${event?.thumbnail || noticeDefault});
              `}
            ></div>

            <div className="flex flex-col">
              <span className="mb-2 text-sm font-medium break-all">
                {event?.title}
              </span>
              <span className="text-xs text-gray400">
                {moment(event?.created_at).format("YYYY-MM-DD")}
              </span>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default Event;
