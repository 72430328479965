import Aside from "../components/shared/MypageAside";
import Layout from "../components/Layout";
import { Content } from "../components/mypageMissionHistory";
import { MypageTitle } from "../components/shared/Title";

const MypageMissionHistory = (props) => {
  return (
    <Layout>
      <div className="flex mypage_lectures default_margin mobile:flex-col max-w-[1200px] h-full">
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>
        <div className="flex-grow w-full mobile:mt-5">
          <MypageTitle title="피드백 없는 과제" />
          <Content />
        </div>
      </div>
    </Layout>
  );
};

export default MypageMissionHistory;
