import { useState, useEffect } from "react";
import Show from "../../pages/LectureShow";

function Preview({ lectureId }) {
  return (
    <>
      <div
        className="bg-white react"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <p className="text-red-800">미리보기 모드에서는 클릭해서 이동이 제한됩니다.(구매하기, 링크 이동 X)</p>
        <Show lectureId={lectureId} />
      </div>
    </>
  );
}

export default Preview;
