import useDeviceCheck from "../../hooks/useDeviceCheck";
import PaymentCardNone from "./PaymentCardNone";
import KgPayCardLg from "./KgPayCardLg";
import KgPayCardSm from "./KgPayCardSm";
import OrderNicePayLg from "./OrderNicePayLg";
import OrderNicePaySm from "./OrderNicePaySm";

const Content = ({ kgPayOrders, nicePayOrders }) => {
  const KgPayOrders = ({ orders }) => {
    const deviceCheck = useDeviceCheck();
    return orders?.map((card) => {
      return deviceCheck === "web" ? (
        <KgPayCardLg card={card} key={card.id} />
      ) : (
        <KgPayCardSm card={card} key={card.id} />
      );
    });
  };

  const NicePayOrders = ({ orders }) => {
    const deviceCheck = useDeviceCheck();
    return orders?.map((order) => {
      return deviceCheck === "web" ? (
        <OrderNicePayLg order={order} key={order.id} />
      ) : (
        <OrderNicePaySm order={order} key={order.id} />
      );
    });
  };

  return kgPayOrders?.length !== 0 || nicePayOrders?.length !== 0 ? (
    <>
      <NicePayOrders orders={nicePayOrders} />
      <KgPayOrders orders={kgPayOrders} />
    </>
  ) : (
    <PaymentCardNone />
  );
};

export default Content;
