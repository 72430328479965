import Rating from "react-rating";
import starFull from "../../assets/icons/star_rank/starFull.svg";
import starEmpty from "../../assets/icons/star_rank/starEmpty.svg";
import { css } from "@emotion/react";
import imgPlus from "../../assets/icons/image_plus.svg";
import { useRef, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import iziToast from "izitoast";
import imgRemoveBtn from "../../assets/icons/img_remove.svg";
import { remove } from "lodash";
import { postReviewsPlain } from "../../api";

const starStyle = css`
  width: 32px;
  height: 32px;
  margin: 0 4px;
`;

const ReviewNewForm = ({ lectureType, lectureId, userId, viewerType }) => {
  const [formOpen, isFormOpen] = useState(false);
  const [countText, setCountText] = useState(0);
  const [imgFiles, setImgFiles] = useState([]);
  const inputFileRef = useRef(null);
  const reviewTextRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      reviewRating: 0,
      reviewText: "",
      "image[]": [],
    },

    validationSchema: Yup.object({
      reviewRating: Yup.number()
        .required("별점을 선택해주세요")
        .min(1, "별점을 선택해주세요")
        .max(5, "별점을 선택해주세요"),
      reviewText: Yup.string()
        .required("30자 이상 필수 입력입니다")
        .min(30, "30자 이상 필수 입력입니다")
        .max(2000, "2000자를 넘길 수 없습니다"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const formData = new FormData();
      imgFiles.forEach((file, index) => {
        formData.append("review[images][]", file);
      });
      formData.append("review[body]", values.reviewText);
      formData.append("review[rating]", values.reviewRating);

      formik.dirty
        ? postReviewsPlain({
            data: formData,
            targetId: lectureId,
            targetType: lectureType,
            userId: userId,
          })
            .then((response) => {
              location.href = `/reviews/result?lecture_id=${lectureId}&lecture_type=${lectureType}&viewer_type=${viewerType}`;
            })
            .catch((err) => {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: "info",
                  title: "주소가 잘못 되었습니다",
                  text: "처음부터 다시 시작해주세요",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonText: "메인 페이지로 이동",
                  cancelButtonText: "취소",
                }).then((result) => {
                  if (result.isConfirmed) {
                    location.href = "/";
                  }
                });
              } else if (err.response.status === 403) {
                Swal.fire({
                  icon: "info",
                  title: "주소가 잘못 되었습니다",
                  text: "처음부터 다시 시작해주세요",
                  reverseButtons: true,
                  showCancelButton: true,
                  confirmButtonText: "메인 페이지로 이동",
                  cancelButtonText: "취소",
                }).then((result) => {
                  if (result.isConfirmed) {
                    location.href = "/";
                  }
                });
              } else {
                Swal.fire(
                  "일시적인 문제가 생겼습니다. 다시 한 번 시도해주세요."
                );
              }
            })
        : iziToast.warning({
            message: "글과 별점은 반드시 입력해주셔야 합니다.",
            position: "topCenter",
            timeout: 1500,
          });
    },
  });

  useEffect(() => {
    setCountText(reviewTextRef.current?.value.length);
  }, [formik.values.reviewText]);

  const doAddImg = (event) => {
    const newFiles = event.currentTarget.files;

    if (imgFiles.length + newFiles.length > 3) {
      Swal.fire("3개를 넘는 이미지를 넣을 수 없습니다");
    } else {
      let tempFiles = [...imgFiles];
      for (let i = 0; i < newFiles.length; i++) {
        tempFiles.push(newFiles[i]);
      }
      setImgFiles(tempFiles);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center justify-center pt-[40px] pb-4 mx-[15px] border-b border-black">
        <span className="mb-[28px]">별점을 남겨주세요!</span>

        <Rating
          emptySymbol={
            <img src={starEmpty} css={starStyle} className="star-img" />
          }
          fullSymbol={
            <img src={starFull} css={starStyle} className="star-img" />
          }
          initialRating={formik.values.reviewRating}
          onChange={(value) => {
            isFormOpen(true);
            setCountText(0);
            formik.setFieldValue("reviewRating", value);
          }}
        />

        {formik.touched.reviewRating && formik.errors.reviewRating ? (
          <div className="mt-2 text-xs text-red500">
            {formik.errors.reviewRating}
          </div>
        ) : (
          <span>&nbsp;&nbsp;&nbsp;</span>
        )}
      </div>
      {formOpen && (
        <div className="py-[40px] mx-[15px] flex flex-col justify-center items-center">
          <span>클래스에 대한 조금 더</span>
          <span>자세한 리뷰를 들려주세요!</span>
          <textarea
            className="w-full h-[180px] rounded-[3px] border border-black p-[14px] text-sm resize-none mt-[33px]"
            type="text"
            placeholder="클래스를 수강하면서 어떤 점이 좋았나요? 아이가 수업에 관해 이야기했던 내용, 부모님들의 생각 모두 좋습니다. 소중한 리뷰를 공유해 주세요."
            name="reviewText"
            id="reviewText"
            ref={reviewTextRef}
            onChange={formik.handleChange}
            value={formik.values.reviewText}
          ></textarea>

          <div className="flex justify-between w-full text-xs mt-[2px]">
            {formik.touched.reviewText && formik.errors.reviewText ? (
              <div className="text-red500">{formik.errors.reviewText}</div>
            ) : (
              <span>&nbsp;&nbsp;&nbsp;</span>
            )}
            <span className="text-gray300">{countText}/2000</span>
          </div>
          <div className="flex w-full mt-[15px] overflow-x-auto">
            <button
              type="button"
              onClick={() => inputFileRef.current.click()}
              className="justify-center items-center w-[100px] h-[100px] border border-black cursor-pointer mr-[5px] shrink-0"
            >
              <img src={imgPlus} alt="이미지추가하기버튼" className="mx-auto" />
            </button>
            <input
              id="imgFile"
              name="file"
              type="file"
              className="hidden"
              ref={inputFileRef}
              multiple
              onChange={(e) => {
                doAddImg(e);
              }}
            />
            {imgFiles.map((imgFile, index) => {
              return (
                <div className="relative" key={`imgFile_${index}`}>
                  <img
                    src={URL.createObjectURL(imgFile)}
                    alt="리뷰"
                    className="w-[100px] h-[100px] aspect-square mr-[5px] shrink-0 max-w-max"
                  />

                  <button
                    className="absolute top-0 right-[5px]"
                    onClick={() =>
                      setImgFiles(
                        remove(imgFiles, (e) => {
                          return e !== imgFile;
                        })
                      )
                    }
                  >
                    <img
                      src={imgRemoveBtn}
                      alt="리뷰이미지삭제"
                      className="w-[33px] h-[33px]"
                    />
                  </button>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between w-full mt-[2px]">
            <span className="mr-2 text-xs text-red500">
              사진만 첨부 할 수 있습니다.
            </span>
            <span className="text-xs text-gray300">{imgFiles?.length}/3</span>
          </div>
        </div>
      )}

      <div className="mx-[15px] mb-[40px] flex flex-col justify-center items-center">
        <button
          disabled={!formOpen || formik.isSubmitting}
          className={`bg-yellow600 text-white rounded-[5px] flex justify-center items-center w-full h-[45px] font-bold ${
            formOpen ? "" : "mt-[277px]"
          }`}
          type="submit"
        >
          {formik.isSubmitting ? `리뷰 등록 중...` : `리뷰 등록하기`}
        </button>
      </div>
    </form>
  );
};

export default ReviewNewForm;
