import { UsersTitle } from "./UsersTitle";
import { UsersParent } from "./UsersParent";
import { getFindEmail } from "../../api";
import Swal from "sweetalert2";
import AuthPhoneNumber from "./AuthPhoneNumber";
import { useState, useEffect, useRef, forwardRef } from "react";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import iziToast from "izitoast";
import Popup from "reactjs-popup";
import closeSm from "../../assets/icons/close_sm.svg";
import { NaverButton } from "./NaverButton";
import { KakaoButton } from "./KakaoButton";
import questionCircle from "../../assets/icons/question_circle.svg";

export const FindEmail = () => {
  const { isUserSignedIn } = useUserSignedIn();

  useEffect(() => {
    if (isUserSignedIn) location.href = "/";
  }, [isUserSignedIn]);

  const idResultRef = useRef(null);
  const [encryptedKey, setEncryptedKey] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [ids, setIds] = useState({
    smallbigclass: null,
    kakao: null,
    naver: null,
  });

  const handleFindEmail = () => {
    setIsSubmitting(true);
    getFindEmail({
      phoneNumber: phoneNumber,
      encryptedKey: encryptedKey,
    })
      .then((response) => {
        setIsSubmitting(false);

        const smallbigEmail = response?.data?.data?.email;
        const naverEmail = response?.data?.data?.identifies?.find(
          (identify) => identify.provider === "naver"
        )?.email;
        const kakaoEmail = response?.data?.data?.identifies?.find(
          (identify) => identify.provider === "kakao"
        )?.email;

        setIds({
          ...ids,
          smallbigclass: smallbigEmail,
          naver: naverEmail,
          kakao: kakaoEmail,
        });

        idResultRef.current.click();
      })
      .catch((error) => {
        iziToast.error({
          message: error.response.data.message,
          position: "topCenter",
          timeout: 1000,
        });
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (!!encryptedKey) {
      handleFindEmail();
    }
  }, [encryptedKey]);

  const HiddenBtn = forwardRef(({ open, ...props }, ref) => (
    <button className="invisible" ref={idResultRef} {...props}></button>
  ));

  return (
    <UsersParent>
      <UsersTitle text="아이디 찾기" />

      <div className="mb-[24px]">
        계정에 연결된 핸드폰 번호를 입력하면 <br /> 메시지로 아이디를
        알려드릴게요.
      </div>

      <AuthPhoneNumber
        setEncryptedKey={setEncryptedKey}
        setPhoneNumber={setPhoneNumber}
      />

      <Popup
        trigger={(open) => <HiddenBtn open={open} />}
        modal
        nested
        overlayStyle={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        lockScroll
        closeOnDocumentClick={false}
        onClose={() => (location.href = `/users/sign_in`)}
      >
        {(close) => (
          <div className="flex flex-col py-5 px-[35px] bg-white max-w-[500px] w-[90vw] rounded-[12px] overflow-auto max-h-[600px]">
            <div className="flex justify-between w-full my-2">
              <span className="text-lg font-bold">보유한 아이디</span>
              <button onClick={close} className="focus:outline-none">
                <img src={closeSm} alt="닫기" className="w-[13px] h-[13px]" />
              </button>
            </div>

            {!!ids?.smallbigclass && (
              <div className="flex flex-col border border-gray-300 rounded-[5px] p-2 my-2">
                <div>일반 로그인 아이디</div>
                <div className="font-bold">{ids?.smallbigclass}</div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <a
                    href={`/users/find_password?email=${ids?.smallbigclass}`}
                    className="w-full h-[45px] flex items-center justify-center rounded-[5px] font-bold border-black border"
                  >
                    비밀번호 찾기
                  </a>
                  <a
                    href="/users/sign_in"
                    className="w-full h-[45px] flex items-center justify-center text-white rounded-[5px] font-bold bg-black"
                  >
                    로그인 하기
                  </a>
                </div>
              </div>
            )}

            {!!ids?.naver && (
              <div className="tooltipBoundary flex flex-col border border-gray-300 rounded-[5px] p-2 my-2">
                <div className="flex items-center justify-between">
                  <div>네이버 아이디</div>
                  <Popup
                    trigger={
                      <img
                        src={questionCircle}
                        alt="유의사항"
                        className="w-[20px] h-[20px] cursor-pointer"
                      />
                    }
                    position={["top center", "bottom right", "bottom left"]}
                    closeOnDocumentClick
                    keepTooltipInside=".tooltipBoundary"
                    on={["hover", "focus"]}
                  >
                    <div className="bg-white border rounded-[5px] max-w-[200px] text-sm p-2">
                      네이버 아이디로 로그인 할 수 없는 경우 채널톡으로 문의
                      주시면 안내 도와드리겠습니다.
                    </div>
                  </Popup>
                </div>
                <div className="font-bold">{ids?.naver}</div>
                <NaverButton page="findEmail" />
              </div>
            )}

            {!!ids?.kakao && (
              <div className="flex flex-col border border-gray-300 rounded-[5px] p-2 my-2">
                <div>카카오 아이디</div>
                <div className="font-bold">{ids?.kakao}</div>
                <KakaoButton page="findEmail" />
              </div>
            )}
          </div>
        )}
      </Popup>
    </UsersParent>
  );
};
