import moment from "moment";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useRef, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { orderBy } from "lodash";
import { css } from "@emotion/react";

import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { deleteReply, postNestedComment } from "../../api";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

import defaultProfile from "../../assets/icons/default_profile.svg";
import adminCrown from "../../assets/icons/admin_crown.svg";
import checkBoxClick from "../../assets/icons/checkbox_click.svg";
import checkBoxNotClick from "../../assets/icons/checkbox_not_click.svg";
import lockImg from "../../assets/icons/lock_img.svg";

const Reply = ({
  comment,
  handleGetBoardReplies,
  setCommentInputs,
  commentInputs,
}) => {
  const [isNestedRepliesOpen, setIsNestedRepliesOpen] = useState(false);
  const { isUserSignedIn } = useUserSignedIn();
  const { currentUser } = useGetCurrentUser();

  const nestedReplyInputRef = useRef(null);

  const handleReplyInputOpen = (targetId, targetType) => {
    isUserSignedIn
      ? setCommentInputs({ id: targetId, type: targetType })
      : Swal.fire({
          title: "로그인이 필요한 서비스입니다.",
          icon: "warning",
          confirmButtonText: "로그인하러 가기",
          showCancelButton: true,
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            setReturnSession();
          }
        });
  };

  useEffect(() => {
    if (
      commentInputs?.id === comment?.id &&
      commentInputs?.type === "Comment"
    ) {
      nestedReplyInputRef.current.scrollIntoView({ block: "center" });
      nestedReplyInputRef.current.focus();
    }
  }, [commentInputs]);

  const handleDeleteReply = (commentId) => {
    Swal.fire({
      title: "댓글을 삭제하시겠습니까?",
      text: "삭제된 댓글은 복구할 수 없습니다.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReply({ replyId: commentId }).then(() => {
          handleGetBoardReplies();
        });
      }
    });
  };

  return (
    <div key={comment?.id} className="flex mt-[30px] mobile:mt-[20px]">
      <img
        src={comment?.user?.avatar?.url ?? defaultProfile}
        alt={`user_profile_${comment?.user?.id}`}
        className="w-[30px] h-[30px] bg-cover bg-center rounded-full bg-no-repeat mr-[10px]"
      />
      <div className="flex flex-col w-full pt-[5px]">
        <div className="flex justify-between items-center">
          <span className="font-[700] mobile:text-[15px]">
            {comment?.user?.nickname || comment?.user?.name}
          </span>
          {comment?.user?.role === "admin" && (
            <img
              src={adminCrown}
              alt={`admin_crown_${comment?.user?.id}`}
              className="ml-1 mb-[3px] mobile:mb-[2px] pt-[1px]"
            />
          )}
          {comment?.is_secret && (
            <img
              src={lockImg}
              alt={`lock_${comment?.user?.id}`}
              className="ml-[2px]"
            />
          )}
          <span className="text-sm font-[500] text-[#B2B2B2] mobile:text-[13px] ml-auto shrink-0">
            {moment(comment?.created_at).format("YYYY-MM-DD")}
          </span>
        </div>

        <div
          className={`mt-[10px] leading-[19px] mobile:leading-[17px] mobile:text-[15px] whitespace-pre-wrap ${
            comment?.is_secret ? "text-gray66" : "whitespace-pre-wrap"
          }`}
        >
          {comment?.body}
        </div>

        <div className="flex mt-[10px] text-sm mobile:text-[13px]">
          <button
            type="button"
            onClick={() => handleReplyInputOpen(comment?.id, "Comment")}
            className="font-[700] text-[#B2B2B2] mr-[15px]"
          >
            답글달기
          </button>

          {comment?.comments?.length > 0 && (
            <button
              type="button"
              onClick={() => setIsNestedRepliesOpen(!isNestedRepliesOpen)}
              className={`font-[700] text-[#B2B2B2] mr-[15px] ${
                isNestedRepliesOpen ? "!text-[#888888]" : ""
              }`}
            >
              {isNestedRepliesOpen
                ? `답글접기 (${comment?.comments?.length})`
                : `답글보기 (${comment?.comments?.length})`}
            </button>
          )}

          {(currentUser?.id === comment?.user?.id ||
            currentUser?.role === "admin") && (
            <button
              type="button"
              onClick={() => handleDeleteReply(comment?.id)}
              className="font-[700] text-[#B2B2B2]"
            >
              삭제하기
            </button>
          )}
        </div>

        {/* 대댓글 리스트 */}
        {isNestedRepliesOpen &&
          orderBy(comment?.comments, "id", "asc")?.map((nestedComment) => {
            return (
              <div
                key={nestedComment.id}
                className="flex mt-[30px] mobile:mt-[20px]"
              >
                <img
                  src={nestedComment?.user?.avatar?.url ?? defaultProfile}
                  alt={`user_profile_${nestedComment?.user?.id}`}
                  className="w-[30px] h-[30px] bg-cover bg-center rounded-full bg-no-repeat mr-[10px]"
                />
                <div className="flex flex-col w-full pt-[5px]">
                  <div className="flex justify-between items-center">
                    <span className="font-[700] mobile:text-[15px]">
                      {nestedComment?.user?.nickname ||
                        nestedComment?.user?.name}
                    </span>
                    {nestedComment?.user?.role === "admin" && (
                      <img
                        src={adminCrown}
                        alt={`admin_crown_${nestedComment?.user?.id}`}
                        className="ml-1 mb-[3px] mobile:mb-[2px]"
                      />
                    )}
                    {nestedComment?.is_secret && (
                      <img
                        src={lockImg}
                        alt={`lock_${nestedComment?.user?.id}`}
                        className="ml-[2px]"
                      />
                    )}
                    <span className="text-sm font-[500] text-[#B2B2B2] mobile:text-[13px] ml-auto shrink-0">
                      {moment(nestedComment?.created_at).format("YYYY-MM-DD")}
                    </span>
                  </div>
                  <div
                    className={`mt-[10px] leading-[19px] mobile:leading-[17px] mobile:text-[15px] whitespace-pre-wrap ${
                      nestedComment?.is_secret
                        ? "text-gray66"
                        : "whitespace-pre-wrap"
                    }`}
                  >
                    {nestedComment?.body}
                  </div>
                  {(currentUser?.id === nestedComment?.user?.id ||
                    currentUser?.role === "admin") && (
                    <div className="flex mt-[10px] text-sm mobile:text-[13px]">
                      <button
                        type="button"
                        onClick={() => handleDeleteReply(nestedComment?.id)}
                        className="font-[700] text-[#B2B2B2]"
                      >
                        삭제하기
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

        {/* 대댓글 입력 */}
        {commentInputs?.id === comment?.id &&
          commentInputs?.type === "Comment" && (
            <div className="mt-[20px]">
              <Formik
                initialValues={{
                  nestedContent: "",
                  isSecret: false,
                }}
                validationSchema={Yup.object().shape({
                  nestedContent: Yup.string().required(),
                })}
                onSubmit={(values, { resetForm }) => {
                  postNestedComment({
                    replyId: comment?.id,
                    content: values.nestedContent,
                    isSecret: values.isSecret,
                  })
                    .then((res) => {
                      if (!!res) {
                        Swal.fire({
                          icon: "success",
                          title: "댓글이 등록되었습니다.",
                          confirmButtonText: "확인",
                          willClose: () => {
                            handleGetBoardReplies();
                            setIsNestedRepliesOpen(true);
                            setCommentInputs(null);
                            resetForm();
                          },
                        });
                      }
                    })
                    .catch(() => {
                      Swal.fire({
                        icon: "error",
                        title: "댓글 작성에 실패했습니다.",
                        text: "문제가 반복될 경우 관리자에게 문의해주세요",
                        confirmButtonText: "확인",
                      });
                    });
                }}
              >
                <Form>
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <img
                        src={currentUser?.avatar?.url ?? defaultProfile}
                        alt={`user_profile_${currentUser?.id}`}
                        className="w-[30px] h-[30px] bg-cover bg-center rounded-full bg-no-repeat mr-[10px]"
                      />
                      <div className="flex items-center pt-[5px]">
                        <span className="font-[700] mobile:text-[15px]">
                          {currentUser?.nickname || currentUser?.name}
                        </span>
                        {currentUser?.role === "admin" && (
                          <img
                            src={adminCrown}
                            alt={`admin_crown_${currentUser?.id}`}
                            className="ml-1 mb-[3px] mobile:mb-[2px]"
                          />
                        )}
                      </div>
                    </div>
                    <Field
                      as="textarea"
                      type="text"
                      name="nestedContent"
                      className="bg-[#FAFAFA] h-[120px] overflow-auto p-[20px] mobile:text-[15px] rounded-[10px] mt-[10px] mobile:mt-[15px] focus:outline-none"
                      placeholder="답글을 입력해주세요."
                      innerRef={(input) =>
                        (nestedReplyInputRef.current = input)
                      }
                    ></Field>

                    <div className="flex items-center mt-[15px] mobile:mt-[10px]">
                      <label
                        htmlFor="isSecret"
                        className="text-gray99 ml-[6px] text-[13px] font-[700] flex items-center pt-[1px] cursor-pointer"
                        css={css`
                          .isSecret {
                            display: none;
                            position: relative;
                            z-index: -9999;
                          }

                          .hiddenCheckbox {
                            display: block;
                            width: 18px;
                            height: 18px;
                            background: url(${checkBoxNotClick});
                          }

                          .isSecret:checked + .hiddenCheckbox {
                            width: 18px;
                            height: 18px;
                            background: url(${checkBoxClick});
                          }
                        `}
                      >
                        <Field
                          name="isSecret"
                          type="checkbox"
                          id="isSecret"
                          className="isSecret h-[18px] w-[18px] rounded-[3px]"
                        ></Field>
                        <span className="hiddenCheckbox mr-2"></span>
                        비밀글 쓰기
                      </label>
                      <button
                        type="submit"
                        onClick={() => setCommentInputs(null)}
                        className="rounded-[15px] bg-[#ECECEC] text-gray99 w-fit flex items-center justify-center font-[700] text-[13px] px-[12px] pt-[5px] pb-[3px] ml-auto mr-4"
                      >
                        취소
                      </button>
                      <button
                        type="submit"
                        className="rounded-[15px] bg-black text-white w-fit flex items-center justify-center font-[700] text-[13px] px-[12px] pt-[5px] pb-[3px]"
                      >
                        답글달기
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          )}
      </div>
    </div>
  );
};

export default Reply;
