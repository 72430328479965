import { useEffect, useState } from "react";
import { MOBILE_MAX_WIDTH, TABELT_MAX_WIDTH } from "../constant";

const useDeviceCheck = () => {
  let defaultDevice;

  const windowWidth = () => {
    const ua = navigator.userAgent || navigator.vender || window.opera;
    const isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
    if (isInstagram) {
      return window.screen.width;
    } else {
      return window.innerWidth;
    }
  };

  if (windowWidth() < MOBILE_MAX_WIDTH) {
    defaultDevice = "mobile";
  } else if (
    MOBILE_MAX_WIDTH <= windowWidth() &&
    windowWidth() <= TABELT_MAX_WIDTH
  ) {
    defaultDevice = "tablet";
  } else {
    defaultDevice = "web";
  }
  const [device, setDevice] = useState(defaultDevice);

  const handleSize = () => {
    if (windowWidth() < MOBILE_MAX_WIDTH) {
      setDevice("mobile");
    } else if (
      MOBILE_MAX_WIDTH <= windowWidth() &&
      windowWidth() <= TABELT_MAX_WIDTH
    ) {
      setDevice("tablet");
    } else {
      setDevice("web");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleSize);
    return () => {
      window.removeEventListener("resize", handleSize, true);
    };
  }, []);

  return device;
};

export default useDeviceCheck;
