import { useEffect } from "react";
import { contentContainerStyle } from "../../style";
import snsNo from "../../assets/icons/sns_no.svg";
import snsYes from "../../assets/icons/sns_yes.svg";
import { find } from "lodash";
import { UsersInputLabel } from "../users/UsersInputLabel";
import { MoreInfoForm } from "../users/MoreInfoForm";
import { EditProfileImg } from "../users/EditProfileImg";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import Swal from "sweetalert2";
import { patchUser } from "../../api";
import { Auth } from "aws-amplify";
import { color } from "../../tailwindStyle";

const KAKAO_SDK = "https://developers.kakao.com/sdk/js/kakao.js";
const KAKAO_TOKEN = process.env.KAKAO_JS_KEY; // js
const KAKAO_REDIRECT = `${window.location.origin}/users/link_kakao`;

const NAVER_CLIENT_ID =
  process.env.NODE_ENV === "development"
    ? "7vRzvMKyOT03ufJx8LjT"
    : process.env.NAVER_CLIENT_ID;
const NAVER_API_REDIRECT = `${window.location.origin}/users/link_naver`;
const NAVER_AUTH_URL = "https://nid.naver.com/oauth2.0/authorize";
const generateState = () => {
  let t = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (n) {
    var e = (t + 16 * Math.random()) % 16 | 0;
    return (t = Math.floor(t / 16)), ("x" === n ? e : (3 & e) | 8).toString(16);
  });
};

const Content = () => {
  const { currentUser } = useGetCurrentUser();

  const NaverLinkUp = () => {
    Swal.fire({
      title: "네이버 아이디로 로그인하시겠습니까?",
      text: "네이버 연동을 하시면 현재 로그인 되어있는 네이버아이디로 자동 연동 됩니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "연동하기",
      cancelButtonText: "연동취소",
    }).then((result) => {
      if (result?.isConfirmed) {
        window.location.href = `${NAVER_AUTH_URL}?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${generateState()}&redirect_uri=${NAVER_API_REDIRECT}&auth_type=reauthenticate`;
      }
    });
  };

  const KakaoLinkUp = () => {
    Swal.fire({
      title: "카카오톡 연동을 하시겠습니까?",
      text: "카카오 연동을 하시면 현재 로그인되어있는 카카오 아이디로 자동 연동 됩니다.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "연동하기",
      cancelButtonText: "연동취소",
    }).then((result) => {
      if (result?.isConfirmed) {
        Kakao.Auth.authorize({
          redirectUri: KAKAO_REDIRECT,
          scope: "account_email",
          prompts: "login",
        });
      }
    });
  };

  const initializeKakaoLogin = () => {
    if (!window.Kakao.isInitialized()) {
      // javascript key 를 이용하여 initialize
      window.Kakao.init(KAKAO_TOKEN);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = KAKAO_SDK;
    script.onload = () => initializeKakaoLogin();
    document.body.appendChild(script);
    return () => script.remove();
  }, []);

  const handleMarketing = () => {
    if (currentUser?.is_marketing) {
      Swal.fire({
        title: "마케팅 수신을 취소하시겠습니까?",
        text: "마케팅 수신을 취소하시면 이벤트 및 할인 정보를 받아보실 수 없습니다.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "마케팅 동의 취소",
        cancelButtonText: "돌아가기",
      }).then((result) => {
        if (result?.isConfirmed) {
          patchUser({
            ...currentUser,
            children_attributes: currentUser?.children,
            is_marketing: !currentUser.is_marketing,
          }).then(() => {
            location.href = "/mypage/edit";
          });
        }
      });
    } else {
      patchUser({
        ...currentUser,
        children_attributes: currentUser?.children,
        is_marketing: !currentUser.is_marketing,
      }).then(() => {
        Swal.fire({
          title: "이제 스몰빅 클래스의 메세지를 받을 수 있어요!",
          confirmButtonText: "확인",
        }).then(() => {
          location.href = "/mypage/edit";
        });
      });
    }
  };

  return (
    <div className="mt-10 my-profile" css={contentContainerStyle}>
      <EditProfileImg page="mypageEdit" />

      {!!currentUser?.email && (
        <div className="flex flex-col">
          <div className="mt-8 mb-2 text-lg font-bold">일반 로그인 아이디</div>
          <input
            type="text"
            className="border-gray200 border-[0.5px] rounded-[5px] h-[45px] px-[15px] w-full shrink-0 text-gray300"
            defaultValue={currentUser?.email}
            disabled
          />
        </div>
      )}

      <div className="flex items-center mt-8 mb-3 text-lg font-bold">
        <span>SNS 연동 아이디</span>
      </div>
      <div className="flex items-center justify-between mt-[11px]">
        <span className="mr-4 font-medium shrink-0">카카오</span>
        {!!find(currentUser?.identifies, ["provider", "kakao"]) ? (
          <span className="inline-block px-3 py-1 ml-4 text-sm font-bold leading-tight uppercase transition duration-150 ease-in-out bg-white rounded">
            연동완료
          </span>
        ) : (
          <span
            onClick={KakaoLinkUp}
            className="inline-block px-3 py-1 ml-4 text-sm font-bold leading-tight uppercase transition duration-150 ease-in-out rounded shadow-md cursor-pointer bg-yellow-kakao hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg"
          >
            연동하기
          </span>
        )}
      </div>
      <div className="flex items-center justify-between mb-5 mt-[11px]">
        <span className="mr-4 font-medium shrink-0">네이버</span>
        {!!find(currentUser?.identifies, ["provider", "naver"]) ? (
          <span className="inline-block px-3 py-1 ml-4 text-sm font-bold leading-tight uppercase transition duration-150 ease-in-out bg-white rounded">
            연동완료
          </span>
        ) : (
          <span
            onClick={NaverLinkUp}
            className="inline-block px-3 py-1 ml-4 text-sm font-bold leading-tight text-white uppercase transition duration-150 ease-in-out rounded shadow-md cursor-pointer bg-green-naver hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg"
          >
            연동하기
          </span>
        )}
      </div>

      <div className="flex justify-between items-center my-[30px]">
        <span className="text-lg font-bold">마케팅 정보 제공 동의</span>
        <img
          src={currentUser?.is_marketing ? snsYes : snsNo}
          alt="snsNo"
          onClick={handleMarketing}
          className="cursor-pointer"
        />
      </div>

      <UsersInputLabel labelName="이름" inputId="name" />

      <input
        name="name"
        type="text"
        id="name"
        disabled
        defaultValue={currentUser?.name}
        className="border-gray200 border-[0.5px] text-gray-400 rounded-[5px] h-[45px] px-[15px] mt-[6px] w-full shrink-0 mb-4"
      />

      <MoreInfoForm userType={currentUser?.style} page="mypageEdit" />

      <button
        type="button"
        className="w-full h-[45px] mt-[30px] rounded-[5px] bg-[#F9F9F9] text-gray99"
        onClick={() => {
          Swal.fire({
            title: "정말 로그아웃 하시겠습니까?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#FF0000",
            cancelButtonColor: color["blue-base"],
            confirmButtonText: "로그아웃",
            cancelButtonText: "돌아가기",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              Auth.signOut().then(() => {
                location.href = "/";
              });
            }
          });
        }}
      >
        로그아웃
      </button>
    </div>
  );
};

export default Content;
