import useRemainTimer from "../../hooks/useRemainTimer";
import { css } from "@emotion/react";

const CouponItem = ({ coupon, couponStatus, openCoupon, remainTime }) => {
  remainTime = useRemainTimer(coupon?.coupon_end_at);

  return couponStatus === "able" ? (
    <div
      onClick={openCoupon}
      className="flex flex-col border border-gray100 rounded-[5px] pt-[28px] pb-[13px] px-4 h-full cursor-pointer"
    >
      <span
        className="mb-auto text-2xl font-bold line-ellipsis"
        css={css`
          -webkit-line-clamp: 2;
        `}
      >
        {coupon?.coupon_title}
      </span>
      <span className="text-sm text-gray400 mt-[5px]">
        {coupon?.coupon_detail_title}
      </span>
      <div className="flex justify-between mt-[27px]">
        <span className="text-xs font-bold text-red400">{remainTime}</span>
        <span className="text-xs text-gray400">자세히 보기 &gt;</span>
      </div>
    </div>
  ) : (
    <div
      onClick={openCoupon}
      className="flex flex-col border border-gray100 rounded-[5px] pt-[28px] pb-[13px] px-4 h-full cursor-pointer"
    >
      <span className="mb-auto text-2xl font-bold opacity-30">
        {coupon?.coupon_title}
      </span>
      <span className="text-sm text-gray400 mt-[5px] opacity-30">
        {coupon?.coupon_detail_title}
      </span>
      <div className="flex justify-between mt-[27px]">
        <span className="text-xs font-bold opacity-30">
          {coupon?.used_at === null ? "기간 만료" : "사용 완료"}
        </span>
        <span className="text-xs text-gray400">자세히 보기 &gt;</span>
      </div>
    </div>
  );
};

export default CouponItem;
