import { useState, useEffect } from "react";
import LectureCard from "../../components/shared/LectureCard";

function PreviewCard({ lecture }) {
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <LectureCard lecture={lecture} />
      </div>
    </>
  );
}

export default PreviewCard;
