import { useState } from "react";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
import DaumPostcode from "react-daum-postcode";
import { useFormik } from "formik";
import * as Yup from "yup";

import { postEditOrderAddress } from "../../api";

import closeSm from "../../assets/icons/close_sm.svg";
import rightArrowRed from "../../assets/icons/arrow_right_red.svg";

const OrderItemInfo = ({ myOrder }) => {
  const [isEdit, setIsEdit] = useState(false);

  const validationSchema = Yup.object({
    postCode: Yup.string().required("우편번호를 입력해주세요"),
    shortAddress: Yup.string().required("주소를 입력해주세요"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      postCode: myOrder?.postcode || "",
      shortAddress: myOrder?.short_address || "",
      detailAddress: myOrder?.detail_address || "",
      desc: myOrder?.desc || "",
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      postEditOrderAddress({
        orderId: myOrder?.id,
        postCode: values?.postCode,
        shortAddress: values?.shortAddress,
        detailAddress: values?.detailAddress,
        desc: values?.desc,
      })
        .then(() => setIsEdit(false))
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "배송지 수정에 실패했습니다.",
            text: "문제가 계속 발생할 경우 채널톡으로 문의주세요",
            showConfirmButton: true,
            confirmButtonText: "확인",
          });
        });
    },
  });

  return (
    <>
      {/* 배송 정보 */}
      <div className="flex items-center justify-between mt-[43px]">
        <span className="text-lg font-bold">배송 정보</span>
        <a href="http://st.sweettracker.co.kr/" className="flex text-sm">
          <span className="mr-[11px] text-red400">배송상태 확인</span>
          <img src={rightArrowRed} alt="자세히보기아이콘" />
        </a>
      </div>

      <div className="border rounded-[5px] border-gray100 w-full py-[30px] flex flex-col mt-[14px]">
        <span className="text-sm font-bold mx-[37px]">배송 정보</span>
        <div className="flex text-sm mt-[9px] mx-[37px]">
          <span className="text-gray400 flex-shrink-0 w-[143px]">이름</span>
          <span className="font-medium">{myOrder?.name}</span>
        </div>
        <div className="flex text-sm mt-[5px] mx-[37px]">
          <span className="text-gray400 flex-shrink-0 w-[143px]">연락처</span>
          <span className="font-medium">{myOrder?.tel}</span>
        </div>
        <form
          className={`${isEdit ? "bg-[#F5F5F5] p-[10px] rounded-[5px]" : ""}`}
        >
          <div className="flex text-sm mt-[5px] mx-[37px] items-center">
            <span className="text-gray400 flex-shrink-0 w-[143px]">
              배송주소
            </span>

            <div className="flex flex-col w-full">
              <div className="flex">
                <div className="flex tablet:flex-col mobile:flex-col items-center">
                  <span className="font-medium mr-2">
                    {formik?.values?.postCode}
                  </span>

                  <span className="font-medium">
                    {formik?.values?.shortAddress}
                  </span>

                  {isEdit && (
                    <Popup
                      trigger={
                        <button
                          type="button"
                          className="text-xs font-medium rounded-[3px] flex items-center justify-center w-[78px] h-[28px] bg-[#EEEEEE] ml-2"
                        >
                          주소 검색
                        </button>
                      }
                      lockScroll
                      modal
                      nested
                      className="postCodePopUp"
                    >
                      {(close) => (
                        <div className="flex flex-col p-4 bg-white">
                          <button
                            className="mb-4 ml-auto w-fit"
                            onClick={close}
                          >
                            <img src={closeSm} alt="닫기" className="w-4" />
                          </button>
                          <DaumPostcode
                            onComplete={(data) => {
                              formik?.setFieldValue("postCode", data?.zonecode);
                              formik?.setFieldValue(
                                "shortAddress",
                                data?.address
                              );
                              close();
                            }}
                          />
                        </div>
                      )}
                    </Popup>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex text-sm mt-[5px] mx-[37px]">
            <span className="text-gray400 flex-shrink-0 w-[143px]">
              상세 주소
            </span>
            <input
              name="detailAddress"
              className={`w-full ${isEdit ? "" : "focus:outline-none"}`}
              readOnly={!isEdit}
              value={formik?.values?.detailAddress}
              onChange={formik?.handleChange}
            />
          </div>
          <div className="flex text-sm mt-[5px] mx-[37px]">
            <span className="text-gray400 flex-shrink-0 w-[143px]">
              배송 요청 사항
            </span>
            <input
              name="desc"
              className={`w-full ${isEdit ? "" : "focus:outline-none"}`}
              readOnly={!isEdit}
              value={formik?.values?.desc}
              onChange={formik?.handleChange}
            />
          </div>

          {myOrder?.shipping_info_updatable && (
            <div className="flex text-sm mt-[5px] mx-[37px]">
              <button
                type="button"
                onClick={() => {
                  !isEdit ? setIsEdit(true) : formik?.handleSubmit();
                }}
                className="w-fit text-xs font-medium rounded-[3px] flex items-center justify-center w-[78px] h-[28px] bg-black text-white mt-2"
              >
                {/* 저장 */}
                {isEdit ? "저장" : "배송 정보 변경"}
              </button>
            </div>
          )}
        </form>

        {/* 상품정보 - 루프 돌려야함. 상품정보가 여러개 일 수도 있음.*/}
        <div className="mt-[22px]">
          {myOrder?.order_items?.length > 0 &&
            myOrder?.order_items?.map((item) => {
              return (
                <div
                  key={item?.id}
                  className="mx-[37px] py-[22px] border-t border-gray100"
                >
                  <span className="mt-[21px] text-sm font-bold">상품 정보</span>
                  <div className="flex text-sm mt-[9px]">
                    <span className="text-gray400 flex-shrink-0 w-[143px]">
                      상품명
                    </span>
                    <span className="font-medium">{item?.title}</span>
                  </div>
                  <div className="flex text-sm mt-[5px]">
                    <span className="text-gray400 flex-shrink-0 w-[143px]">
                      상품 수량
                    </span>
                    <span className="font-medium">{item?.quantity}개 </span>
                  </div>
                  <div className="flex text-sm mt-[5px]">
                    <span className="text-gray400 flex-shrink-0 w-[143px]">
                      택배사 정보
                    </span>
                    <span className="font-medium">
                      {item?.status === "completed" && !!item?.company_name
                        ? item?.company_name
                        : "배송 (준비) 중"}
                    </span>
                  </div>
                  <div className="flex text-sm mt-[5px]">
                    <span className="text-gray400 flex-shrink-0 w-[143px]">
                      운송장 번호
                    </span>
                    <span className="font-medium">
                      {item?.status === "completed" && !!item?.invoice_number
                        ? item?.invoice_number
                        : "배송 (준비) 중"}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default OrderItemInfo;
