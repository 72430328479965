import moment from "moment/moment";
import { useEffect, useState } from "react";

export const useDiffTime = ({ endTimeDate, trigger }) => {
  const [timeIsLoading, setTimeIsLoading] = useState(true);
  const endTime = moment(endTimeDate).format("YYYY-MM-DD HH:mm:ss");
  const [diffTime, setDiffTime] = useState(null);

  useEffect(() => {
    const doInterval = setInterval(() => {
      if (moment(endTime).diff(moment()) > 0) {
        setDiffTime(moment(endTime).diff(moment()));
        setTimeIsLoading(false);
      }
    }, 1000);

    return () => clearInterval(doInterval);
  }, [trigger]);

  useEffect(() => {
    const doInterval = setInterval(() => {
      if (moment(endTime).diff(moment()) > 0) {
        setDiffTime(moment(endTime).diff(moment()));
        setTimeIsLoading(false);
      }
    }, 1000);

    return () => clearInterval(doInterval);
  }, []);

  return {
    timeIsLoading,
    duration: moment.duration(diffTime),
    days: moment.duration(diffTime).days(),
    hours: moment.duration(diffTime).hours(),
    minutes: moment.duration(diffTime).minutes(),
    seconds: moment.duration(diffTime).seconds(),
  };
};
