import { useState } from "react";
import { css } from "@emotion/react";
import replySkeleton from "../../assets/icons/reply_skeleton.svg";
import arrowDownGray600 from "../../assets/icons/arrow_down_gray600.svg";
import { ReplyMaster } from "./ReplyMaster";
import arrowUpGray600 from "../../assets/icons/arrow_up_gray600.svg";

export const Reply = ({ qnas, replyTitle }) => {
  const [isReplyFold, setIsReplyFold] = useState(true);

  const openReplies = () => {
    setIsReplyFold(false);
  };

  const closeReplies = () => {
    setIsReplyFold(true);
  };

  const replyFoldBtnStyle = css`
    position: relative;
    &::after {
      height: 57px;
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
      );
      width: 100%;
    }
  `;

  return (
    <>
      {qnas?.length !== 0 &&
        (isReplyFold ? (
          <div className="relative w-full web:mt-4">
            <div css={replyFoldBtnStyle}>
              <img src={replySkeleton} alt="댓글스켈레톤" />
            </div>
            <div
              onClick={openReplies}
              className="hover:bg-yellow100 top-[100%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center justify-center text-gray600 rounded-[50px] w-[176px] h-[38px] mx-auto cursor-pointer absolute z-[15]"
              id="other_question_view"
            >
              <span className="mr-2 text-sm font-medium">{replyTitle}</span>
              <img src={arrowDownGray600} alt="열기" />
            </div>
          </div>
        ) : (
          <div className="overflow-auto">
            {qnas?.map((qna) => {
              return <ReplyMaster key={`스레드_${qna?.id}`} qna={qna} />;
            })}

            <div
              onClick={closeReplies}
              className="hover:bg-yellow100 mt-4 flex items-center justify-center text-gray600 rounded-[50px] w-[176px] mx-auto cursor-pointer py-2"
            >
              <span className="mr-2 text-sm font-medium">
                다른 학생들 질문 닫기
              </span>
              <img src={arrowUpGray600} alt="열기" />
            </div>
          </div>
        ))}
    </>
  );
};
