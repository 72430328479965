import Swal from "sweetalert2";
import { useState, useEffect, useContext, useRef } from "react";

import { DrawerContext } from "../../pages/promotion/Sseobolgga";
import { getWritingOrder, postOrderOption } from "../../api";
import { addComma, setSseoReturnSession } from "../../utils";
import { find } from "lodash";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import closeSm from "../../assets/icons/closeSm.svg";

export const OrderContent = () => {
  const { isUserSignedIn } = useUserSignedIn();

  const { closeDrawer } = useContext(DrawerContext);

  const [orderOptions, setOrderOptions] = useState(null); // []
  const [selectOption, setSelectOption] = useState(null);
  const [selectLecture, setSelectLecture] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    getWritingOrder().then((response) => {
      setOrderOptions(response?.data);
    });
  }, []);

  useEffect(() => {
    if (!!selectOption) {
      const result = find(orderOptions, { id: Number(selectOption) });
      setSelectLecture(result);
    }
  }, [selectOption]);

  const orderParams = {
    order: {
      orderable_type: "Lecture",
      orderable_id: selectLecture?.id,
      order_type: "none",
      price: selectLecture?.price,
      slug: null,
    },
  };

  const handleSubmit = () => {
    postOrderOption(orderParams)
      .then((response) => {
        location.href = `/orders/${response?.data?.order_id}`;
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "오류가 발생했습니다.",
          text: "다시 시도해주세요.",
        });
      });
  };

  const resetOption = () => {
    setSelectOption(null);
    setSelectLecture(null);
    selectRef.current.value = "";
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mx-[15px]">
        <div className="mx-auto web:text-[22px] tablet:text-lg mobile:text-lg font-bold flex items-center justify-center">
          써볼까?! 주문하기
        </div>
        <img
          src={closeSm}
          alt="닫기"
          className="web:w-[22px] tablet:w-[18px] mobile:w-[18px] object-contain cursor-pointer absolute top-[17px] web:right-[15px] tablet:right-[15px] mobile:right-[15px]"
          onClick={closeDrawer}
        />
      </div>

      <span className="text-gray400 font-medium tablet:text-sm mobile:text-sm mt-[3px] mx-[15px] flex items-center justify-center">
        써볼까?! 구매는 한 번에 1개씩 가능합니다.
      </span>

      <div className="flex flex-col mt-[14px]">
        <select
          ref={selectRef}
          name="selectOption"
          className="rounded-[7px] border-[0.75px] mx-[15px] py-[7px] mt-[10px] px-[13px] text-sm h-[35px]"
          onChange={(e) => {
            setSelectOption(e.currentTarget.value);
          }}
        >
          <option value="">옵션</option>
          {orderOptions?.map((orderOption) => {
            return (
              <option
                value={orderOption?.id}
                key={orderOption?.id}
                disabled={!orderOption?.is_active && isUserSignedIn}
              >
                {orderOption?.title}
              </option>
            );
          })}
        </select>

        {!!selectOption && (
          <div className="border-[0.75px] border-gray100 rounded-[7px] flex mx-[15px] mt-[10px] px-[14px] py-[9px] relative">
            <img
              src={selectLecture?.image_url}
              alt="선택강의"
              className="mr-4 max-w-[50px] object-contain"
            />
            <div className="flex flex-col justify-between w-full">
              <div className="flex items-center justify-between">
                <span className="font-medium">{selectLecture?.title}</span>
              </div>
              <div className="flex justify-between mt-[10px] text-sm">
                <span>{addComma(Number(selectLecture?.price))} 원</span>
              </div>
            </div>

            <img
              src={closeSm}
              alt="오더닫기"
              className="absolute w-4 right-[10px] top-[11px] cursor-pointer"
              onClick={resetOption}
            />
          </div>
        )}
      </div>

      <hr className="w-full mt-[15px]" />

      <div className="flex items-center justify-between mx-[15px] mt-[13px]">
        <span className="font-bold text-[#1A32E5] ml-auto web:text-xl">
          {!!selectOption ? "9,900 원" : "0 원"}
        </span>
      </div>

      <button
        id="checkout_btn"
        onClick={
          isUserSignedIn
            ? handleSubmit
            : () => setSseoReturnSession({ orderParams: orderParams })
        }
        type="button"
        className={`web:text-[22px] mx-[15px] text-white rounded-[9px] justify-center items-center py-[11px] mt-[14px] bg-[#1A32E5] ${
          !!selectOption ? "" : "opacity-30"
        } `}
        disabled={!!selectOption ? false : true}
      >
        주문하기
      </button>
    </div>
  );
};
