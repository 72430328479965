import { css } from "@emotion/react";
import { MOBILE_MAX_WIDTH } from "../../constant";

const scrollBoxStyle = css`
  max-height: 170px;
  overflow: auto;
  margin-top: 1.5rem;
  white-space: nowrap;
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 1.25rem 5rem 1.25rem 1.25rem;
  }

  /* always show scrollbars */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d8d8d8;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CautionWeb = ({ age_range, total_time, notice, has_kit }) => {
  return (
    <div className="overflow-auto text-sm bg-gray25" css={scrollBoxStyle}>
      <div className="flex">
        <span className="mr-2 font-bold">[권장 연령]</span>
        <span className="mr-8 text-gray500">{age_range}</span>
        <span className="mr-2 font-bold">[강의 구성]</span>
        <span className="mr-8 text-gray500">{total_time}</span>
        <span className="mr-2 font-bold">[키트 유무]</span>
        <span className="mr-8 text-gray500">
          {has_kit ? "키트 있음" : "키트 없음"}
        </span>
      </div>
      <div className="flex flex-col mt-2">
        <span className="mr-2 font-bold">유의사항</span>
        <span
          className="mr-8 text-gray500"
          css={css`
            overflow-wrap: break-word;
          `}
          dangerouslySetInnerHTML={{ __html: notice }}
        ></span>
      </div>
    </div>
  );
};

export default CautionWeb;
