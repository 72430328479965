import { useEffect } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import { postOrderCheckAuth } from "../api";
import Swal from "sweetalert2";

const loaderStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const OrderServerAuth = (props) => {
  const orderId = props.orderId || null;
  const tid = props.tid || null;
  const amount = props.amount || null;

  const loadEventInit = () => {
    const successResult = () => {
      window.location.href = `/orders/${orderId}/result`;
    };

    const errorResult = () => {
      window.location.href = `/lectures/kids`;
    };

    postOrderCheckAuth({
      orderId,
      params: {
        tid,
        amount,
      },
    })
      .then((response) => {
        const { payment_id: paymentId } = response?.data;
        checkNicePurchaseEvent(paymentId)
          .then(() => {
            setTimeout(() => {
              successResult();
            }, 1000);
          })
          .catch((err) => {
            errorResult();
          });
      })
      .catch((err) => {
        errorResult();
      });
  };

  useEffect(() => {
    loadEventInit();
  }, []);

  return (
    <div css={loaderStyle}>
      <div className="lds-dual-ring"></div>
      <p>결제 진행 중입니다.</p>
      <p>새로 고침하시지 마시고 잠시 기다려주세요.</p>
    </div>
  );
};

export default OrderServerAuth;
