import { useState, useEffect, useRef } from "react";
import moment from "moment";

const useRemainTimer = (endTime, trigger = null) => {
  const [remainTime, setRemainTime] = useState(null);

  const timeRef = useRef(moment());

  const timeFormat = (num) => {
    if (num < 10) return `0${num}`;
    return num;
  };

  const remainTimeToString = (diffTime, diffDay) => {
    const duration = moment.duration(diffTime);
    const hour = duration.hours();
    const min = duration.minutes();
    const sec = duration.seconds();

    if (diffDay >= 1) {
      return `${diffDay}일 ${timeFormat(hour)}:${timeFormat(min)}:${timeFormat(
        sec
      )}`;
    } else if (diffTime >= 0) {
      return `${timeFormat(hour)}:${timeFormat(min)}:${timeFormat(sec)}`;
    }

    return "00:00:00";
  };

  useEffect(() => {
    const countDown = setInterval(() => {
      timeRef.current.add(1, "seconds");
      let diffTime = moment(endTime).diff(moment());
      let diffDay = moment(endTime).diff(moment(), "days");

      setRemainTime(remainTimeToString(diffTime, diffDay));
      if (diffTime < 0) {
        clearInterval(countDown);
      }
    }); //setInterval default delay => 0
    return () => {
      clearInterval(countDown);
    };
  }, [trigger]);

  return remainTime;
};

export default useRemainTimer;
