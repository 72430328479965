import { useEffect, useState } from "react";
import SoldoutModal from "../components/shared/SoldoutModal";

const SoldoutComponent = (props) => {
  const [isOpenSoldout, setIsOpenSoldout] = useState(false);

  useEffect(() => {
    if (props.lectureId === 33 || props.lectureId === 35) {
      setIsOpenSoldout(true);
    }
  }, []);

  return <SoldoutModal isOpen={isOpenSoldout} setIsOpen={setIsOpenSoldout} />;
};

export default SoldoutComponent;
