import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "@emotion/react";

import { getRecommendLectures } from "../../api";
import useDeviceCheck from "../../hooks/useDeviceCheck";

const RecommendLectures = ({ lectureId, className }) => {
  const device = useDeviceCheck();
  const [isSwiperEnd, setIsSwiperEnd] = useState(false);
  const [recommendLectures, setRecommendLectures] = useState(null);

  useEffect(() => {
    getRecommendLectures({ lectureId: lectureId }).then((response) => {
      setRecommendLectures(response?.data);
    });
  }, []);

  return (
    recommendLectures?.length > 0 &&
    (device !== "mobile" ? (
      <div className={className}>
        <div className="font-[700] text-[22px] mb-[20px]">
          이 강의를 추천드려요 ✨
        </div>
        <div className="relative">
          <Swiper
            slidesPerView={3.8}
            spaceBetween={14}
            onReachEnd={(e) => {
              setIsSwiperEnd(e.isEnd && !e.isBeginning);
            }}
          >
            {recommendLectures?.map((lecture) => {
              return (
                <SwiperSlide key={`recommend_class_${lecture?.id}`}>
                  <a href={`/lectures/${lecture?.id}`}>
                    <div className="flex flex-col w-full">
                      <img
                        src={lecture?.image?.thumb?.url || lecture?.image?.url}
                        alt={`추천강의이미지_${lecture?.id}`}
                        className="w-full aspect-square object-cover rounded-[10px]"
                      />
                      <div
                        className="font-[500] line-ellipsis mt-[14px] leading-[19px]"
                        css={css`
                          -webkit-line-clamp: 2;
                        `}
                      >
                        {lecture?.title}
                      </div>
                      <div className="text-gray99 text-[15px] font-[500] mt-2">
                        {lecture?.teacher?.name}
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {!isSwiperEnd && (
            <div
              className="absolute"
              css={css`
                background: linear-gradient(
                  270deg,
                  #ffffff 0%,
                  rgba(0, 0, 0, 0) 80%
                );
                top: 0;
                right: 0;
                height: 100%;
                width: 80px;
                z-index: 1;
              `}
            ></div>
          )}
        </div>
      </div>
    ) : (
      <div className={className}>
        <div className="font-[700] text-[22px] mb-[20px]">
          이 강의를 추천드려요 ✨
        </div>
        <Swiper slidesPerView={2.2} spaceBetween={14}>
          {recommendLectures?.map((lecture) => {
            return (
              <SwiperSlide key={`recommend_class_${lecture?.id}`}>
                <a href={`/lectures/${lecture?.id}`}>
                  <div className="flex flex-col w-full">
                    <img
                      src={lecture?.image?.thumb?.url || lecture?.image?.url}
                      alt={`추천강의이미지_${lecture?.id}`}
                      className="w-full aspect-square object-cover rounded-[10px]"
                    />
                    <div
                      className="font-[500] line-ellipsis mt-[14px] text-sm leading-[17px]"
                      css={css`
                        -webkit-line-clamp: 2;
                      `}
                    >
                      {lecture?.title}
                    </div>
                    <div className="text-gray99 text-[13px] font-[500] mt-2">
                      {lecture?.teacher?.name}
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    ))
  );
};

export default RecommendLectures;
