import { useContext, useState, useEffect } from "react";
import { DrawerContext } from "../../pages/promotion/Sseobolgga";
import { SseobolggaVideoTab } from "./SseobolggaVideoTab";
import { css } from "@emotion/react";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

import { setSseoReturnSession } from "../../utils";
import { SseobolggaFaq } from "./SseobolggaFaq";
// import { RemainTime } from "./RemainTime";
import { postOrderOption, getWritingOrder } from "../../api";

import writingGif from "../../assets/images/sseobolgga/writing_gif.gif";
import image1 from "../../assets/images/sseobolgga/image1.gif";
import image1Bg from "../../assets/images/sseobolgga/image1_bg.png";
import image2 from "../../assets/images/sseobolgga/image2.png";
import image4 from "../../assets/images/sseobolgga/image4.png";
import image6 from "../../assets/images/sseobolgga/image6.png";
import image9_1 from "../../assets/images/sseobolgga/image9_1.png";
import image9_3 from "../../assets/images/sseobolgga/image9_3.png";
import button9900_middle from "../../assets/images/sseobolgga/button9900_middle.png";
import image10 from "../../assets/images/sseobolgga/image10.png";
import button9900 from "../../assets/images/sseobolgga/button9900.png";
import image8_1_main from "../../assets/images/sseobolgga/image8_1_main.png";
import image8_1_sub1 from "../../assets/images/sseobolgga/image8_1_sub1.png";
import image8_1_sub2 from "../../assets/images/sseobolgga/image8_1_sub2.png";
import image8_2_main from "../../assets/images/sseobolgga/image8_2_main.png";
import image8_2_sub1 from "../../assets/images/sseobolgga/image8_2_sub1.png";
import image8_2_sub2 from "../../assets/images/sseobolgga/image8_2_sub2.png";
import image8_3_main from "../../assets/images/sseobolgga/image8_3_main.png";
import image8_3_sub1 from "../../assets/images/sseobolgga/image8_3_sub1.png";
import image8_3_sub2 from "../../assets/images/sseobolgga/image8_3_sub2.png";
import image8_4_main from "../../assets/images/sseobolgga/image8_4_main.png";
import image8_4_sub1 from "../../assets/images/sseobolgga/image8_4_sub1.png";
import image8_4_sub2 from "../../assets/images/sseobolgga/image8_4_sub2.png";
import arrowRWhite from "../../assets/icons/arrow_r_white.svg";
import { LayoutContext } from "../Layout";
import Swal from "sweetalert2";
import moment from "moment";

const image1Style = css`
  background-image: url(${image1Bg});
`;

export const Content = () => {
  const endTimeDate = new Date("2022-11-15T23:59:59+09:00");
  const endTime = moment(endTimeDate).format("YYYY-MM-DD HH:mm:ss");

  const { eventBannerState, navHeight, eventBannerHeight } =
    useContext(LayoutContext);
  const { openDrawer } = useContext(DrawerContext);
  const { isUserSignedIn } = useUserSignedIn();

  const [orderOptions, setOrderOptions] = useState([0, 0, 0, 0]);

  useEffect(() => {
    getWritingOrder().then((response) => {
      setOrderOptions(response?.data);
    });
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("error_message")) {
      Swal.fire({
        icon: "error",
        title: sessionStorage.getItem("error_message"),
        confirmButtonText: "확인",
        willClose: () => {
          sessionStorage.removeItem("error_message");
        },
      });
    }
  }, []);

  const gradeType = new URLSearchParams(window.location.search);

  const [grade, setGrade] = useState(gradeType.get("grade") || "1");

  const setPushState = (stateName) => {
    setGrade(stateName);
    window.history.replaceState(
      stateName,
      null,
      `/sseobolgga?grade=${stateName}`
    );
  };

  const TabBtn = ({ text, isActive, grade }) => {
    return (
      <div
        id={`age_tab_${grade}`}
        onClick={() => {
          setPushState(grade);
          document
            .getElementById("tabSection")
            .scrollIntoView({ behavior: "smooth" });
        }}
        className={`font-[EliceDigitalBaeum-Bd] py-2 cursor-pointer flex items-center justify-center bg-black web:text-xl w-full relative ${
          isActive
            ? "text-white after:content-[''] after:border-b after:border-white after:bottom-0 after:absolute after:w-full after:border-[4px]"
            : "text-[#888888]"
        }`}
      >
        {text}
      </div>
    );
  };

  const LectureBtn = ({ id, text, name, isActive }) => {
    const orderParams = {
      order: {
        orderable_type: "Lecture",
        orderable_id: id,
        order_type: "none",
        price: 9900,
        slug: null,
      },
    };

    const handleClick = () => {
      if (isActive) {
        postOrderOption(orderParams)
          .then((response) => {
            location.href = `/orders/${response?.data?.order_id}`;
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "오류가 발생했습니다.",
              text: "다시 시도해주세요.",
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "이미 구매한 강의입니다.",
          confirmButtonText: "확인",
          confirmButtonColor: "#1A32E5",
        });
      }
    };

    return (
      <button
        id={name}
        type="button"
        onClick={
          isUserSignedIn
            ? () => handleClick()
            : () => setSseoReturnSession({ orderParams: orderParams })
        }
        className="flex items-center justify-center w-full px-[15px] max-w-[650px] bg-[#1A32E5] py-[1rem] text-white font-[700] web:text-[30px] rounded-[10px]"
        css={css`
          box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
        `}
      >
        <div className="shrink-0">{text}</div>
        <img
          src={arrowRWhite}
          alt="결제바로가기"
          className="web:ml-4 tablet:ml-2 mobile:ml-2 tablet:w-3 mobile:w-3"
        />
      </button>
    );
  };

  const lectureDetail = {
    grade1: {
      mainImg: image8_1_main,
      videoUrl:
        "https://smallbig-asset.s3.ap-northeast-2.amazonaws.com/sseobolgga_video/video_1.mp4",
      subImg1: image8_1_sub1,
      subImg2: image8_1_sub2,
      btn: (
        <LectureBtn
          name="sseo-cta_grade1"
          id={orderOptions[0]?.id}
          text="'일기 한 편 써볼까?!' 바로 구매하기"
          isActive={orderOptions[0]?.is_active}
        />
      ),
    },
    grade2: {
      mainImg: image8_2_main,
      videoUrl:
        "https://smallbig-asset.s3.ap-northeast-2.amazonaws.com/sseobolgga_video/video_2.mp4",
      subImg1: image8_2_sub1,
      subImg2: image8_2_sub2,
      btn: (
        <LectureBtn
          name="sseo-cta_grade2_1"
          id={orderOptions[1]?.id}
          text="'편지글 한 편 써볼까?!' 바로 구매하기"
          isActive={orderOptions[1]?.is_active}
        />
      ),
    },
    grade3: {
      mainImg: image8_3_main,
      videoUrl:
        "https://smallbig-asset.s3.ap-northeast-2.amazonaws.com/sseobolgga_video/video_3.mp4",
      subImg1: image8_3_sub1,
      subImg2: image8_3_sub2,
      btn: (
        <LectureBtn
          name="sseo-cta_grade2_2"
          id={orderOptions[2]?.id}
          text="'기사글 한 편 써볼까?!' 바로 구매하기"
          isActive={orderOptions[2]?.is_active}
        />
      ),
    },
  };

  return (
    <div className="font-[EliceDigitalBaeum]">
      <section className="bg-[#1D27DD] w-full" id="section1">
        <div
          className="mx-auto max-w-[650px] min-w-[360px] flex justify-center flex-col items-center"
          css={image1Style}
        >
          <img
            src={image1}
            alt="써볼까배너"
            className="mx-auto w-full px-[15px]"
            id="sseo-banner"
          />
        </div>
      </section>

      <section className="bg-[#1D27DD] w-full" id="section2">
        <div
          className="mx-auto max-w-[650px] min-w-[360px] pb-[35px] flex justify-center flex-col items-center w-full"
          css={image1Style}
        >
          <button type="button" onClick={openDrawer} className="px-[55px]">
            <img
              src={button9900}
              alt="구매버튼최상단"
              className="mx-auto"
              id="sseo-cta_top"
            />
          </button>
        </div>
      </section>

      <section className="bg-[#F7F6F3] w-full" id="section5">
        <div className="mx-auto max-w-[650px] min-w-[360px] flex flex-col items-center px-[15px]">
          <img
            src={image9_1}
            alt="써볼까신청이유"
            className="w-full mt-[26px]"
            id="sseo-why_put"
          />
          <img
            src={image9_3}
            alt="혜택2"
            className="w-full mt-[30px]"
            id="sseo-adv2"
          />
          <button
            type="button"
            onClick={openDrawer}
            className="mt-[60px] mb-[50px] w-full"
          >
            <img
              src={button9900_middle}
              alt="중단버튼"
              className="w-full"
              id="sseo-cta_mid"
            />
          </button>
        </div>
      </section>

      <section className="bg-black" id="section3">
        <div className="mx-auto max-w-[650px] min-w-[360px] flex flex-col items-center bg-black">
          <img
            src={image4}
            alt="아이가하루30분집중"
            className="w-full"
            id="sseo-child_concentrate"
          />
          <div className="px-[20px] bg-black rounded">
            <img
              src={writingGif}
              alt="글쓰기아이예시"
              className="w-full rounded-[18px]"
              id="sseo-writing_example"
            />
          </div>
          <img
            src={image2}
            alt="부모님만족도"
            className="w-full"
            id="sseo-parent_satisfy"
          />
        </div>
      </section>

      <section className="bg-[#F0F0F0] w-full" id="section4">
        <div className="mx-auto max-w-[650px] min-w-[360px] flex flex-col items-center">
          <img
            src={image6}
            alt="어떤과정인가요"
            className="w-full"
            id="sseo-what_curriculum"
          />
        </div>
      </section>

      {/* <SseobolggaVideoTab /> */}
      <section
        id="tabSection"
        css={css`
          @media (max-width: 768px) {
            scroll-margin-top: ${eventBannerState
              ? eventBannerHeight + navHeight - 1
              : navHeight - 1};
          }
        `}
      >
        <div
          className="sticky z-[10] web:top-0 bg-black"
          css={css`
            @media (max-width: 767px) {
              top: ${eventBannerState
                ? eventBannerHeight + navHeight - 1
                : navHeight - 1}px;
            }
          `}
        >
          <div className="max-w-[650px] mx-auto grid grid-cols-2">
            <TabBtn text="예비~3학년" isActive={grade === "1"} grade="1" />
            <TabBtn text="초2~4학년" isActive={grade === "2"} grade="2" />
          </div>
        </div>

        {grade === "1" && (
          <SseobolggaVideoTab
            name="grade1"
            mainImg={lectureDetail.grade1.mainImg}
            videoUrl={lectureDetail.grade1.videoUrl}
            subImg1={lectureDetail.grade1.subImg1}
            subImg2={lectureDetail.grade1.subImg2}
            btn={lectureDetail.grade1.btn}
          />
        )}
        {grade === "2" && (
          <div className="flex flex-col">
            <SseobolggaVideoTab
              name="grade2_1"
              mainImg={lectureDetail.grade2.mainImg}
              videoUrl={lectureDetail.grade2.videoUrl}
              subImg1={lectureDetail.grade2.subImg1}
              subImg2={lectureDetail.grade2.subImg2}
              btn={lectureDetail.grade2.btn}
            />
            <SseobolggaVideoTab
              name="grade2_2"
              inversion={true}
              mainImg={lectureDetail.grade3.mainImg}
              videoUrl={lectureDetail.grade3.videoUrl}
              subImg1={lectureDetail.grade3.subImg1}
              subImg2={lectureDetail.grade3.subImg2}
              btn={lectureDetail.grade3.btn}
            />
          </div>
        )}
      </section>

      <section className="bg-[#E0E0E0] w-full" id="section6">
        <div className="mx-auto max-w-[650px] min-w-[360px] flex flex-col items-center">
          <img
            src={image10}
            alt="진행방법이궁금해요"
            className="w-full"
            id="sseo-how_to_progress"
          />
        </div>
      </section>

      <section className="bg-[#444444] w-full" id="section7">
        <SseobolggaFaq />
      </section>

      <div className="fixed bottom-0 w-full z-[10]">
        <div className="flex items-center justify-center max-w-[650px] min-w-[360px] mx-auto">
          <button
            type="button"
            id="sseo-cta_bottom"
            className="flex justify-center items-center text-white bg-[#1A32E5] font-[700] w-full py-4"
            onClick={openDrawer}
          >
            <span
              css={css`
                font-size: clamp(16px, 4.4vw, 22px);
              `}
            >
              9,900원으로 시작하기 👉
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
