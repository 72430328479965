import saleBasket from "../../assets/icons/sale_basket.svg";

const EventBanner = ({ discount_rate }) => {
  return (
    <div className="web:max-w-[1200px] flex justify-between py-[25px] w-screen bg-yellow800 mx-auto tablet:max-w-[640px] mobile:max-w-[640px] web:mt-[140px] tablet:mt-[80px] mobile:mt-[80px] px-[15px]">
      <div className="flex flex-col web:pl-[55px] tablet:pl-[15px] mobile:pl-[15px] max-w-[1200px]">
        <span className="font-bold text-white opacity-70">
          스몰빅 부모학교 LIVE
        </span>
        <div className="text-blue-base mt-[12px] web:text-[26px] tablet:text-lg mobile:text-lg font-bold">
          <span>3월 25일 (금) 18:00</span>
        </div>
        <span className="text-white mt-[2px] font-bold web:text-[26px] tablet:text-lg mobile:text-lg">
          얼리버드 {discount_rate}% 할인 마감
        </span>
      </div>

      <img
        src={saleBasket}
        alt="할인아이콘"
        className="web:mr-[88px] tablet:max-h-[90px] mobile:max-h-[90px]"
      />
    </div>
  );
};

export default EventBanner;
