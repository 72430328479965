import ReviewNewSuccess from "../components/review/ReviewNewSuccess";

const ReviewResult = ({ lectureId, lectureType, viewerType }) => {
  return (
    <div className="max-w-[640px] mx-auto">
      <ReviewNewSuccess
        viewerType={viewerType}
        lectureType={lectureType}
        lectureId={lectureId}
      />
    </div>
  );
};

export default ReviewResult;
