import React from "react";
import naverSymbol from "../../assets/icons/naver_symbol_logo.svg";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const NAVER_CLIENT_ID =
  process.env.NODE_ENV === "development"
    ? "7vRzvMKyOT03ufJx8LjT"
    : process.env.NAVER_CLIENT_ID;
const NAVER_API_REDIRECT = `${window.location.origin}/users/auth/naver`;
const NAVER_AUTH_URL = "https://nid.naver.com/oauth2.0/authorize";

export const NaverButton = ({ page }) => {
  const { isUserSignedIn } = useUserSignedIn();

  const generateState = () => {
    let t = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (n) {
        var e = (t + 16 * Math.random()) % 16 | 0;
        return (
          (t = Math.floor(t / 16)), ("x" === n ? e : (3 & e) | 8).toString(16)
        );
      }
    );
  };

  return (
    <a
      className="flex items-center mr-5 h-[45px] bg-green-naver mt-[7px] rounded-[5px] justify-center w-full shadow"
      href={`${NAVER_AUTH_URL}?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${generateState()}&redirect_uri=${NAVER_API_REDIRECT}`}
    >
      <img src={naverSymbol} alt="네이버로그인심볼" className="max-h-[45px]" />
      <div className="flex justify-center ml-[18px] font-bold text-white">
        {page === "findEmail" ? "네이버 아이디로 로그인" : "네이버로 시작하기"}
      </div>
    </a>
  );
};
export default NaverButton;
