import { useState, useEffect } from "react";
import { css } from "@emotion/react";

import Layout from "../../components/Layout";
import { Content } from "../../components/makeyourdream/index";
import { postOrderOption, getPackageInfo } from "../../api";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { setReturnSession } from "../../utils";

const handleSubmit = (packageInfo) => {
  const orderParams = {
    order: {
      orderable_type: "Package",
      orderable_id: 1,
      order_type: packageInfo?.sales_option,
      price: packageInfo?.main_option_price, //임시
      slug: packageInfo?.slug,
    },
  };

  if (packageInfo?.has_purchased) {
    //이미 구입했으면
    Swal.fire({
      icon: "warning",
      title: "이미 구입한 상품입니다.",
    });
  } else {
    //구입하지 않았으면
    postOrderOption(orderParams)
      .then((response) => {
        location.href = `/orders/${response?.data?.order_id}`;
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "오류가 발생했습니다.",
          text: "다시 시도해주세요.",
        });
      });
  }
};

const GoOrderBtn = ({ packageInfo, isLoading, isDochimom }) => {
  const { isUserSignedIn } = useUserSignedIn();

  return isUserSignedIn
    ? !isLoading && (
        <button
          onClick={() => handleSubmit(packageInfo)}
          type="button"
          id="makeyourdream-cta_bottom"
          className="w-full fixed bottom-[20px] max-w-[650px] w-full h-[52px] flex flex-col items-center justify-center bg-[#FFFFFF] rounded-[10px] w-[90%]"
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
            background: linear-gradient(
              -90deg,
              #ffffff,
              #dddddd,
              #ffffff,
              #eeeeee,
              #ffffff
            );
            animation: gradient 10s ease infinite;
            background-size: 400% 400%;
            @keyframes gradient {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          `}
        >
          <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
            {isDochimom
              ? `42% 할인 받고 수강신청하기 >`
              : `20% 할인 받고 수강신청하기 >`}
          </div>
        </button>
      )
    : !isLoading && (
        <button
          onClick={() => setReturnSession()}
          type="button"
          id="makeyourdream-cta_bottom"
          className="w-full fixed bottom-[20px] max-w-[650px] w-full h-[52px] flex flex-col items-center justify-center bg-[#FFFFFF] rounded-[10px] w-[90%]"
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
            background: linear-gradient(
              -90deg,
              #ffffff,
              #dddddd,
              #ffffff,
              #eeeeee,
              #ffffff
            );
            animation: gradient 10s ease infinite;
            background-size: 400% 400%;
            @keyframes gradient {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          `}
        >
          <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
            {isDochimom
              ? `42% 할인 받고 수강신청하기 >`
              : `20% 할인 받고 수강신청하기 >`}
          </div>
        </button>
      );
};

const Makeyourdream = (props) => {
  const [packageInfo, setPackageInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getPackageInfo({ packageClassId: 1, slug: props?.slug }).then(
      (response) => {
        setPackageInfo(response?.data);
      }
    );
    setIsLoading(false);
  }, []);

  const [isDochimom, setIsDochimom] = useState(
    props?.slug?.includes("dochimom") ? false : false
  );

  return (
    <div className="pb-[52px]">
      <Layout>
        <Content
          handleSubmit={handleSubmit}
          packageInfo={packageInfo}
          isDochimom={isDochimom}
        />

        <GoOrderBtn
          packageInfo={packageInfo}
          isLoading={isLoading}
          isDochimom={isDochimom}
        />
      </Layout>
    </div>
  );
};

export default Makeyourdream;
