import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import "react-calendar/dist/Calendar.css";
import Swal from "sweetalert2";

import { UsersParent } from "./UsersParent";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { deleteChildInfo, getUser, patchUser } from "../../api";
import { returnSessionFunc } from "../../utils";

import closeBtn from "../../assets/icons/close_btn_24_moreinfo.svg";

export const MoreInfo = () => {
  useEffect(() => {
    var _nasa = {};
    window.wcs_add = { wa: "s_18b486de454c" };
    wcs.inflow("smallbigclass.com");
    _nasa["cnv"] = wcs.cnv("2", "1");
    wcs_do(_nasa);
    var _nasa = {};
  }, []);

  const { currentUser, setCurrentUser } = useGetCurrentUser();
  const [infoType, setInfoType] = useState("parent"); // parent, child

  const validationSchema = Yup.object({
    year: Yup.number()
      .min(1900, "태어난 연도를 정확하게 입력해주세요.")
      .max(2030, "태어난 연도를 정확하게 입력해주세요."),
    month: Yup.number()
      .max(12, "태어난 월을 정확하게 입력해주세요.")
      .min(1, "태어난 월을 정확하게 입력해주세요."),
    day: Yup.number()
      .max(31, "태어난 일자를 정확하게 입력해주세요.")
      .min(1, "태어난 일자를 정확하게 입력해주세요."),
  });

  const parentFormik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: {
      nickname: currentUser?.nickname || "",
      year: currentUser?.birthday?.slice(0, 4) || "",
      month: currentUser?.birthday?.slice(5, 7) || "",
      day: currentUser?.birthday?.slice(8, 10) || "",
      gender: currentUser?.gender || "none",
      relation: currentUser?.relation || "기타",
      children: currentUser?.children || [],
    },
    onSubmit: (values) => {
      patchUser({
        nickname: values.nickname,
        birthday: `${values.year}-${values.month}-${values.day}`,
        gender: values?.gender,
        relation: values?.relation,
        children_attributes: values?.children,
      })
        .then(() => returnSessionFunc())
        .catch(() => {
          iziToast.error({
            message: "에러가 발생했습니다.",
            position: "topCenter",
          });
        });
    },
  });

  const childFormik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: {
      nickname: "",
      year: "",
      month: "",
      day: "",
      gender: "none",
    },

    onSubmit: (values) => {
      patchUser({
        nickname: parentFormik.values?.nickname,
        birthday: `${parentFormik.values?.year}-${parentFormik.values?.month}-${parentFormik.values?.day}`,
        gender: parentFormik.values?.gender,
        relation: parentFormik.values?.relation,
        children_attributes: [
          ...parentFormik.values?.children,
          {
            nickname: values?.nickname,
            birthday: `${values?.year}-${values?.month}-${values?.day}`,
            gender: values?.gender,
          },
        ],
      })
        .then(() => {
          getUser().then((response) => {
            setCurrentUser(response?.data);
          });

          setInfoType("parent");
        })
        .catch(() => {
          iziToast.error({
            message: "에러가 발생했습니다.",
            position: "topCenter",
          });
        });
    },
  });

  return infoType === "parent" ? (
    <UsersParent>
      <form onSubmit={parentFormik.handleSubmit}>
        <div className="flex flex-col mt-[40px]">
          <label
            className="text-[13px] font-[500] text-gray99"
            htmlFor="nickname"
          >
            닉네임
          </label>

          <input
            id="nickname"
            placeholder="홍길동"
            className={`border-b pb-[10px] mt-[14px] outline-none text-lg font-[500] placeholder:text-[#E4E4E4] rounded-[0px] ${
              !!parentFormik.values.nickname
                ? "border-black"
                : "border-[#E4E4E4]"
            }`}
            value={parentFormik.values.nickname}
            onChange={parentFormik.handleChange}
          />
        </div>

        <>
          <div className="flex flex-col mt-[40px]">
            <label
              className="text-[13px] font-[500] text-gray99"
              htmlFor="parentBirthday"
            >
              나의 생일
            </label>

            <div
              id="parentBirthday"
              className="grid grid-cols-3 gap-[14px] mt-[14px]"
            >
              <input
                className={`border-b outline-none text-lg font-[500] text-center pb-[10px] placeholder:text-[#E4E4E4] ${
                  !!parentFormik?.values?.year
                    ? "border-black"
                    : "border-[#E4E4E4]"
                }`}
                name="year"
                type="number"
                value={parentFormik?.values?.year || ""}
                onChange={parentFormik.handleChange}
                placeholder="1900"
              />

              <input
                className={`border-b outline-none text-lg font-[500] text-center pb-[10px] placeholder:text-[#E4E4E4] ${
                  parentFormik?.values?.month
                    ? "border-black"
                    : "border-[#E4E4E4]"
                }`}
                name="month"
                type="number"
                value={parentFormik?.values?.month || ""}
                onChange={parentFormik.handleChange}
                placeholder="12"
              />

              <input
                className={`border-b outline-none text-lg font-[500] text-center pb-[10px] placeholder:text-[#E4E4E4] ${
                  !!parentFormik?.values?.day
                    ? "border-black"
                    : "border-[#E4E4E4]"
                }`}
                name="day"
                type="number"
                value={parentFormik?.values?.day || ""}
                onChange={parentFormik.handleChange}
                placeholder="10"
              />
            </div>
          </div>

          <div className="flex flex-col">
            {parentFormik?.errors?.year && parentFormik?.touched?.year && (
              <div className="text-[#E10000] text-sm mt-2">
                {parentFormik?.errors?.year}
              </div>
            )}
            {parentFormik?.errors?.month && parentFormik?.touched?.month && (
              <div className="text-[#E10000] text-sm mt-2">
                {parentFormik?.errors?.month}
              </div>
            )}
            {parentFormik?.errors?.day && parentFormik?.touched?.day && (
              <div className="text-[#E10000] text-sm mt-2">
                {parentFormik?.errors?.day}
              </div>
            )}
          </div>
        </>

        <div className="flex flex-col mt-[40px]">
          <label
            className="text-[13px] font-[500] text-gray99"
            htmlFor="parentGenders"
          >
            나의 성별
          </label>

          <div
            id="parentGenders"
            className="grid grid-cols-3 mt-[10px] font-[500] gap-[5px]"
          >
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                parentFormik?.values?.gender === "male"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => parentFormik.setFieldValue("gender", "male")}
              type="button"
            >
              남자
            </button>
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                parentFormik?.values?.gender === "female"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => parentFormik.setFieldValue("gender", "female")}
              type="button"
            >
              여자
            </button>
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                parentFormik?.values?.gender === "none"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => parentFormik.setFieldValue("gender", "none")}
              type="button"
            >
              비공개
            </button>
          </div>
        </div>

        <div className="flex flex-col mt-[40px]">
          <label
            className="text-[13px] font-[500] text-gray99"
            htmlFor="childrens"
          >
            아이 정보
          </label>

          {currentUser?.children?.map((child) => {
            return (
              <div
                id="childrens"
                className="mt-[10px] font-[500]"
                key={child?.id}
              >
                <div className="rounded-[5px] border flex items-center justify-between w-full h-[50px] pl-[15px] pr-[22px]">
                  <span>
                    {child?.nickname || "아이1"} |{" "}
                    {moment(child?.birthday).format("YY.MM.DD")}
                  </span>

                  <button
                    type="button"
                    onClick={() =>
                      Swal.fire({
                        title: "자녀 정보를 삭제하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonText: "삭제",
                        cancelButtonText: "취소",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // 삭제 API 호출
                          deleteChildInfo({
                            childId: child?.id,
                          })
                            .then(() => {
                              window.location.reload();
                            })
                            .catch((error) => {
                              if (
                                error.response.status === 404 // 프론트에서만 자녀정보 array가 있고 backend에는 저장된 array element가 없는 경우
                              ) {
                                arrayHelpers.remove(index);
                              } else {
                                iziToast.error({
                                  message: "에러가 발생했습니다.",
                                  position: "topCenter",
                                });
                              }
                            });
                        }
                      })
                    }
                  >
                    <img src={closeBtn} alt={`자녀정보삭제_${child?.id}`} />
                  </button>
                </div>
              </div>
            );
          })}

          <div className="mt-[10px] font-[500]">
            <button
              type="button"
              onClick={() => {
                setInfoType("child");
              }}
              className="rounded-[5px] border flex items-center justify-center w-full h-[50px] border-[#E4E4E4] text-gray99 w-full"
            >
              아이 정보 추가 +
            </button>
          </div>
        </div>

        <div className="flex flex-col mt-[40px]">
          <label
            className="text-[13px] font-[500] text-gray99"
            htmlFor="parentRelation"
          >
            아이와의 관계
          </label>

          <div
            id="parentRelation"
            className="grid grid-cols-3 mt-[10px] font-[500] gap-[5px]"
          >
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                parentFormik?.values?.relation === "엄마"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => parentFormik?.setFieldValue("relation", "엄마")}
              type="button"
            >
              엄마
            </button>
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                parentFormik?.values?.relation === "아빠"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => parentFormik?.setFieldValue("relation", "아빠")}
              type="button"
            >
              아빠
            </button>
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                parentFormik?.values?.relation === "기타"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => parentFormik?.setFieldValue("relation", "기타")}
              type="button"
            >
              기타
            </button>
          </div>
        </div>
        <div className="flex flex-col mt-[53px]">
          <button
            type="button"
            onClick={() => returnSessionFunc()}
            className="text-center text-[#B2B2B2] text-[13px] font-[500] w-full"
          >
            다음에 입력하기
          </button>
          <button
            type="submit"
            className="bg-black text-white rounded-[5px] w-full h-[52px] flex justify-center items-center mt-[20px] font-bold"
          >
            저장하기
          </button>
        </div>
      </form>
    </UsersParent>
  ) : (
    <UsersParent>
      <form onSubmit={childFormik?.handleSubmit}>
        <div className="flex flex-col mt-[40px]">
          <label
            className="text-[13px] font-[500] text-gray99"
            htmlFor="nickname"
          >
            아이 이름
          </label>

          <input
            id="nickname"
            placeholder="홍길동"
            className={`border-b pb-[10px] mt-[14px] outline-none text-lg font-[500] placeholder:text-[#E4E4E4] rounded-[0px] ${
              !!childFormik.values.nickname
                ? "border-black"
                : "border-[#E4E4E4]"
            }`}
            value={childFormik.values.nickname}
            onChange={childFormik.handleChange}
          />
        </div>

        <>
          <div className="flex flex-col mt-[40px]">
            <label
              className="text-[13px] font-[500] text-gray99"
              htmlFor="parentBirthday"
            >
              아이 생년월일
            </label>

            <div
              id="parentBirthday"
              className="grid grid-cols-3 gap-[14px] mt-[14px]"
            >
              <input
                className={`border-b outline-none text-lg font-[500] text-center pb-[10px] placeholder:text-[#E4E4E4] ${
                  !!childFormik?.values?.year
                    ? "border-black"
                    : "border-[#E4E4E4]"
                }`}
                name="year"
                type="number"
                value={childFormik?.values?.year || ""}
                onChange={childFormik.handleChange}
                placeholder="1900"
              />

              <input
                className={`border-b outline-none text-lg font-[500] text-center pb-[10px] placeholder:text-[#E4E4E4] ${
                  childFormik?.values?.month
                    ? "border-black"
                    : "border-[#E4E4E4]"
                }`}
                name="month"
                type="number"
                value={childFormik?.values?.month || ""}
                onChange={childFormik.handleChange}
                placeholder="12"
              />

              <input
                className={`border-b outline-none text-lg font-[500] text-center pb-[10px] placeholder:text-[#E4E4E4] ${
                  !!childFormik?.values?.day
                    ? "border-black"
                    : "border-[#E4E4E4]"
                }`}
                name="day"
                type="number"
                value={childFormik?.values?.day || ""}
                onChange={childFormik.handleChange}
                placeholder="10"
              />
            </div>
          </div>

          {((childFormik?.errors?.year && childFormik?.touched?.year) ||
            (childFormik?.errors?.month && childFormik?.touched?.month) ||
            (childFormik?.errors?.day && childFormik?.touched?.day)) && (
            <div className="text-[#E10000] text-sm mt-2">
              {childFormik?.errors?.year}
            </div>
          )}
        </>

        <div className="flex flex-col mt-[40px]">
          <label
            className="text-[13px] font-[500] text-gray99"
            htmlFor="parentGenders"
          >
            아이 성별
          </label>

          <div
            id="parentGenders"
            className="grid grid-cols-3 mt-[10px] font-[500] gap-[5px]"
          >
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                childFormik?.values?.gender === "male"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => childFormik.setFieldValue("gender", "male")}
              type="button"
            >
              남자
            </button>
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                childFormik?.values?.gender === "female"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => childFormik.setFieldValue("gender", "female")}
              type="button"
            >
              여자
            </button>
            <button
              className={`rounded-[5px] border flex items-center justify-center w-full h-[50px] ${
                childFormik?.values?.gender === "none"
                  ? "border-black text-black"
                  : "border-[#E4E4E4] text-gray99"
              }`}
              onClick={() => childFormik.setFieldValue("gender", "none")}
              type="button"
            >
              비공개
            </button>
          </div>
        </div>

        <div className="flex flex-col mt-[53px]">
          <button
            type="button"
            onClick={() => setInfoType("parent")}
            className="text-center text-[#B2B2B2] text-[13px] font-[500] w-full"
          >
            다음에 입력하기
          </button>
          <button
            type="submit"
            className="bg-black text-white rounded-[5px] w-full h-[52px] flex justify-center items-center mt-[20px] font-bold"
          >
            저장하기
          </button>
        </div>
      </form>
    </UsersParent>
  );
};
