import UserChart from "./UserChart";
import LectureTakeChart from "./LectureTakeChart";
import KakaoAlimTalkInfo from "./KakaoAlimTalkInfo";

const Index = () => {
  return (
    <>
      <KakaoAlimTalkInfo />
      <div className="grid grid-cols-12 gap-4" style={{ height: 500 }}>
        <div className="col-span-6 tablet:col-span-12 mobile:col-span-12">
          <UserChart />
        </div>
        <div className="col-span-6 tablet:col-span-12 mobile:col-span-12">
          <LectureTakeChart />
        </div>
      </div>
    </>
  );
};

export default Index;
