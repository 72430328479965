import { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { color } from "../../tailwindStyle";

const ClassIntroduce = ({ content_image }) => {
  const [isClassIntroSpread, setIsClassIntroSpread] = useState(false);
  const classIntroduceRef = useRef();

  useEffect(() => {
    return () => {
      if (isClassIntroSpread) {
        classIntroduceRef.current.scrollIntoView();
      }
    };
  }, [isClassIntroSpread]);

  const arrowDown = css`
    border-radius: 1px;
    border: solid white;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    vertical-align: middle;
    margin-bottom: 5px;
  `;

  const arrowUp = css`
    border-radius: 1px;
    border: solid white;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    vertical-align: middle;
    margin-top: 8px;
  `;

  const classIntroduceTextStyle = css`
    max-height: 600px;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
      );
      width: 100%;
      height: 6rem;
    }
  `;

  const buttonLookMore = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23.8px;
    background-color: ${color.blue300};
    width: 240px;
    height: 38px;
    content: "더보기";
    color: white;
    cursor: pointer;
  `;

  return (
    <div ref={classIntroduceRef}>
      <div
        className="web:max-w-[1024px] tablet:max-w-[640px] mobile:max-w-[640px] mx-auto default_margin web:mt-[42px]"
        css={!isClassIntroSpread && classIntroduceTextStyle}
      >
        <span className="font-bold web:text-2xl mobile:hidden tablet:hidden">
          클래스 소개
        </span>
        <LazyLoadImage
          src={content_image?.url}
          alt="클래스소개"
          className="w-full web:mt-[54px] tablet:mt-[27px] mobile:mt-[27px] mx-auto max-w-[900px]"
        />
      </div>

      <div
        className="mx-auto mt-4 cursor-pointer"
        css={buttonLookMore}
        onClick={() => {
          setIsClassIntroSpread(!isClassIntroSpread);
        }}
      >
        <span className="mr-4">{isClassIntroSpread ? "접기" : "더보기"}</span>
        <i css={isClassIntroSpread ? arrowUp : arrowDown}></i>
      </div>
    </div>
  );
};

export default ClassIntroduce;
