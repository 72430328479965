import moment from "moment";

export const Content = ({ onedayLecture }) => {
  return (
    <div className="px-4 mx-auto max-w-[650px] flex flex-col">
      <span className="mt-[40px] text-gray99 text-sm">
        {moment(onedayLecture?.start_at).format("YYYY.MM.DD")} ~{" "}
        {moment(onedayLecture?.end_at).format("MM.DD (HH:mm까지)")}
      </span>
      <span className="font-bold text-xl mt-5">{onedayLecture?.title}</span>

      <span></span>
      <div className="border-b-[2px] border-b-black my-5"></div>

      <div dangerouslySetInnerHTML={{ __html: onedayLecture?.content }}></div>
    </div>
  );
};
