import { css } from "@emotion/react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { useState, useEffect, useContext, useRef } from "react";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { lectureDefaultMargin } from "../../style";
import { useCookies } from "react-cookie";
import {
  TeacherIntroduceMobile,
  TeacherIntroduceWeb,
} from "./TeacherIntroduce";
import MainIframe from "./MainIframe";
import CautionAndTitle from "./CautionAndTitle";
import Review from "../showReview/Review";
import CompanyIntroduce from "./CompanyIntroduce";
import WatchingAfter from "./WatchingAfter";
import ClassIntroduce from "./ClassIntroduce";
import KitComponent from "./KitComponent";
import Curriculum from "./Curriculum";
import AddedSection from "./AddedSection";
import { isEmpty } from "lodash";
import { Link, Element } from "react-scroll";
import { LayoutContext } from "../Layout";
import { useScrollDirection } from "../../hooks/useScrollDirection";

const contentStyle = css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    max-height: 7000px;
  }
`;

const Content = ({
  lecture,
  setIsModalOpen,
  setSelectReview,
  hasPurchased,
}) => {
  const { navHeight, eventBannerState, eventBannerHeight } =
    useContext(LayoutContext);

  const lectureTabRef = useRef(null);
  const [lectureTabHeight, setLectureTabHeight] = useState(0);
  const deviceCheck = useDeviceCheck();

  const [isAdded, setIsAdded] = useState(
    !!lecture?.live_image_lg_url &&
      !!lecture?.live_image_sm_url &&
      !!lecture?.live_image_title
  );

  const teacherBannerStyle = css`
    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      position: relative;
      width: 100vw;
      left: -3.75vw;
    }
  `;

  const tabNavStyle = css`
    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      top: ${eventBannerState
        ? navHeight + eventBannerHeight - 1
        : navHeight - 1}px;
    }

    z-index: 3;
    &::-webkit-scrollbar {
      display: none;
    }

    a + a {
      margin-left: 27px;
    }
  `;

  const navProps = {
    activeClass: "!text-black font-bold",
    spy: true,
    smooth: true,
    offset:
      deviceCheck === "mobile"
        ? -(eventBannerState
            ? lectureTabHeight + navHeight + eventBannerHeight
            : lectureTabHeight + navHeight)
        : -(lectureTabHeight + 10),
    duration: 500,
    className:
      "web:text-xl tablet:text-sm mobile:text-sm text-gray200 my-auto cursor-pointer class_top_tab",
  };

  useEffect(() => {
    setLectureTabHeight(lectureTabRef.current.offsetHeight);
  }, [deviceCheck]);

  const { scrollDirection } = useScrollDirection();
  const { setNavHeight, setEventBannerState } = useContext(LayoutContext);
  const [cookies, setCookie] = useCookies(["isEventBannerOpen"]);

  useEffect(() => {
    if (deviceCheck === "mobile") {
      if (scrollDirection === "down") {
        setEventBannerState(false);
        setLectureTabHeight(0);
        setNavHeight(0);
      } else {
        setEventBannerState(
          cookies["isEventBannerOpen"] === "false" ? false : true
        );
        setLectureTabHeight(lectureTabRef.current.offsetHeight);
        setNavHeight(96);
      }
    } else {
      setNavHeight(120);
    }
  }, [deviceCheck, scrollDirection]);

  return (
    <div css={contentStyle}>
      {/* 영상 */}
      {!isEmpty(lecture?.promotion_video) && (
        <div className="class_video_play">
          <MainIframe deviceCheck={deviceCheck} {...lecture} />
        </div>
      )}

      {/* 강좌 제목과 주의사항 */}
      {!isEmpty(lecture?.title) && (
        <CautionAndTitle
          deviceCheck={deviceCheck}
          {...lecture}
          hasAuth={hasPurchased?.has_authority}
        />
      )}

      {/* 메뉴탭 */}
      <nav
        css={tabNavStyle}
        ref={lectureTabRef}
        className="sticky tablet:top-[-1px] web:top-[-1px] mobile:px-[15px] flex mt-6 overflow-auto bg-white mobile:w-screen web:w-full tablet:w-full whitespace-nowrap h-[42px]"
      >
        {!isEmpty(lecture?.desc) && (
          <Link to="tab1" {...navProps}>
            클래스 소개
          </Link>
        )}
        {!isEmpty(lecture?.teacher_banner_lg) &&
          !isEmpty(lecture?.teacher_banner_sm) && (
            <Link to="tab2" {...navProps}>
              강사 소개
            </Link>
          )}

        <Link to="tab3" {...navProps}>
          리뷰
        </Link>
        {!isEmpty(lecture?.lecture_advantages) && (
          <Link to="tab4" className="class_top_tab" {...navProps}>
            이 강의를 들으면
          </Link>
        )}
        {!isEmpty(lecture?.kit_items) && (
          <Link to="tab5" {...navProps}>
            키트 구성
          </Link>
        )}
        {!isEmpty(lecture?.company_name) && (
          <Link to="tab6" {...navProps}>
            업체 소개
          </Link>
        )}
        {isAdded && (
          <Link to="tab7" {...navProps}>
            {lecture?.live_image_title}
          </Link>
        )}
        <Link to="tab8" {...navProps}>
          커리큘럼
        </Link>
      </nav>

      <div css={lectureDefaultMargin}>
        {/* 클래스소개 */}
        {!isEmpty(lecture?.desc) && (
          <Element
            name="tab1"
            className="flex flex-col"
            id="lecture-detail-tab1-content"
          >
            <ClassIntroduce {...lecture} deviceCheck={deviceCheck} />
          </Element>
        )}

        {/* 강사 소개 */}
        {!isEmpty(lecture?.teacher_banner_lg) &&
          !isEmpty(lecture?.teacher_banner_sm) &&
          (deviceCheck === "web" ? (
            <div id="tab2">
              <TeacherIntroduceWeb deviceCheck={deviceCheck} {...lecture} />
            </div>
          ) : (
            <div id="tab2" css={teacherBannerStyle}>
              <TeacherIntroduceMobile deviceCheck={deviceCheck} {...lecture} />
            </div>
          ))}

        {/* 리뷰 */}
        <div id="tab3">
          <Review
            targetId={lecture?.id}
            targetType="Lecture"
            setIsModalOpen={setIsModalOpen}
            setSelectReview={setSelectReview}
          />
        </div>

        {/* 이 강의를 들으면 */}
        {!isEmpty(lecture?.lecture_advantages) && (
          <div id="tab4">
            <WatchingAfter deviceCheck={deviceCheck} {...lecture} />
          </div>
        )}

        <>
          {/* 키트구성 */}
          {!isEmpty(lecture?.kit_items) && (
            <div id="tab5">
              <KitComponent {...lecture} />
            </div>
          )}

          {/* 업체 소개 */}
          {!isEmpty(lecture?.company_name) && (
            <div id="tab6">
              <CompanyIntroduce {...lecture} />
            </div>
          )}
        </>

        {/* 추가 섹션 */}
        {isAdded && (
          <div id="tab7">
            <AddedSection lecture={lecture} deviceCheck={deviceCheck} />
          </div>
        )}

        {/* 커리큘럼 */}
        <div id="tab8">
          <Curriculum
            lectureId={lecture?.id}
            hasAuth={hasPurchased?.has_authority}
          />
        </div>
      </div>
    </div>
  );
};

export default Content;
