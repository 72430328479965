import { css } from "@emotion/react";
import accounceIcon from "../../assets/icons/board/announce.svg";
import { useState } from "react";
import { useEffect } from "react";
import { getPinnedNotice } from "../../api";

const PinnedNotice = () => {
  const [pinnedNotices, setPinnedNotices] = useState(null);
  useEffect(() => {
    getPinnedNotice().then((response) => {
      setPinnedNotices(response?.data);
    });
  }, []);

  return (
    <div
      className="mt-[29px] text-xs"
      css={css`
        .importantItem + .importantItem {
          margin-top: 8px;
        }
      `}
    >
      {pinnedNotices?.map((pinnedNotice) => {
        return (
          <a
            href={`/boards/${pinnedNotice?.id}`}
            key={pinnedNotice?.id}
            className="importantItem item w-full rounded-[12px] flex items-center border-gray100 border p-2 content-start"
          >
            <img src={accounceIcon} alt="알림아이콘" className="mr-[13px]" />
            <span className="mr-[7px] min-w-[50px]">
              {pinnedNotice?.board_type === "notice" ? "[공지]" : "[이벤트]"}
            </span>
            <span>{pinnedNotice?.title}</span>
          </a>
        );
      })}
    </div>
  );
};

export default PinnedNotice;
