import { css } from "@emotion/react"

export const CloseSm = ({ color }) => {
  return (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 40 40" style={{ enableBackground: "new 0 0 40 40" }} xmlSpace="preserve">
      <g>
        <rect css={css`
          fill: ${color};
        `} className="opacity-0" width="40" height="40" />
        <path css={css`
          stroke: ${color};
        `} className="fill-none stroke-[5px]" d="M7.5,7.5l25,25 M7.5,32.5l25-25" />
      </g>
    </svg>

  )
}