import closeIcon from "../../assets/icons/close.svg";
import { addComma } from "../../utils";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { orderBy } from "lodash";

const CouponContent = ({
  closeModal,
  coupons,
  selectCoupon,
  setSelectCoupon,
  closeDrawer,
}) => {
  const deviceCheck = useDeviceCheck();
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <span className="font-medium">쿠폰 선택</span>
        <img
          src={closeIcon}
          alt="닫기"
          className="w-[10px] object-contain cursor-pointer"
          onClick={deviceCheck === "mobile" ? closeDrawer : closeModal}
        />
      </div>
      <div className="border-t flex items-center pt-[12px] pb-[14px] text-sm mt-4">
        <input
          type="radio"
          name="couponRadio"
          id="checkNone"
          value="0"
          checked={selectCoupon?.couponId === 0}
          onChange={() => {
            setSelectCoupon({
              couponId: 0,
              usedCouponName: "적용 안함",
              discountValue: 0,
            });
            deviceCheck === "mobile" ? closeDrawer() : closeModal();
          }}
        />
        <label htmlFor="checkNone" className="ml-[15px] w-full">
          적용 안함
        </label>
      </div>

      {orderBy(coupons, ["abled", "true"], "desc").map((coupon) => {
        return (
          <div
            className="border-t flex flex-col pt-[12px] pb-[14px] text-sm"
            key={coupon.id}
          >
            <div className="flex items-center">
              <input
                type="radio"
                name="couponRadio"
                id={`coupon${coupon?.id}`}
                disabled={!coupon?.abled}
                checked={selectCoupon?.couponId === coupon?.id}
                onChange={() => {
                  setSelectCoupon({
                    couponId: coupon?.id,
                    usedCouponName: coupon?.title,
                    discountValue: coupon?.discount_target_value,
                  });
                  deviceCheck === "mobile" ? closeDrawer() : closeModal();
                }}
              />
              <label
                htmlFor={`coupon${coupon?.id}`}
                className={`ml-[15px] flex flex-col w-full ${
                  coupon?.abled ? "text-black" : "text-gray400"
                }`}
              >
                <span>{coupon?.title}</span>
              </label>
            </div>
            <label
              htmlFor={`coupon${coupon?.id}`}
              className="ml-[27px] flex flex-col w-full"
            >
              <span
                className={`mt-2 font-bold ${
                  coupon?.abled ? "text-blue-base" : "text-gray400"
                }`}
              >
                {addComma(Number(coupon?.discount_target_value))} 원 할인
              </span>
              <span className="mt-2 text-gray400">
                {coupon?.end_time} 23:59 까지
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CouponContent;
