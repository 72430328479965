export const payMethodString = (payMethod) => {
  switch (payMethod) {
    case "card": {
      return "카드 결제";
      break;
    }
    case "vbank": {
      return "무통장 입금";
      break;
    }
  }
};
