import rightArrowRed from "../../assets/icons/arrow_right_red.svg";
import moment from "moment";
import { addComma } from "../../utils";

const OrderRefund = ({ refund }) => {
  return (
    <>
      {/* 주문정보 */}
      <div className="flex items-center justify-between mt-[46px]">
        <span className="text-lg font-bold">
          {moment(refund?.cancelledAt).format("YYYY.MM.DD")}
        </span>
        <a href={refund?.receiptUrl} className="flex text-sm">
          <span className="mr-[11px] text-red400">영수증 보기</span>
          <img src={rightArrowRed} alt="자세히보기아이콘" />
        </a>
      </div>
      <div className="border rounded-[5px] border-gray100 w-full py-[35px] flex flex-col mt-[14px]">
        <span className="text-lg font-bold mx-[37px]">환불 정보</span>

        <div className="flex justify-between items-center text-sm mt-[15px] mx-[37px]">
          <span className="text-gray400">환불 금액</span>
          <span>{addComma(refund?.amount)} 원</span>
        </div>
      </div>
    </>
  );
};

export default OrderRefund;
