import { css } from "@emotion/react";

import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

import defaultProfile from "../../assets/images/default_profile.png";
import asideArrowRLight from "../../assets/icons/aside_arrow_r_light.svg";
import asideArrowRBold from "../../assets/icons/aside_arrow_r_bold";

const ProfileImg = ({ currentUser }) => {
  return (
    <div
      css={css`
        background-image: url(${currentUser?.avatar?.url || defaultProfile});
      `}
      className={`rounded-full bg-cover bg-center web:w-[100px] tablet:w-[100px] mobile:w-[50px] web:h-[100px] tablet:h-[100px] mobile:h-[50px] mx-auto bg-[url(${
        currentUser?.avatar?.url || defaultProfile
      })]`}
    ></div>
  );
};

const Profile = () => {
  const { currentUser } = useGetCurrentUser();

  return (
    <div>
      <div className="flex flex-col web:p-[57px_31px_39px_31px] tablet:p-[57px_31px_39px_31px] mobile:p-[19px_14px_23px_14px] mobile:justify-center w-full h-full rounded-[5px] border border-gray100 web:max-w-[314px] tablet:max-w-[314px]">
        <ProfileImg currentUser={currentUser} />

        <div className="flex items-center justify-between w-full web:mt-[67px] tablet:mt-[67px] mobile:mt-[21px]">
          <span className="font-bold web:text-[24px] tablet:text-[24px] mobile:text-xl mr-[9px] truncate">
            {currentUser?.nickname || currentUser?.name}
          </span>
        </div>
        <span className="mt-2 font-bold break-all web:text-lg tablet:text-lg text-gray400">
          {currentUser?.email}
        </span>
      </div>
    </div>
  );
};

const Menu = ({ pageName }) => {
  const { currentUser } = useGetCurrentUser();

  const Item = ({ name, pageNameProp }) => {
    return (
      <div
        className="flex items-center justify-between web:mt-[48px] tablet:mt-[48px] mobile:mt-[19px]"
        onClick={() => (location.href = `/mypage/${pageNameProp}`)}
      >
        <span
          className={`cursor-pointer ${
            pageName === pageNameProp ? "font-bold" : "text-gray400"
          }`}
        >
          {name}
        </span>
        {pageName === pageNameProp ? (
          <img
            src={asideArrowRBold}
            alt="이동화살표진함"
            className="web:w-[12px] web:h-[14px] tablet:w-[12px] tablet:h-[14px] mobile:w-[8px] mobile:h-[11px] inline-block cursor-pointer"
          />
        ) : (
          <img
            src={asideArrowRLight}
            alt="이동화살표연함"
            className="web:w-[12px] web:h-[14px] tablet:w-[12px] tablet:h-[14px] mobile:w-[8px] mobile:h-[11px] inline-block cursor-pointer"
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col web:text-xl tablet:text-xl">
        <Item name="나의 클래스" pageNameProp="lectures_kids" />
        <Item name="주문/배송 정보" pageNameProp="payment" />
        <Item name="쿠폰/수강권 등록" pageNameProp="coupon" />
        <Item name="내 정보 관리" pageNameProp="edit" />
        {currentUser?.role === "admin" && (
          <Item name="피드백 없는 과제" pageNameProp="mission_history" />
        )}
      </div>
      <div
        className="w-screen h-2 web:hidden tablet:hidden bg-gray100"
        css={css`
          position: absolute;
          left: calc(-50vw + 50%);
        `}
      ></div>
    </>
  );
};

const Aside = ({ pageName }) => {
  return (
    <div className="web:max-w-[314px] tablet:max-w-[314px] web:w-[314px] tablet:w-[314px] mobile:w-full">
      <Profile />
      <Menu pageName={pageName} />
    </div>
  );
};

export default Aside;
