import { css } from "@emotion/react";

const ItemInfo = ({ device, card }) => {
  let textbookQ = 0;
  let deliveryQ = 0;

  card?.order_items.map((item) => {
    if (item?.item_type == "Textbook") {
      textbookQ += item?.quantity;
    } else if (item.item_type == "Delivery") {
      deliveryQ += item?.quantity;
    }
  });

  const totalQ = textbookQ + deliveryQ;

  return (
    <>
      {totalQ > 0 && (
        <>
          {textbookQ > 0 && (
            <>
              <span className="text-gray500">교재 수량&nbsp;</span>
              <span
                className={`text-gray800 ${
                  device == "mobile" ? "font-bold" : null
                }`}
              >
                {textbookQ}
              </span>
            </>
          )}
          {textbookQ > 0 && deliveryQ > 0 && (
            <span className="text-gray500">&nbsp;|&nbsp;</span>
          )}
          {deliveryQ > 0 && (
            <>
              <span className="text-gray500">키트 수량&nbsp;</span>
              <span
                className={`text-gray800 ${
                  device == "mobile" ? "font-bold" : null
                }`}
              >
                {deliveryQ}
              </span>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ItemInfo;
