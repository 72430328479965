import iziToast from "izitoast";
import moment from "moment";
import MyLectureCardNone from "../mypageLectures/MyLectureCardNone";

const Content = ({ liveLectures }) => {
  const BeforeLiveBtn = () => {
    return (
      <button
        className="flex mt-[24px] justify-center items-center rounded-[7px] font-bold py-2 w-full text-white bg-red400"
        disabled
      >
        라이브 예정입니다
      </button>
    );
  };

  const OnLiveBtn = ({ liveLecture }) => {
    return (
      <a
        href={liveLecture?.live_link}
        className="flex mt-[24px] justify-center items-center rounded-[7px] font-bold py-2 w-full text-white bg-red400"
      >
        라이브 입장하기
      </a>
    );
  };

  const FinishedLiveBtn = () => {
    return (
      <button className="flex mt-[24px] justify-center items-center rounded-[7px] font-bold py-2 w-full bg-gray400 text-white opacity-30">
        종료된 라이브 입니다
      </button>
    );
  };

  const ReplayAbleBtn = ({ liveLecture }) => {
    return (
      <a
        href={liveLecture?.replay_link}
        className="flex mt-[24px] justify-center items-center rounded-[7px] font-bold py-2 w-full text-white bg-red400"
      >
        강연 다시보기
      </a>
    );
  };

  const ReplayReadyBtn = () => {
    return (
      <button
        onClick={() => {
          iziToast.warning({
            message:
              "1~2시간 이내로 ‘다시보기’ 영상이 제공될 예정입니다. 조금만 기다려주세요!",
            position: "topCenter",
          });
        }}
        className="flex mt-[24px] justify-center items-center rounded-[7px] font-bold py-2 w-full text-white bg-red400"
      >
        강연 다시보기
      </button>
    );
  };

  const MainText = ({ liveLecture }) => {
    return (
      <span className="font-medium text-sm mt-[13px]">
        {liveLecture?.status === "before_live" &&
          `라이브 일정: ${moment(liveLecture?.start_at)
            .locale("ko")
            .format("MM월 DD일 (ddd) LT")}`}

        {liveLecture?.status === "on_live" &&
          `라이브 30분 전부터 입장 가능합니다.`}

        {["replay_able", "replay_ready"].includes(liveLecture?.status) &&
          `${moment(liveLecture?.start_at)
            .add(7, "days")
            .locale("ko")
            .format("YYYY.MM.DD (ddd)")} 까지 다시보기 시청 가능`}
      </span>
    );
  };

  const SubText = ({ liveLecture }) => {
    return (
      <span className="font-medium text-xs text-gray400 mt-[4px]">
        {liveLecture?.status === "before_live" &&
          `라이브 30분 전부터 입장 가능합니다.`}
        {liveLecture?.status === "on_live" &&
          `‘라이브 입장하기’를 누르면 클래스 창으로 이동합니다.`}

        {["replay_able", "replay_ready"].includes(liveLecture?.status) &&
          `‘강연 다시보기’ 버튼을 누르면 다시보기 시청이 가능합니다.`}
      </span>
    );
  };

  const BottomText = ({ liveLecture }) => {
    return (
      <span className="text-gray400 text-xs font-medium mt-[15px]">
        {["before_live", "on_live"].includes(liveLecture?.status) &&
          `이용기간 :${" "}
          ${moment(liveLecture?.start_at)
            .subtract(30, "minutes")
            .format("YYYY.MM.DD HH:mm")}${" "}
          부터 입장 가능`}

        {["replay_able", "replay_ready"].includes(liveLecture?.status) &&
          `다시보기 영상은 안내드린 기간 이내에만 이용 가능합니다.`}
      </span>
    );
  };

  const MyLiveLectureCard = ({ liveLecture }) => {
    return (
      <div className="border-[2px] border-gray100 rounded-[5px] p-[15px] flex flex-col">
        <img
          src={liveLecture?.image_url}
          alt="라이브클래스썸네일"
          className="w-full"
        />
        <span className="font-bold text-sm mt-[13px]">
          {liveLecture?.special_event_title}
        </span>
        <span className="font-bold truncate">{liveLecture?.title}</span>
        <MainText liveLecture={liveLecture} />
        <SubText liveLecture={liveLecture} />

        {liveLecture?.status === "before_live" && <BeforeLiveBtn />}
        {liveLecture?.status === "on_live" && (
          <OnLiveBtn liveLecture={liveLecture} />
        )}
        {liveLecture?.status === "finished_live" && (
          <FinishedLiveBtn liveLecture={liveLecture} />
        )}
        {/* 강연다시보기활성화 */}
        {liveLecture?.status === "replay_able" && (
          <ReplayAbleBtn liveLecture={liveLecture} />
        )}
        {/* 강연다시보기준비중 */}
        {liveLecture?.status === "replay_ready" && (
          <ReplayReadyBtn liveLecture={liveLecture} />
        )}

        <BottomText liveLecture={liveLecture} />
      </div>
    );
  };

  return liveLectures?.length !== 0 ? (
    <div className="web:mt-[32px] tablet:mt-[32px] mobile:mt-[27px] grid web:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-3">
      {liveLectures.map((liveLecture) => {
        return (
          <MyLiveLectureCard
            key={`수강중인라이브${liveLecture?.id}`}
            liveLecture={liveLecture}
          />
        );
      })}
    </div>
  ) : (
    <MyLectureCardNone />
  );
};

export default Content;
