import { css } from "@emotion/react";
import { useState, useEffect, useRef } from "react";
import { MypageTitle } from "../shared/Title";
import iziToast from "izitoast";
import { color } from "../../tailwindStyle";
import { filter } from "lodash";
import moment from "moment";
import Coupon from "./Coupon";
import EmptyContentCard from "../shared/EmptyContentCard";
import { postRegisterCoupon } from "../../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getMyCoupons } from "../../api";

const Content = () => {
  const [couponStatus, setCouponStatus] = useState("able");
  const [filteredCoupons, setFilteredCoupons] = useState(null);

  const couponsRef = useRef(null);

  const initMyCoupons = () => {
    getMyCoupons().then((response) => {
      couponsRef.current = response?.data;
      setFilteredCoupons(filteringCoupons(couponsRef.current));
    });
  };

  useEffect(() => {
    initMyCoupons();
  }, []);

  useEffect(() => {
    initMyCoupons();
  }, [couponStatus]);

  const filteringCoupons = (coupons) => {
    return filter(coupons, (coupon) => {
      const now = moment();
      const end_at = moment(coupon?.coupon_end_at);
      const duration = end_at.diff(now);
      if (couponStatus === "able") {
        return (
          duration > 0 &&
          coupon?.used_at === null &&
          coupon?.status === "not_use"
        );
      } else {
        return duration <= 0 || coupon?.used_at !== null;
      }
    });
  };

  const couponTabStyle = css`
    button + button {
      margin-left: 36px;
    }

    button.active {
      color: black !important;
    }

    button.active::after {
      position: absolute;
      content: "";
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 7px;
      border-radius: 3.5px;
      background-color: ${color.yellow600};
      border: 1px solid ${color.yellow600};
    }
  `;

  const validationSchema = Yup.object().shape({
    couponCode: Yup.string().required("쿠폰 코드를 입력해주세요."),
  });

  const formik = useFormik({
    initialValues: {
      couponCode: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      postRegisterCoupon({ couponCode: values.couponCode })
        .then((response) => {
          const { code, coupon_type: couponType } = response?.data;

          // 쿠폰 정상 작동 케이스
          if (code === 1) {
            if (couponType === "activation") {
              setSubmitting(false);
              iziToast.success({
                message: "수강권 등록을 성공했습니다.",
                position: "topCenter",
                timeout: 1000,
                onClosed: () => {
                  location.href = "/mypage/lectures_kids";
                },
              });
            } else if (couponType === "price" || couponType === "percent") {
              setSubmitting(false);
              iziToast.success({
                message: "쿠폰 등록을 성공했습니다.",
                position: "topCenter",
                timeout: 1000,
                onClosed: () => {
                  window.location.reload();
                },
              });
            }
            initMyCoupons();
          } else if (code === -1) {
            // CouponService::CouponExistsError 케이스
            setSubmitting(false);
            iziToast.warning({
              message: "해당 쿠폰은 이미 발급되었습니다.",
              position: "topCenter",
              timeout: 1000,
            });
          }
        })
        .catch((err) => {
          setSubmitting(false);
          iziToast.warning({
            message: err?.response?.data?.message,
            position: "topCenter",
            timeout: 1000,
          });
        });
    },
  });

  return (
    <>
      <form
        className="flex web:mb-3 tablet:mb-3 mobile:mb-[10px] h-14"
        onSubmit={formik.handleSubmit}
      >
        <input
          name="couponCode"
          onChange={formik.handleChange}
          placeholder="쿠폰 및 수강권 번호를 입력하세요"
          className="w-full h-full pl-4 rounded-[5px] placeholder-gray400 tablet:text-sm mobile:text-sm border-gray100 border"
        />
        <button
          disabled={formik.isSubmitting}
          type="submit"
          className="web:ml-[9px] tablet:ml-[6px] mobile:ml-[6px] lex w-full items-center flex-shrink-0 justify-center web:max-w-[176px] tablet:max-w-[176px] mobile:max-w-[104px] font-bold text-white rounded-[5px] bg-navy900 web:text-lg tablet:text-lg mobile:text-sm"
        >
          {formik.isSubmitting ? "등록중..." : "등록"}
        </button>
      </form>

      {formik.touched.couponCode && formik.errors.couponCode && (
        <span className="text-red400">{formik.errors.couponCode}</span>
      )}

      <div className="font-normal text-gray400 mobile:mb-[25px]">
        * 수강권은 등록과 동시에 사용됩니다.
      </div>

      <div
        className="w-screen h-2 web:hidden tablet:hidden bg-gray100"
        css={css`
          position: absolute;
          left: calc(-50vw + 50%);
        `}
      ></div>

      <div className="flex web:mt-[34px] tablet:mt-[34px] mobile:mt-[48px]">
        <MypageTitle title="내 쿠폰 / 수강권" />
      </div>

      <div className="flex" css={couponTabStyle}>
        <button
          onClick={() => setCouponStatus("able")}
          className={`relative text-gray400 ${
            couponStatus === "able" ? "active" : ""
          }`}
        >
          사용 가능
        </button>
        <button
          onClick={() => setCouponStatus("disable")}
          className={`relative text-gray400 ${
            couponStatus === "disable" ? "active" : ""
          }`}
        >
          사용 완료 / 기간 만료
        </button>
      </div>

      {couponStatus === "able" &&
        (filteredCoupons?.length !== 0 ? (
          <div className="gap-[11px] web:mt-[39px] tablet:mt-[39px] mobile:mt-[24px] grid web:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1">
            {filteredCoupons?.map((coupon) => {
              return (
                <Coupon
                  coupon={coupon}
                  couponStatus={couponStatus}
                  key={coupon?.id}
                />
              );
            })}
          </div>
        ) : (
          <EmptyContentCard
            text="사용 가능한 쿠폰이 없습니다."
            classNames="mt-[30px] pt-[69px] pb-[56px]"
          />
        ))}

      {couponStatus === "disable" &&
        (filteredCoupons?.length !== 0 ? (
          <div className="gap-[11px] web:mt-[39px] tablet:mt-[39px] mobile:mt-[24px] grid web:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1">
            {filteredCoupons?.map((coupon) => {
              return (
                <Coupon
                  coupon={coupon}
                  couponStatus={couponStatus}
                  key={coupon?.id}
                />
              );
            })}
          </div>
        ) : (
          <EmptyContentCard
            text="사용 완료 / 기간 만료된 쿠폰이 없습니다."
            classNames="mt-[30px] pt-[69px] pb-[56px]"
          />
        ))}
    </>
  );
};

export default Content;
