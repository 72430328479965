import { css } from "@emotion/react";

import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { setReturnSession } from "../../utils";

import image1 from "../../assets/images/cooingklePackage/image1.png";
import image1Infinite from "../../assets/images/cooingklePackage/image1_infinite.png";
import image1Dochimom from "../../assets/images/cooingklePackage/image1_dochimom.png";
import cta1 from "../../assets/images/cooingklePackage/cta1.png";
import cta1Sale2 from "../../assets/images/cooingklePackage/cta1_sale2.png";
import cta1Dochimom from "../../assets/images/cooingklePackage/cta1_dochimom.png";
import cta1Dochimom2 from "../../assets/images/cooingklePackage/cta1_dochimom2.png";
import cta1AfterSale2 from "../../assets/images/cooingklePackage/cta1_after_sale2.png";
import image2 from "../../assets/images/cooingklePackage/image2.png";
import image2Sale2 from "../../assets/images/cooingklePackage/image2_sale2.png";
import image2AfterSale2 from "../../assets/images/cooingklePackage/image2_after_sale2.png";
import image2Dochimom from "../../assets/images/cooingklePackage/image2_dochimom.png";
import image2Dochimom2 from "../../assets/images/cooingklePackage/image2_dochimom2.png";
import image2_2 from "../../assets/images/cooingklePackage/image2_2.png";
import image3 from "../../assets/images/cooingklePackage/image3.png";
import image3AfterSale2 from "../../assets/images/cooingklePackage/image3_after_sale2.png";
import image3Dochimom from "../../assets/images/cooingklePackage/image3_dochimom.png";
import image3Dochimom2 from "../../assets/images/cooingklePackage/image3_dochimom2.png";
import image4 from "../../assets/images/cooingklePackage/image4.png";
import image4Bg from "../../assets/images/cooingklePackage/image4_bg.svg";
import image4BgMobile from "../../assets/images/cooingklePackage/image4_bg_mobile.svg";
import image5 from "../../assets/images/cooingklePackage/image5.png";
import image6 from "../../assets/images/cooingklePackage/image6.png";
import image7 from "../../assets/images/cooingklePackage/image7.png";
import image8 from "../../assets/images/cooingklePackage/image8.png";
import image9 from "../../assets/images/cooingklePackage/image9.png";

export const Content = ({ handleSubmit, packageInfo, isDochimom }) => {
  const { isUserSignedIn } = useUserSignedIn();

  return (
    <div className="flex flex-col">
      <section id="section1" className="bg-[#F2E8FF]">
        <img
          src={isDochimom ? image1Dochimom : image1Infinite}
          alt="image1"
          className="max-w-[650px] w-full mx-auto"
        />
      </section>
      {/* <section
        id="section2"
        className="bg-[#F2E8FF] web:pb-[44px] tablet:pb-[22px] mobile:pb-[22px] flex items-center justify-center"
      >
        {isUserSignedIn ? (
          <button
            type="button"
            className="mx-auto max-w-[650px] w-full"
            onClick={() => handleSubmit(packageInfo)}
          >
            <img src={isDochimom ? cta1Dochimom2 : cta1AfterSale2} alt="cta1" />
          </button>
        ) : (
          <button
            type="button"
            className="mx-auto max-w-[650px] w-full"
            onClick={() => setReturnSession()}
          >
            <img src={isDochimom ? cta1Dochimom2 : cta1AfterSale2} alt="cta1" />
          </button>
        )}
      </section> */}
      <section id="section13" className="bg-[#F7DFEB]">
        <img
          src={image2_2}
          alt="image2_2"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section3" className="bg-[#B744CA]">
        <img
          src={isDochimom ? image2Dochimom2 : image2AfterSale2}
          alt="image2"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section4" className="bg-white">
        <img
          src={isDochimom ? image3Dochimom2 : image3AfterSale2}
          alt="image3"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section
        id="section5"
        css={css`
          @media (min-width: 1024px) {
            background: url(${image4Bg});
          }

          @media (max-width: 1023px) {
            background: url(${image4BgMobile});
          }
        `}
        className="w-full web:h-[108px] tablet:h-[62px] mobile:h-[62px]"
      ></section>
      <section id="section6">
        <img
          src={image4}
          alt="image4"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section7" className="bg-[#C7AFA7]">
        <img
          src={image5}
          alt="image5"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section8">
        <img
          src={image9}
          alt="image9"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section9" className="bg-[#F4DEFC]">
        <img
          src={image6}
          alt="image6"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section10">
        <img
          src={image7}
          alt="image7"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section11" className="bg-[#FCF3FF]">
        <img
          src={image8}
          alt="image8"
          className="mx-auto max-w-[650px] w-full"
        />
      </section>
      <section id="section12">
        <div className="flex flex-col mx-auto max-w-[650px] web:py-[55px] tablet:py-[30px] mobile:py-[30px] web:px-[76px] tablet:px-[43px] mobile:px-[43px] text-gray99">
          <span className="font-bold mt-[30px]">유의사항</span>
          <ul className="list-disc mt-[20px] text-sm">
            <li>{`해당 패키지는 <지원쌤의 쿠잉클 : 달콤 디저트 만들기>, <지원쌤의 쿠잉클2 : 영어동화 스토리쿠킹> 클래스가 포함된 클래스 입니다.`}</li>
            <li>{`이 패키지에는 <지원쌤의 쿠잉클 : 달콤 디저트 만들기>, <지원쌤의 쿠잉클2 : 영어동화 스토리쿠킹>에 포함되어있는 ‘키트 6종’ (키트 3종 세트 2개)이 포함되어있습니다.`}</li>
            <li>{`키트 내 식품이 포함되어 있기 때문에 선주문 후포장 처리되며, 월~목 17시 30분까지 주문 시 익일 출고 됩니다.`}</li>
            <li>{`키트의 유통기한은 출고일로부터 1~5개월 이상이며, 실온보관 해주세요. 자세한 유통기한은 개별 키트에 표시된 유통기한을 확인해주시기 바랍니다. 또한, 최대한 빠른 시일 내에 사용을 권장드립니다.`}</li>
            <li>{`클래스 수강 기간은 기존보다 6개월 연장 혜택이 적용되며, 두 클래스 모두 12개월간 수강하실 수 있습니다.`}</li>
            <li>{`본 상품은 쿠폰 사용이 불가능한 상품입니다.`}</li>
            <li>{`패키지에 포함된 클래스들의 구성을 변경할 수 없습니다.`}</li>
            <li>{`키트 추가 구매는 구매 후, 마이페이지 > 나의 클래스를 통해 가능합니다.`}</li>
            <li className="mt-2">
              <a
                className="underline"
                href="https://smallbigclass.com/boards/19"
              >
                ❖ 쿠잉클1키트 원재료 살펴보기(클릭)
              </a>
            </li>
            <li>
              <a
                className="underline"
                href="https://smallbigclass.com/boards/24"
              >
                ❖ 쿠잉클2키트 원재료 살펴보기(클릭)
              </a>
            </li>
          </ul>
          <span className="font-bold mt-[30px]">환불규정</span>
          <ul className="list-disc mt-[20px] text-sm">
            <li>{`이 클래스는 두 개의 강의가 포함되어있는 패키지 클래스입니다. 환불 시 일부 강의만 환불하는 것은 불가능하며 전체 환불만 가능합니다.`}</li>
            <li>{`두 개의 강의 모두 수강하지 않으신 경우, 강의 수강 시작일로부터(결제일), 7일 이내인 경우 전액 환불해드립니다.`}</li>
            <li>{`정상 수강 기간 (6개월) 또는 수강 진도 1/3 경과 전 결제 대금에 2/3에 해당하는 금액을 환불해드립니다.`}</li>
            <li>{`정상 수강 기간 (6개월) 또는 수강 진도 1/2 경과 전 결제 대금에 1/3에 해당하는 금액을 환불해드립니다.`}</li>
            <li>{`정상 수강 기간 (6개월) 또는 수강 진도 1/2 경과 후 환불이 불가합니다.`}</li>
            <li>{`반품 및 환불 문의는 채널톡을 통해 접수 가능합니다.`}</li>
          </ul>

          <span className="text-sm text-gray99 mt-[30px]">
            ※ 유의사항 미 숙지로 인한 불이익에 대해 책임지지 않습니다.
          </span>
        </div>
      </section>
    </div>
  );
};
