import WatchingLectures from "./WatchingLectures";
import OriginalClass from "./OriginalClass";
import RankSwiper from "./RankSwiper";
import EduTactic from "./EduTactic";
import KitClass from "./KitClass";

export {
  WatchingLectures,
  OriginalClass,
  RankSwiper,
  EduTactic,
  KitClass,
};
