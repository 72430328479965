import { css } from "@emotion/react";
import starFull from "../../assets/icons/star_rank/starFull.svg";
import starHalf from "../../assets/icons/star_rank/starHalf.svg";
import starEmpty from "../../assets/icons/star_rank/starEmpty.svg";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { times } from "lodash";

const StarRankGroup = ({
  value,
  webSize,
  mobileSize,
  widthRatio,
  gapRatio,
}) => {
  const mappingStar = (value) => {
    const doubleRoundValue = Math.ceil(value * 2) / 2;
    const fullStar = doubleRoundValue / 1;
    let halfStar;
    if (doubleRoundValue % 1 === 0.5) {
      halfStar = 1;
    } else {
      halfStar = 0;
    }
    const emptyStar = 5 - fullStar - halfStar;

    return (
      <>
        {times(fullStar).map((e, i) => {
          return (
            <img
              src={starFull}
              css={starStyle}
              className="starImg"
              key={`starfull${i}`}
              alt={`starfull${i}`}
            />
          );
        })}
        {times(halfStar).map((e, i) => {
          return (
            <img
              src={starHalf}
              css={starStyle}
              className="starImg"
              key={`halfFull${i}`}
              alt={`halfFull${i}`}
            />
          );
        })}
        {times(emptyStar).map((e, i) => {
          return (
            <img
              src={starEmpty}
              css={starStyle}
              className="starImg"
              key={`emptyfull${i}`}
              alt={`emptyfull${i}`}
            />
          );
        })}
      </>
    );
  };

  const starStyle = css`
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      width: ${webSize}px;
    }
    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      width: ${mobileSize}px;
    }

    height: auto;
  `;

  const starGroupStyle = css`
    width: ${widthRatio};
    .starImg + .starImg {
      margin-left: ${gapRatio};
    }
  `;

  return (
    <div className="flex" css={starGroupStyle}>
      {mappingStar(value)}
    </div>
  );
};

export default StarRankGroup;
