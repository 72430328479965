import { css } from "@emotion/react";
import { color } from "../../tailwindStyle";

const RatingBar = ({ value, num }) => {
  const ratingBarStyle = css`
    background: linear-gradient(
      to right,
      ${color.yellow600} 0 ${parseInt(value?.toFixed(0))}%,
      ${color.gray100} ${parseInt(value?.toFixed(0))}% 100%
    );
  `;

  return (
    <div className="flex items-center w-full gapStyle">
      <div className="min-w-[18px] text-gray400 text-xs w-[20px]">
        {num}점
      </div>
      <div
        css={ratingBarStyle}
        className="ml-[9px] rounded-[5px] h-[10px] mobile:h-[7px] w-full relative"
      ></div>
    </div>
  );
};

export default RatingBar;
