import { css } from "@emotion/react";
import { LectureTitle } from "../shared/Title";
import { MOBILE_MAX_WIDTH } from "../../constant";
import PropTypes from "prop-types";

const companyIntroContentStyle = css`
  border: 1px solid #d4d4d4;
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    padding: 30px 3.75vw;
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 57px 4.2% 55px 5.3%;
    display: grid;
    grid-template-columns: auto 56.8%;
    gap: 7%;
  }
`;

const CompanyIntroduce = ({
  company_desc,
  company_name,
  company_image_url,
}) => {
  const companyLogo = css`
    width: 140px;
    height: 140px;
    background-image: url(${company_image_url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  `;

  return (
    <div>
      <LectureTitle text="업체 소개" />
      <div className="web:grid" css={companyIntroContentStyle}>
        <div className="flex flex-col items-center justify-center">
          <div css={companyLogo}></div>
          <span
            className="mt-4 web:text-2xl tablet:text-2xl text-gray800 web:pb-2 tablet:pb-2 web:border-b-4 tablet:border-b-4 "
            style={{ borderColor: "#ebf0f5" }}
          >
            {company_name}
          </span>
        </div>
        <span
          className="mobile:text-sm text-gray800 mobile:mt-6"
          dangerouslySetInnerHTML={{ __html: company_desc }}
        ></span>
      </div>
    </div>
  );
};

export default CompanyIntroduce;

CompanyIntroduce.propTypes = {
  company_desc: PropTypes.string,
  company_name: PropTypes.string,
  company_image_url: PropTypes.string,
};
