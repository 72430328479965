import { UsersParent } from "./UsersParent";
import { UsersInput } from "./UsersInput";
import { UsersTitle } from "./UsersTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postChangePw } from "../../api";
import Swal from "sweetalert2";

export const ChangePw = () => {
  const pwSchema = Yup.object().shape({
    newPw: Yup.string().min(6, "6글자 이상으로 입력해주세요.").required(),
    newPwConfirm: Yup.string()
      .oneOf(
        [Yup.ref("newPw")],
        "비밀번호가 일치하지 않습니다. 다시 확인해주세요."
      )
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      newPw: "",
      newPwConfirm: "",
    },
    validateOnBlur: true,
    validationSchema: pwSchema,

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const params = new URLSearchParams(window.location.search);
      postChangePw({
        userEncryptedKey: params.get("user_encrypted_key"),
        email: params.get("email"),
        password: values.newPw,
        passwordConfirmation: values.newPwConfirm,
      })
        .then(() => {
          window.location.href = "/users/sign_in";
        })
        .catch((error) => {
          setSubmitting(false);
          Swal.fire({
            title: error.response.data.message,
          });
        });
    },
  });

  return (
    <UsersParent>
      <UsersTitle text="새 비밀번호 입력" />

      <form onSubmit={formik.handleSubmit} className="flex flex-col">
        <label
          htmlFor="newPw "
          className={`font-medium ${
            formik.errors.newPw && formik.touched.newPw ? "text-red400" : ""
          }`}
        >
          새 비밀번호
        </label>
        <UsersInput
          inputId="newPw"
          inputType="password"
          onChange={formik.handleChange}
          value={formik.values.newPw}
          onBlur={formik.handleBlur}
        />

        <span
          className={`text-xs font-medium mt-[6px] mb-[13px] ${
            formik.errors.newPw && formik.touched.newPw ? "text-red400" : ""
          }`}
        >
          6글자 이상으로 입력해주세요.
        </span>

        <label
          htmlFor="newPwConfirm"
          className={`font-medium ${
            formik.errors.newPwConfirm && formik.touched.newPwConfirm
              ? "text-red400"
              : ""
          }`}
        >
          새 비밀번호 확인
        </label>

        <UsersInput
          inputId="newPwConfirm"
          inputType="password"
          onChange={formik.handleChange}
          value={formik.values.newPwConfirm}
          onBlur={formik.handleBlur}
        />

        {formik.errors.newPwConfirm && formik.touched.newPwConfirm && (
          <span className="text-xs text-red400 mt-[6px]">
            비밀번호가 일치하지 않습니다. 다시 확인해주세요
          </span>
        )}

        <button
          type="submit"
          disabled={formik.isSubmitting}
          className="h-[45px] w-full bg-black rounded-[5px] shrink-0 text-white mt-[28px]"
        >
          {formik.isSubmitting ? "변경 중..." : "비밀번호 변경하기"}
        </button>
      </form>
    </UsersParent>
  );
};
