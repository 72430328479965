import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";
import { useEffect, useState } from "react";
import { addComma } from "../../utils";
import moment from "moment";
import Select from "react-select";
import iziToast from "izitoast";

const UserChart = () => {
  const [signupDatas, setSignupDatas] = useState([]);
  const [scope, setScope] = useState("day");
  const [chartPage, setChartPage] = useState(1);

  useEffect(() => {
    getSignupData({ page: chartPage, pScope: scope });
  }, []);

  const changeDataArray = (apiData) => {
    let arr = [];
    const keys = Object.keys(apiData);
    const values = Object.values(apiData);
    for (let i = 0; i < values.length; i++) {
      if (scope === "month") {
        arr.push({
          date: moment(keys[i]).format("YY년 MM월"),
          subscribers: Number(values[i]),
        });
      } else if (scope === "week") {
        arr.push({
          date:
            moment(keys[i]).format("YY.MM.DD") +
            " ~\r\n" +
            moment(keys[i]).add(6, "days").format("YY.MM.DD"),
          subscribers: Number(values[i]),
        });
      } else if (scope === "day") {
        arr.push({
          date: moment(keys[i]).format("YY년 MM월 DD일"),
          subscribers: Number(values[i]),
        });
      } else if (scope === "year") {
        arr.push({
          date: moment(keys[i]).format("YYYY년"),
          subscribers: Number(values[i]),
        });
      }
    }
    return arr;
  };

  const getSignupData = ({ page, pScope }) => {
    axios
      .get("/api/users/signup_data", {
        params: {
          page: page,
          scope: pScope,
        },
      })
      .then((response) => {
        const result = changeDataArray(response.data.data);
        setSignupDatas(result);
        setChartPage(page);
        setScope(pScope);

        iziToast.success({
          message: "기간별 가입자 수 로드 성공!",
          position: "topCenter",
          timeout: 500,
        });
      })
      .catch((err) => {
        iziToast.error({
          message: "실패!",
          position: "topCenter",
          timeout: 500,
        });
      });
  };

  const refreshData = () => {
    getSignupData({ page: 1, pScope: scope });
  };

  const handleSelectScope = (value) => {
    setScope(value.value);
  };

  const handlePagePrevious = () => {
    getSignupData({ page: chartPage + 1, pScope: scope });
  };

  const handlePageNext = () => {
    getSignupData({ page: chartPage - 1, pScope: scope });
  };

  const getIterSum = () => {
    if (signupDatas?.length < 1) return;
    return addComma(
      signupDatas.reduce((sum, cv, ci) => {
        sum = sum + cv["subscribers"];
        return sum;
      }, 0)
    );
  };

  const chartProps = {
    margin: { top: 60, right: 80, bottom: 60, left: 80 },
    data: signupDatas,
    indexBy: "date",
    padding: 0.2,
    labelTextColor: "inherit:darker(1.4)",
    padding: 0.1,
    keys: ["subscribers"],
    label: (d) => `${addComma(String(d.value))}명`,
    borderWidth: 1,
    borderColor: { from: "color", modifiers: [["darker", 1.6]] },
    axisLeft: {
      format: (value) => {
        if (Number.isInteger(value)) {
          return addComma(String(value));
        } else {
          return "-";
        }
      },
    },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "가입자 수",
      legendPosition: "middle",
      legendOffset: 32,
      tickRotation: -30,
    },
    tooltip: (data) => (
      <div
        style={{
          padding: 12,
          color: data.color,
          background: "black",
        }}
      >
        <strong>
          [{"가입자 수"}] {data.indexValue} : {addComma(String(data.value))}명
        </strong>
      </div>
    ),
    colors: { scheme: "set2" },
    colorBy: "indexValue",
  };

  return (
    <div className="flex flex-col justify-between p-4 border border-black">
      <div className="mb-4 text-xl font-bold">
        기간별 가입자 수
        {signupDatas.length > 0 && (
          <>
            <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-normal leading-none text-black bg-green-300 rounded-full">
              [{signupDatas[0]?.date}] ~ [
              {signupDatas[signupDatas.length - 1]?.date}]
            </span>

            <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-normal leading-none text-black bg-green-300 rounded-full">
              해당 기간 가입자 수 총 합: {getIterSum()}명
            </span>
          </>
        )}
      </div>
      <div>
        <Select
          options={[
            { value: "day", label: "일" },
            { value: "week", label: "주" },
            { value: "month", label: "월" },
            { value: "year", label: "년" },
          ]}
          defaultValue={{ value: "day", label: "일" }}
          onChange={handleSelectScope}
        />
      </div>
      <div className="invisible mt-2">
        <Select />
      </div>
      <button
        className="p-2 mt-2 ml-auto text-white border rounded-md bg-navy900"
        onClick={refreshData}
      >
        제출
      </button>
      <div className="w-full" style={{ height: "50vh" }}>
        <ResponsiveBar {...chartProps} />
      </div>

      <div className="flex justify-between">
        <button onClick={handlePagePrevious}>이전</button>
        <button
          className={chartPage === 1 ? "invisible" : undefined}
          onClick={handlePageNext}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default UserChart;
