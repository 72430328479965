import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { forEach } from "lodash";
import Swal from "sweetalert2";
import axios from "axios";

const LectureRecommend = ({ lectures, selectedList }) => {
  const [spreadLectures, setSpreadLectures] = useState(
    lectures?.map((lecture) => ({
      ...lecture,
      selected: selectedList.includes(lecture.id),
    }))
  );

  const formik = useFormik({
    initialValues: {
      recommendList:
        selectedList.length > 3
          ? selectedList.map((list, index) => {
              return { position: index + 1, lectureId: list };
            })
          : [
              { position: 1, lectureId: null },
              { position: 2, lectureId: null },
              { position: 3, lectureId: null },
              { position: 4, lectureId: null },
            ],
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values?.recommendList.find((recommend) => !recommend.lectureId)) {
        Swal.fire("강의를 모두 선택해주세요");
      } else {
        Swal.fire({
          title: "설정 완료하시겠습니까?",
          confirmButtonText: "설정",
          showCancelButton: true,
          cancelButtonText: "취소",
          showCloseButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .post("/api/lecture_recommend", {
                recommend_list: values.recommendList,
              })
              .then((response) => {
                window.location.href = "/admin/lectures";
              });
          }
        });
      }
      setSubmitting(false);
    },
  });

  const addRecommend = (position) => {
    formik.setValues({
      recommendList: [
        ...formik.values.recommendList,
        { position: position, lectureId: null },
      ],
    });
  };

  const removeRecommend = (position, lectureId) => {
    if (!!lectureId) {
      setSpreadLectures((spreadLectures) =>
        spreadLectures.map((lec) => {
          if (Number(lec.id) === Number(lectureId)) {
            return { ...lec, selected: false };
          }
          return lec;
        })
      );
    }

    formik.setValues({
      recommendList: [
        ...formik.values.recommendList.filter((recommend) => {
          return recommend.position !== position;
        }),
      ],
    });
  };

  useEffect(() => {
    const recommentLectureIds = formik.values.recommendList.map((recomment) =>
      Number(recomment.lectureId)
    );

    setSpreadLectures(
      spreadLectures.map((spreadLecture) => ({
        ...spreadLecture,
        selected: recommentLectureIds.includes(Number(spreadLecture?.id)),
      }))
    );
  }, [formik.values]);

  return (
    <form className="mx-12" onSubmit={formik.handleSubmit}>
      <h3 className="mb-2 text-xl font-bold text-black">강좌 선택하기</h3>
      <div className="mb-4">
        {formik.values.recommendList?.map((recommendInfo, index) => (
          <div className="my-4" key={`select${index}`}>
            <div>{`${recommendInfo.position}위`}</div>
            <div className="flex">
              <select
                className="mr-4 border border-black"
                name={`recommendList.${index}.lectureId`}
                onChange={formik.handleChange}
                value={formik.values.recommendList[index]?.lectureId}
              >
                <option value="">없음</option>
                {spreadLectures?.map((lecture) => {
                  return (
                    <option
                      value={lecture?.id}
                      key={`select${index}_${lecture?.id}`}
                      disabled={lecture?.selected}
                    >
                      {lecture?.title}
                    </option>
                  );
                })}
              </select>
              {formik.values.recommendList.length === index + 1 && (
                <>
                  <button
                    type="button"
                    className="ml-44 font-normal rounded-sm text-black bg-white border border-black text-xl px-3"
                    onClick={() => addRecommend(index + 2)}
                  >
                    +
                  </button>
                  {index > 3 && (
                    <button
                      type="button"
                      className="ml-4 font-normal rounded-sm text-black bg-white border border-black text-xl px-3"
                      onClick={() =>
                        removeRecommend(index + 1, recommendInfo.lectureId)
                      }
                    >
                      -
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <button
        type="submit"
        className="w-16 font-normal rounded-sm bg-blue300 text-white py-2"
      >
        제출
      </button>
    </form>
  );
};

export default React.memo(LectureRecommend);
