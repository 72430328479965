import { useEffect, useState } from "react";
import { MypageTitle } from "../components/shared/Title";
import {
  MyLectureCardNone,
  MyLectureCardExist,
  LiveLectures,
} from "../components/mypageLectures/index";
import Aside from "../components/shared/MypageAside";
import { css } from "@emotion/react";
import { WEB_MAX_WIDTH } from "../constant";
import Layout from "../components/Layout";
import { getMyParentLectures, getMyParentLiveLectures } from "../api";

const MypageLecturesParents = (props) => {
  const [myLectures, setMyLectures] = useState(null);
  const [myLiveLectures, setMyLiveLectures] = useState(null);
  useEffect(() => {
    getMyParentLectures().then((response) => {
      setMyLectures(response?.data);
    });

    getMyParentLiveLectures().then((response) => {
      setMyLiveLectures(response?.data);
    });
  }, []);

  return (
    <Layout>
      <div
        className="flex mypage_lectures default_margin mobile:flex-col"
        css={css`
          max-width: ${WEB_MAX_WIDTH}px;
        `}
      >
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full mobile:mt-5">
            <MypageTitle title="수강 중인 클래스" />
            {myLectures &&
              (myLectures?.length !== 0 ? (
                <MyLectureCardExist value={myLectures} />
              ) : (
                <MyLectureCardNone />
              ))}
          </div>

          <div className="w-full mt-[56px]">
            <MypageTitle title="수강 중인 라이브 클래스" />
            {myLiveLectures &&
              (myLiveLectures?.length !== 0 ? (
                <LiveLectures liveLectures={myLiveLectures} />
              ) : (
                <MyLectureCardNone />
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MypageLecturesParents;
