import { useEffect, useState } from "react";
import { getOptionList } from "../../api";
import closeSm from "../../assets/icons/close_sm.svg";
import { addComma } from "../../utils";

const OrderOption = ({
  setOptionQuantity,
  optionQuantity,
  selectedOptionList,
  setSelectedOptionList,
  orderOptions,
  setOrderOptions,
  setFinalPrice,
  price,
  doDecrease,
  doIncrease,
  is_only,
  option,
  slug,
}) => {
  return (
    <div className="flex flex-col">
      <span className="text-sm mt-[25px] mx-[15px] font-medium">교재 옵션</span>
      {!is_only && (
        <select
          name="selectedOptionList"
          id=""
          className={
            selectedOptionList !== "0"
              ? "rounded-[7px] border-[0.75px] mx-[15px] py-[7px] mt-[10px] text-blue-base px-[13px] text-sm h-[35px]"
              : "rounded-[7px] border-[0.75px] mx-[15px] py-[7px] mt-[10px] px-[13px] text-sm h-[35px]"
          }
          value={selectedOptionList}
          onChange={(e) => {
            setSelectOption("0");
            setOptionQuantity(0);
          }}
        >
          <option value="0">옵션</option>
          {optionLists?.map((optionList, index) => (
            <option
              key={index}
              value={optionList.id}
              disabled={!optionList?.is_active}
            >
              {optionList?.title}
            </option>
          ))}
        </select>
      )}
      {selectedOptionList?.is_active && selectedOptionList?.amount > 0 && (
        <div className="border-[0.75px] border-gray100 rounded-[7px] flex mx-[15px] mt-[10px] px-[13px] py-[10px]">
          <img
            src={option.image_url}
            alt="교재"
            className="w-[49px] h-[71px] mr-[12px] shrink-0 object-contain"
          />
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <span className="text-sm">{option.title}</span>
              {!is_only && (
                <img
                  src={closeSm}
                  alt=""
                  className="object-contain w-2 cursor-pointer shrink-0"
                  onClick={() => {
                    setOptionList("0");
                  }}
                />
              )}
            </div>

            <div className="flex justify-between w-full mt-[25px]">
              <div className="border-[0.75px] border-gray100 rounded-[5px] flex">
                <span
                  className="flex justify-center items-center w-[23px] cursor-pointer border-r-[0.75px] border-r-gray100"
                  onClick={doDecrease}
                >
                  -
                </span>
                <input
                  className="flex justify-center items-center w-[47px] pl-[15px]"
                  type="number"
                  readOnly
                  min="1"
                  value={optionQuantity}
                />
                <span
                  className="flex justify-center items-center w-[23px] cursor-pointer border-l-[0.75px] border-l-gray100"
                  onClick={doIncrease}
                >
                  +
                </span>
              </div>
              <span className="font-medium">
                {addComma(optionQuantity * (selectedOptionList?.amount || 0))}{" "}
                원
              </span>
            </div>
          </div>
        </div>
      )}
      <span
        className="mx-[15px] text-sm font-normal text-gray400 mt-[17px]"
        dangerouslySetInnerHTML={{ __html: option?.notice_text }}
      />
    </div>
  );
};

export default OrderOption;
