import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { addComma } from "../../utils";
import moment from "moment";

const KakaoAlimTalkInfo = () => {
  const [nextSend, setNextSend] = useState(0);
  const [nextDate, setNextDate] = useState("");
  const [currentPoint, setCurrentPoint] = useState(null);

  useEffect(() => {
    axios.get("/api/kakao_alim_talk_info").then((response) => {
      setNextSend(response?.data?.next_send);
      setNextDate(response?.data?.next_date);
      setCurrentPoint(response?.data?.current_point);
    });
  }, []);

  return (
    <div>
      <div className="mb-3 text-lg">
        <span className="">
          현재 알림톡 잔고(예상):
          <span className="font-bold">
            {currentPoint !== null ? `${addComma(Number(currentPoint))}P` : `-`}
          </span>
        </span>
        <span className="ml-4">
          다음에 보낼 알림톡 갯수(예상):
          <span className="font-bold">{addComma(Number(nextSend))} 개</span>
          {!!nextSend && nextSend > 0 && (
            <a
              className="ml-2"
              href={`/admin/all_coupon_users?q[end_at_eq]=${moment(nextDate)
                .format("YYYY-MM-DD")}`}
            >
              확인
            </a>
          )}
        </span>
        <span className="ml-4">
          알림톡 차감 금액(예상):
          <span className="font-bold">
            {addComma(Number(nextSend * 6.5))} 원
          </span>
        </span>
      </div>
    </div>
  );
};

export default KakaoAlimTalkInfo;
