import rightArrowRed from "../../assets/icons/arrow_right_red.svg";
import { addComma } from "../../utils";
import moment from "moment";
import { css } from "@emotion/react";
import Swal from "sweetalert2";

const KgPayCardSm = ({ card }) => {
  return (
    <div className="mb-[26px]">
      <div className="flex items-center justify-between text-sm">
        <span className="text-bold">
          {moment(card?.created_at).format("YYYY.MM.DD")}
        </span>
        <a href={`${card?.receipt_url}`} className="flex text-sm">
          <span className="mr-[11px] text-red400">영수증 보기</span>
          <img src={rightArrowRed} alt="자세히보기아이콘" />
        </a>
      </div>

      <div className="border rounded-[5px] border-gray100 mt-2 py-[18px] px-[13px]">
        <span className="text-sm font-bold">{card?.lecture_title}</span>
        <div className="flex items-center mt-5 font-medium">
          <div
            className="w-[95px] h-[63px] mr-[10px] bg-contain bg-center bg-no-repeat"
            css={css`
              background-image: url(${card?.lecture_image_url});
            `}
          ></div>
          <div className="flex flex-col text-sm">
            <div className="flex">
              <span className="text-gray400 mr-[12px]">
                결제 상태
              </span>
              <span className="font-bold">{card?.payment_status}</span>
            </div>
            <div className="flex mt-[5px]">
              <span className="text-gray400 mr-[12px]">
                결제 금액
              </span>
              <span>{addComma(parseInt(card?.amount))} 원</span>
            </div>
            <div className="flex mt-[5px]">
              <span className="text-gray400 mr-[12px]">
                결제 상태
              </span>
              <span>카드 결제</span>
            </div>
          </div>
        </div>

        {card?.payment_card_name !== null && (
          <div className="flex flex-col">
            <hr className="border border-gray100 w-full mt-[23px]" />

            <div className="flex mt-2 text-sm font-medium">
              <span className="w-[95px] text-gray400">카드 정보</span>
              <span className="">{card?.payment_card_name}</span>
            </div>

            <div className="flex text-sm font-medium">
              <span className="w-[95px] text-gray400">결제 정보</span>
              <span className="">{card?.payment_installment_str}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KgPayCardSm;
