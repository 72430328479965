import { css } from "@emotion/react";
import { MOBILE_MAX_WIDTH } from "../../constant";

export const LectureTitle = ({ text, isInFullScreen }) => {
  const titleStyle = css`
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      font-size: 20px;
    }
    font-weight: bold;
    line-height: 1.83;
    letter-spacing: -0.45px;
    text-align: left;
    color: #454545;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    padding-left: ${isInFullScreen ? `3.75vw` : `0`};
  `;
  return <div css={titleStyle}>{text}</div>;
};

export function MypageTitle(props) {
  const title = props.title;
  const titleColor = props.color;

  const titleStyle = css`
    color: ${titleColor};
  `;
  
  return (
    <div className="flex items-center">
      <div className="mb-4 font-bold label web:text-xl" css={titleStyle}>
        {title}
      </div>
    </div>
  );
}
