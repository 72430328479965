import { forEach } from "lodash";
import { getAhoyAuthenticateUser, postOrderOption } from "./api";
import ahoy from "ahoy.js";

// 일의 자리 숫자라면 앞에 0을 추가해주는 함수
export const addZeroFrontOfNum = (num) => {
  if (num >= 10) {
    return (num + 1).toString();
  } else if (num < 10) {
    const temp = (num + 1).toString();
    return "0" + temp;
  }
};

//세 자리마다 콤마 추가
export const addComma = (num) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ",");
};

//복사하기
export const doCopy = (text) => {
  // 흐름 1.
  if (!document.queryCommandSupported("copy")) {
    return iziToast.error({
      title: "복사 실패",
      message: "지원되지 않는 브라우저 입니다",
      position: "topCenter",
      timeout: 1000,
    });
  }

  // 흐름 2.
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.top = 0;
  textarea.style.left = 0;
  textarea.style.position = "fixed";

  // 흐름 3.
  document.body.appendChild(textarea);
  // focus() -> 사파리 브라우저 서포팅
  textarea.focus();
  // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
  textarea.select();
  // 흐름 4.
  document.execCommand("copy");
  // 흐름 5.
  document.body.removeChild(textarea);
  iziToast.success({
    title: "복사 성공",
    position: "topCenter",
    timeout: 1000,
  });
};

export const setUtm = ({ utm_source, utm_medium, utm_campaign }) => {
  const url = new URL(window.location.href);
  const path = new URL(url.origin + url.pathname);
  const search_params = path.searchParams;

  search_params.append("utm_source", utm_source);
  search_params.append("utm_medium", utm_medium);
  search_params.append("utm_campaign", utm_campaign);

  path.search = search_params.toString();

  return path.toString();
};

//returnSession을 설정하는 코드
export const setReturnSession = (nextPage = null) => {
  let pathLocation = window.location.pathname + window.location.search;

  if (!!nextPage) {
    pathLocation = nextPage;
  }

  if (!pathLocation?.includes("/users")) {
    sessionStorage.setItem("returnUrl", pathLocation);
  }

  location.href = "/users/sign_in";
};

//sseobolgga 전용 returnSession 설정 코드
export const setSseoReturnSession = ({ orderParams }) => {
  sessionStorage.setItem("orderParams", JSON.stringify(orderParams));
  location.href = "/users/sign_in";
};

//returnSession을 이용해서 redirect하는 코드
export const returnSessionFunc = () => {
  getAhoyAuthenticateUser();
  const returnUrl = sessionStorage.getItem("returnUrl") || "/";
  const sseoOrderParams = sessionStorage.getItem("orderParams");

  if (!!sseoOrderParams) {
    //sseoOrder를 이용한 분기
    postOrderOption(sseoOrderParams)
      .then((response) => {
        sessionStorage.removeItem("orderParams");
        sessionStorage.setItem("fallbackFromSseoOrder", "true");
        location.href = `/orders/${response?.data?.order_id}`;
      })
      .catch(() => {
        sessionStorage.removeItem("returnUrl");
        location.href = "/";
      });
  } else {
    sessionStorage.removeItem("returnUrl");
    location.href = returnUrl;
  }
};

export const changeToSchoolAge = (age) => {
  if (age <= 7) {
    return `${age}세`;
  } else if (7 < age && age <= 13) {
    return `초${age - 7}`;
  } else if (13 < age && age <= 16) {
    return `중${age - 13}`;
  } else if (16 < age && age <= 19) {
    return `고${age - 16}`;
  }
};

export const changeToSchoolAgeDnav = (age) => {
  if (age <= 7) {
    return `${age}세`;
  } else if (7 < age && age <= 13) {
    return `초등 ${age - 7}학년`;
  } else if (13 < age && age <= 16) {
    return `중등 ${age - 13}학년`;
  } else if (16 < age && age <= 19) {
    return `고등 ${age - 16}학년`;
  }
};

export const resizeIframes = (className) => {
  const iframes = document.getElementsByClassName(className);
  const parentClass = [
    "relative",
    "web:pb-[45.25%]",
    "tablet:pb-[56.25%]",
    "mobile:pb-[56.25%]",
    "h-0",
  ];

  const iframeClass = ["absolute", "top-0", "left-0", "w-full", "h-full"];

  forEach(iframes, (iframe) => {
    const parentOfIframe = iframe.parentNode;

    parentOfIframe.classList.add(...parentClass);
    iframe.classList.add(...iframeClass);
  });
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const timeForToday = (value) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );
  if (betweenTime < 1) return "방금전";
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 72) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일전`;
  }

  return `${Math.floor(betweenTimeDay / 365)}년전`;
};

//점심 12시 이전 주문이라, 취소가 가능한지 or 점심 12시 이후라서 취소가 불가능한지 알아보는 함수
