import Rating from "react-rating";
import starFull from "../../assets/icons/star_full";
import starEmpty from "../../assets/icons/star_empty";
import { css } from "@emotion/react";
import { MOBILE_MAX_WIDTH } from "../../constant";

const RatingStar = ({ initialRating, readonly, sizeMobile, sizeWeb }) => {
  const reviewStarStyle = css`
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      width: ${sizeWeb}px;
      height: ${sizeWeb}px;
      margin: 0 0.125rem;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      width: ${sizeMobile}px;
      height: ${sizeMobile}px;
      margin: 0 0.1rem;
    }
  `;

  return (
    <Rating
      readonly={readonly}
      initialRating={initialRating}
      emptySymbol={<img src={starEmpty} css={reviewStarStyle} />}
      fullSymbol={<img src={starFull} css={reviewStarStyle} />}
    />
  );
};

export default RatingStar;
