import { FindPw } from "../components/users/index";
import Layout from "../components/Layout";

const UsersFindPw = () => {
  return (
    <Layout>
      <div className="max-w-[1200px] mx-auto">
        <FindPw />
      </div>
    </Layout>
  );
};

export default UsersFindPw;
