import { useState, useCallback } from "react";


const ImageForm = ({ lectureFiles }) => {
  const [files, setFiles] = useState(lectureFiles.map((file) => file.table))
  const [state, setState] = useState({
    isUploading: false,
    isError: false,
    error: null,
  });
  
  // 파일 등록 시 미리보기
  // 기존 파일 삭제 가능
  // 순서 변경 가능해야함
  // 

  // 1. 완전 처음 업로드 할 때
  // 멀티플 파일 업로드 가능
  // 업로드 시 파일 타입에 따라 이미지와 비디오로 나워서 보여줌
  // 포지션 변경할 수 있는 방법 있어야 함(드래그 / 순서대로 업로드  등등)
  // 업로드한 파일 중 특정 파일 삭제 가능하게
  // 

  const inputRef = useCallback((el) => {
    if (!el) return;

    const uploadFiles = async (targetFiles) => {
      setState((state) => ({ ...state, isUploading: true, isError: false }));
      try {
        Array.from(targetFiles).map(async (targetFile) => {
          const fileUrl = URL.createObjectURL(targetFile)
          setFiles((prev) => [...prev, { type: targetFile.type.split("/")[0], url: fileUrl }])
        });
      } catch (error) {
        console.log(error)
        setState((state) => ({ ...state, isError: true, error }));
      } finally {
        setState((state) => ({ ...state, isUploading: false }));
      }
    };
    el.onchange = (e) => {
      useState(lectureFiles)
      uploadFiles(e.target.files);
    };
  }, []);

  const deleteFile = (file) => {
    setFiles((prev) => prev.filter((e) => e.url !== file.url))
  }

  return (
    <li>
      <div className="flex flex-row space-x-4 overflow-scroll">
        <div>
          <label className="aspect-square flex justify-center items-center w-20 h-20 border-2 border-theme-gray-light rounded-2xl"
          style={{ width: '180px'}}>
            <span className="text-[48px]">+</span>
            <input ref={inputRef} type="file" accept="*/*" multiple className="hidden" name="content_files[]"/>
          </label>
        </div>
        {state.isError && <div className="text-lg" style={{ color: "red" }}>오류가 발생했습니다</div>}
        {files?.map((file, index) => (
          <div key={`files_${index}`} className="">
            <div className="aspect-square border-2 border-theme-gray-light rounded-2xl relative" style={{ width: '180px'}}>
              {file.type === "image" && 
                <img
                  src={file.url}
                  className="aspect-square rounded object-cover object-center"
                  style={{ width: '180px'}}
                />
              }
              {file.type === "video" && 
                <video
                  id="vimeo-video" 
                  className="aspect-square rounded object-cover object-center video-js"
                  controls
                  style={{ width: '180px'}}
                >
                  <source src={file.url} type="video/vimeo"/>
                </video>
              }
              <div
                className="cursor-pointer w-[30px] aspect-square p-0 top-0 right-0 absolute rounded-full bg-white border-2 text-center"
                onClick={async () => {
                  await deleteFile(file);
                }}
              >
                <span className="text-center text-[48px]">-</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </li>
  );
}

export default ImageForm;
