import { css } from "@emotion/react";
import { MOBILE_MAX_WIDTH } from "./constant";
import { color } from "./tailwindStyle";

//마이페이지 프로필 수정 input
export const profileEditInput = css`
  border: 1px solid ${color.gray100};
  border-radius: 5px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  min-height: 45px;
  width: 100%;
`;

export const lectureDefaultMargin = css`
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    margin-top: 1rem;
    padding: 0 3.75vw;
  }
`;

export const gridSpanIEStyle = css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    div:nth-of-type(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    div:nth-of-type(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    div:nth-of-type(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    div:nth-of-type(4) {
      -ms-grid-row: 1;
      -ms-grid-column: 4;
    }

    div:nth-of-type(5) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }

    div + div {
      margin-left: 1rem;
    }
  }
`;

export const soldoutStyle = {
  content: {
    maxWidth: "500px",
    position: "fixed",
    top: "50%",
    left: "50%",
    padding: "0",
    transform: "translate(-50%, -50%)",
    height: "fit-content",
    zIndex: 20000000,
    inset: "50% -150px 40px 50%",
    border: "0",
  },
  overlay: {
    background: "rgba(0,0,0,0.75)",
    zIndex: 20000000,
  },
};

export const contentContainerStyle = css`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;

  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    padding-left: 8.75vw;
    padding-right: 8.75vw;
    margin-top: 3rem;
    width: 100%;
  }

  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    max-width: 320px;
  }

  .form-input-text {
    height: 45px;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    border: 1px ${color.gray300} solid;
    color: #131313;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #f7f7f7;

    &::placeholder {
      color: ${color.gray300};
    }
  }

  .form-button-submit {
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    color: white;
    border-radius: 0.25rem;
    background-color: ${color.navy900};
  }

  .form-button-blue {
    background-color: #61b3ec;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    color: white;
    border-radius: 0.25rem;
  }

  .disabled {
    background-color: #d5d6d7 !important;
    color: white !important;
  }

  .add-image {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    width: 79px;
    height: 82px;

    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #000000;
      width: 20px;
      height: 20px;
      font-weight: 900;
      content: "\\f044";
      position: absolute;
      bottom: 0;
      right: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
      background-color: #ffffff;
      font-size: 0.6rem;
      font-family: FontAwesome;
    }
  }
`;

export const orderModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    zIndex: "20000000",
    height: "fit-content",
    maxWidth: "500px",
    width: "90vw",
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "0px",
    paddingRight: "0px",
    border: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "20000000",
  },
};

//review Modal style
export const reviewModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    zIndex: "20000000",
    height: "fit-content",
    maxWidth: "640px",
    maxHeight: "60vh",
    width: "90vw",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "20000000",
  },
};

export const missionMobileTabBtnsStyle = css`
  &.active {
    color: black !important;
  }

  &.active::after {
    position: absolute;
    content: "";
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 5.5px;
    border-radius: 3.5px;
    background-color: ${color.yellow600};
    border: 1px solid ${color.yellow600};
  }
`;
