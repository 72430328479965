import React, { createContext, useState, useEffect, useCallback } from "react";
import Drawer from "react-bottom-drawer";

import {
  Content,
  CouponContent,
  OrderCouponModal,
} from "../components/order/index";
import useDeviceCheck from "../hooks/useDeviceCheck";
import Layout from "../components/Layout";
import { getBeginCheckout, getMyDetailCoupons, getOrder } from "../api";

export const ModalContext = createContext(null);
export const DrawerContext = createContext(null);

const Order = (props) => {
  const deviceCheck = useDeviceCheck();

  const [order, setOrder] = useState({});
  const [orderable, setOrderable] = useState({});
  const [orderableList, setOrderableList] = useState([]);

  const orderInit = () => {
    getOrder({ orderId: props?.orderId })
      .then((response) => {
        setOrder(response?.data?.order);
        setOrderable(response?.data?.orderable);
        setOrderableList(response?.data?.orderable_list);
        getMyDetailCoupons({
          orderId: response?.data?.order?.id,
        }).then((res) => {
          setPersonalCouponDatas(res?.data);
        });
      })
      .catch((error) => {
        sessionStorage.setItem("error_message", error?.response?.data?.message);
        if (sessionStorage.getItem("fallbackFromSseoOrder") === "true") {
          location.href = "/sseobolgga";
        } else {
          location.href = "/";
        }
      });
  };

  const gtagOrderInit = () => {
    getBeginCheckout({ orderId: props?.orderId }).then((response) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: response?.data,
        },
      });
    });
  };

  useEffect(() => {
    if (!!props.orderId) {
      orderInit();
      gtagOrderInit();
    }
  }, [props?.orderId]);

  //액션시트 Drawer에 관련된 State(모바일), //모달에 관련된 State(웹, 태블릿)
  const [isCouponContentOpen, setIsCouponContentOpen] = useState(false);

  //actionSheet callback state
  const closeSheetDrawer = useCallback(() => setIsCouponContentOpen(false), []);
  const openSheetDrawer = useCallback(() => setIsCouponContentOpen(true), []);

  const [personalCouponDatas, setPersonalCouponDatas] = useState([]);

  const [selectCoupon, setSelectCoupon] = useState({
    couponId: 0,
    usedCouponName: "",
    discountValue: 0,
  });

  const closeDrawer = () => {
    setIsCouponContentOpen(false);
  };

  return (
    <Layout>
      <Content
        openSheetDrawer={openSheetDrawer}
        coupons={personalCouponDatas}
        order={order}
        orderable={orderable}
        orderableList={orderableList}
        selectCoupon={selectCoupon}
        setSelectCoupon={setSelectCoupon}
      />
      {deviceCheck === "mobile" ? (
        <Drawer
          duration={250}
          hideScrollbars={true}
          onClose={closeSheetDrawer}
          isVisible={isCouponContentOpen}
          className="orderActionSheetStyle"
        >
          <CouponContent
            coupons={personalCouponDatas}
            selectCoupon={selectCoupon}
            setSelectCoupon={setSelectCoupon}
            closeDrawer={closeDrawer}
          />
        </Drawer>
      ) : (
        <OrderCouponModal
          isCouponContentOpen={isCouponContentOpen}
          setIsCouponContentOpen={setIsCouponContentOpen}
          selectCoupon={selectCoupon}
          setSelectCoupon={setSelectCoupon}
          coupons={personalCouponDatas}
        />
      )}
    </Layout>
  );
};

export default Order;
