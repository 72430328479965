import defaultProfile from "../../assets/icons/default_profile";
import { css } from "@emotion/react";
import { useRef } from "react";
import { patchUserProfile } from "../../api";
import iziToast from "izitoast";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

export const EditProfileImg = ({ page }) => {
  const hiddenFileInputRef = useRef(null);

  const { currentUser } = useGetCurrentUser();

  const imgStyle = css`
    background-image: url(${currentUser?.avatar?.url || defaultProfile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 79px;
    height: 82px;
    position: relative;

    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #000000;
      width: 20px;
      height: 20px;
      font-weight: 900;
      content: "\\f044";
      position: absolute;
      bottom: 0;
      right: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
      background-color: #ffffff;
      font-size: 0.6rem;
      font-family: FontAwesome;
    }
  `;
  const clickEditImg = () => {
    hiddenFileInputRef.current.click();
  };

  const handleImg = () => {
    const formData = new FormData();
    formData.append("avatar", hiddenFileInputRef.current.files[0]);
    patchUserProfile(formData)
      .then(() => {
        if (page === "mypageEdit") {
          location.href = "/mypage/edit";
        } else {
          location.reload();
        }
      })
      .catch(() => {
        iziToast.error({
          message: "프로필 이미지 변경에 실패했습니다.",
          position: "topCenter",
        });
      });
  };

  return (
    <div className="flex justify-center w-full">
      <button type="button" className="mx-auto" onClick={clickEditImg}>
        <input
          type="file"
          className="hidden"
          ref={hiddenFileInputRef}
          onChange={handleImg}
        />
        <div
          className="w-[84px] h-[84px] rounded-full object-cover bg-cover"
          css={imgStyle}
        ></div>
      </button>
    </div>
  );
};
