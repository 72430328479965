import receiptIcon from "../../assets/icons/receipt.svg";
import moment from "moment";
import { doCopy, addComma } from "../../utils";
import DetailBox from "./DetailBox";
import ResultBottomButton from "./ResultBottomButton";

const Vbank = ({ order, orderable }) => {
  return (
    <div className="max-w-[640px] min-w-[330px] w-full mobile:mt-[46px] tablet:mt-[70px] web:mt-[70px] mb-[20px] mx-auto">
      <div className="mx-[15px]">
        <img
          src={receiptIcon}
          alt="영수증"
          className="mx-auto w-[74px]"
        />
        <div className="font-bold mt-[23px] mx-auto text-center">
          아래의 계좌로 입금해주세요
        </div>
        <div className="text-xs text-center mt-[15px] text-gray400">
          무통장 입금 확인은 최대 5~10분 정도 소요될 수 있는 점
          참고부탁드립니다.
        </div>

        <div className="mt-[24px] border-gray100 border rounded-[12px] py-[37px] px-[30px] text-sm flex">
          <div className="flex flex-col min-w-[55px] text-gray400 mr-[28px]">
            <span>은행명</span>
            <span className="mt-[17px]">예금주</span>

            <span className="mt-[17px]">계좌번호</span>

            <span className="mt-[17px]">입금금액</span>
            <span className="mt-[17px]">입금기한</span>
          </div>
          <div className="flex flex-col font-medium">
            <span>{order?.vbank?.vbankName}</span>
            <span className="mt-[17px]">{order?.vbank?.vbankHolder}</span>
            <div className="flex mt-[17px]">
              <span>{order?.vbank?.vbankNumber}</span>
              <span
                className="ml-[14px] underline text-gray400 cursor-pointer"
                onClick={() => {
                  doCopy(order?.vbank?.vbankNumber);
                }}
              >
                복사
              </span>
            </div>
            <span className="mt-[17px] text-red400">
              {addComma(Number(order?.payment_amount))} 원
            </span>
            {/* 2022년 1월 20일 23: 59분 까지 */}
            <span className="mt-[17px]">
              {moment(order?.vbank?.vbankExpDate).format(
                "YYYY년 MM월 DD일 h시 mm분 까지"
              )}
            </span>
          </div>
        </div>
        <DetailBox order={order} orderable={orderable} />
        <ResultBottomButton order={order} orderable={orderable} />
      </div>
    </div>
  );
};

export default Vbank;
