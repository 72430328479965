import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import ReviewSwiperContent from "../showReview/ReviewSwiperContent";
import { LectureTitle } from "../shared/Title";
import { MOBILE_MAX_WIDTH, TABELT_MAX_WIDTH } from "../../constant";

import arrowR from "../../assets/icons/arrow_r.svg";
import starFull from "../../assets/icons/star_rank/starFull.svg";

const ReviewContent = ({
  reviews,
  targetId,
  totalCount,
  targetType,
  setIsModalOpen,
  setSelectReview,
  reviewsMeta,
}) => {
  const CalRating = () => {
    const reviewRatings = reviewsMeta?.rating_count;
    const reviewAvg =
      (reviewRatings[1] * 1 +
        reviewRatings[2] * 2 +
        reviewRatings[3] * 3 +
        reviewRatings[4] * 4 +
        reviewRatings[5] * 5) /
      totalCount;

    return reviewAvg.toFixed(1);
  };

  const [ratingAvg, setRatingAvg] = useState(null);
  useEffect(() => {
    !!totalCount && !!reviewsMeta && setRatingAvg(CalRating());
  }, [reviewsMeta, totalCount]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex flex-col w-full">
          <LectureTitle text="수강생 분들의 소중한 후기" />
          <div className="flex items-center justify-between mb-2 whitespace-nowrap">
            <div className="flex flex-col">
              {totalCount !== 0 && (
                <div className="flex items-baseline">
                  <div className="flex items-center">
                    <img
                      src={starFull}
                      alt="별점"
                      className="w-auto web:h-[33px] tablet:h-[33px] mobile:h-[20px]"
                    />

                    <div className="flex items-baseline">
                      <span className="ml-[9px] mr-[5px] font-bold web:text-[28px] tablet:text-[28px] mobile:text-xl">
                        {ratingAvg}
                        {/* {CalRating()} */}
                      </span>
                      <span className="text-sm text-gray300">
                        총 {totalCount}개
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {reviews?.length !== 0 && (
              <div className="flex items-center">
                <a
                  href={`/reviews?reviewable_id=${targetId}&reviewable_type=${targetType}`}
                >
                  <span className="mr-1 text-sm text-gray500">전체보기</span>
                </a>
                <img
                  src={arrowR}
                  alt="전체보기가기"
                  className="text-gray400 pt-[2px] web:w-[19px] tablet:w-[19px] mobile:w-[14px]"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`${totalCount !== 0 ? "mb-4" : ""}`}>
        <Swiper
          breakpoints={{
            [TABELT_MAX_WIDTH]: {
              slidesPerView: 3,
            },
            [MOBILE_MAX_WIDTH]: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1.15,
            },
          }}
          spaceBetween={15}
        >
          {reviews?.map((reviewItem) => {
            return (
              <SwiperSlide key={`리뷰항목${reviewItem?.id}`}>
                <ReviewSwiperContent
                  review={reviewItem}
                  setIsModalOpen={setIsModalOpen}
                  setSelectReview={setSelectReview}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default ReviewContent;
