import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { css } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { OriginalClassTitle } from "./SectionTitle";
import { color } from "../../tailwindStyle";
import LazyLoad from "react-lazyload";
import { MOBILE_MAX_WIDTH } from "../../constant";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { gridSpanIEStyle } from "../../style";
import VideoJs from "../shared/VideoJs";
import { useState, useEffect } from "react";
import { getOriginalLectures } from "../../api";

SwiperCore.use([Pagination]);

const originalClassSwiper = css`
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    padding-bottom: 85px;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin: 0 7px;
    }
    .swiper-pagination-bullet-active {
      background-color: ${color.navy900};
    }

    .swiper-pagination-bullet {
      &:not(.swiper-pagination-bullet-active) {
        &:hover {
          background-color: #7a8e87 !important;
        }
      }
    }
  }
`;

const TagElement = ({ text }) => {
  return (
    <div className="flex items-center justify-center px-4 py-1 mr-2 border-solid border-gray800 web:text-base tablet:text-sm mobile:text-xs mobile:font-bold rounded-3xl web:border-2 tablet:border mobile:border">
      {text}
    </div>
  );
};

const OriginalClass = () => {
  const deviceCheck = useDeviceCheck();
  const [players, setPlayers] = useState([]);

  const [lectures, setLectures] = useState(null);
  useEffect(() => {
    getOriginalLectures().then((response) => {
      setLectures(response?.data);
    });
  }, []);

  return (
    <div className="w-full default_margin web:mt-17 tablet:mt-17 mobile:mt-10">
      <OriginalClassTitle />
      <Swiper
        slidesPerView={
          deviceCheck === "web" || deviceCheck === "tablet" ? 1 : 1.15
        }
        spaceBetween={deviceCheck === "mobile" && 15}
        css={originalClassSwiper}
        pagination={
          (deviceCheck === "web" || deviceCheck === "tablet") && {
            clickable: true,
          }
        }
        onSlideChange={(swiper) => {
          players.map((player) => player.pause());
        }}
      >
        {lectures?.map((lecture) => {
          return (
            <SwiperSlide key={lecture?.id}>
              <div
                className="grid web:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 web:gap-12 tablet:gap-12 mobile:gap-4"
                css={gridSpanIEStyle}
              >
                <LazyLoad once>
                  <div
                    css={css`
                      float: none;
                      clear: both;
                      width: 100%;
                      position: relative;
                      height: 0;
                      padding-bottom: 56.25%;
                    `}
                  >
                    <VideoJs
                      options={{
                        preload: "none",
                        autoplay: false,
                        controls: true,
                        responsive: true,
                        fluid: true,
                        sources: [
                          {
                            src: lecture?.promotion_video_url,
                            type: "video/vimeo",
                          },
                        ],
                      }}
                      setPlayers={setPlayers}
                    />
                  </div>
                </LazyLoad>
                <div className="flex-col">
                  <a href={`/lectures/${lecture?.id}`}>
                    <div
                      className="grid grid-cols-3 web:flex tablet:flex"
                      css={css`
                        white-space: nowrap;
                      `}
                    >
                      {lecture?.ordered_tag_list?.length !== 0 &&
                        lecture?.ordered_tag_list?.map((tag, index) => {
                          return <TagElement text={tag} key={index} />;
                        })}
                    </div>
                    <div className="mt-2 mb-4 font-bold web:text-2xl tablet:text-2xl mobile:text-xl">
                      {lecture?.title}
                    </div>
                    <div className="leading-normal text-gray700 web:text-lg tablet:text-base mobile:text-sm">
                      {lecture?.short_desc}
                    </div>
                  </a>
                  {!!lecture?.promotion_lesson_link &&
                    lecture?.promotion_lesson_link !== "/lessons/" && (
                      <button
                        className="main_free_class items-center justify-center inline-block mt-4 text-white web:text-lg tablet:text-base mobile:text-sm rounded-3xl bg-navy900"
                        css={css`
                          padding: 0.25rem 2rem;
                        `}
                        onClick={() => {
                          location.href = lecture?.promotion_lesson_link;
                        }}
                      >
                        1강 무료체험 하기
                      </button>
                    )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default OriginalClass;
