module.exports = {
  color: {
    "sky-darken-1": "#759BCC", // -> ??
    
    "green-naver": "#03C75A",
    "yellow-kakao": "#FEE500",

    "gray33": "#333333",
    "gray66": "#666666",
    "gray88": "#888888", 
    "gray99": "#999999", 
    "gray25": "#FAFAFA",
    "gray50": "#F2F2F2",
    "gray100": "#E9E9E9",
    "gray200": "#CCCCCC",
    "gray300": "#B3B3B3",
    "gray400": "#999999", //text-gray-lighten 으로 쓰던 색 
    "gray500": "#808080",
    "gray600": "#666666",
    "gray700": "#4C4C4C",
    "gray800": "#333333",
    "gray900": "#1A1A1A",

    "navy50": "#E4E6EC",
    "navy100": "#BCBFD2",
    "navy200": "#9297B3",
    "navy300": "#6A7095",
    "navy400": "#4D5381",
    "navy500": "#32386F",
    "navy600": "#2C3267",
    "navy700": "#25295D",
    "navy800": "#1E2150",
    "navy900": "#12123A", //스몰빅색

    "red50": "#FFEBED",
    "red100": "#FFCDD0",
    "red200": "#F19A97",
    "red300": "#E7726F",
    "red400": "#F1524A",
    "red500": "#F5422E",
    "red600": "#E7382E",
    "red700": "#D52E28",
    "red800": "#C82621", 
    "red900": "#B91A14",

    "yellow50": "#FFFCE7",
    "yellow100": "#FFF7C3",
    "yellow200": "#FFF29C",
    "yellow300": "#FFED75",
    "yellow400": "#FFE856",
    "yellow500": "#FEE337",
    "yellow600": "#FFD336",
    "yellow700": "#FCBC30",
    "yellow800": "#F9A429",
    "yellow900": "#F47B1F",

    "orange50": "#FCE9E6",
    "orange100": "#FFCDB8",
    "orange200": "#FFAD8B",
    "orange300": "#FF8D5B",
    "orange400": "#FF7434",
    "orange500": "#FF5C00",
    "orange600": "#F45700",
    "orange700": "#E65000",
    "orange800": "#D94900",
    "orange900": "#C13A00",

    "purple50": "#F1E4FF",
    "purple100": "#D8BDFE",
    "purple200": "#BE90FE",
    "purple300": "#A15DFF",
    "purple400": "#8A2EFF",
    "purple500": "#6C00FD",
    "purple600": "#5E00F7",
    "purple700": "#E65000",
    "purple800": "#2A00EC",
    "purple900": "#0000DD",

    "blue-base": "#0049FF", //상세 페이지에서 메인 blue
    "blue50": "#E9EDFE",
    "blue100": "#C6D1FD",
    "blue200": "#9DB4FC",
    "blue300": "#6E96FC",
    "blue400": "#407DFB",
    "blue500": "#0065F2",
    "blue600": "#005CE6",
    "blue700": "#0051D9",
    "blue800": "#0046CC",
    "blue900": "#0033B5",
  },
};
