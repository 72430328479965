import OrderShowNone from "./OrderShowNone";
import OrderShowOption from "./OrderShowOption";
import OrderShowOptionOnly from "./OrderShowOptionOnly";
import { getMyOrderDetail } from "../../api";
import { useState, useEffect } from "react";

const Content = ({ orderId }) => {
  // nicepay를 쓰기 전 order_type
  // 1. none_delivery
  // 2. only_delivery
  // 3. delivery

  // nicepay를 적용한 이후 order_type
  // 1. none
  // 2. kit
  // 3. textbook
  // 4. only_kit
  // 5. only_textbook

  // 결제 리뉴얼를 적용한 이후 order_type
  // 1. delivery
  // 2. only_delivery
  // 3. none

  const [myOrder, setMyOrder] = useState(null);
  useEffect(() => {
    getMyOrderDetail({ params: orderId }).then((response) => {
      setMyOrder(response?.data);
    });
  }, []);

  return (
    <div className="mb-[26px]">
      {myOrder?.order?.order_type === "none" && (
        <OrderShowNone
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}
      {myOrder?.order?.order_type === "textbook" &&
        myOrder?.order?.added_item_amount === 0 && (
          <OrderShowNone
            myOrder={myOrder?.order}
            myOrderOrderable={myOrder?.orderable}
          />
        )}

      {myOrder?.order?.order_type === "kit" && (
        <OrderShowOption
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}
      {myOrder?.order?.order_type === "textbook" &&
        myOrder?.order?.added_item_amount !== 0 && (
          <OrderShowOption
            myOrder={myOrder?.order}
            myOrderOrderable={myOrder?.orderable}
          />
        )}

      {myOrder?.order?.order_type === "only_kit" && (
        <OrderShowOptionOnly
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}
      {myOrder?.order?.order_type === "only_textbook" && (
        <OrderShowOptionOnly
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}

      {myOrder?.order?.order_type === "delivery" && (
        <OrderShowOption
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}

      {myOrder?.order?.order_type === "only_delivery" && (
        <OrderShowOptionOnly
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}

      {myOrder?.order?.order_type === "none_delivery" && (
        <OrderShowNone
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}

      {myOrder?.order?.order_type === "textbook_download" && (
        <OrderShowNone
          myOrder={myOrder?.order}
          myOrderOrderable={myOrder?.orderable}
        />
      )}
    </div>
  );
};

export default Content;
