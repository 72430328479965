import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import replyEmpty from "../../assets/icons/reply_empty.svg";
import congratu from "../../assets/icons/congratu.svg";
import { getAssignments } from "../../api";
import { Pagination } from "antd";
import { pageBtnStyle } from "../shared/Pagination";

const otherMissionsStyle = css`
  .other_mission:last-of-type {
    border-bottom: 0 solid white !important;
    padding-bottom: 0 !important;
  }

  .other_mission:first-of-type {
    padding-top: 0 !important;
  }
`;

export const AssignmentLists = ({ lessonId, assignmentId }) => {
  const [assignments, setAssignments] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get("page")) || 1
  );

  const setPushState = (page) => {
    window.history.replaceState(
      page,
      null,
      `${location.pathname}?page=${page}`
    );
  };

  const loadOtherMissionPage = (assignment) => {
    if (assignment?.is_writer || assignment?.is_auth) {
      return () =>
        (window.location.href = `/assignments/${assignment?.id}?page=${currentPage}`);
    } else {
      if (assignment?.is_private) {
        return () =>
          Swal.fire({
            title: "비공개 미션 입니다",
            confirmButtonText: "확인",
          });
      } else {
        return () =>
          (window.location.href = `/assignments/${assignment?.id}?page=${currentPage}`);
      }
    }
  };

  const handleClickPageBtn = (page, pageSize) => {
    setPushState(page);
    setCurrentPage(page);
    getAssignments({ lessonId: lessonId, page: page, per: pageSize }).then(
      (response) => {
        setAssignments(response?.data);
      }
    );
  };

  useEffect(() => {
    getAssignments({ lessonId: lessonId, page: currentPage, per: 6 }).then(
      (response) => {
        setAssignments(response?.data);
      }
    );
  }, []);

  return (
    <div className="flex flex-col mt-[35px]">
      <span className="tablet:hidden mobile:hidden font-bold web:text-xl mb-[13px]">
        미션 게시판
      </span>
      <div
        className="border border-gray100 rounded-[10px] flex flex-col px-[19px] py-[29px]"
        css={otherMissionsStyle}
      >
        {assignments?.length !== 0 ? (
          assignments?.assignments?.map((assignment) => {
            return (
              <div
                onClick={loadOtherMissionPage(assignment)}
                key={assignment?.id}
                className={`flex items-center justify-between other_mission py-[29px] cursor-pointer border-b border-b-gray100 ${
                  assignment?.is_writer ? "" : "other_mission_view"
                }`}
              >
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <span
                      className={`tablet:text-sm mobile:text-sm ${
                        assignment?.id === assignmentId
                          ? "font-bold"
                          : "font-medium"
                      }`}
                    >
                      {assignment?.title}
                    </span>

                    {assignment?.is_private && (
                      <div className="text-white h-fit bg-gray200 text-[10px] flex items-center justify-center px-1 rounded-full text-sm whitespace-nowrap flex-shrink-0 ml-2">
                        비공개
                      </div>
                    )}
                    {assignment?.is_writer && (
                      <div className="text-white h-fit bg-yellow900 text-[10px] flex items-center justify-center px-1 rounded-full text-sm whitespace-nowrap flex-shrink-0 ml-2 mr-4">
                        내 미션
                      </div>
                    )}
                  </div>
                  <span
                    className={`web:text-gray300 tablet:text-gray500 mobile:text-gray500 web:text-sm tablet:text-xs mobile:text-xs ${
                      assignment?.id === assignmentId
                        ? "font-bold"
                        : "font-medium"
                    }`}
                  >
                    {assignment?.nickname || assignment?.name}
                  </span>
                </div>

                <div className="flex shrink-0">
                  <img src={replyEmpty} alt="댓글수" className="w-4" />
                  <span className="ml-2 text-xs text-gray300">
                    {assignment?.comments_count}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex flex-col items-center justify-center text-sm font-medium text-gray300 mt-[45px] mb-[32px]">
            <img src={congratu} alt="미션없음" className="w-[39px] mb-[28px]" />
            <span>아직 미션을 작성한 친구가 없습니다!</span>
            <span>첫번째로 미션을 작성해주세요!</span>
          </div>
        )}
      </div>

      <div
        className="flex flex-col items-center justify-center mt-4"
        css={pageBtnStyle}
      >
        <Pagination
          total={assignments?.meta?.total_count}
          pageSize={6}
          showSizeChanger={false}
          onChange={handleClickPageBtn}
          current={currentPage}
          defaultPageSize={6}
        />
      </div>
    </div>
  );
};
