import Layout from "../components/Layout";
import { FindEmail } from "../components/users/index";

const UsersFindEmail = () => {
  return (
    <Layout>
      <div className="max-w-[1200px] mx-auto">
        <FindEmail />
      </div>
    </Layout>
  );
};

export default UsersFindEmail;
