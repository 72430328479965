import { LectureTitle } from "../shared/Title";
import PropTypes from "prop-types";

const TeacherIntroduceWeb = ({ teacher_desc, teacher_banner_lg }) => {
  return (
    <>
      <LectureTitle text="강사 소개" />

      {teacher_banner_lg !== null && (
        <img src={teacher_banner_lg} alt="강사소개웹" className="w-full mt-4" />
      )}
      <div className="flex flex-col mt-8">
        <span dangerouslySetInnerHTML={{ __html: teacher_desc }}></span>
      </div>
    </>
  );
};

const TeacherIntroduceMobile = ({
  deviceCheck,
  teacher_desc,
  teacher_banner_sm,
}) => {
  return (
    <>
      <div className="mobile:mx-[3.75vw]">
        <LectureTitle text="강사 소개" />
      </div>

      {teacher_banner_sm !== null && (
        <img src={teacher_banner_sm} alt="강사소개모바일" className="w-full" />
      )}
      <div className="default_margin mobile:text-sm">
        <span dangerouslySetInnerHTML={{ __html: teacher_desc }}></span>
        {deviceCheck === "mobile" && (
          <hr className="mx-auto mt-8 text-gray100 w-[88%]" />
        )}
      </div>
    </>
  );
};

export { TeacherIntroduceMobile, TeacherIntroduceWeb };

TeacherIntroduceWeb.propTypes = {
  teacher_desc: PropTypes.string,
  teacher_banner_sm: PropTypes.string,
};

TeacherIntroduceMobile.propTypes = {
  teacher_desc: PropTypes.string,
  teacher_banner_lg: PropTypes.string,
};
