import { contentContainerStyle } from "../../style";
import { patchUserPassword } from "../../api";
import { useFormik } from "formik";
import { UsersInput } from "../users/UsersInput";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";

const Content = () => {
  const validationSchema = Yup.object().shape({
    currentPw: Yup.string().required("현재 비밀번호를 입력해주세요."),
    newPw: Yup.string()
      .min(6, "비밀번호는 6자 이상이어야 합니다.")
      .required("새 비밀번호를 입력해주세요."),
    newPwConfirm: Yup.string()
      .oneOf(
        [Yup.ref("newPw")],
        "비밀번호가 일치하지 않습니다. 다시 확인해주세요."
      )
      .required("비밀번호 확인을 해주셔야 합니다."),
  });

  const formik = useFormik({
    initialValues: {
      currentPw: "",
      newPw: "",
      newPwConfirm: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      patchUserPassword({
        currentPw: values.currentPw,
        newPw: values.newPw,
        newPwConfirm: values.newPwConfirm,
      })
        .then(() => {
          Swal.fire({
            title: "비밀번호 변경 성공",
            confirmButtonText: "확인",
            willClose: () => {
              Auth.signOut({ global: true }).then(() => {
                location.href = "/users/sign_in";
              });
            },
          });
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              title: "현재 비밀번호가 틀립니다.",
              confirmButtonText: "확인",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "잠시 후 다시 시도해주세요.",
              text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
              confirmButtonText: "확인",
            });
          }
          setSubmitting(false);
        });
    },
  });

  return (
    <form
      className="mt-10"
      onSubmit={formik.handleSubmit}
      css={contentContainerStyle}
    >
      <UsersInput
        classOption="mt-2 mb-3 form-input-text"
        inputType="password"
        inputPlaceHolder="현재 비밀번호"
        inputId="currentPw"
        onChange={formik.handleChange}
        value={formik.values.currentPw}
      />
      {formik.errors.currentPw && formik.touched.currentPw && (
        <div className="mb-2 text-xs text-red400">
          {formik.errors.currentPw}
        </div>
      )}

      <div className="mb-3 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out cursor-pointer">
        <a href="/mypage/password_change_by_phone">
          현재 비밀번호를 모르시나요?
        </a>
      </div>

      <UsersInput
        classOption="mt-2 mb-3 form-input-text"
        inputType="password"
        inputPlaceHolder="새 비밀번호"
        inputId="newPw"
        onChange={formik.handleChange}
        value={formik.values.newPw}
      />
      {formik.errors.newPw && formik.touched.newPw && (
        <div className="mb-2 text-xs text-red400">{formik.errors.newPw}</div>
      )}
      <UsersInput
        classOption="mt-2 mb-3 form-input-text"
        inputType="password"
        inputPlaceHolder="새 비밀번호 확인"
        inputId="newPwConfirm"
        onChange={formik.handleChange}
        value={formik.values.newPwConfirm}
      />
      {formik.errors.newPwConfirm && formik.touched.newPwConfirm && (
        <div className="mb-2 text-xs text-red400">
          {formik.errors.newPwConfirm}
        </div>
      )}
      <button
        disabled={formik.isSubmitting}
        type="submit"
        className="flex items-center justify-center text-white bg-black h-[45px] rounded-[5px] mt-4 w-full"
      >
        {formik.isSubmitting ? "수정 중..." : "수정하기"}
      </button>
    </form>
  );
};

export default Content;
