import { css } from "@emotion/react";
import { useEffect, useState, useRef } from "react";
import { range } from "lodash";
import { color } from "../../tailwindStyle";
import arrowUpSm from "../../assets/icons/arrow_up_small.svg";
import arrowDownSm from "../../assets/icons/arrow_down_small.svg";
import congratu from "../../assets/icons/congratu.svg";

import CommentImgs from "./CommentImgs";
import { getReviews } from "../../api";

const reviewAllStyle = css`
  border-radius: 16px;
  border: 1px solid ${color.gray100};
  margin-top: 14.1px;
`;

const rightArrow = css`
  border: solid ${color.gray300};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const leftArrow = css`
  border: solid ${color.gray300};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

const pageBtnListStyle = css`
  .pageBtn + .pageBtn {
    margin-left: 0.2rem;
  }
`;

const ReviewAll = ({ targetId, targetType, setRatingAvg, setMetaRating }) => {
  const pagePer = useRef(5);
  const [reviews, setReviews] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  //전체리뷰가 true, 내가작성한리뷰가 false
  const [selectValue, setSelectValue] = useState(true);
  const [myReviews, setMyReviews] = useState(null);

  const reviewTitleRef = useRef(null);

  const handleScrollToTitle = () => {
    window.scrollTo({
      top: reviewTitleRef.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const setArragement = (arragementSize, currentPage, totalPage) => {
    let currentArrangement = Math.floor((currentPage - 1) / arragementSize) + 1;
    let first = arragementSize * (currentArrangement - 1) + 1;
    let last;
    if (arragementSize * currentArrangement > totalPage) {
      last = totalPage + 1;
    } else if (arragementSize > totalPage) {
      last = totalPage;
    } else {
      last = first + arragementSize;
    }
    return range(first, last, 1);
  };

  const nextArrangement = (cp, pagePer) => {
    const calc = cp + pagePer - ((cp - 1) % pagePer);
    return calc;
  };

  const prevArrangement = (cp, pagePer) => {
    const calc = cp - (cp % pagePer);
    return calc;
  };

  useEffect(() => {
    getReviews({
      page: currentPage,
      per: pagePer.current,
      targetId: targetId,
      targetType: targetType,
    }).then((response) => {
      setReviews(response?.data?.reviews);
      const ratingCount = response?.data?.meta?.rating_count;
      setTotalPage(response?.data?.meta?.total);
      setTotalCount(response?.data?.meta?.total_count);
      setMyReviews(response?.data?.meta?.my_reviews);
      const currentArrangementPage = setArragement(
        pagePer.current,
        currentPage,
        response?.data?.meta?.total
      );

      setPages(currentArrangementPage);

      const sum =
        ratingCount["1"] +
        ratingCount["2"] +
        ratingCount["3"] +
        ratingCount["4"] +
        ratingCount["5"];

      const avg =
        (ratingCount["1"] * 1 +
          ratingCount["2"] * 2 +
          ratingCount["3"] * 3 +
          ratingCount["4"] * 4 +
          ratingCount["5"] * 5) /
        sum;
      setRatingAvg(avg);

      let tempRatingCount = {};

      Object.keys(ratingCount).map((k) => {
        tempRatingCount[k] = (ratingCount[k] / sum) * 100;
      });

      setMetaRating(tempRatingCount);
    });
  }, [currentPage]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <span
          className="text-lg font-bold whitespace-nowrap"
          ref={reviewTitleRef}
        >
          {selectValue ? "전체 리뷰" : "내가 작성한 리뷰"}
        </span>
        <div
          className={`flex flex-col items-center justify-between px-[7.6px] text-xs text-gray800  border border-gray100 w-[115.8px] relative ${
            selectOpen ? "rounded-tl-[8px] rounded-tr-[8px]" : "rounded-[8px]"
          }`}
        >
          <div
            onClick={() => {
              setSelectOpen(!selectOpen);
              setSelectValue(selectValue);
            }}
            className="flex w-full cursor-pointer whitespace-nowrap"
          >
            <span className="mx-auto my-1 text-gray800">
              {selectValue ? "전체 리뷰" : "내가 작성한 리뷰"}
            </span>
            <img src={selectOpen ? arrowUpSm : arrowDownSm} alt="selectDown" />
          </div>
          {selectOpen && (
            <div
              onClick={() => {
                setSelectOpen(!selectOpen);
                setSelectValue(!selectValue);
              }}
              className="bg-white my-1 mx-auto text-gray800 absolute top-[20px] border border-gray100 w-[115.8px] flex justify-center items-center rounded-bl-[8px] rounded-br-[8px] cursor-pointer"
            >
              <span className="my-1">
                {!selectValue ? "전체 리뷰" : "내가 작성한 리뷰"}
              </span>
            </div>
          )}
        </div>
      </div>

      {(myReviews?.length === 0 && !selectValue) || reviews?.length === 0 ? (
        <div className="mt-[14.5px] rounded-[16px] border border-gray100 bg-gray25 flex justify-center items-center flex-col w-full h-[305px] mb-[50px]">
          <img src={congratu} alt="내리뷰없음" className="w-[50px] h-[49px]" />
          <span className="text-sm mt-[20px] text-gray300">
            아직 작성하신 리뷰가 없습니다
          </span>
        </div>
      ) : (
        <div
          css={reviewAllStyle}
          className="flex flex-col mb-[45.6px] pt-[24.2px] px-[15px]"
        >
          <div
            className="flex flex-col items-center justify-center"
            css={css`
              .singleCard:last-child {
                border-bottom: 0 !important;
                margin-bottom: 0 !important;
              }
            `}
          >
            {selectValue
              ? reviews?.map((review) => {
                  return <CommentImgs review={review} key={review?.id} />;
                })
              : myReviews?.map((review) => {
                  return <CommentImgs review={review} key={review?.id} />;
                })}
          </div>
        </div>
      )}

      {/* 페이지네이션 */}
      {reviews?.length !== 0 && (
        <div
          className="flex items-center justify-center w-full text-xs"
          css={pageBtnListStyle}
        >
          {!pages?.includes(1) && (
            <span
              style={{
                width: "24.1px",
                height: "24.7px",
                paddingLeft: "1px",
              }}
              className="flex items-center justify-center cursor-pointer pageBtn border-gray600"
              onClick={() => {
                setCurrentPage(prevArrangement(currentPage, pagePer.current));
                handleScrollToTitle();
              }}
            >
              <i css={leftArrow}></i>
            </span>
          )}

          <div className="flex items-center justify-between mx-[1rem]">
            {pages?.map((page) => {
              return (
                <span
                  style={{
                    width: "24.1px",
                    height: "24.7px",
                  }}
                  className={`cursor-pointer flex justify-center items-center pageBtn ${
                    page === currentPage
                      ? "font-bold bg-yellow600 text-white rounded-[8px]"
                      : "text-gray300"
                  }`}
                  key={`pageNum${page}`}
                  onClick={() => {
                    setCurrentPage(page);
                    handleScrollToTitle();
                  }}
                >
                  {page}
                </span>
              );
            })}
          </div>

          {!pages?.includes(totalPage) && (
            <span
              style={{
                width: "24.1px",
                height: "24.7px",
                paddingRight: "2px",
              }}
              className="flex items-center justify-center cursor-pointer pageBtn border-gray600"
              onClick={() => {
                setCurrentPage(nextArrangement(currentPage, pagePer.current));
                handleScrollToTitle();
              }}
            >
              <i css={rightArrow}></i>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewAll;
