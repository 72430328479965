import noticeDefault from "../../assets/images/notice_default_img.svg";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import moment from "moment";
import { getOnedayBoards } from "../../api";
import LecturesNone from "./LecturesNone";

export const Content = () => {
  const [onedayLectures, setOnedayLectures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getOnedayBoards()
      .then((response) => {
        setOnedayLectures(response?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="px-4 mx-auto max-w-[650px] flex flex-col mb-[150px]">
      <span className="mt-[40px] text-[24px] font-bold">원데이클래스</span>
      <div className="border-b-[2px] border-b-[#000000] mt-5"></div>
      {!isLoading &&
        (onedayLectures?.length > 0 ? (
          onedayLectures?.map((onedayLecture) => {
            return (
              <a
                href={`/oneday_boards/${onedayLecture?.id}`}
                className="flex flex-col"
                key={onedayLecture?.id}
              >
                <div className="mt-[30px] flex">
                  <div className="relative shrink-0 mr-[15px]">
                    <img
                      src={onedayLecture?.thumbnail || noticeDefault}
                      alt={`기본프로필_${onedayLecture?.id}`}
                      className="w-[100px] h-[80px] object-cover"
                    />

                    {moment().isAfter(moment(onedayLecture?.end_at)) && (
                      <div>
                        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-black opacity-60"></div>
                        <div className="absolute h-full w-full font-[500] text-lg z-3 text-white m-auto w-full flex justify-center items-center top-0 left-0">
                          <div className="flex flex-col items-center justify-center">
                            마감
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col h-[80px] w-full mobile:justify-between">
                    <span
                      className="font-[500] text-[15px] line-ellipsis"
                      css={css`
                        -webkit-line-clamp: 2;
                      `}
                    >
                      {onedayLecture?.title}
                    </span>

                    <span className="mt-[10px] text-xs font-[500] text-gray99">
                      {moment(onedayLecture?.start_at).format("YYYY.MM.DD")} ~{" "}
                      {moment(onedayLecture?.end_at).format(
                        "MM.DD (HH:mm까지)"
                      )}
                    </span>
                  </div>
                </div>
              </a>
            );
          })
        ) : (
          <LecturesNone />
        ))}
    </div>
  );
};
