import { useEffect, createContext, useState, useCallback } from "react";
import Layout from "../components/Layout";
import Modal from "react-modal";
import ShareModal from "../components/specialEventShow/ShareModal";
import ReviewShow from "../components/showReview/ReviewShow";
import { FooterSticky, Content } from "../components/specialEventShow/index";
import Drawer from "react-bottom-drawer";
import ActionSheet from "../components/specialEventShow/ActionSheet";
import { orderModalStyle } from "../style";
import { getSpecialEvent } from "../api";

export const DrawerContext = createContext(null);
export const ModalContext = createContext(null);

export const SpecialEventContext = createContext(null);

const SpecialEvent = (props) => {
  const SPECIAL_EVENT_ID = props.specialEventId || null;

  const [isVisible, setIsVisible] = useState(false);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const openDrawer = useCallback(() => setIsVisible(true), []);

  //btnStatus가 포함된 new livelLectures Object
  const [reduceLiveLectures, setReduceLiveLectures] = useState([]);

  //구입 모달
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const closeModal = () => {
    setIsOrderModalOpen(false);
  };

  const openModal = () => {
    setIsOrderModalOpen(true);
  };

  //review 모달 컨트롤 state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectReview, setSelectReview] = useState(null);

  //공유하기 모달
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const [specialEvent, setSpecialEvent] = useState(null);
  const [loading, setLoading] = useState(true);

  const initSpecialEvent = () => {
    getSpecialEvent({ specialEventId: SPECIAL_EVENT_ID }).then((response) => {
      setSpecialEvent((prev) => ({ ...prev, ...response?.data }));
      setLoading(false);
    });
  };

  useEffect(() => {
    initSpecialEvent();
  }, []);

  useEffect(() => {
    isModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isModalOpen]);

  return (
    <>
      {!loading && (
        <SpecialEventContext.Provider
          value={{ specialEventId: SPECIAL_EVENT_ID }}
        >
          <ModalContext.Provider
            value={{
              openModal: openModal,
              closeModal: closeModal,
            }}
          >
            <DrawerContext.Provider
              value={{
                openSheetDrawer: openDrawer,
                closeSheetDrawer: closeDrawer,
              }}
            >
              <Layout>
                <Content
                  specialEvent={specialEvent}
                  setIsModalOpen={setIsModalOpen}
                  setSelectReview={setSelectReview}
                />
              </Layout>
              {/* footer 구매하기 */}
              <FooterSticky
                openDrawer={openDrawer}
                openModal={openModal}
                {...specialEvent}
                openShareModal={openShareModal}
                reduceLiveLectures={reduceLiveLectures}
                setReduceLiveLectures={setReduceLiveLectures}
              />

              <Drawer
                duration={250}
                hideScrollbars={true}
                onClose={closeDrawer}
                isVisible={isVisible}
                className="liveclassActionSheetStyle"
              >
                <ActionSheet live_lectures={reduceLiveLectures} />
              </Drawer>

              <Modal
                isOpen={isOrderModalOpen}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={orderModalStyle}
              >
                <ActionSheet live_lectures={reduceLiveLectures} />
              </Modal>

              <Modal
                isOpen={isShareModalOpen}
                onRequestClose={closeShareModal}
                ariaHideApp={false}
                style={orderModalStyle}
              >
                <ShareModal
                  closeShareModal={closeShareModal}
                  specialEvent={specialEvent}
                />
              </Modal>
            </DrawerContext.Provider>

            <ReviewShow
              selectReview={selectReview}
              modalIsOpen={isModalOpen}
              setModalIsOpen={setIsModalOpen}
            />
          </ModalContext.Provider>
        </SpecialEventContext.Provider>
      )}
    </>
  );
};

export default SpecialEvent;
