import { css } from "@emotion/react";
import arrowSharp from "../../assets/icons/arrow_down_sharp";
import { useRef } from "react";

const LecturesKidsFilter = ({
  lecturesParams,
  setLecturesParams,
  subjects,
}) => {
  const kitCheck = useRef(null);
  const textbookCheck = useRef(null);

  const handleSelectOption = (value) => {
    if (!value) {
      setLecturesParams({
        ...lecturesParams,
        subject: null,
      });
    } else {
      setLecturesParams({
        ...lecturesParams,
        subject: value,
      });
    }
  };

  const handleCheckbox = (value) => {
    setLecturesParams({
      ...lecturesParams,
      is_kit: kitCheck.current.checked,
      is_textbook: textbookCheck.current.checked,
    });
  };

  return (
    <div className="default_margin max-w-[1200px] w-full">
      <div className="flex">
        <div className="flex flex-col mr-[55px]">
          <span className="font-medium tablet:text-sm mobile:text-sm">
            관심있는 클래스 주제
          </span>

          <select
            className="w-[120%] border-gray100 text-gray400 rounded-[3px] border web:text-sm tablet:text-xs mobile:text-xs web:h-[42px] tablet:h-[29px] mobile:h-[29px] web:px-[19px] tablet:px-[13px] mobile:px-[13px] web:mt-[12px] tablet:mt-[7px] mobile:mt-[7px]"
            css={css`
              appearance: none;
              background: url(${arrowSharp}) no-repeat right 18px center;
            `}
            onChange={(e) => handleSelectOption(e.currentTarget.value)}
          >
            <option value="">전체</option>
            {subjects?.map((subject, index) => {
              return (
                <option key={`subject_${index}`} value={subject?.title}>
                  {subject?.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex flex-col font-medium">
          <span className="font-medium tablet:text-sm mobile:text-sm web:mb-[22px] tablet:mb-[11px] mobile:mb-[11px]">
            클래스 구성품
          </span>

          <div className="flex items-center web:text-sm tablet:text-xs mobile:text-xs">
            <input
              type="checkbox"
              id="checkKit"
              value="키트"
              ref={kitCheck}
              onClick={(e) => {
                handleCheckbox(e.currentTarget);
              }}
            />
            <label
              htmlFor="checkKit"
              className="web:ml-[10px] tablet:ml-[5px] mobile:ml-[5px] web:mr-[42px] tablet:mr-[12px] mobile:mr-[12px]"
              value="키트"
            >
              키트
            </label>

            <input
              type="checkbox"
              id="checkTextbook"
              value="교재"
              ref={textbookCheck}
              onClick={(e) => {
                handleCheckbox(e.currentTarget);
              }}
            />
            <label
              htmlFor="checkTextbook"
              className="web:ml-[10px] tablet:ml-[5px] mobile:ml-[5px]"
              value="교재"
            >
              교재
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LecturesKidsFilter;
