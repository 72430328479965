import { useRef, useReducer } from "react";
import { CSVReader } from "react-papaparse";
import { useFormik } from "formik";
import { chunk, find } from "lodash";
import { addComma } from "../../../components/utils";
import axios from "axios";
import * as Yup from "yup";

// status => loading, fail, success
function reducer(state, action) {
  switch (action.type) {
    case "PARSING_DATAS":
      action.setFieldValue("importDatas", action.datas);
      const result = action.datas.map((data) => {
        return {
          orderNumber: data.orderNumber,
          orderAmount: data.orderAmount,
          lectureId: data.lectureId,
          userPhone: data.userPhone,
          userName: data.userName,
          quantity: data.quantity,
          company: data.company,
          address: data.address,
          courier: data.courier,
          invoiceNumber: data.invoiceNumber,
          paidAt: data.paidAt,
          optionText: data.optionText,
          setCoupon: null,
          message: null,
        };
      });
      return [...result];
    case "REMOVE":
      action.setFieldValue("importDatas", null);
      return [];
    case "SET_DATA":
      const resDatas = action.data;
      let result2 = state;
      resDatas.forEach((data) => {
        const findData = find(result2, ["orderNumber", data.order_number]);

        findData["lectureId"] = data.lecture_id;
        findData["setCoupon"] = data.set_coupon;
        findData["userPhone"] = data.phone;
        findData["userName"] = data.name;
        findData["paidAt"] = data.paid_at;
        findData["company"] = data.company;
        findData["quantity"] = data.quantity;
        findData["orderAmount"] = data.amount;
        findData["address"] = data.address;
        findData["courier"] = data.courier;
        findData["invoiceNumber"] = data.invoice_number;
        findData["optionText"] = data.option_text;
        findData["message"] = data.message;
      });
      return [...result2];
    default:
      return state;
  }
}

function Badge({ text, className, ...rest }) {
  return (
    <button
      type="button"
      className={`w-12 mx-2 py-1 font-normal rounded-sm ${className}`}
      {...rest}
    >
      {text}
    </button>
  );
}

function checkLectureAndCompany(itemNumber) {
  if (itemNumber === "2433759157") {
    return { lectureId: 47, company: "지마켓" };
  }
  if (itemNumber === "C594126043") {
    return { lectureId: 47, company: "옥션" };
  }
  if (itemNumber === "2333392269") {
    return { lectureId: 10, company: "지마켓" };
  }
  if (itemNumber === "C503804369") {
    return { lectureId: 10, company: "옥션" };
  }
  if (itemNumber === "2333374531") {
    return { lectureId: 34, company: "지마켓" };
  }
  if (itemNumber === "C503788778") {
    return { lectureId: 34, company: "옥션" };
  }
  if (itemNumber === "2333348097") {
    return { lectureId: 33, company: "지마켓" };
  }
  if (itemNumber === "C503767853") {
    return { lectureId: 33, company: "옥션" };
  }
  if (itemNumber === "6885306199") {
    return { lectureId: 51, company: "스마트스토어" };
  }
}

function CreateOneTimeCoupons() {
  const [users, dispatch] = useReducer(reducer, []);
  const buttonRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      importDatas: null,
    },
    validationSchema: Yup.object().shape({
      importDatas: Yup.array().required(),
    }),
    onSubmit: ({ importDatas }) => {
      const chunkByImportDatas = chunk(importDatas, 10);
      chunkByImportDatas.forEach(async (import_datas) => {
        const response = await axios({
          url: `/admin/one_time_coupons/set_create_one_time_coupons`,
          method: "PATCH",
          headers: {
            "X-CSRF-Token":
              document.getElementsByTagName("meta")["csrf-token"].content,
          },
          data: {
            import_datas,
          },
        });

        dispatch({
          type: "SET_DATA",
          data: response.data,
        });
      });
    },
  });

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    const regExp_ctn =
      /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
    const parsedDatas = data.reduce((rst, obj) => {
      const orderNumber = obj.data[0];
      const orderAmount = obj.data[1];
      const lectureId = checkLectureAndCompany(obj.data[2])?.lectureId
        ? String(checkLectureAndCompany(obj.data[2])?.lectureId)
        : null;
      const company = checkLectureAndCompany(obj.data[2])?.company
        ? checkLectureAndCompany(obj.data[2])?.company
        : null;
      const quantity = obj.data[3];
      const userPhone = regExp_ctn.test(`0${obj.data[4]}`)
        ? `0${obj.data[4]}`
        : null;
      const userName = obj.data[5];
      const paidAt = obj.data[6];
      const address = obj.data[7];
      const courier = obj.data[8];
      const invoiceNumber = obj.data[9];
      const optionText = obj.data[10];

      rst.push({
        orderNumber,
        lectureId,
        quantity,
        userPhone,
        userName,
        company,
        orderAmount,
        paidAt,
        address,
        courier,
        invoiceNumber,
        optionText,
      });
      return rst;
    }, []);

    dispatch({
      type: "PARSING_DATAS",
      datas: parsedDatas,
      setFieldValue: formik.setFieldValue,
    });
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    dispatch({
      type: "REMOVE",
      setFieldValue: formik.setFieldValue,
    });
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <div className="mx-12">
      <h3 className="mb-2 text-xl font-bold text-black">파일 업로드</h3>
      <div className="flex items-center mb-4">
        <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          config={{}}
          style={{}}
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <div className="flex">
              <Badge
                className={"text-white bg-green-400"}
                text="업로드"
                onClick={handleOpenDialog}
              />
              <div>{file && file.name}</div>
              <Badge
                className={"text-white bg-red-400"}
                text="제거"
                onClick={handleRemoveFile}
              />
            </div>
          )}
        </CSVReader>
        <button
          className={`w-12 py-1 font-normal text-white ${
            !formik.dirty || !formik.isValid ? "bg-gray-500" : "bg-blue-500"
          } rounded-sm`}
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || !formik.isValid}
        >
          제출
        </button>
      </div>
      <div className="flex justify-center">
        <table className="w-1/2 text-center table-auto index_table index">
          <thead>
            <tr>
              <th className="text-center">주문번호</th>
              <th className="text-center">가격</th>
              <th className="text-center">강의id</th>
              <th className="text-center">수량</th>
              <th className="text-center">판매처</th>
              <th className="text-center">이름</th>
              <th className="text-center">전화번호</th>
              <th className="text-center">구매시간</th>
              <th className="text-center">주소</th>
              <th className="text-center">택배사</th>
              <th className="text-center">송장번호</th>
              <th className="text-center">추가구성</th>
              <th className="text-center">상태</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(
              (
                {
                  orderNumber,
                  orderAmount,
                  lectureId,
                  quantity,
                  company,
                  userName,
                  userPhone,
                  paidAt,
                  address,
                  courier,
                  invoiceNumber,
                  optionText,
                  setCoupon,
                  message,
                },
                idx
              ) => {
                return (
                  <tr key={idx}>
                    <td>{orderNumber}</td>
                    <td>{addComma(orderAmount)}원</td>
                    <td>{lectureId}</td>
                    <td>{quantity}</td>
                    <td>{company}</td>
                    <td>{userName}</td>
                    <td>{userPhone}</td>
                    <td>{paidAt}</td>
                    <td>{address}</td>
                    <td>{courier}</td>
                    <td>{invoiceNumber}</td>
                    <td>{optionText}</td>
                    <td>
                      {setCoupon == null ? (
                        <Badge
                          className={"text-black bg-gray-300"}
                          text="부여 전"
                        />
                      ) : setCoupon ? (
                        <Badge
                          className={"text-white bg-green-700"}
                          text="성공"
                        />
                      ) : (
                        <>
                          <Badge
                            className={"text-white bg-red-700"}
                            text="실패"
                          />
                          <p>{message}</p>
                        </>
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>

      <div className="grid gap-4 web:grid-cols-3"></div>
    </div>
  );
}

export default CreateOneTimeCoupons;
