import { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import {
  ShowContent,
  AssignmentsInfo,
  AssignmentLists,
  ShowContentMobile,
} from "../components/assignments/index";
import { ReplyInputForm } from "../components/lessons/ReplyInputForm";
import { QnaTitle } from "../components/lessons/QnaTitle";
import { Reply } from "../components/lessons/Reply";
import useDeviceCheck from "../hooks/useDeviceCheck";
import { QnasContext } from "./Lessons";
import { getAssignment, getAssignmentComments, getLesson } from "../api";

const AssignmentsShow = (props) => {
  const deviceCheck = useDeviceCheck(null);

  const qnasRef = useRef(null);
  const [qnas, setQnas] = useState(null);

  const [assignment, setAssignment] = useState(null);

  useEffect(() => {
    getAssignment({ assignmentId: props?.assignmentId }).then((response) => {
      setAssignment(response?.data);
    });

    getAssignmentComments({ assignmentId: props?.assignmentId }).then(
      (response) => {
        qnasRef.current = response?.data;
        setQnas(qnasRef.current?.comments);
      }
    );
  }, [props?.assignmentId]);

  useEffect(() => {
    if (!!props?.lessonId) {
      getLesson({ lessonId: props?.lessonId }).then((response) => {
        dataLayer.push({
          lecture_id: response?.data?.lecture_id,
          lesson_id: props?.lessonId,
        });
      });
    }
  }, [props?.lessonId]);

  return (
    !!deviceCheck && (
      <Layout>
        <QnasContext.Provider
          value={{
            qnas: qnas,
            setQnas: setQnas,
          }}
        >
          <div className="max-w-[1230px] px-[15px] mx-auto web:grid web:grid-cols-[43fr_57fr] web:gap-[28px]">
            <div className="flex flex-col w-full">
              <AssignmentsInfo lessonId={props?.lessonId} />
              {deviceCheck === "web" && (
                <AssignmentLists
                  lessonId={props?.lessonId}
                  assignmentId={props?.assignmentId}
                />
              )}
            </div>
            {deviceCheck === "web" && !!qnas && (
              <div className="flex flex-col w-full">
                <ShowContent
                  lessonId={props?.lessonId}
                  assignment={assignment}
                />
                <QnaTitle
                  qnasCount={qnas?.length}
                  titleName="와글와글"
                  titleContent={`<span class="font-bold">다른 친구의 과제에 대한 칭찬이나 여러분의 의견을 나눌 수 있습니다</span> <br /> <br />
                  <span>
                    ※ 나쁜 말은 안 돼요! \n
                    욕설, 비난 등 다른 친구들에게 상처를 주는 말은 삭제될 수 있어요. <br /> 나쁜 말은 하지 않기, 약속~!
                  </span>`}
                />
                <ReplyInputForm
                  commentable_id={props?.assignmentId}
                  commentable_type="Assignment"
                />
                <Reply qnas={qnas} replyTitle="다른 친구들의 와글와글" />
              </div>
            )}

            {deviceCheck !== "web" && !!qnas && (
              <ShowContentMobile
                lessonId={props?.lessonId}
                assignment={assignment}
              />
            )}
          </div>
        </QnasContext.Provider>
      </Layout>
    )
  );
};

export default AssignmentsShow;
