import Layout from "../components/Layout";
import { Content } from "../components/lecturesDnav";

const LecturesDnav = (props) => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default LecturesDnav;
