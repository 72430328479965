import { useEffect, useState } from "react";
import { MypageTitle } from "../components/shared/Title";
import {
  MyLectureCardNone,
  MyLectureCardExist,
} from "../components/mypageLectures/index";

import {
  MyDnavLectureCardNone,
  MyDnavLectureCardExist,
} from "../components/mypageDnavLectures/index";

import Aside from "../components/shared/MypageAside";
import { css } from "@emotion/react";
import { WEB_MAX_WIDTH } from "../constant";
import Layout from "../components/Layout";
import { getMyKidLectures, getMyDnavLectures } from "../api";

const MypageLecturesKids = (props) => {
  const [myLectures, setMyLectures] = useState(null);
  const [myDnavLectures, setMyDnavLectures] = useState(null);
  const [myLiveLectures, setMyLiveLectures] = useState(null);
  useEffect(() => {
    getMyKidLectures().then((response) => {
      setMyLectures(response?.data);
    });

    getMyDnavLectures().then((response) => {
      setMyDnavLectures(response?.data);
    });
  }, []);

  return (
    <Layout>
      <div
        className="flex mypage_lectures default_margin mobile:flex-col"
        css={css`
          max-width: ${WEB_MAX_WIDTH}px;
        `}
      >
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full mt-5">
            <MypageTitle title="디냅 클래스" />
            {myDnavLectures &&
              (myDnavLectures?.length !== 0 ? (
                <MyDnavLectureCardExist value={myDnavLectures} />
              ) : (
                <MyDnavLectureCardNone />
              ))}
          </div>

          <div className="w-full mt-5">
            <MypageTitle title="수강 중인 클래스" />
            {myLectures &&
              (myLectures?.length !== 0 ? (
                <MyLectureCardExist value={myLectures} />
              ) : (
                <MyLectureCardNone />
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MypageLecturesKids;
