// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();
require("@rails/activestorage").start();
require("channels");
require("webpack-jquery-ui");
require("webpack-jquery-ui/css");

require("./server_rendering");

//인터넷 익스플로러 호환 코드
// IE9의 경우
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// IE11의 경우
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
//인터넷 익스플로러 호환 코드 끝

import "lodash";
import "lazysizes";
import iziToast from "izitoast";
import StarRating from "star-rating.js";
import Swal from "sweetalert2";
import "../utils/ahoy_init";
import iziModal from "izimodal/js/iziModal";

const images = require.context("../stylesheets/images", true);

$.ajaxSetup({
  headers: {
    "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
  },
});

global.Rails = Rails;
global.process_env = process.env;
global.env = process.env.NODE_ENV;
global.iziToast = iziToast;
global.StarRating = StarRating;
global.Swal = Swal;
global.$ = $;
$.fn.iziModal = iziModal;
global.iziModal = iziModal;

import "../utils/marketing_init";

// js packages
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";

// vimeo 트래킹
import "../features/vimeo_tracking";

// scss
import "stylesheets/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//fontawesome
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

document.addEventListener("DOMContentLoaded", () => {
  const removeCollapse = (e) => {
    if (
      !$(e.target).is("#collapse-target") &&
      !$(e.target).is("#navbar-collapse")
    ) {
      const target = $("#collapse-target");
      const arrowTarget = $("#collapse-arrow");
      target.removeClass("active");
      arrowTarget.removeClass("down");
    }
  };

  ChannelIO("boot", {
    pluginKey: "8ae16fe9-4a77-461e-b7c0-acb2c5928606",
  });

  $("#navbar-collapse").on("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = $("#collapse-target");
    const arrowTarget = $("#collapse-arrow");
    target.toggleClass("active");
    arrowTarget.toggleClass("down");
    $("html").one("click", removeCollapse);
  });

  const getCookieValue = (key) => {
    let cookieKey = key + "=";
    let result = "";
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      if (cookieArr[i][0] === " ") {
        cookieArr[i] = cookieArr[i].substring(1);
      }

      if (cookieArr[i].indexOf(cookieKey) === 0) {
        result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
        return result;
      }
    }
    return result;
  };

  const setCookie = (name, value, expiredays) => {
    var date = new Date();
    date.setDate(date.getDate() + expiredays);
    document.cookie =
      escape(name) + "=" + escape(value) + "; expires=" + date.toUTCString();
  };

  window.addEventListener("unload", function () {});

  $(".resize_textarea").on("keyup", (e) => {
    const obj = e.target;
    obj.style.height = "1px";
    obj.style.height = obj.scrollHeight + "px";
  });

  $("#set_browser").on("click", (e) => {
    e.preventDefault();
    setCookie("browser_check", "Y", 7);

    location.href = "/";
  });
});
