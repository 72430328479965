import Amplify, { Auth } from "aws-amplify";
import { cognitoKeyWithEnv } from "../constant";

Amplify.configure({
  Auth: {
    region: "ap-northeast-2",
    userPoolId: cognitoKeyWithEnv[process.env.TARGET_ENV]["userPoolId"],
    userPoolWebClientId:
      cognitoKeyWithEnv[process.env.TARGET_ENV]["userPoolWebClientId"],
  },
});

const AmplifySignIn = async ({ email, password }) => {
  await Auth.signIn({
    username: email,
    password,
  });
};

const AmplifySignUp = async ({ email, password, phoneNumber }) => {
  await Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      phone_number: phoneNumber,
    },
  });
};

const AmplifyChangePassword = ({ currentUser, oldPassword, newPassword }) => {
  Auth.changePassword({ user: currentUser, oldPassword, newPassword });
};
const AmplifyForgotPassword = ({ username }) => {
  Auth.forgotPassword({ username });
};

export {
  AmplifySignIn,
  AmplifySignUp,
  AmplifyChangePassword,
  AmplifyForgotPassword,
};
