import Modal from "react-modal";
import Drawer from "react-bottom-drawer";
import { useEffect, useState, useCallback } from "react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import CouponItem from "./CouponItem";
import CouponDetail from "./CouponDetail";
import { color } from "../../tailwindStyle";

const Coupon = ({ coupon, couponStatus }) => {
  const deviceCheck = useDeviceCheck();
  //coupon Modal 컨트롤 state
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const closeModal = () => {
    setIsCouponModalOpen(false);
  };

  const openModal = () => {
    setIsCouponModalOpen(true);
  };

  //modal 열렸을때 스크롤 금지
  useEffect(() => {
    isCouponModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isCouponModalOpen]);

  //coupon actionSheet 컨트롤 State
  const [isSheetVisible, setIsSheetVisible] = useState(false);
  const closeSheetDrawer = useCallback(() => setIsSheetVisible(false), []);
  const openSheetDrawer = useCallback(() => setIsSheetVisible(true), []);

  const couponModalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      border: `1px solid ${color.gray100}`,
      zIndex: "20000000",
      height: "fit-content",
      width: "500px",
      paddingTop: "25px",
      paddingBottom: "25px",
      paddingLeft: "32px",
      paddingRight: "32px",
      maxHeight: "560px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: "20000000",
    },
  };

  return deviceCheck === "mobile" ? (
    <div>
      <CouponItem
        coupon={coupon}
        couponStatus={couponStatus}
        openCoupon={deviceCheck === "mobile" ? openSheetDrawer : openModal}
      />
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeSheetDrawer}
        isVisible={isSheetVisible}
        className="orderActionSheetStyle"
      >
        <CouponDetail
          closeCoupon={closeSheetDrawer}
          coupon={coupon}
          couponStatus={couponStatus}
        />
      </Drawer>
    </div>
  ) : (
    <div>
      <CouponItem
        coupon={coupon}
        couponStatus={couponStatus}
        openCoupon={deviceCheck === "mobile" ? openSheetDrawer : openModal}
      />
      <Modal
        isOpen={isCouponModalOpen}
        ariaHideApp={false}
        style={couponModalStyle}
        onRequestClose={closeModal}
        preventScroll={true}
      >
        <CouponDetail
          closeCoupon={closeModal}
          coupon={coupon}
          couponStatus={couponStatus}
        />
      </Modal>
    </div>
  );
};

export default Coupon;
