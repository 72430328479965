import PropTypes from "prop-types";

const MainIframe = ({ deviceCheck, promotion_video }) => {
  return (
    <div className="web:mt-[30px] tablet:mt-[30px]">
      <iframe
        width="100%"
        height={
          deviceCheck === "web" || deviceCheck === "tablet" ? "522" : "200"
        }
        src={promotion_video}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default MainIframe;

MainIframe.propTypes = {
  promotion_video: PropTypes.string,
};
