import Modal from "react-modal";
import CouponContent from "./CouponContent";
import { color } from "../../tailwindStyle";

const couponModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    border: `1px solid ${color.gray100}`,
    zIndex: "20000000",
    height: "fit-content",
    width: "500px",
    maxHeight: "80vh",
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "20000000",
  },
};

const OrderCouponModal = ({
  isCouponContentOpen,
  setIsCouponContentOpen,
  coupons,
  selectCoupon,
  setSelectCoupon,
}) => {
  const closeModal = () => {
    setIsCouponContentOpen(false);
  };
  return (
    <Modal
      isOpen={isCouponContentOpen}
      ariaHideApp={false}
      style={couponModalStyle}
      onRequestClose={closeModal}
    >
      <CouponContent
        closeModal={closeModal}
        coupons={coupons}
        selectCoupon={selectCoupon}
        setSelectCoupon={setSelectCoupon}
      />
    </Modal>
  );
};

export default OrderCouponModal;
