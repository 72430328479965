import OneTeacher from "./OneTeacher";
import { useState, useEffect, useContext } from "react";
import { getSpecialEventInterest } from "../../api";
import { SpecialEventContext } from "../../pages/SpecialEvent";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const Curriculum = ({ curriculum_text, live_lectures, id, discount_rate }) => {
  const { isUserSignedIn } = useUserSignedIn();
  const [currentUserInterests, setCurrentUserInterests] = useState(null);

  const { specialEventId } = useContext(SpecialEventContext);

  useEffect(() => {
    if (isUserSignedIn) {
      getSpecialEventInterest({ specialEventId: specialEventId }).then(
        (response) => setCurrentUserInterests(response?.data)
      );
    }
  }, [isUserSignedIn]);

  return (
    <div className="web:max-w-[1024px] tablet:max-w-[640px] mobile:max-w-[640px] default_margin mx-auto web:mt-[206px] tablet:mt-[43px] mobile:mt-[43px] flex flex-col">
      <span className="font-bold web:text-2xl">커리큘럼</span>

      <div className="web:text-[28px] tablet:text-xl mobile:text-xl web:mt-[21px] tablet:mt-2 mobile:mt-2 font-bold web:mb-[50px] tablet:mb-[39px] mobile:mb-[39px]">
        <div dangerouslySetInnerHTML={{ __html: curriculum_text }}></div>
      </div>

      <div className="grid web:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-[47px]">
        {live_lectures.map((lecture, index) => {
          return (
            <OneTeacher
              lecture={lecture}
              key={lecture?.id}
              indexNum={index + 1}
              interest={
                currentUserInterests && currentUserInterests.length !== 0
                  ? currentUserInterests.find(
                      (res) => res.interestable_id === lecture?.id
                    )
                  : null
              }
              id={id}
              discount_rate={discount_rate}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Curriculum;
