import { useState, useRef } from "react";
import { UsersInputLabel } from "./UsersInputLabel";
import { UsersInput } from "./UsersInput";
import { getAuthCodesCheck, postAuthCodes } from "../../api";
import iziToast from "izitoast";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import * as awsAllowedCountries from "../../awsAllowedCountries.json";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import confirmSuccess from "../../assets/icons/confirm_success";
import useRemainTimer from "../../hooks/useRemainTimer";
import moment from "moment";

/**
 *
 * @param 암호화된키 state, 핸드폰번호 state
 */
const AuthPhoneNumber = ({ setEncryptedKey, setPhoneNumber }) => {
  const [isReceiveInputOpen, setIsReceiveInputOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [clearTrigger, setClearTrigger] = useState(null);
  const [code, setCode] = useState(null);
  const codeInputRef = useRef(null);

  const remainTime = useRemainTimer(moment().add(3, "minutes"), clearTrigger);

  //인증번호 Form 로직
  const sendMessageformik = useFormik({
    initialValues: {
      isValid: false,
      phoneNumber: "",
      countryCode: "",
    },

    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("휴대폰 번호를 입력해주세요."),
      countryCode: Yup.string().required("국가를 선택해주세요."),
    }),

    onSubmit: (values, { setSubmitting }) => {
      if (values?.isValid) {
        setSubmitting(true);

        postAuthCodes({
          phoneNumber: values.phoneNumber,
          countryCode: "+" + values.countryCode,
        })
          .then((response) => {
            setClearTrigger((prev) => !prev);
            const { code, message } = response.data;
            if (message === "development") {
              iziToast.warning({
                title: "임시인증번호-개발서버입니다",
                message: code,
                position: "topCenter",
              });
            }

            setCode(code);
            setIsReceiveInputOpen(true);
            !!setPhoneNumber && setPhoneNumber(values.phoneNumber);
            setSubmitting(false);
            codeInputRef.current.focus();
          })
          .catch((error) => {
            switch (error.response.data.message) {
              case "phone_user_not_exist":
                Swal.fire({
                  text: "입력한 번호의 사용자가 존재하지 않습니다.",
                  confirmButtonText: "확인",
                });
                break;
              case "wrong_country_code":
                Swal.fire({
                  text: "입력한 국가코드가 일치하지 않습니다.",
                  confirmButtonText: "확인",
                });
                break;
              case "send_failed":
                Swal.fire({
                  text: "문자 전송을 실패했습니다.",
                  confirmButtonText: "확인",
                });
                break;
              default:
                Swal.fire({
                  icon: "error",
                  title: "잠시 후 다시 시도해주세요.",
                  text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
                  confirmButtonText: "확인",
                });
                break;
            }
            setSubmitting(false);
          });
      } else {
        Swal.fire({
          icon: "error",
          text: "올바른 형식의 번호를 입력해주세요.",
        });
        setSubmitting(false);
      }
    },
  });

  const receiveInputFormik = useFormik({
    initialValues: {
      phoneNumber: sendMessageformik.values.phoneNumber,
      code: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("휴대폰 번호를 입력해주세요."),
      code: Yup.string().required("인증번호를 입력해주세요."),
    }),
    onSubmit: (values, { setSubmitting }) => {
      getAuthCodesCheck({
        phoneNumber: values.phoneNumber,
        code: values.code,
      })
        .then((response) => {
          const { user_encrypted_key } = response?.data;
          setEncryptedKey(user_encrypted_key);
          setIsConfirmed(true);
          setIsReceiveInputOpen(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
      setSubmitting(true);
    },
  });

  return (
    <>
      <form onSubmit={sendMessageformik.handleSubmit} className="mt-[18px]">
        <UsersInputLabel labelName="핸드폰 번호" inputId="phoneNumber" />

        <div className="flex items-center mt-[6px]">
          <div className="relative flex items-center w-full">
            <IntlTelInput
              containerClassName="intl-tel-input w-full"
              inputClassName="border-gray200 border-[0.5px] h-[45px] shrink-0 w-full rounded-[5px] mr-2 px-[12px]"
              defaultCountry="kr"
              fieldName="phoneNumber"
              fieldId="phoneNumber"
              value={sendMessageformik.values.phoneNumber}
              preferredCountries={["kr", "us"]}
              formatOnInit
              format
              onPhoneNumberChange={(
                isValid,
                value,
                selectedCountryData,
                fullNumber,
                extension
              ) => {
                sendMessageformik.setFieldValue("isValid", isValid);
                sendMessageformik.setFieldValue("phoneNumber", value);
                sendMessageformik.setFieldValue(
                  "countryCode",
                  selectedCountryData.dialCode
                );
              }}
              onPhoneNumberBlur={() => {
                sendMessageformik.setFieldTouched("phoneNumber", true);
              }}
              onSelectFlag={(
                currentNumber,
                selectedCountryData,
                fullNumber,
                isValid
              ) => {
                if (
                  // awsAllowedCountries?.codes.includes(
                  //   selectedCountryData.dialCode
                  // ) -> 해외 사용자 임시로 막음.
                  selectedCountryData.dialCode === "82"
                ) {
                  sendMessageformik.setFieldValue("isValid", isValid);
                  sendMessageformik.setFieldValue(
                    "countryCode",
                    selectedCountryData.dialCode
                  );
                } else {
                  sendMessageformik.setFieldValue("phoneNumber", "");
                  sendMessageformik.setFieldValue("isValid", false);
                  Swal.fire({
                    // icon: "error",
                    // text: "지원하지 않는 국가입니다. 채널톡을 통해 관리자에게 연락주세요.",
                    icon: "error",
                    title: "불편을 드려 죄송합니다.",
                    text: "해외 거주 등의 사유로 인해 휴대폰 번호 인증이 어려우신 경우 채널톡으로 문의 부탁드립니다. 빠른 시일 내에 해외 사용자도 가능하도록 업데이트 하겠습니다.",
                    confirmButtonText: "확인",
                  });
                }
              }}
            />
            {isConfirmed && (
              <img
                src={confirmSuccess}
                alt="인증성공"
                className="absolute top-[13px] right-[16px]"
              />
            )}
          </div>

          {!isConfirmed && (
            <button
              className="text-sm flex items-center justify-center text-white bg-black rounded-[5px] h-[45px] ml-[5px] w-[106px] shrink-0"
              type="submit"
              disabled={sendMessageformik?.isSubmitting}
            >
              {isReceiveInputOpen ? "재전송" : "인증 번호 받기"}
            </button>
          )}
        </div>

        {sendMessageformik.touched && sendMessageformik.errors && (
          <div className="flex flex-col">
            <span className="mt-2 text-xs text-red400">
              {sendMessageformik.errors.phoneNumber}
            </span>
            <span className="mt-2 text-xs text-red400">
              {sendMessageformik.errors.code}
            </span>
          </div>
        )}
      </form>

      {isReceiveInputOpen && (
        <form onSubmit={receiveInputFormik.handleSubmit} className="mt-[18px]">
          <UsersInputLabel labelName="인증번호" inputId="confirmCode" />
          <div className="flex items-center w-full">
            <UsersInput
              inputPlaceHolder="문자메세지로 받은 번호를 입력하세요"
              inputId="code"
              inputType="text"
              onChange={receiveInputFormik.handleChange}
              inputRef={codeInputRef}
            />
            <button
              className="text-sm flex items-center justify-center text-white bg-black rounded-[5px] h-[45px] ml-[5px] mt-[6px] shrink-0 w-[72px]"
              type="submit"
            >
              확인
            </button>
          </div>

          <div className="flex justify-end mt-2 text-red400">{remainTime}</div>

          {receiveInputFormik.touched && receiveInputFormik.errors && (
            <div className="flex flex-col">
              <span className="mt-2 text-xs text-red400">
                {receiveInputFormik.errors.phoneNumber}
              </span>
              <span className="mt-2 text-xs text-red400">
                {receiveInputFormik.errors.code}
              </span>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default AuthPhoneNumber;
