import { LectureTitle } from "../shared/Title";
import KitTextItem from "./KitTextItem";
import { addZeroFrontOfNum } from "../../utils";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

const KitComponent = ({ kit_image_url, kit_items, kit_info_link }) => {
  return (
    <>
      <LectureTitle text="키트 구성" />
      <img src={kit_image_url} alt="키트소개" />
      {kit_items?.map((item, index) => {
        return (
          <KitTextItem
            item={item}
            key={item.id}
            realIndex={addZeroFrontOfNum(index)}
          />
        );
      })}
      {!isEmpty(kit_info_link) && (
        <a
          className="underline cursor-pointer text-[14px]"
          target="_blank"
          href={kit_info_link}
        >
          ❖ 키트 원재료 살펴보기(클릭)
        </a>
      )}
    </>
  );
};

export default KitComponent;

KitComponent.propTypes = {
  kit_image_url: PropTypes.string,
  kit_items: PropTypes.array,
  kit_info_link: PropTypes.string,
};
