import axios from "axios";
import Amplify, { Auth } from "aws-amplify";
import { cognitoKeyWithEnv } from "./constant";
import { setReturnSession } from "./utils";

Amplify.configure({
  Auth: {
    region: "ap-northeast-2",
    userPoolId: cognitoKeyWithEnv[process.env.TARGET_ENV]["userPoolId"],
    userPoolWebClientId:
      cognitoKeyWithEnv[process.env.TARGET_ENV]["userPoolWebClientId"],
  },
});

const AuthAPI = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status == 304;
  },
});

AuthAPI.interceptors.request.use(
  async (config) => {
    try {
      const cognitoToken = await Auth.currentSession();

      const jwtToken = cognitoToken.getAccessToken().getJwtToken();
      config.headers.Authorization = `Bearer ${jwtToken}`;
    } catch {
      delete config.headers.Authorization;
    } finally {
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

AuthAPI.interceptors.response.use(
  (success) => {
    return success;
  },
  async (error) => {
    switch (error.response.status) {
      case 401:
        await Auth.signOut();
        setReturnSession();
        break;
      case 403:
        if (history.length === 1) return (location.href = "/");
        history.back();
        break;
      default:
        return Promise.reject(error);
    }
  }
);

//home
export const getMainBanners = () => AuthAPI.get("/api/main_banners/main");
export const getWatchingLectures = (props) =>
  AuthAPI.get("/api/main/lectures", {
    params: {
      page: props?.page,
      per: props?.per,
    },
  });
export const getOriginalLectures = () => AuthAPI.get("/api/lectures/original");
export const getRatedLectures = () => AuthAPI.get("/api/lectures/rating");
export const getKitLectures = () => AuthAPI.get("/api/lectures/kit");

//로그인 회원가입
export const postSignup = ({
  name,
  email,
  isMarketing,
  phoneConfirmId,
  password,
  countryCode,
  identifyId,
  style,
}) =>
  AuthAPI.post("/api/users", {
    name: name,
    email: email,
    style: style,
    is_marketing: isMarketing,
    phone_confirm_id: phoneConfirmId,
    country_code: countryCode,
    identify_id: identifyId,
    password: password,
  });
export const postPhoneNumber = ({ phoneNumber, countryCode }) =>
  AuthAPI.post("/api/phone_confirms", {
    phone_number: phoneNumber,
    country_code: countryCode,
  });
export const getPhoneConfirmCheck = ({ phoneNumber, code }) =>
  AuthAPI.get("/api/phone_confirms/check", {
    params: { phone_number: phoneNumber, code: code },
  });
export const getEmailExist = ({ email }) =>
  AuthAPI.get("/api/users/check_email_overlap", { params: { email: email } });
export const postAuthCodes = ({ phoneNumber, countryCode }) =>
  AuthAPI.post("/api/auth_codes", {
    phone_number: phoneNumber,
    country_code: countryCode,
  });
export const getAuthCodesCheck = ({ phoneNumber, code }) =>
  AuthAPI.get("/api/auth_codes/check", {
    params: {
      phone_number: phoneNumber,
      code: code,
    },
  });
export const getFindEmail = ({ encryptedKey, phoneNumber }) =>
  AuthAPI.get("/api/users/check_email", {
    params: {
      user_encrypted_key: encryptedKey,
      phone_number: phoneNumber,
    },
  });
export const checkPasswordAndCreateCognitoId = ({ email, password }) =>
  AuthAPI.post("/api/users/create_cognito_user_with_password", {
    email: email,
    password: password,
  });
export const postChangePw = ({
  userEncryptedKey,
  password,
  email,
  passwordConfirmation,
}) =>
  AuthAPI.post("/api/users/change_password", {
    user_encrypted_key: userEncryptedKey,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
  });
export const postKakaoAuth = ({ code }) =>
  AuthAPI.post("/api/identifies/kakao", {
    code: code,
  });
export const postNaverAuth = ({ code, state }) =>
  AuthAPI.post("/api/identifies/naver", {
    code: code,
    state: state,
  });
export const postKakaoLinkUp = ({ code }) =>
  AuthAPI.post("/api/identifies/link_kakao", {
    code: code,
  });
export const postNaverLinkUp = ({ code, state }) =>
  AuthAPI.post("/api/identifies/link_naver", {
    code: code,
    state: state,
  });
export const postComparisonEmailAndPw = ({ email, user_encrypted_key }) =>
  AuthAPI.post("/api/users/comparison_input_email_with_user_email", {
    email: email,
    user_encrypted_key: user_encrypted_key,
  });

//상단띠배너
export const getTopBanner = () => AuthAPI.get("/api/top_banners");

//새소식
export const getPinnedNotice = () => AuthAPI.get("/api/board_banners");
export const getNotices = () => AuthAPI.get("/api/notices");
export const getEvents = () => AuthAPI.get("/api/events");
export const getFaqs = () => AuthAPI.get("/api/faqs");
export const getNoticeDetail = ({ params }) =>
  AuthAPI.get(`/api/notices/${params}`);
export const getEventDetail = ({ params }) =>
  AuthAPI.get(`/api/events/${params}`);
export const getSubjects = () => AuthAPI.get("/api/subjects");
export const getBoardReplies = ({ boardId, page, per }) =>
  AuthAPI.get("/api/comments", {
    params: {
      commentable_type: "Board",
      commentable_id: boardId,
      page: page,
      per: per,
    },
  });
export const postBoardReply = ({ boardId, content, isSecret }) =>
  AuthAPI.post("/api/comments", {
    commentable_type: "Board",
    commentable_id: boardId,
    body: content,
    is_secret: isSecret,
  });
export const deleteBoardReply = ({ replyId }) =>
  AuthAPI.delete(`/api/comments/${replyId}`);

//초등클래스전체
export const getKidBanners = () => AuthAPI.get("/api/main_banners/kid");
export const getKidLectures = (query = {}) =>
  AuthAPI.get("/api/kid_lectures", { params: query });

//부모클래스전체
export const getParentBanners = () => AuthAPI.get("/api/main_banners/parent");
export const getParentLectures = () => AuthAPI.get("/api/parent_lectures");

//사용자정보
export const getUser = () => AuthAPI.get("/api/users/detail");

// ahoy 사용자 인증
export const getAhoyAuthenticateUser = () =>
  AuthAPI.get("/api/users/ahoy_authenticate_user");

//마이페이지
export const getMyDnavLectures = () =>
  AuthAPI.get("/api/mypage/dnav_lectures");
export const getMyKidLectures = () =>
  AuthAPI.get("/api/mypage/lectures?type=kid");
export const getMyKidLiveLectures = () =>
  AuthAPI.get("/api/mypage/live_lectures?type=kid");
export const getMyParentLectures = () =>
  AuthAPI.get("/api/mypage/lectures?type=parent");
export const getMyParentLiveLectures = () =>
  AuthAPI.get("/api/mypage/live_lectures?type=parent");
export const getMySseobolgga = () =>
  AuthAPI.get("/api/mypage/lectures?type=write");
export const getMyLiveLectures = () => AuthAPI.get("/api/mypage/live_lectures");
export const getKgPayOrders = () => AuthAPI.get("/api/mypage/orders");
export const getNicePayOrders = () => AuthAPI.get("/api/mypage/nice_orders");
export const patchUser = (params = {}) => AuthAPI.patch("/api/users", params);
export const patchUserProfile = (params = {}) =>
  AuthAPI.patch("/api/users/avatar", params);
export const getMyCoupons = () => AuthAPI.get("/api/coupons");
export const postRegisterCoupon = ({ couponCode }) =>
  AuthAPI.post("/api/coupons/check", {
    coupon: { code: couponCode },
  });
export const patchUserPassword = ({ currentPw, newPw, newPwConfirm }) =>
  AuthAPI.patch("/api/users/update_password_with_prev_password", {
    password: currentPw,
    new_password: newPw,
    new_password_confirmation: newPwConfirm,
  });
export const getMyOrderDetail = ({ params }) =>
  AuthAPI.get(`/api/mypage/orders/${params}`);
export const deleteChildInfo = ({ childId }) =>
  AuthAPI.delete(`/api/children/${childId}`);
export const patchChangePw = ({ encryptedKey, password, passwordConfirm }) =>
  AuthAPI.patch("/api/users/update_password_with_phone", {
    user_encrypted_key: encryptedKey,
    password: password,
    password_confirmation: passwordConfirm,
  });
export const getMissionHistories = ({ page, per }) =>
  AuthAPI.get("/api/mypage/mission_history", {
    params: {
      page: page,
      per: per,
    },
  });
export const patchFeedbackStatus = ({ missionId }) =>
  AuthAPI.patch(`/api/assignments/${missionId}/feedback_complete`);

//강의상세
export const getLecture = ({ lectureId, query }) =>
  AuthAPI.get(`/api/lecture_details/${lectureId}`, { params: query });
export const getCurriculums = ({ lectureId }) =>
  AuthAPI.get(`/api/lecture_details/${lectureId}/curriculums`);
export const postOrderOption = (params = {}) =>
  AuthAPI.post(`/api/orders/option_create`, params);
export const getHasPurchased = ({ lectureId }) =>
  AuthAPI.get(`/api/lecture_details/${lectureId}/has_purchased`);
export const getLectureTextbookDownload = ({ lectureId }) =>
  AuthAPI.get(`/api/lectures/${lectureId}/download`, { responseType: "blob" });
export const getOptionList = ({ optionId, slug }) =>
  AuthAPI.get(`/api/options/${optionId}/option_lists`, {
    params: { slug: slug },
  });
export const getRecommendLectures = ({ lectureId }) =>
  AuthAPI.get(`/api/lectures/${lectureId}/recommendation_list`);

//강의시청
export const getLectureLessons = ({ lectureId }) =>
  AuthAPI.get(`/api/lectures/${lectureId}/lessons`);
export const getLesson = ({ lessonId }) =>
  AuthAPI.get(`/api/lessons/${lessonId}`);
export const getLessonComments = ({ lessonId }) =>
  AuthAPI.get("/api/comments", {
    params: { commentable_type: "Lesson", commentable_id: lessonId },
  });
export const patchUserColdCallActive = ({ isColdCall }) =>
  AuthAPI.patch("/api/users/change_cold_call_option", {
    is_cold_call: isColdCall,
  });
export const getColdCalls = ({ lessonId }) =>
  AuthAPI.get(`/api/lessons/${lessonId}/called_questions`);
export const getLessonDownloads = ({ lectureId }) =>
  AuthAPI.get(`/api/lectures/${lectureId}/lessons/download_list`);
export const getLessonTextbookDownload = ({ lessonId }) =>
  AuthAPI.get(`/api/lessons/${lessonId}/download`, {
    responseType: "blob",
  });
export const getMission = ({ lessonId }) =>
  AuthAPI.get(`/api/lessons/${lessonId}/mission`);
export const getAssignments = ({ lessonId, page, per }) =>
  AuthAPI.get(`/api/lessons/${lessonId}/assignments`, {
    params: {
      page: page,
      per: per,
    },
  });
export const postLessonReply = (params = {}) =>
  AuthAPI.post(`/api/comments`, params);

export const getCalledQuestions = ({ id, value }) =>
  AuthAPI.get(`/api/called_questions/${id}/check_answer`, {
    params: {
      answer: value,
    },
  });
export const getCheckWatching = ({ lessonId }) =>
  AuthAPI.get(`/api/lessons/${lessonId}/check_watching`);

//비디오 관련
export const getSyncUserLecture = ({ lessonId }) =>
  AuthAPI.get(`/api/lessons/${lessonId}/sync_user_lecture`);
export const putWatching = ({ lessonId, params }) =>
  AuthAPI.put(`/api/lessons/${lessonId}/update_watching`, params);

//과제
export const getAssignmentComments = ({ assignmentId }) =>
  AuthAPI.get("/api/comments", {
    params: { commentable_type: "Assignment", commentable_id: assignmentId },
  });
export const getAssignment = ({ assignmentId }) =>
  AuthAPI.get(`/api/assignments/${assignmentId}`);
export const patchAssignment = ({ assignmentId, params }) =>
  AuthAPI.patch(`/api/assignments/${assignmentId}`, params);
export const postAssignment = ({ lessonId, params }) =>
  AuthAPI.post(`/api/lessons/${lessonId}/assignments`, params);
export const deleteAssignment = ({ assignmentId }) =>
  AuthAPI.delete(`/api/assignments/${assignmentId}`);
export const postAssignmentImage = (data) =>
  AuthAPI.post(`/api/assets/images_upload`, data);

//주문
export const getOrder = ({ orderId }) => AuthAPI.get(`/api/orders/${orderId}`);
export const getMyDetailCoupons = ({ orderId }) =>
  AuthAPI.get(`/api/coupon_users`, { params: { order_id: orderId } }); //할인율등의 coupon 정보가 더 들어가 있음. getMycoupons 와 다름
export const patchOrder = ({ orderId, params }) =>
  AuthAPI.patch(`/api/orders/${orderId}`, params);
export const getCouponsNotUsed = ({ couponId }) =>
  AuthAPI.get(`/api/coupon_users/${couponId}/not_use`);
export const postOrderCheckAuth = ({ orderId, params }) =>
  AuthAPI.post(`/api/orders/${orderId}/check_auth`, params);
export const postCancelOrder = ({ orderId, reason }) =>
  AuthAPI.post("/api/nice_payments/cancel", {
    order_id: orderId,
    reason: reason,
  });
export const postEditOrderAddress = ({
  orderId,
  postCode,
  shortAddress,
  detailAddress,
  desc,
}) =>
  AuthAPI.post(`/api/orders/${orderId}/update_address`, {
    postcode: postCode,
    short_address: shortAddress,
    detail_address: detailAddress,
    desc: desc,
  });

//써볼까
export const getWritingOrder = () =>
  AuthAPI.get(`/api/lectures/sseobolgga_lectures`);
export const getAssignmentOnlyView = ({ assignmentId, userId }) =>
  AuthAPI.get(`/api/assignments/${assignmentId}/only_view`, {
    params: { user_id: userId },
  });

//디냅 결제
export const getDnavLectures = () => AuthAPI.get(`/api/dnav_lectures`);

//주문완료
export const getOrderResult = ({ orderId }) =>
  AuthAPI.get(`/api/orders/${orderId}/detail`);

//리뷰
export const getReviews = ({ targetId, targetType, page, per }) =>
  AuthAPI.get("/api/reviews", {
    params: {
      reviewable_id: targetId,
      reviewable_type: targetType,
      page: page,
      per: per,
    },
  });
export const postReviews = ({ data, targetId, targetType }) =>
  AuthAPI.post("/api/reviews", data, {
    params: {
      reviewable_id: targetId,
      reviewable_type: targetType,
    },
  });
export const postReviewsPlain = ({ data, targetId, targetType, userId }) =>
  AuthAPI.post("/api/reviews", data, {
    params: {
      reviewable_id: targetId,
      reviewable_type: targetType,
      user_id: userId,
    },
  });
export const deleteReview = ({ reviewId }) =>
  AuthAPI.delete(`/api/reviews/${reviewId}`);
export const getEncryptedReview = ({ userId, reviewableId, reviewableType }) =>
  AuthAPI.get("/api/reviews/check", {
    params: {
      user_id: userId,
      reviewable_id: reviewableId,
      reviewable_type: reviewableType,
    },
  });

//찜하기(좋아요)
export const deleteInterest = ({ interestId }) =>
  AuthAPI.delete(`/api/interests/${interestId}`);
export const getSpecialEventInterest = ({ specialEventId }) =>
  AuthAPI.get("/api/interests/targets", {
    params: {
      interestable_type: "SpecialEvent",
      interestable_id: specialEventId,
    },
  });
export const postLiveLectureInterest = ({ lectureId }) =>
  AuthAPI.post("/api/interests", {
    interestable_type: "LiveLecture",
    interestable_id: lectureId,
  });

//스페셜이벤트
export const getSpecialEvent = ({ specialEventId }) =>
  AuthAPI.get(`/api/special_events/${specialEventId}`);

//gtag 태깅
export const getUserProperties = () =>
  AuthAPI.get("/api/users/gtm_user_properties");
export const getLectureItemProperties = ({ lectureId }) =>
  AuthAPI.get(`/api/lectures/${lectureId}/gtag_items`);
export const getBeginCheckout = ({ orderId }) =>
  AuthAPI.get(`/api/orders/${orderId}/begin_checkout`);
export const getNicePayCheck = ({ paymentId }) =>
  AuthAPI.get(`/api/nice_payments/${paymentId}/check`);

//암호화
export const postEncrypt = ({ targetValue }) =>
  AuthAPI.post(`/api/encrypts/encrypt?target_key=${targetValue}`);

//댓글
export const postNestedComment = ({ replyId, content, isSecret }) =>
  AuthAPI.post("/api/comments", {
    commentable_type: "Comment",
    commentable_id: replyId,
    body: content,
    is_secret: isSecret,
  });
export const deleteReply = ({ replyId }) =>
  AuthAPI.delete(`/api/comments/${replyId}`);

//패키지클래스
export const getPackageInfo = ({ packageClassId, slug }) =>
  AuthAPI.get(`/api/packages/${packageClassId}/has_purchased`, {
    params: { slug: slug },
  });

//탐글올킬패키지
export const getTamgeulPrice = ({ slug }) =>
  AuthAPI.get(`/api/packages/tamgeul_allkill?slug=${slug}`);

export const getOnedayBoards = () => AuthAPI.get("/api/oneday_boards");
export const getOnedayBoard = ({ id }) =>
  AuthAPI.get(`/api/oneday_boards/${id}`);

export const submitOnedayBoard = ({
  id,
  parentName,
  parentPhone,
  ages,
  lectures,
}) =>
  AuthAPI.post(`/api/oneday_boards/${id}/submit`, {
    name: parentName,
    phone: parentPhone,
    grades: ages,
    lectures: lectures,
  });
