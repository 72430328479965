import { payMethodString } from "./OrderUtils";
import { addComma } from "../../utils";

const OrderUserInfo = ({ myOrder }) => {
  return (
    <div className="flex flex-col">
      <div className="border-b border-gray100 mt-[7px] mx-[37px]"></div>
      <div className="flex justify-between items-center mt-[7px] mx-[37px]">
        <span className="text-sm text-gray400">최종 결제 금액</span>
        <span className="font-bold">{addComma(myOrder?.amount)} 원</span>
      </div>
      <div className="flex justify-between items-center text-sm mt-[9px] mx-[37px]">
        <span className="text-gray400">결제 수단</span>
        <span>{payMethodString(myOrder?.pay_method)}</span>
      </div>
      <div className="border-b-[2px] border-gray100 mt-[25px]"></div>
      <span className="mt-[25px] text-sm font-bold mx-[37px]">결제자 정보</span>
      <div className="flex justify-between items-center text-sm mt-[15px] mx-[37px]">
        <span className="text-gray400">이름</span>
        <span className="font-medium">{myOrder?.name}</span>
      </div>
      <div className="flex justify-between items-center text-sm mt-[7px] mx-[37px]">
        <span className="text-gray400">연락처</span>
        <span className="font-medium">{myOrder?.tel}</span>
      </div>
    </div>
  );
};

export default OrderUserInfo;
