import React, { useRef } from "react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import * as Yup from "yup";
import { postAssignment } from "../../api";
import { useFormik } from "formik";
import { AssignmentEditor } from "./AssignmentEditor";

export const NewContent = ({ lessonId }) => {
  const deviceCheck = useDeviceCheck(null);
  const editorRef = useRef(null);

  const assignmentSchema = Yup.object().shape({
    title: Yup.string().required("제목은 필수로 입력해야합니다"),
    content: Yup.string().required("내용은 필수로 입력해야합니다"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      is_private: false,
    },
    validationSchema: assignmentSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      postAssignment({ lessonId: lessonId, params: values }).then(
        (response) => {
          window.location.href = `/assignments/${response?.data?.id}`;
        }
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col">
      {deviceCheck === "web" && (
        <div className="mb-[9px]">
          <span className="font-medium tablet:text-sm mobile:text-sm">
            &nbsp;&nbsp;&nbsp;
          </span>
          <div className="flex items-center justify-between">
            <span className="font-bold web:text-xl">미션 도전!</span>
            <a
              href={`/lessons/${lessonId}?tab=assignment`}
              className="text-white web:px-5 tablet:px-[7px] mobile:px-[7px] web:py-[6px] rounded-[5px] font-bold web:text-sm tablet:text-xs mobile:text-xs bg-yellow700 p-[6px]"
            >
              강의로 돌아가기
            </a>
          </div>
        </div>
      )}
      <span className="font-bold web:text-xl mt-[20px]">미션 제목</span>
      <input
        type="text"
        name="title"
        className="w-full text-lg border border-gray100 web:h-[52px] tablet:h-[32px] mobile:h-[32px] web:rounded-[10px] tablet:rounded-[5px] mobile:rounded-[5px] mb-[18px] px-2 mt-1"
        onChange={formik.handleChange}
      />
      {formik.touched.title && formik.errors.title && (
        <div className="text-red400">{formik.errors.title}</div>
      )}
      <div className="flex items-center justify-between web:mt-[20px] tablet:mt-[10px] mobile:mt-[10px] mb-1">
        <span className="font-bold web:text-xl">미션 내용</span>
      </div>
      <AssignmentEditor editorRef={editorRef} formik={formik} />
      {formik.touched.content && formik.errors.content && (
        <div className="text-red400">{formik.errors.content}</div>
      )}
      {deviceCheck !== "web" && (
        <div className="flex items-center my-4">
          <input
            type="checkbox"
            onChange={formik.handleChange}
            className="mr-2 w-[15px] h-[15px]"
            name="is_private"
            id="is_private"
          />

          <label htmlFor="is_private" className="text-sm">
            선생님한테만 보여줄래요
          </label>
        </div>
      )}
      <div className="flex justify-between mt-[7px]">
        {deviceCheck === "web" && (
          <div className="flex items-center my-4">
            <input
              type="checkbox"
              onChange={formik.handleChange}
              className="mr-2 w-[15px] h-[15px]"
              name="is_private"
              id="is_private"
            />

            <label htmlFor="is_private" className="text-sm">
              선생님한테만 보여줄래요
            </label>
          </div>
        )}

        <button
          type="submit"
          disabled={formik.isSubmitting ? true : false}
          className="bg-black text-white rounded-[5px] w-full h-[45px] web:max-w-[256px] flex items-center justify-center"
        >
          {formik.isSubmitting ? "미션 제출 중..." : "미션 제출하기"}
        </button>
      </div>
    </form>
  );
};
