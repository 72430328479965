import { useEffect } from "react";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import { useUserSignedIn } from "../hooks/useUserSignedIn";
import { getUserProperties } from "../api";

const Application = () => {
  const { currentUser } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();

  useEffect(() => {
    if (isUserSignedIn && currentUser) {
      getUserProperties().then((response) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'user_properties': {
            'user_id': response?.data?.id,
            'user_nm': response?.data?.name,
            'gender': response?.data?.gender,
            'birth_date': response?.data?.birthday,
            'phone': response?.data?.phone,
            'email': response?.data?.email,
            'registration_date': response?.data?.registration_date,
            'registration_type': response?.data?.identifies?.length !== 0 ? 'sns' : 'smallbigclass',
            'grades': 'general',
            'lifetime_value': response?.data?.lifetime_value,
            'order_counts': response?.data?.order_counts,
            'logged_in': "yes",
            'email_agree': null,
            'phone_agree': response?.data?.phone_agree,
          }
        })
      })
    }
  }, [isUserSignedIn, currentUser]);

  return <></>;
};

export default Application;
