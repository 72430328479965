import { MoreInfo } from "../components/users/index";
import Layout from "../components/Layout";

const UsersMoreInfo = () => {
  return (
    <Layout>
      <MoreInfo />
    </Layout>
  );
};

export default UsersMoreInfo;
