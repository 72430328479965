import backBtn from "../../assets/icons/back_btn";

const GoBackBtn = ({ lectureId }) => {
  return (
    <a href={`/lectures/${lectureId}`}>
      <img
        src={backBtn}
        alt="뒤로가기버튼"
        className="mobile:w-[18px] mobile:h-[13px] web:w-[22px] web:h-[17px] tablet:w-[22px] tablet:h-[17px]"
      />
    </a>
  );
};

export default GoBackBtn;
