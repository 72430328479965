import { useEffect, useState } from "react";

import LecturesNone from "./LecturesNone";
import EmptyScreen from "../shared/EmptyScreen";
import LectureCard from "../shared/LectureCard";
import { getDnavLectures } from "../../api";

const Content = () => {
  const [lectures, setLectures] = useState(null);
  const [loading, setLoading] = useState(true);

  const setLecture = (lecture) => {
    return {
      id: lecture?.id,
      title: lecture?.title,
      reviews: lecture?.reviews,
      teacher_name: lecture?.teacher_name,
      ordered_tag_list: lecture?.ordered_tag_list,
      object_type: "lecturesDnav",
      lecture_type: "lectures",
      status: lecture?.status,
      coming_text: lecture?.coming_text,
      img_url: lecture?.image?.url,
      is_finish: null,
      is_active: lecture?.is_active,
      min_age: lecture?.min_age,
      max_age: lecture?.max_age,
    };
  };

  useEffect(() => {
    getDnavLectures().then((response) => {
      setLectures(response?.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="flex flex-col">
      {!loading ? (
        lectures?.length !== 0 ? (
          <div className="max-w-[1230px] mx-auto px-[15px] flex flex-col my-[22px] w-full grid web:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 gap-x-4 web:gap-y-[50px] tablet:gap-y-[30px] mobile:gap-y-[30px]">
            {lectures?.map((lecture) => {
              return (
                <LectureCard key={lecture?.id} lecture={setLecture(lecture)} />
              );
            })}
          </div>
        ) : (
          <LecturesNone />
        )
      ) : (
        <EmptyScreen />
      )}
    </div>
  );
};

export default Content;
