import { LectureTitle } from "../../components/shared/Title";

const AddedSection = ({ deviceCheck, lecture }) => {
  return (
    <>
      <LectureTitle text={lecture?.live_image_title} />
      {deviceCheck === "web" || deviceCheck === "tablet" ? (
        <img src={lecture?.live_image_lg_url} alt="" />
      ) : (
        <img src={lecture?.live_image_sm_url} alt="" />
      )}
    </>
  );
};

export default AddedSection;
