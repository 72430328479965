import { useState, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { LectureTitle } from "../shared/Title";
import { resizeIframes } from "../../utils";
import { color } from "../../tailwindStyle";

const arrowDown = css`
  border-radius: 1px;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  vertical-align: middle;
  margin-bottom: 5px;
`;

const arrowUp = css`
  border-radius: 1px;
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  vertical-align: middle;
  margin-top: 8px;
`;

const buttonLookMore = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23.8px;
  background-color: ${color.blue300};
  width: 240px;
  height: 38px;
  content: "더보기";
  color: white;
  cursor: pointer;
`;

const classIntroduceTextStyle = css`
  max-height: 600px;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );
    width: 100%;
    height: 6rem;
  }
`;

const ClassIntroduce = ({ deviceCheck, desc }) => {
  const [isClassIntroSpread, setIsClassIntroSpread] = useState(false);
  const classIntroduceRef = useRef();

  useEffect(() => {
    resizeIframes("note-video-clip");
  }, [desc]);

  useEffect(() => {
    return () => {
      if (isClassIntroSpread) {
        classIntroduceRef.current.scrollIntoView();
      }
    };
  }, [isClassIntroSpread]);

  return (
    <div ref={classIntroduceRef}>
      <div css={!isClassIntroSpread && classIntroduceTextStyle}>
        <span className="web:pt-4 tablet:pt-4">
          {deviceCheck === "mobile" && <LectureTitle text="클래스 소개" />}
          <span dangerouslySetInnerHTML={{ __html: desc }}></span>
        </span>
      </div>
      <div
        className="mx-auto mt-4 cursor-pointer class_more_view"
        css={buttonLookMore}
        onClick={() => setIsClassIntroSpread(!isClassIntroSpread)}
      >
        <span className="mr-4">{isClassIntroSpread ? "접기" : "더보기"}</span>
        <i css={isClassIntroSpread ? arrowUp : arrowDown}></i>
      </div>
    </div>
  );
};

export default ClassIntroduce;
