import { addComma } from "../../utils";
import liveAndVod from "../../assets/icons/liveAndVod.svg";

const Title = ({
  title,
  ordered_tag_list,
  discount_rate,
  origin_price,
  price,
}) => {
  return (
    <div className="mt-[70px] flex flex-col default_margin mobile:max-w-[640px] tablet:max-w-[640px]">
      <img
        src={liveAndVod}
        alt="라이브Vod1주일"
        className="web:w-[224px] tablet:w-[130px] mobile:w-[130px]"
      />

      <div className="flex tablet:flex-col mobile:flex-col web:justify-between web:items-center mt-[12px]">
        <div>
          <span className="font-bold tablet:text-xs mobile:text-xs opacity-30">
            {ordered_tag_list}
          </span>
          <div className="col-span-1 web:text-[26px] tablet:text-lg mobile:text-lg font-bold">
            {title}
          </div>
        </div>

        <div>
          <div className="flex web:flex-col tablet:justify-between mobile:justify-between tablet:items-center mobile:items-center tablet:mt-[18px] mobile:mt-[18px]">
            <div className="flex items-center text-gray400 tablet:text-sm mobile:text-sm">
              <span className="mr-2 font-bold">클래스 1회차 기준</span>
              <del>{addComma(parseInt(origin_price))} 원</del>
            </div>

            <div className="flex items-center font-bold text-blue-base web:ml-auto">
              <span className="mr-2">{parseInt(discount_rate)}%</span>
              <span className="web:text-2xl tablet:text-xl mobile:text-xl">
                {addComma(parseInt(price))} 원
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
