import Layout from "../components/Layout";
import {
  AssignmentLists,
  NewContentMobile,
  NewContent,
} from "../components/assignments/index";
import useDeviceCheck from "../hooks/useDeviceCheck";
import { AssignmentsInfo } from "../components/assignments/AssignmentsInfo";

const AssignmentsNew = (props) => {
  const deviceCheck = useDeviceCheck(null);

  return (
    <Layout>
      <div className="max-w-[1230px] px-[15px] mx-auto web:grid web:grid-cols-[43fr_57fr] web:gap-[28px]">
        <div className="flex flex-col w-full">
          <AssignmentsInfo lessonId={props?.lessonId} />
          {deviceCheck === "web" && (
            <AssignmentLists lessonId={props?.lessonId} />
          )}
        </div>
        {deviceCheck === "web" ? (
          <div className="flex flex-col w-full">
            <NewContent lessonId={props?.lessonId} />
          </div>
        ) : (
          <NewContentMobile lessonId={props?.lessonId} />
        )}
      </div>
    </Layout>
  );
};

export default AssignmentsNew;
