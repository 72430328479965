import rightArrowRed from "../../assets/icons/arrow_right_red.svg";
import moment from "moment";
import { addComma } from "../../utils";
import OrderUserInfo from "./OrderUserInfo";
import OrderRefund from "./OrderRefund";

const OrderShowNone = ({ myOrder, myOrderOrderable }) => {
  return (
    <>
      {/* 주문정보 */}
      <div className="flex items-center justify-between">
        <span className="text-lg font-bold">
          {moment(myOrder?.created_at).format("YYYY.MM.DD")}
        </span>
        <a href={myOrder?.receipt_url} className="flex text-sm">
          <span className="mr-[11px] text-red400">영수증 보기</span>
          <img src={rightArrowRed} alt="자세히보기아이콘" />
        </a>
      </div>

      <div className="border rounded-[5px] border-gray100 w-full py-[35px] flex flex-col mt-[14px]">
        <span className="text-lg font-bold mx-[37px]">
          {myOrderOrderable?.title}
        </span>
        <span className="mt-[21px] text-sm font-bold mx-[37px]">결제 정보</span>
        <div className="flex justify-between items-center text-sm mt-[15px] mx-[37px]">
          <span className="text-gray400">클래스 금액</span>
          <span>{addComma(myOrderOrderable?.display_price)} 원</span>
        </div>
        <div className="border-b border-gray100 mt-[7px] mx-[37px]"></div>
        <div className="flex justify-between items-center text-sm mt-[7px] mx-[37px]">
          <span className="text-gray400">총 상품 금액</span>
          <span>{addComma(myOrderOrderable?.display_price)} 원</span>
        </div>
        <div className="flex items-center justify-between mt-5 text-sm mx-[37px]">
          <span className="text-gray400">쿠폰 할인 금액</span>
          <span>{addComma(myOrder?.used_coupon_amount)} 원</span>
        </div>

        <OrderUserInfo myOrder={myOrder} />
      </div>

      <span className="text-xs text-red400">
        환불 금액이 있을 경우 최종 결제금액이 정확하지 않을 수 있습니다
      </span>

      {myOrder.refund_info?.length !== 0 &&
        myOrder.refund_info?.map((refund, index) => {
          return <OrderRefund refund={refund} key={`refund${index}`} />;
        })}
    </>
  );
};

export default OrderShowNone;
