import { css } from "@emotion/react";
import noticeDefault from "../../assets/images/notice_default_img.svg";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { getNotices } from "../../api";

//공지사항
const Notice = () => {
  const [notices, setNotices] = useState(null);
  useEffect(() => {
    let subscribed = true;

    getNotices().then((response) => {
      if (subscribed) {
        setNotices(response?.data);
      }
    });

    return () => (subscribed = false);
  }, []);

  return (
    <div
      className="mt-5"
      css={css`
        .item:first-of-type {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        .item:last-of-type {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      `}
    >
      {notices?.map((notice) => {
        return (
          <a
            href={`/boards/${notice?.id}`}
            key={notice?.id}
            className="pl-4 pr-2 py-[10px] item border border-gray100 flex items-center"
          >
            <div
              className="w-[90px] h-[73px] bg-gray25 flex mr-[9px] justify-center items-center shrink-0 bg-cover"
              css={css`
                background-image: url(${notice?.thumbnail || noticeDefault});
              `}
            ></div>

            <div className="flex flex-col">
              <span className="mb-2 text-sm font-medium break-all">
                {notice?.title}
              </span>
              <span className="text-xs text-gray400">
                {moment(notice?.created_at).format("YYYY-MM-DD")}
              </span>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default Notice;
