import { useState, useEffect } from "react";
import heartEmpty from "../../assets/icons/heart_empty.svg";
import heartFill from "../../assets/icons/heart_fill.svg";
import moment from "moment";
import { css } from "@emotion/react";
import useRemainTimer from "../../hooks/useRemainTimer";
import iziToast from "izitoast";
import { postLiveLectureInterest, deleteInterest } from "../../api";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const OneTeacher = ({ lecture, indexNum, interest, discount_rate }) => {
  const [statusText, setStatusText] = useState("");
  const [statusColor, setStatusColor] = useState("bg-blue-base");
  const { isUserSignedIn } = useUserSignedIn();
  const [interestState, setInterestState] = useState(null);

  const initLiveStatusText = () => {
    if (lecture?.is_purchase) {
      setStatusText("LIVE 구매 가능");
      setStatusColor("bg-blue-base");
    } else {
      setStatusText("판매 종료된 LIVE");
      setStatusColor("bg-gray100");
    }
  };

  const postInterest = () => {
    postLiveLectureInterest({ lectureId: lecture?.id }).then((response) => {
      setInterestState(response?.data);
      iziToast.success({
        message: `라이브 클래스, 꼭 들어보고 싶어요!<br>${discount_rate}% 할인 이벤트 마감 전, 안내 문자를 보내드리고 있습니다.`,
        position: "topCenter",
        timeout: 3000,
      });
    });
  };

  useEffect(() => {
    setInterestState(interest);
  }, [interest]);

  const clickInterest = () => {
    if (isUserSignedIn) {
      if (!!interestState) {
        deleteInterest({ interestId: interestState?.id }).then(() => {
          setInterestState(null);
        });
      } else {
        postInterest();
      }
    } else {
      iziToast.warning({
        message:
          "로그인 후 이용 가능합니다. ♥ 하트를 눌러주시면 마감임박 전 문자를 보내드려요!",
        position: "topCenter",
        timeout: 3000,
      });
    }
  };

  useEffect(() => {
    initLiveStatusText();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between font-bold">
        <span className="web:text-2xl tablet:text-xl mobile:text-xl mr-[9px]">
          {indexNum}회차
        </span>
        <span
          className={`mr-auto web:ml-[24px] web:text-xl tablet:text-sm mobile:text-sm ${
            !lecture?.is_purchase ? "text-gray400" : "text-blue-base"
          }`}
        >
          구매까지 남은 시간 {useRemainTimer(moment(lecture?.start_at))}
        </span>
        <button
          disabled={!lecture?.is_purchase}
          onClick={() => clickInterest()}
        >
          <img
            src={!!interestState ? heartFill : heartEmpty}
            alt={`관심있어요아이콘_${lecture?.id}`}
            className="web:w-[24px] web:h-[21px] tablet:w-[20px] tablet:h-[17px] mobile:min-w-[20px] mobile:h-[17px]"
          />
        </button>
      </div>

      <div className="flex items-center justify-between mt-[15px]">
        <span className="web:text-lg web:font-bold tablet:text-sm mobile:text-sm">
          {moment(lecture?.start_at).format("YYYY.MM.DD HH:mm")} -{" "}
          {moment(lecture?.end_at).format("HH:mm")}
        </span>
        <div
          className={`web:rounded-[5px] tablet:rounded-[5px] mobile:rounded-[5px] text-white flex items-center justify-center web:text-lg tablet:text-xs mobile:text-xs px-[15px] py-[4px] ${statusColor}`}
        >
          {statusText}
        </div>
      </div>

      <div
        className="flex rounded-[8px] border border-gray100 py-[34px] px-[9px] mt-[10px] h-full relative break-all"
        css={css`
          &::after {
            display: ${!lecture?.is_purchase ? "block" : "none"};
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 8px;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }
        `}
      >
        <div
          css={css`
            background-image: url(${lecture?.image?.url});
          `}
          className="web:w-[162px] web:h-[190px] tablet:w-[72px] tablet:h-[84px] mobile:w-[97px] mobile:h-[61px] h-auto bg-center bg-no-repeat mr-[15px] shrink-0 bg-cover"
        ></div>
        <div className="flex flex-col">
          <span className="font-bold web:text-lg tablet:text-sm mobile:text-sm">
            {lecture?.teacher_name}
          </span>
          <div className="tablet:text-xs mobile:text-xs font-medium mt-[9px]">
            <span dangerouslySetInnerHTML={{ __html: lecture?.content }}></span>
          </div>
        </div>
        {!lecture?.is_purchase && (
          <div
            className="z-[9] flex font-bold text-white tablet:flex-col mobile:flex-col tablet:items-center mobile:items-center"
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          >
            <span>신청 마감되었습니다.</span>
            <span>감사합니다!</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OneTeacher;
