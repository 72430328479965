import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";
import { useEffect, useState } from "react";
import { addComma } from "../../utils";
import moment from "moment";
import Select from "react-select";
import { css } from "@emotion/react";
import iziToast from "izitoast";

const LectureTakeChart = () => {
  const [salesDatas, setSalesDatas] = useState([]);
  const [lectureId, setLectureId] = useState(null);
  const [scope, setScope] = useState("day");
  const [chartPage, setChartPage] = useState(1);

  const [lectureOption, setLectureOption] = useState(null);

  const changeDataArray = (apiData) => {
    let arr = [];
    const keys = Object.keys(apiData);
    const values = Object.values(apiData);
    for (let i = 0; i < values.length; i++) {
      if (scope === "month") {
        arr.push({
          date: moment(keys[i]).format("YY년 MM월"),
          매출액: Number(values[i]),
        });
      } else if (scope === "week") {
        arr.push({
          date:
            moment(keys[i]).format("YY.MM.DD") +
            " ~\r\n" +
            moment(keys[i]).add(6, "days").format("YY.MM.DD"),
          매출액: Number(values[i]),
        });
      } else if (scope === "day") {
        arr.push({
          date: moment(keys[i]).format("YY년 MM월 DD일"),
          매출액: Number(values[i]),
        });
      } else if (scope === "year") {
        arr.push({
          date: moment(keys[i]).format("YYYY년"),
          매출액: Number(values[i]),
        });
      }
    }
    return arr;
  };

  const getSalesData = ({ page, pScope, pLectureId }) => {
    axios
      .get("/api/sales_data", {
        params: {
          page: page,
          scope: pScope,
          lecture_id: pLectureId,
        },
      })
      .then((response) => {
        const result = changeDataArray(response.data.data);
        setSalesDatas(result);
        setChartPage(page);
        setScope(pScope);
        setLectureId(pLectureId);

        iziToast.success({
          message: "기간별 강좌 매출액 로드 성공!",
          position: "topCenter",
          timeout: 1000,
        });
      })
      .catch((err) => {
        iziToast.error({
          message: "실패!",
          position: "topCenter",
          timeout: 1000,
        });
      });
  };

  const getLectureList = () => {
    axios.get("/api/lecture_list").then((response) => {
      let arr = [{ value: "전체클래스", label: "전체클래스" }];
      response?.data?.map((lecture) => {
        return arr.push({
          value: lecture.title,
          label: `[${lecture.is_publish ? "유료" : "무료"}] ${
            lecture.title
          } - (출시일 ${moment(lecture.created_at).format("YY년 MM월")})`,
          id: lecture.id,
        });
      });
      setLectureOption(arr);
    });
  };

  const getIterSum = () => {
    if (salesDatas?.length < 1) return;
    return addComma(
      salesDatas.reduce((sum, cv, ci) => {
        sum = sum + cv["매출액"];

        return sum;
      }, 0)
    );
  };

  //클래스선택 api 초기화
  useEffect(() => {
    getLectureList();
    getSalesData({ page: chartPage, pScope: scope, pLectureId: lectureId });
  }, []);

  const refreshData = () => {
    getSalesData({ page: 1, pScope: scope, pLectureId: lectureId });
  };

  const handleSelectLecture = (value) => {
    if (value.value === "전체클래스") {
      setLectureId(null);
    } else {
      setLectureId(value.id);
    }
  };

  const handleSelectScope = (value) => {
    setScope(value.value);
  };

  const handlePagePrevious = () => {
    getSalesData({ page: chartPage + 1, pScope: scope, pLectureId: lectureId });
  };

  const handlePageNext = () => {
    getSalesData({ page: chartPage - 1, pScope: scope, pLectureId: lectureId });
  };

  const chartProps = {
    margin: { top: 60, right: 80, bottom: 60, left: 80 },
    data: salesDatas,
    indexBy: "date",
    padding: 0.2,
    labelTextColor: "inherit:darker(1.4)",
    padding: 0.1,
    keys: ["매출액"],
    label: (d) => `${addComma(String(d.value))}원`,
    borderWidth: 1,
    borderColor: { from: "color", modifiers: [["darker", 1.6]] },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "날짜",
      legendPosition: "middle",
      legendOffset: 32,
      tickRotation: -30,
    },
    axisLeft: {
      format: (value) => addComma(String(value)),
    },
    tooltip: (data) => (
      <div
        style={{
          padding: 12,
          color: data.color,
          background: "black",
        }}
      >
        <strong>
          [{data.id}] {data.indexValue} : {addComma(String(data.value))}원
        </strong>
      </div>
    ),
    colors: { scheme: "set2" },
    colorBy: "indexValue",
  };

  return (
    <div className="flex flex-col justify-between p-4 border border-black">
      <div className="flex items-center mb-4 text-xl font-bold">
        기간별 강좌 매출액
        {salesDatas.length > 0 && (
          <>
            <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-normal leading-none text-black bg-green-300 rounded-full">
              [{salesDatas[0]?.date}] ~ [
              {salesDatas[salesDatas.length - 1]?.date}]
            </span>

            <span className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-normal leading-none text-black bg-green-300 rounded-full">
              해당 기간 매출액 총 합: {getIterSum()}원
            </span>
          </>
        )}
      </div>
      <div>
        <Select
          options={[
            { value: "day", label: "일" },
            { value: "week", label: "주" },
            { value: "month", label: "월" },
            { value: "year", label: "년" },
          ]}
          defaultValue={{ value: "day", label: "일" }}
          onChange={handleSelectScope}
        />
      </div>
      <div className="mt-2">
        <Select
          options={lectureOption}
          defaultValue={{ value: "전체클래스", label: "전체클래스" }}
          onChange={handleSelectLecture}
        />
      </div>
      <button
        className="p-2 mt-2 ml-auto text-white border rounded-md bg-navy900"
        onClick={refreshData}
      >
        제출
      </button>
      <div className="w-full" style={{ height: "50vh" }}>
        <ResponsiveBar {...chartProps} />
      </div>

      <div className="flex justify-between">
        <button onClick={handlePagePrevious}>이전</button>
        <button
          className={chartPage === 1 ? "invisible" : undefined}
          onClick={handlePageNext}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default LectureTakeChart;
