import { Reply } from "./Reply";
import { ReplyInputForm } from "./ReplyInputForm";
import { QnaTitle } from "./QnaTitle";

const Questions = ({ lessonId, qnas, commentableType }) => {
  return (
    <>
      {qnas && (
        <div className="flex flex-col">
          <QnaTitle
            qnasCount={qnas?.length}
            titleName="선생님 질문있어요!"
            titleContent={`<span class="font-bold">선생님 질문있어요</span> <br /> <br />
              <span>
                이곳은 학생들이 강의 내용에 대해 질문하는 공간입니다. 그 외의
                댓글은 삭제될 수 있습니다. <br /> <br /> 수강 신청, 강의 이용
                방법 등이 궁금하신가요? <br /> 화면 오른쪽 아래 채널톡을 눌러
                문의해주세요!
              </span>`}
          />
          <ReplyInputForm
            commentable_type={commentableType}
            commentable_id={lessonId}
          />
          <Reply qnas={qnas} replyTitle="다른 학생들 질문 보기" />
        </div>
      )}
    </>
  );
};

export default Questions;
