import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import LecturesKidsFilter from "./LecturesKidsFilter";
import LecturesNone from "./LecturesNone";
import EmptyScreen from "../shared/EmptyScreen";
import LectureCard from "../shared/LectureCard";
import { getKidLectures, getSubjects } from "../../api";

const Content = () => {
  const [subjects, setSubjects] = useState(null);
  useEffect(() => {
    getSubjects().then((response) => {
      setSubjects(response?.data);
    });
  }, []);

  const [lectures, setLectures] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [lecturesParams, setLecturesParams] = useState({
    subject: null,
    is_kit: false,
    is_textbook: false,
    per: 12,
  });

  const setLecture = (lecture) => {
    return {
      id: lecture?.id,
      title: lecture?.title,
      reviews: lecture?.reviews,
      teacher_name: lecture?.teacher_name,
      ordered_tag_list: lecture?.ordered_tag_list,
      object_type: "lecturesKids",
      lecture_type: "lectures",
      status: lecture?.status,
      coming_text: lecture?.coming_text,
      img_url: lecture?.image?.url,
      is_finish: null,
      is_active: lecture?.is_active,
      min_age: lecture?.min_age,
      max_age: lecture?.max_age,
    };
  };

  useEffect(() => {
    getKidLectures({ ...lecturesParams, page: 1 }).then((response) => {
      setLectures(response?.data);
      setLoading(false);
      setCurrentPage(1);
    });
  }, [lecturesParams]);

  const fetchLectures = () => {
    getKidLectures({ ...lecturesParams, page: currentPage + 1 }).then(
      (response) => {
        setLectures([...lectures, ...response?.data]);
        setCurrentPage(currentPage + 1);
      }
    );
  };

  return (
    <div className="flex flex-col web:mt-[50px] tablet:mt-[50px] mobile:mt-[40px]">
      <LecturesKidsFilter
        lecturesParams={lecturesParams}
        setLecturesParams={setLecturesParams}
        subjects={subjects}
      />

      {!loading ? (
        lectures?.length !== 0 ? (
          <div className="max-w-[1230px] mx-auto px-[15px] flex flex-col my-[22px] w-full">
            <InfiniteScroll
              hasMore={true}
              next={fetchLectures}
              dataLength={lectures?.length}
              className="grid web:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 gap-x-4 web:gap-y-[50px] tablet:gap-y-[30px] mobile:gap-y-[30px]"
            >
              {lectures?.map((lecture) => {
                return (
                  <LectureCard
                    key={lecture?.id}
                    lecture={setLecture(lecture)}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <LecturesNone />
        )
      ) : (
        <EmptyScreen />
      )}
    </div>
  );
};

export default Content;
