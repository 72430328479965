import Modal from "react-modal";
import { soldoutStyle } from "../../style";
import closeSm from "../../assets/icons/closeSm.svg";
import { css } from "@emotion/react";

const SoldoutModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      style={soldoutStyle}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      {/* <img
        src={popUpMainImg}
        alt="매진팝업"
        className="relative w-full"
      /> */}
      <img
        src={closeSm}
        alt="닫기"
        onClick={() => setIsOpen(false)}
        className="cursor-pointer w-[20px] absolute z-[20000000] top-[10px] right-[10px]"
        css={css`
          filter: brightness(0) invert(1);
        `}
      />
    </Modal>
  );
};

export default SoldoutModal;
