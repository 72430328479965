import { useState, useEffect } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export const UsersInput = ({
  inputPlaceHolder,
  inputId,
  inputType,
  onChange,
  value,
  inputRef,
  onBlur,
  classOption,
}) => {
  const [isEyeOpen, setIsEyeOpen] = useState(false);

  useEffect(() => {
    if (inputType === "password") {
      isEyeOpen
        ? (document.getElementById(inputId).type = "text")
        : (document.getElementById(inputId).type = "password");
    }
  }, [isEyeOpen]);

  return (
    <div className="relative flex flex-col w-full font-medium">
      <input
        id={inputId}
        name={inputId}
        type={inputType}
        onChange={onChange}
        value={value}
        ref={inputRef}
        onBlur={onBlur}
        className={`border-gray200 border-[0.5px] rounded-[5px] h-[45px] px-[15px] mt-[6px] w-full shrink-0 ${classOption}`}
        placeholder={inputPlaceHolder}
        autoComplete="off"
      />

      {inputType === "password" && (
        <div className="absolute right-[15px] top-[15px]">
          {isEyeOpen ? (
            <EyeOutlined
              style={{ fontSize: "20px" }}
              onClick={() => {
                setIsEyeOpen(!isEyeOpen);
              }}
            />
          ) : (
            <EyeInvisibleOutlined
              style={{ fontSize: "20px" }}
              onClick={() => {
                setIsEyeOpen(!isEyeOpen);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
