import ReviewForm from "./ReviewForm";
import ReviewContent from "./ReviewContent";
import { useState, useEffect } from "react";
import { getReviews } from "../../api";

const Review = ({ targetId, targetType, setIsModalOpen, setSelectReview }) => {
  const [reviews, setReviews] = useState(null);
  const [reviewsMeta, setReviewsMeta] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    setGetReviews();
  }, [targetId]);

  const setGetReviews = () => {
    getReviews({
      targetId: targetId,
      targetType: targetType,
      page: 1,
      per: 5,
    }).then((response) => {
      setReviews(response?.data?.reviews);
      setReviewsMeta(response?.data?.meta);
      setTotalCount(response?.data?.meta?.total_count);
    });
  };

  return (
    <>
      <ReviewContent
        targetId={targetId}
        targetType={targetType}
        reviews={reviews}
        totalCount={totalCount}
        setIsModalOpen={setIsModalOpen}
        setSelectReview={setSelectReview}
        reviewsMeta={reviewsMeta}
      />
      <ReviewForm
        targetId={targetId}
        targetType={targetType}
        getReviews={setGetReviews}
      />
    </>
  );
};

export default Review;
