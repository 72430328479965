import { css } from "@emotion/react";

import { addComma, setReturnSession } from "../../utils";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

import image1 from "../../assets/images/tamgeulAllkill/image1.png";
import image2 from "../../assets/images/tamgeulAllkill/image2.png";
import image3 from "../../assets/images/tamgeulAllkill/image3.png";
import image3AfterSale from "../../assets/images/tamgeulAllkill/image3_after_sale.png";
import image4 from "../../assets/images/tamgeulAllkill/image4.png";
import image5 from "../../assets/images/tamgeulAllkill/image5.png";
import image6 from "../../assets/images/tamgeulAllkill/image6.png";
import image7 from "../../assets/images/tamgeulAllkill/image7.png";
import image7AfterSale from "../../assets/images/tamgeulAllkill/image7_after_sale.png";
import image8 from "../../assets/images/tamgeulAllkill/image8.png";
import image1Bg from "../../assets/images/tamgeulAllkill/image1_bg.png";
import cta1 from "../../assets/images/tamgeulAllkill/cta1.png";

const Content = ({ packageInfo, handleSubmit, isDochimom }) => {
  const { isUserSignedIn } = useUserSignedIn();

  return (
    <div className="w-full flex flex-col">
      <section
        id="section1"
        className="bg-center bg-cover relative w-full"
        css={css`
          background-image: url(${image1Bg});
        `}
      >
        <div className="max-w-[650px] mx-auto">
          <img src={image1} alt="image1" />
        </div>
        <img
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
          `}
          onClick={() => {
            isUserSignedIn ? handleSubmit(packageInfo) : setReturnSession();
          }}
          className="absolute web:bottom-[72px] tablet:bottom-[36px] mobile:bottom-[36px] tablet:w-[80%] mobile:w-[80%] cursor-pointer"
          src={cta1}
          alt="cta1"
          id="tamgeulAllkill-cta1"
        />
      </section>
      <section className="w-full" id="section7">
        <img
          src={image7AfterSale}
          alt="image7"
          className="w-full mx-auto max-w-[650px]"
        />
      </section>
      <section className="w-full" id="section8">
        <div className="w-full mx-auto max-w-[650px] relative">
          <div className="flex justify-between items-center mx-[30px] px-[30px] rounded-[10px] bg-[#FFF4CC] text-[#DE0000] web:h-[125px] tablet:h-[70px] mobile:h-[70px] mt-[10px]">
            <span className="web:text-[24px] tablet:text-sm mobile:text-sm">
              현재 얼리버드 판매가
            </span>
            <span className="web:text-[34px] tablet:text-[20px] mobile:text-[20px] web:mr-[20px] tablet:mr-[10px] mobile:mr-[10px] ml-auto">
              {isDochimom
                ? "45%"
                : `${Math.round(Number(packageInfo?.discount_rate))}%`}
            </span>
            <span className="web:text-[34px] tablet:text-[20px] mobile:text-[20px] font-bold">
              {isDochimom
                ? "130,100원"
                : `${addComma(Number(packageInfo?.main_option_price))}원`}
            </span>
          </div>
        </div>
      </section>
      <section className="w-full" id="section5">
        <img
          src={image6}
          alt="image6"
          className="w-full mx-auto max-w-[650px]"
        />
      </section>
      <section className="w-full" id="section2">
        <img
          src={image2}
          alt="image2"
          className="w-full mx-auto max-w-[650px]"
        />
      </section>
      <section className="bg-[#DDDDDD]" id="section3">
        <div className="max-w-[650px] mx-auto relative">
          <img src={image3AfterSale} alt="가격정보" className="mx-auto" />
          <span
            className="font-bold w-full text-center web:text-[70px] tablet:text-[40px] mobile:text-[40px] absolute bottom-[17%] text-white"
            css={css`
              left: 50%;
              transform: translate(-50%, 0%);
            `}
          >
            {isDochimom
              ? "130,100원"
              : `${addComma(Number(packageInfo?.main_option_price))}원`}
          </span>
        </div>
      </section>
      <section className="w-full" id="section4">
        <img
          src={image4}
          alt="image4"
          className="w-full mx-auto max-w-[650px]"
        />
      </section>
      <section className="w-full bg-[#323232]" id="section5">
        <img
          src={image5}
          alt="image5"
          className="w-full mx-auto max-w-[650px]"
        />
      </section>
    </div>
  );
};

export default Content;
