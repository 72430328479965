import { Collapse } from "react-collapse";
import { useState, useEffect } from "react";
import backBtnSharp from "../../assets/icons/back_btn_sharp.svg";
import arrowDownSharp from "../../assets/icons/arrow_down_sharp.svg";
import arrowUpSharp from "../../assets/icons/arrow_up_sharp.svg";
import editPencil from "../../assets/icons/edit_pencil.svg";
import closeSm from "../../assets/icons/close_sm.svg";
import DaumPostcode from "react-daum-postcode";
import Popup from "reactjs-popup";
import creditCard from "../../assets/icons/credit_card.svg";
import depositWithoutBank from "../../assets/icons/deposit_without_bank.svg";
import { css } from "@emotion/react";
import { addComma } from "../../utils";
import * as Yup from "yup";
import iziToast from "izitoast";
import moment from "moment";
import { useRef } from "react";
import { getCouponsNotUsed, patchOrder } from "../../api";
import Swal from "sweetalert2";
import { filter, find, groupBy, sumBy } from "lodash";

const Content = ({
  openSheetDrawer,
  coupons,
  order,
  orderable,
  orderableList,
  selectCoupon,
}) => {
  //sales_option => kit, textbook, only_kit, only_textbook, none

  const [isOrderInfoOpen, setIsOrderInfoOpen] = useState(true);
  const [isDeliveryInfoOpen, setIsDeliveryInfoOpen] = useState(true);
  const [isPaymentInfoOpen, setIsPaymentInfoOpen] = useState(true);

  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    const groupedItems = groupBy(order?.order_items, (i) => i?.option_id);
    order?.options?.map((option) => {
      if (!!groupedItems[option.id]) {
        setOrderItems((prev) => [
          ...prev,
          {
            id: option?.id,
            shipping_fee: option?.shipping_fee,
            items: groupedItems[option.id],
          },
        ]);
      }
    });
  }, [order]);

  //howToPay State => 0이면 선택 안함. 1이면 카드결제. 2면 무통장입금
  //payMethod State => ""이면 선택 안함. "cardAndEasyPay" => 카드결제. "vbank" => 무통장입금
  const [payMethod, setPayMethod] = useState("");

  //받으시는 분과 휴대폰 번호 input 활성화
  const [isCommonUserInfoDisabled, setIsCommonUserInfoDisabled] =
    useState(true);

  //주소 state
  const [orderForm, setOrderForm] = useState({
    name: "",
    tel: "",
    postCode: "",
    shortAddress: "",
    detailAddress: "",
  });

  useEffect(() => {
    setOrderForm({
      name: order?.user?.name || "",
      tel: order?.user?.phone || "",
      postCode: order?.user?.postcode || "",
      shortAddress: order?.user?.short_address || "",
      detailAddress: order?.user?.detail_address || "",
    });
  }, [order]);

  //배송요청사항
  const [deliveryAskOption, setDeliveryAskOption] =
    useState("문 앞에 놓아 주세요.");
  const [deliveryAskText, setDeliveryAskText] =
    useState("문 앞에 놓아 주세요.");
  const [finalPrice, setFinalPrice] = useState(0);

  const askTextRef = useRef(null);

  const setPayMethodToggleBtn = (clickState) => {
    if (payMethod === clickState) {
      setPayMethod("");
    } else {
      setPayMethod(clickState);
    }
  };

  //배송비 계산
  const [realShippingFee, setRealShippingFee] = useState(0);
  useEffect(() => {
    let shippingFee = 0;
    orderItems?.map((x) => {
      if (
        sumBy(x?.items, "item_quantity") +
          sumBy(x?.items, "default_option_quantity") >
        0
      ) {
        shippingFee += Number(x?.shipping_fee);
      }
    });

    setRealShippingFee(shippingFee);
  }, [orderItems]);

  //"delivery", "only_delivery", "none"
  useEffect(() => {
    setFinalPrice(
      Number(order?.amount) -
        Number(selectCoupon?.discountValue) +
        Number(realShippingFee)
    );
  }, [order, selectCoupon, realShippingFee]);

  const calActivateCoupon = () => {
    let num = 0;
    coupons?.map((coupon) => {
      if (coupon.abled) {
        num += 1;
      }
    });
    return num;
  };

  const submitForm = () => {
    const validationSchema = Yup.object({
      name: Yup.string().required("이름을 입력 해주세요"),
      tel: Yup.string()
        .min(11, "전화번호를 바르게 입력해주세요")
        .max(11, "전화번호를 바르게 입력해주세요")
        .required("전화번호를 바르게 입력해주세요"),
      postcode:
        order?.is_shipping &&
        Yup.string()
          .trim()
          .required("우편번호 검색을 눌러 주소를 입력해주세요"),
      short_address:
        order?.is_shipping &&
        Yup.string()
          .trim()
          .required("우편번호 검색을 눌러 주소를 입력해주세요"),
      detail_address:
        order?.is_shipping &&
        Yup.string().trim().required("상세주소를 입력해주세요"),
      amount: Yup.string().required(
        "최종 결제 금액이 정상적으로 입력되지 않았습니다"
      ),
      payMethod: Yup.string().required("결제 수단을 선택해주세요"),
    });

    const parseForm = validationSchema
      .validate({
        name: orderForm.name,
        tel: orderForm.tel,
        postcode: orderForm.postCode,
        short_address: orderForm.shortAddress,
        detail_address: orderForm.detailAddress,
        amount: finalPrice,
        payMethod,
      })
      .then((response) => {
        const params = {
          order: {
            name: response.name,
            tel: response.tel,
            postcode: response.postcode,
            short_address: response.short_address,
            detail_address: response.detail_address,
            desc: deliveryAskText,
            amount: response.amount,
            pay_method: payMethod === "cardAndEasyPay" ? "card" : payMethod, // cardAndEasyPay 값 card로 값 보정
          },
        };

        if (selectCoupon?.discountValue > 0) {
          params["coupon_user"] = {
            id: selectCoupon?.couponId || null,
            used_amount: selectCoupon?.discountValue || null,
          };
        }

        patchOrder({ orderId: order?.id, params: params })
          .then((patchResponse) => {
            const { merchant_uid, amount, name, email, tel } =
              patchResponse.data.order;

            const payOption = {
              clientId: `${process.env.NICE_PAY_CLIENT_KEY}`,
              method: payMethod,
              orderId: merchant_uid,
              amount: amount,
              goodsName: "스몰빅클래스 - 강의",
              buyerName: name,
              buyerTel: tel,
              buyerEmail: email,
              returnUrl: `${window.location.origin}/orders/${order?.id}/server_auth`, // 해당 부분 front & backend 분리 시점 체크 필요
              fnError: () => {
                if (selectCoupon?.discountValue > 0) {
                  getCouponsNotUsed({ couponId: selectCoupon?.couponId });
                }

                Swal.fire({
                  icon: "error",
                  title: "죄송합니다, 결제를 실패했습니다.",
                  text: "처음부터 다시 한 번 시도해주세요. 문제가 반복될 경우 채널톡으로 문의주세요.",
                  confirmButtonText: "확인",
                  willClose: () => {
                    history.back();
                  },
                });
              },
            };

            if (payMethod === "vbank") payOption["vbankHolder"] = name;
            if (payMethod === "vbank") payOption["vbankValidHours"] = 1;
            AUTHNICE.requestPay({ ...payOption });
          })
          .catch(() => {
            iziToast.error({
              message: "비정상적 접근입니다",
              timeout: 2000,
              position: "topCenter",
              onClosing: function () {
                history.back();
              },
            });
          });
      })
      .catch((err) => {
        iziToast.error({
          message: err.message,
          timeout: 2000,
          position: "topCenter",
        });
      });
  };

  //image 비율 및 스타일
  const imgStyle =
    "aspect-[570/657] object-contain mr-[15px] shrink-0 w-[60px] object-center";

  return (
    <div className="flex flex-col max-w-[640px] mx-auto">
      <div className="px-[15px] flex justify-center pb-[24px] items-center">
        <span className="mx-auto font-bold">결제하기</span>
      </div>

      {/* 주문 정보 Collapse */}
      <div className="flex flex-col px-[15px] border-t-[5px] border-b-[5px] border-gray100">
        <div
          className="flex justify-between py-[24px]"
          onClick={() => setIsOrderInfoOpen(!isOrderInfoOpen)}
        >
          <span className="text-lg font-bold">주문 정보</span>
          <img
            src={isOrderInfoOpen ? arrowUpSharp : arrowDownSharp}
            alt="주문정보접기"
            className="w-[10px]"
          />
        </div>

        <Collapse
          isOpened={isOrderInfoOpen}
          theme={{
            collapse: "orderCollapse",
            content: "orderCollapseContent",
          }}
        >
          {order?.orderable_type === "LiveLecture" ? (
            <div className="border-t-gray100 border-t pt-[24px] pb-[39px]">
              <span className="font-bold">수강할 클래스</span>
              <div className="flex items-center mt-[25px]">
                <img
                  src={orderable?.image?.url}
                  alt="수강할클래스"
                  className={imgStyle}
                />
                <div className="flex flex-col text-sm">
                  <span className="font-medium">{orderable?.title}</span>
                  <div className="flex mt-[6px] font-bold text-red-700">
                    <span className="mr-[40px] font-medium">라이브 일시</span>
                    <span>
                      {moment(orderable?.start_at)
                        .locale("ko")
                        .format("YYYY.MM.DD (ddd) HH:mm")}{" "}
                      - {moment(orderable?.end_at).format("HH:mm")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="pb-4">
              {/* 수강할 클래스 */}

              {!order?.is_only && (
                <div className="border-t-gray100 border-t py-[25px] pb-[39px]">
                  {order?.orderable_type === "Package" && (
                    <div className="flex flex-col mb-[25px]">
                      <span className="font-bold">구매하는 패키지</span>
                      <div
                        className="flex items-center mt-[25px]"
                        key={`orderable_package`}
                      >
                        <img
                          src={orderable?.image_url}
                          alt="수강할클래스"
                          className={imgStyle}
                        />
                        <div className="flex flex-col text-sm">
                          <span className="font-medium">
                            {orderable?.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <span className="font-bold">수강할 클래스</span>
                  {orderableList &&
                    orderableList?.map((lecture) => (
                      <div
                        className="flex items-center mt-[25px]"
                        key={`orderable_${lecture.id}`}
                      >
                        <img
                          src={lecture?.image_url}
                          alt="수강할클래스"
                          className={imgStyle}
                        />
                        <div className="flex flex-col text-sm">
                          <span className="font-medium">{lecture?.title}</span>
                          {lecture?.viewer_type !== "dnav" && (
                            <div className="flex mt-[6px]">
                              <span className="mr-[40px] font-medium">
                                수강기한
                              </span>
                              {lecture?.viewer_type !== "write" ? (
                                order?.orderable_type === "Package" ? (
                                  <span className="text-gray400">
                                    ~{" "}
                                    {moment()
                                      .add(lecture?.watching_period, "days")
                                      .format("YYYY-MM-DD")}{" "}
                                    까지
                                  </span>
                                ) : (
                                  <span className="text-gray400">
                                    ~{" "}
                                    {moment()
                                      .add(lecture?.watching_period, "months")
                                      .format("YYYY-MM-DD")}{" "}
                                    까지
                                  </span>
                                )
                              ) : (
                                <span className="text-gray400">
                                  강의 구매 후 2주 동안
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}

              {/* 기본 구매 상품 */}
              {filter(order?.order_items, (o) => o.default_option_quantity > 0)
                .length > 0 && (
                <div className="flex flex-col">
                  <div className="mt-2 text-sm font-bold">기본 구성 상품</div>
                  {orderItems?.map((oi, index) => (
                    <div className="flex" key={`defaultItems_${index}`}>
                      <div className="flex flex-col w-full">
                        {oi?.items?.map(
                          (orderItem, orderItemIndex) =>
                            orderItem?.default_option_quantity > 0 && (
                              <div
                                className="flex flex-col w-full"
                                key={`orderItemIndexDefault_${orderItemIndex}`}
                              >
                                <div className="flex items-center py-2 border-b border-b-gray100">
                                  <img
                                    src={orderItem?.item_image_url}
                                    alt="기본구성상품"
                                    className={imgStyle}
                                  />
                                  <div className="flex flex-col text-sm">
                                    <span className="font-medium">
                                      {orderItem?.item_title}
                                    </span>
                                    <div className="flex mt-[6px]">
                                      <span className="mr-[40px] font-medium">
                                        수량{" "}
                                        {orderItem?.default_option_quantity} 개
                                      </span>
                                      <span className="text-gray400">0 원</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      {filter(oi?.items, (o) => o?.default_option_quantity > 0)
                        .length > 0 && (
                        <div className="flex items-center w-[75px] justify-center flex-col text-sm border-b border-l border-gray100">
                          <span className="font-bold">배송비</span>
                          <span className="mt-1">
                            {addComma(Number(oi?.shipping_fee))}원
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {/* 추가 구매 상품 */}
              {filter(order?.order_items, (o) => o.item_quantity > 0)?.length >
                0 && (
                <div className="flex flex-col">
                  <div className="mt-2 text-sm font-bold">추가 구성 상품</div>
                  {orderItems?.map((oi, index) => (
                    <div className="flex" key={`defaultItems_${index}`}>
                      <div className="flex flex-col w-full">
                        {oi?.items?.map(
                          (orderItem, orderItemIndex) =>
                            orderItem?.item_quantity > 0 && (
                              <div
                                className="flex flex-col w-full"
                                key={`orderItemIndexOption_${orderItemIndex}`}
                              >
                                <div className="flex items-center py-2 border-b border-b-gray100">
                                  <img
                                    src={orderItem?.item_image_url}
                                    alt="추가구성상품"
                                    className={imgStyle}
                                  />
                                  <div className="flex flex-col text-sm">
                                    <span className="font-medium">
                                      {orderItem?.item_title}
                                    </span>
                                    <div className="flex mt-[6px]">
                                      <span className="mr-[40px] font-medium">
                                        수량 {orderItem?.item_quantity} 개
                                      </span>
                                      <span className="text-gray400">
                                        {addComma(
                                          orderItem?.item_quantity *
                                            orderItem?.item_only_price
                                        )}{" "}
                                        원
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      {filter(oi?.items, (o) => o.item_quantity > 0).length >
                        0 && (
                        <div className="flex items-center w-[75px] justify-center flex-col text-sm border-b border-l border-gray100">
                          <span className="font-bold">배송비</span>
                          <span className="mt-1">
                            {oi?.items?.filter(
                              (oii) => oii?.default_option_quantity > 0
                            ).length > 0
                              ? "0"
                              : addComma(Number(oi?.shipping_fee))}
                            원
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Collapse>
      </div>

      {/* 배송 정보 Collapse */}
      <div className="flex flex-col px-[15px] py-[24px] border-b-[5px] border-gray100">
        <div
          className="flex justify-between"
          onClick={() => setIsDeliveryInfoOpen(!isDeliveryInfoOpen)}
        >
          <span className="text-lg font-bold">
            {!order?.is_shipping ? "배송 정보" : "개인 정보"}
          </span>
          <img
            src={isDeliveryInfoOpen ? arrowUpSharp : arrowDownSharp}
            alt="주문정보접기"
            className="w-[10px]"
          />
        </div>

        <Collapse
          isOpened={isDeliveryInfoOpen}
          theme={{
            collapse: "orderCollapse",
            content: "orderCollapseContent",
          }}
        >
          <div className="flex justify-between mt-[24px]">
            <label htmlFor="receiver" className="text-sm">
              받으시는 분
            </label>
            <button onClick={() => setIsCommonUserInfoDisabled(false)}>
              <img
                src={editPencil}
                alt="기본배송정보수정아이콘"
                className="w-[22px]"
              />
            </button>
          </div>
          <input
            className={`w-full border border-gray100 rounded-[5px] mt-[7px] px-4 pt-[13px] pb-[12px] focus:outline-black text-sm ${
              !isCommonUserInfoDisabled ? "" : "text-gray400"
            }`}
            id="receiver"
            type="text"
            onChange={(e) =>
              setOrderForm({
                ...orderForm,
                name: e.currentTarget.value,
              })
            }
            value={orderForm.name}
            disabled={isCommonUserInfoDisabled}
          />
          <div className="flex justify-between mt-[17px] text-sm">
            <label htmlFor="receiver" className="text-sm">
              휴대폰 번호
            </label>
          </div>
          <input
            className={`w-full border border-gray100 text-sm rounded-[5px] mt-[7px] px-4 pt-[13px] pb-[12px] focus:outline-black ${
              !isCommonUserInfoDisabled ? "" : "text-gray400"
            }`}
            id="receiver"
            type="tel"
            onChange={(e) =>
              setOrderForm({
                ...orderForm,
                tel: e.currentTarget.value,
              })
            }
            value={orderForm.tel}
            disabled={isCommonUserInfoDisabled}
            placeholder="숫자만 입력해주세요."
          />
          {order?.orderable_type === "LiveLecture" && (
            <div className="flex justify-between mt-[17px] text-sm">
              수업 1시간 전 카카오톡을 통해 알림 메시지를 드릴 예정이오니 휴대폰
              번호를 정확하게 입력해 주세요!
            </div>
          )}
          {order?.is_shipping && (
            <>
              <hr className="border-gray100 my-[30px]" />

              <div className="flex justify-between text-sm">
                <span>배송 주소</span>
                {order?.user?.is_past_address_info && (
                  <button
                    onClick={() => {
                      setOrderForm({
                        ...orderForm,
                        postCode: order?.user?.postcode,
                        shortAddress: order?.user?.short_address,
                        detailAddress: order?.user?.detail_address,
                      });
                    }}
                  >
                    <span className="text-blue-base">이전 배송지 불러오기</span>
                  </button>
                )}
              </div>

              <div className="mt-[10px] flex h-[45px] text-sm">
                <input
                  placeholder="우편 번호"
                  className="focus:outline-black flex-grow w-full border border-gray100 rounded-[5px] px-[11px] items-center text-gray400 mr-[5px]"
                  type="text"
                  value={orderForm.postCode}
                  onChange={(e) =>
                    setOrderForm({
                      ...orderForm,
                      postCode: e.currentTarget.value,
                    })
                  }
                  disabled
                />

                <Popup
                  trigger={
                    <button className="shrink-0 border border-blue-base text-blue-base px-[11px] rounded-[5px] flex items-center justify-center">
                      우편 번호 찾기
                    </button>
                  }
                  modal
                  nested
                  className="postCodePopUp"
                >
                  {(close) => (
                    <div className="flex flex-col p-4 bg-white">
                      <button
                        type="button"
                        className="mb-4 ml-auto w-fit"
                        onClick={close}
                      >
                        <img src={closeSm} alt="닫기" className="w-4" />
                      </button>
                      <DaumPostcode
                        onComplete={(data) => {
                          setOrderForm({
                            ...orderForm,
                            postCode: data?.zonecode,
                            shortAddress: data?.address,
                          });
                          close();
                        }}
                      />
                    </div>
                  )}
                </Popup>
              </div>

              <input
                type="text"
                placeholder="주소"
                className="focus:outline-black px-[11px] h-[45px] border rounded-[5px] border-gray100 text-sm mt-[9px] w-full text-gray400"
                value={orderForm.shortAddress}
                onChange={(e) =>
                  setOrderForm({
                    ...orderForm,
                    shortAddress: e.currentTarget.value,
                  })
                }
                disabled
              />

              <input
                type="text"
                placeholder="상세주소를 입력해주세요."
                value={orderForm.detailAddress}
                onChange={(e) =>
                  setOrderForm({
                    ...orderForm,
                    detailAddress: e.currentTarget.value,
                  })
                }
                className="focus:outline-black px-[11px] h-[45px] border rounded-[5px] border-gray100 text-sm mt-[9px] w-full"
              />

              <div className="flex flex-col text-sm mt-[14px]">
                <label htmlFor="deliveryAsk">배송 요청사항</label>
                <select
                  id="deliveryAsk"
                  ref={askTextRef}
                  className="flex items-center px-[11px] border rounded-[5px] border-gray100 h-[45px] mt-[10px]"
                  onChange={(e) => {
                    setDeliveryAskOption(e.currentTarget.value);
                    setDeliveryAskText(e.currentTarget.value);
                  }}
                >
                  <option value="문 앞에 놓아 주세요.">
                    문 앞에 놓아 주세요.
                  </option>
                  <option value="경비실에 맡겨 주세요.">
                    경비실에 맡겨 주세요.
                  </option>
                  <option value="도착 전에 연락 부탁드려요.">
                    도착 전에 연락 부탁드려요.
                  </option>
                  <option value="택배함에 넣어 주세요.">
                    택배함에 넣어 주세요.
                  </option>
                  <option value="직접 받겠습니다.">직접 받겠습니다.</option>
                  <option value="">직접 입력</option>
                </select>
              </div>

              <textarea
                maxLength="50"
                className={`${
                  deliveryAskOption !== "" ? "hidden" : ""
                } border rounded-[5px] border-gray100 min-h-[68px] w-full px-[11px] py-[12px] text-sm mt-[9px] resize-none`}
                placeholder="배송시 요청사항을 입력해주세요"
                value={`${deliveryAskOption === "" ? deliveryAskText : ""}`}
                onChange={(e) => setDeliveryAskText(e.target.value)}
              ></textarea>
            </>
          )}
        </Collapse>
      </div>

      {/* 쿠폰/수강권 */}
      {!order?.is_only && (
        <div className="flex flex-col px-[15px] py-[24px] border-b-[5px] border-gray100">
          <span className="text-lg font-bold">쿠폰 / 수강권</span>

          <div className="flex flex-col mt-[21px]">
            <label htmlFor="couponInput" className="text-sm font-medium">
              할인 쿠폰
            </label>
            <div className="mt-[10px] flex h-[45px] text-sm">
              <input
                placeholder={`사용 가능 쿠폰 ${calActivateCoupon()}장 / 전체 ${
                  coupons?.length
                }장`}
                className="flex-grow w-full border border-gray100 rounded-[5px] px-[11px] items-center text-gray400 mr-[9px]"
                type="text"
                value={selectCoupon?.usedCouponName}
                id="couponInput"
                disabled
              />

              <button
                onClick={openSheetDrawer}
                className="flex items-center justify-center bg-navy900 rounded-[5px] text-white shrink-0 px-[29px]"
              >
                쿠폰 선택
              </button>
            </div>
            <div className="flex justify-between mt-4 text-sm">
              <span>할인 금액</span>
              <span>{addComma(Number(selectCoupon?.discountValue))} 원</span>
            </div>
          </div>
        </div>
      )}

      {/* 결제 금액 Collapse */}
      <div className="flex flex-col px-[15px] py-[24px] border-b-[5px] border-gray100">
        <div
          className="flex justify-between"
          onClick={() => setIsPaymentInfoOpen(!isPaymentInfoOpen)}
        >
          <span className="text-lg font-bold">결제 금액</span>
          <img
            src={isPaymentInfoOpen ? arrowUpSharp : arrowDownSharp}
            alt="주문정보접기"
            className="w-[10px]"
          />
        </div>

        <Collapse
          isOpened={isPaymentInfoOpen}
          theme={{
            collapse: "orderCollapse",
            content: "orderCollapseContent",
          }}
        >
          {!order?.is_only && orderable && (
            <div className="text-gray400 mb-[6px]">
              <div className="flex justify-between text-sm mt-[29px]">
                <span>클래스 금액</span>
                <span>{addComma(Number(orderable?.display_price))} 원</span>
              </div>
            </div>
          )}

          <div className="flex justify-between text-sm mt-[6px] border-b border-gray100 pb-[8px]">
            <span>추가 구매 금액</span>
            <span>
              {addComma(
                Number(
                  sumBy(
                    order?.order_items,
                    (item) => item.item_quantity * Number(item?.price)
                  )
                )
              )}{" "}
              원
            </span>
          </div>

          <div className="flex justify-between text-sm font-medium mt-[29px]">
            <span>총 상품 금액</span>
            <span>{addComma(Number(order?.amount))} 원</span>
          </div>

          {!order?.is_only && (
            <div className="flex justify-between mt-[14px] text-sm text-blue-base">
              <span>쿠폰 할인 금액</span>
              <span>- {addComma(Number(selectCoupon?.discountValue))} 원</span>
            </div>
          )}

          {order?.is_shipping && (
            <div className="flex justify-between mt-[6px] text-sm">
              <span>배송비</span>
              <span>{addComma(realShippingFee)} 원</span>
            </div>
          )}

          <div className="flex justify-between mt-[12px] text-blue-base font-bold">
            <span className="text-sm">최종 결제 금액</span>
            {/* 쿠폰할인금액 아직 안넣음 */}
            <span>{addComma(finalPrice)} 원</span>
          </div>
        </Collapse>
      </div>

      {/* 결제 수단 */}
      <div className="flex flex-col px-[15px] py-[24px] border-gray100">
        <span className="text-lg font-bold">결제 수단</span>
        <div className="grid grid-cols-2 gap-[18px] mt-[8px]">
          <button
            onClick={() => setPayMethodToggleBtn("cardAndEasyPay")}
            className={`flex items-center justify-center flex-col border rounded-[5px] pt-[15px] pb-[10px] ${
              payMethod === "cardAndEasyPay"
                ? "border-blue-base"
                : "border-gray100"
            }`}
          >
            <img src={creditCard} alt="카드선택" className="w-[58px]" />
            <span className="text-xs mt-[6px]">카드</span>
          </button>
          <button
            onClick={() => setPayMethodToggleBtn("vbank")}
            className={`flex items-center justify-center flex-col border rounded-[5px] pt-[15px] pb-[10px] ${
              payMethod === "vbank" ? "border-blue-base" : "border-gray100"
            }`}
          >
            <img
              src={depositWithoutBank}
              alt="무통장입금"
              className="w-[38px]"
            />
            <span className="text-xs mt-[6px]">무통장입금</span>
          </button>
        </div>
      </div>

      <div className="w-full bg-white max-w-[640px] mx-auto mobile:fixed tablet:sticky web:sticky bottom-0 border-white border">
        <button
          type="submit"
          onClick={submitForm}
          className="rounded-[7px] bg-blue-base text-white w-full flex justify-center items-center mb-4 pt-[15px] pb-[14px] mx-auto"
          css={css`
            width: calc(100% - 30px);
          `}
        >
          {addComma(finalPrice)} 원 결제하기
        </button>
      </div>
    </div>
  );
};

export default Content;
