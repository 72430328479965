import { Content } from "../components/board/index";
import Layout from "../components/Layout";

const Board = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default Board;
