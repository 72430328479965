import { LectureTitle } from "../shared/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "@emotion/react";
import { useRef } from "react";
import { addZeroFrontOfNum } from "../../utils";
import PropTypes from "prop-types";
import { MOBILE_MAX_WIDTH } from "../../constant";

const arrowParentL = css`
  position: absolute;
  top: 60%;
  border: 1px white solid;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 28px;
  height: 28px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 1rem;
`;

const arrowParentR = css`
  position: absolute;
  top: 60%;
  border: 1px white solid;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 28px;
  height: 28px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1rem;
`;

const navArrowR = css`
  transform: rotate(-45deg);
  border: solid white;
  border-width: 0 0.5px 0.5px 0;
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 5px;
`;

const navArrowL = css`
  transform: rotate(-45deg);
  border: solid white;
  border-width: 0.5px 0 0 0.5px;
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 5px;
`;

const swiperStyle = css`
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    max-height: 320px !important;
    img {
      max-height: 247px;
    }
  }
`;

const WatchingAfter = ({ deviceCheck, lecture_advantages }) => {
  const swiperRef = useRef(null);

  return (
    <>
      <LectureTitle text="이 강의를 들으면" />
      <Swiper
        slidesPerView={
          deviceCheck === "web" || deviceCheck === "tablet" ? 2 : 1
        }
        spaceBetween={12}
        ref={swiperRef}
        css={swiperStyle}
      >
        {lecture_advantages?.map((item, index) => {
          return (
            <SwiperSlide key={`이강의를들으면${item.id}`}>
              <div
                className="flex flex-col"
                css={css`
                  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
                    font-size: 14px;
                  }
                `}
              >
                <img
                  src={item?.image_url}
                  alt={`이강의를들으면${item.id}`}
                  className="w-full"
                />
                <div className="flex mt-2">
                  <span className="mr-4 font-bold">
                    {addZeroFrontOfNum(index)}
                  </span>
                  <span className="pr-4 mb-4">{item?.title}</span>
                </div>
              </div>
            </SwiperSlide>
          );
        })}

        <button
          css={arrowParentL}
          onClick={() => swiperRef.current.swiper.slidePrev()}
        >
          <i css={navArrowL}></i>
        </button>
        <button
          css={arrowParentR}
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <i css={navArrowR}></i>
        </button>
      </Swiper>
    </>
  );
};

export default WatchingAfter;

WatchingAfter.propTypes = {
  lecture_advantages: PropTypes.array,
};
