import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

export const useUserSignedIn = () => {
  const [isUserSignedIn, setIsUserSignedIn] = useState(null);

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        setIsUserSignedIn(true);
      })
      .catch(() => {
        setIsUserSignedIn(false);
      });
  }, []);

  return { isUserSignedIn };
};
