import questionCircle from "../../assets/icons/question_circle.svg";
import { css } from "@emotion/react";

const qnaHoverCard = css`
  &:hover {
    .hoverCard {
      display: flex !important;
    }
  }
`;

export const QnaTitle = ({ qnasCount, titleName, titleContent }) => {
  return (
    <div className="flex items-center justify-between mt-4 mb-2">
      <div
        className="relative flex items-center cursor-pointer"
        css={qnaHoverCard}
      >
        <span className="mr-2 font-bold web:text-lg">{titleName}</span>
        <>
          <img
            src={questionCircle}
            alt="질문있어요유의사항"
            className="mr-auto w-[17px] h-[17px]"
          />
          <div
            className="rounded-[6px] z-[20] hidden absolute flex-col text-sm web:max-w-[350px] tablet:max-w-[280x] mobile:max-w-[280px] web:w-[350px] tablet:w-[280px] mobile:w-[280px] hoverCard bg-white web:right-[-360px] web:top-[4px] tablet:top-[30px] mobile:top-[30px] tablet:left-0 mobile:left-0 py-[13px] px-[17px]"
            css={css`
              box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
            `}
          >
            <div dangerouslySetInnerHTML={{ __html: titleContent }}></div>
          </div>
        </>
      </div>
      <span className="text-sm text-gray300">{qnasCount}개</span>
    </div>
  );
};
