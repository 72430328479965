export const SseobolggaFaq = () => {
  return (
    <div className="font-[EliceDigitalBaeum] mx-auto max-w-[650px] min-w-[360px] px-[20px] flex flex-col justify-center pt-[60px] pb-[40px] web:text-[22px] tablet:text-[16px] mobile:text-[16px]">
      <div className="text-white font-[EliceDigitalBaeum-Bd] flex justify-center">
        꼭 읽어보세요 👀
      </div>
      <div className="flex flex-col text-white text-[0.7em] mt-[30px]">
        <div className="flex">
          <span>1.&nbsp;</span>
          <span>
            수강 기한은 결제일로부터 14일이며, 기한 내에 자유롭게 수강이
            가능합니다.
          </span>
        </div>
        <div className="flex mt-2">
          <span>2.&nbsp;</span>
          <span>써볼까?! 클래스는 1강으로 구성되어 있습니다.</span>
        </div>
        <div className="flex mt-2">
          <span>3.&nbsp;</span>
          <span>써볼까?! 클래스의 워크시트는 PDF로 다운로드가 가능합니다.</span>
        </div>
        <div className="flex mt-2">
          <span>4.&nbsp;</span>
          <span>
            미션 제출 후, 피드백과 함께 전체 클래스 9,900원 할인 쿠폰이
            발급됩니다.
          </span>
        </div>
        <div className="flex mt-2">
          <span>5.&nbsp;</span>
          <span>
            미션 피드백은 주말 제외 48시간 이내 달리며, 스몰빅클래스 카카오톡
            알림톡으로 안내됩니다.
          </span>
        </div>
        <div className="flex mt-2">
          <span>6.&nbsp;</span>
          <span>미션 제출과 피드백은 각각 1회로 진행됩니다.</span>
        </div>
        <div className="flex mt-2">
          <span>7.&nbsp;</span>
          <span>전체 클래스 9,900원 할인쿠폰의 사용 기한은 7일 입니다.</span>
        </div>
      </div>

      <div className="flex flex-col text-[#B6B6B6] mt-[20px]">
        <span className="mt-2 text-[0.8em] font-[EliceDigitalBaeum-Bd]">
          유의 사항
        </span>
        <div className="flex mt-[10px] text-[0.7em] text-[#999999]">
          <span>-&nbsp;</span>
          <span>
            '써볼까?!' 클래스의 수강 기한과 전체 클래스 9,900원 할인 쿠폰의 기한
            연장은 불가합니다.
          </span>
        </div>

        <div className="flex mt-2 text-[0.7em] text-[#999999]">
          <span>-&nbsp;</span>
          <span>'써볼까?!' 클래스 결제 시 할인 쿠폰 적용이 불가합니다.</span>
        </div>

        <div className="flex mt-2 text-[0.7em] text-[#999999]">
          <span>-&nbsp;</span>
          <span>수강 기한이 종료된 후에는 미션 작성이 불가합니다.</span>
        </div>

        <div className="flex mt-2 text-[0.7em] text-[#999999]">
          <span>-&nbsp;</span>
          <span>
            '써볼까?!' 클래스는 스몰빅클래스 오리지널 글쓰기 클래스와 동일한
            내용을 담고 있습니다.
          </span>
        </div>

        <div className="flex flex-col text-[#B6B6B6] mt-[20px]">
          <span className="mt-[10px] text-[0.8em] font-[EliceDigitalBaeum-Bd]">
            환불 규정
          </span>
          <div className="flex mt-2 text-[0.7em] text-[#999999]">
            <span>-&nbsp;</span>
            <span>
              수강 시작일로부터(결제일), 7일 이내인 경우 전액 환불해드립니다.
            </span>
          </div>
          <div className="flex mt-2 text-[0.7em] text-[#999999]">
            <span>-&nbsp;</span>
            <span>
              수강 시작일로부터(결제일), 8일 ~ 10일 이내인 경우 1/2
              환불해드립니다.
            </span>
          </div>
          <div className="flex mt-2 text-[0.7em] text-[#999999]">
            <span>-&nbsp;</span>
            <span>수강 기간이 10일이 지나면 환불이 불가합니다.</span>
          </div>
          <div className="flex mt-2 text-[0.7em] text-[#999999]">
            <span>-&nbsp;</span>
            <span>
              미션 제출 후, 피드백을 받으신 경우 수강 기간에 상관없이 환불이
              불가합니다.
            </span>
          </div>
        </div>

        <div className="mt-[20px] text-[0.8em] font-[EliceDigitalBaeum-Bd] text-[#999999]">
          ※ 유의사항 미 숙지로 인한 불이익에 대해 책임지지 않습니다.
        </div>

        <div className="text-[0.6em] mt-[60px]">이모지: 토스페이스</div>
      </div>
    </div>
  );
};
