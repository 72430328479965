import lessonDownload from "../../assets/icons/lesson_download.svg";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import { getLessonTextbookDownload } from "../../api";

const textbookStyle = css`
  .textbookStyle + .textbookStyle {
    margin-top: 18px;
  }

  .textbookStyle:last-child {
    padding-bottom: 0 !important;
  }
`;

export const TextbookLists = ({ textbooks, lessonId }) => {
  const onClickDownload = (textbook) => {
    getLessonTextbookDownload({ lessonId: textbook?.id }).then((response) => {
      const blob = new Blob([response.data]);
      const fileObjectUrl = window.URL.createObjectURL(blob);
      const fileType = response?.data?.type?.split("/")[1];
      const link = document.createElement("a");
      link.href = fileObjectUrl;
      link.style.display = "none";

      link.download = `${textbook.title}-교재.${fileType}`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(fileObjectUrl);
    });
  };

  return (
    <div css={textbookStyle}>
      {textbooks?.map((textbook) => {
        return (
          <a
            key={textbook?.id}
            onClick={
              textbook?.is_authority
                ? () => {
                    onClickDownload(textbook);
                  }
                : () => {
                    Swal.fire("권한이 없습니다");
                  }
            }
            className={`flex flex-col w-full textbookStyle ${
              textbook?.is_authority ? "" : "opacity-20 cursor-default"
            }`}
          >
            <div className="flex items-center justify-between pb-[17px w-full">
              <div className="flex flex-col max-w-[80%]">
                <span className="font-bold text-black">{textbook?.title}</span>
              </div>
              <img
                src={lessonDownload}
                alt="교재다운"
                className="w-[20px] ml-4"
              />
            </div>
          </a>
        );
      })}
    </div>
  );
};
