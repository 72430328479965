import React, { useEffect } from "react";
import kakaoLoginImg from "../../assets/icons/kakao_symbol_logo.png";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const KAKAO_SDK = "https://developers.kakao.com/sdk/js/kakao.js";
const KAKAO_TOKEN = process.env.KAKAO_JS_KEY; // js
const KAKAO_REDIRECT = `${window.location.origin}/users/auth/kakao`;

export const KakaoButton = ({ page }) => {
  const { isUserSignedIn } = useUserSignedIn();

  const kakaoLoginClickHandler = () => {
    Kakao.Auth.authorize({
      redirectUri: KAKAO_REDIRECT,
      scope: "account_email",
    });
  };

  const initializeKakaoLogin = () => {
    if (!window.Kakao.isInitialized()) {
      // javascript key 를 이용하여 initialize
      window.Kakao.init(KAKAO_TOKEN);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = KAKAO_SDK;
    script.onload = () => initializeKakaoLogin();
    document.body.appendChild(script);
    return () => script.remove();
  }, []);

  return (
    <button
      type="button"
      onClick={kakaoLoginClickHandler}
      className="h-[45px] bg-yellow-kakao mt-[7px] rounded-[5px] flex justify-center items-center cursor-pointer w-full shadow"
    >
      <img src={kakaoLoginImg} alt="카카오로그인" className="h-[18px] mx-2" />
      <div className="flex justify-center ml-5 font-bold">
        {page === "findEmail" ? "카카오 아이디로 로그인" : "카카오로 시작하기"}
      </div>
    </button>
  );
};
