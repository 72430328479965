import { useRef, useState } from "react";
import { css } from "@emotion/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LazyLoad from "react-lazyload";
import { MOBILE_MAX_WIDTH, WEB_MAX_WIDTH } from "../../constant";
import useDeviceCheck from "../../hooks/useDeviceCheck";

SwiperCore.use([Navigation]);

const BannerSwiperWeb = ({ banners }) => {
  const [bannerNum, setBannerNum] = useState(1);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const buttonGroup = css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      display: none !important;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    padding: 16px 24px 12px 24px;
    bottom: 10%;
    right: 0%;
    @media (min-width: ${WEB_MAX_WIDTH}px) {
      margin-right: calc(((100vw - ${WEB_MAX_WIDTH}px) / 2) + 29px);
    }
    @media (max-width: ${WEB_MAX_WIDTH - 1}px) {
      margin-right: calc(100vw * 0.024);
    }
    z-index: 2;
    width: 125px;

    &::after {
      position: absolute;
      content: "/  ${banners?.length}";
      color: white;
      top: 14px;
      left: 61px;
      font-size: 1rem;
    }

    &::before {
      position: absolute;
      content: "${bannerNum}";
      color: white;
      top: 14px;
      left: 40px;
      font-size: 1rem;
      width: 10%;
      text-align: end;
    }
  `;

  const navArrowR = css`
    transform: rotate(-45deg);
    border: solid white;
    border-width: 0 0.5px 0.5px 0;
    display: inline-block;
    width: 1rem;
    height: 1rem;
  `;

  const navArrowL = css`
    transform: rotate(-45deg);
    border: solid white;
    border-width: 0.5px 0 0 0.5px;
    display: inline-block;
    width: 1rem;
    height: 1rem;
  `;

  const bannerWebStyle = css`
    @media (min-width: ${MOBILE_MAX_WIDTH}px) and (max-width: ${WEB_MAX_WIDTH -
      1}px) {
      padding-left: 3.75vw;
      padding-right: 3.75vw;
    }
    @media (min-width: ${WEB_MAX_WIDTH}px) {
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
    }
  `;

  return (
    <Swiper
      slidesPerView={1}
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
      }}
      onSlideChange={(swiper) => {
        setBannerNum(swiper.realIndex + 1);
      }}
    >
      {banners?.map((banner) => {
        return (
          <SwiperSlide
            key={banner?.id}
            style={{ backgroundColor: banner?.bg_color, cursor: "pointer" }}
            onClick={() => (location.href = banner?.link)}
          >
            <LazyLoad once>
              <img
                src={banner?.image_lg?.url}
                css={bannerWebStyle}
                alt={`메인배너${banner?.id}`}
              />
            </LazyLoad>
          </SwiperSlide>
        );
      })}
      <div css={buttonGroup}>
        <button ref={prevRef}>
          <i css={navArrowL}></i>
        </button>
        <button ref={nextRef}>
          <i css={navArrowR}></i>
        </button>
      </div>
    </Swiper>
  );
};

const BannerSwiperMobile = ({ banners }) => {
  return (
    <Swiper spaceBetween={15} slidesPerView={1.15}>
      {banners?.map((banner) => {
        return (
          <SwiperSlide
            key={banner?.id}
            onClick={() => (location.href = banner?.link)}
            style={{ cursor: "pointer" }}
          >
            <LazyLoad once>
              <img
                src={banner?.image_sm?.url}
                className="rounded-xl ml-[3.75vw]"
                alt={`메인모바일배너${banner?.id}`}
              />
            </LazyLoad>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const BannerSwiper = ({ banners }) => {
  const deviceCheck = useDeviceCheck();

  return deviceCheck === "web" || deviceCheck === "tablet" ? (
    <BannerSwiperWeb banners={banners} />
  ) : (
    <BannerSwiperMobile banners={banners} />
  );
};

export default BannerSwiper;
