import Swal from "sweetalert2";
import { find, sumBy, orderBy, filter, forEach, range } from "lodash";
import { useState, useEffect, createContext, useContext } from "react";
import { useFormik } from "formik";

import { getOptionList, postOrderOption } from "../../api";
import {
  addComma,
  changeToSchoolAge,
  changeToSchoolAgeDnav,
} from "../../utils";

import closeSm from "../../assets/icons/close_sm.svg";

const OrderBodyContext = createContext(null);

const OptionItem = (props) => {
  const { setOptionList } = useContext(OrderBodyContext);

  const {
    amount,
    id,
    image_url,
    title,
    notice,
    quantity,
    max_quantity,
    default_quantity,
  } = props;

  const doIncrease = () => {
    if (quantity + default_quantity < max_quantity) {
      setOptionList((prev) => {
        const newOptionList = [...prev];
        const target = newOptionList.find((option) => option.id === id);
        target.quantity += 1;
        return newOptionList;
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "최대 수량을 초과하였습니다.",
        confirmButtonText: "확인",
      });
    }
  };

  const doDecrease = () => {
    if (quantity > 1) {
      setOptionList((prev) => {
        const newOptionList = [...prev];
        const target = newOptionList.find((option) => option.id === id);
        target.quantity -= 1;
        return newOptionList;
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "기본 수량 보다 적게 선택 할 수 없습니다.",
        confirmButtonText: "확인",
      });
    }
  };

  // 옵션 리스트에서 제거 될 경우
  useEffect(() => {
    return () => {
      setOptionList((prev) => {
        const newOptionList = [...prev];
        const target = newOptionList.find((option) => option.id === id);
        target.quantity = 0;
        target.selected = false;
        return newOptionList;
      });
    };
  }, []);

  return (
    <div className="border-[0.75px] border-gray100 rounded-[7px] flex mt-[10px] px-[13px] py-[10px]">
      <img
        src={image_url}
        alt="옵션"
        className="w-[49px] h-[71px] mr-[12px] shrink-0 object-contain"
      />
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <span className="text-sm">{title}</span>
          <img
            src={closeSm}
            alt={`옵션닫기_${title}`}
            className="object-contain w-2 cursor-pointer shrink-0"
            onClick={() => {
              setOptionList((prev) => {
                const newOptionList = [...prev];
                const target = newOptionList.find((option) => option.id === id);
                target.selected = false;
                return newOptionList;
              });
            }}
          />
        </div>

        {notice && (
          <div
            className="text-xs mt-[10px]"
            dangerouslySetInnerHTML={{ __html: notice }}
          ></div>
        )}

        <div className="flex justify-between w-full mt-[25px]">
          <div className="border-[0.75px] border-gray100 rounded-[5px] flex">
            <span
              className="flex justify-center items-center w-[23px] cursor-pointer border-r-[0.75px] border-r-gray100"
              onClick={doDecrease}
            >
              -
            </span>
            <input
              className="flex justify-center items-center w-[50px] pl-[15px]"
              type="number"
              readOnly
              value={quantity}
            />
            <span
              className="flex justify-center items-center w-[23px] cursor-pointer border-l-[0.75px] border-l-gray100"
              onClick={doIncrease}
            >
              +
            </span>
          </div>
          <span className="font-medium">
            {addComma(quantity * (amount || 0))} 원
          </span>
        </div>
      </div>
    </div>
  );
};

const SelectOption = (props) => {
  const { optionList, setOptionList } = useContext(OrderBodyContext);
  const {
    id,
    slug,
    title,
    setDefaultOptions,
    additionalOption,
    setAdditionalOption,
  } = props;

  const [optionsPrice, setOptionsPrice] = useState(0);

  useEffect(() => {
    getOptionList({ optionId: id, slug: slug }).then((response) => {
      response?.data?.map((option) => {
        setOptionList((prev) => [
          ...prev,
          { ...option, quantity: 0, selected: false },
        ]);

        if (option?.default_quantity < option?.max_quantity) {
          setAdditionalOption((prev) => [
            ...prev,
            {
              id: option?.id,
              option_id: id,
            },
          ]);
        }

        if (option?.default_quantity > 0) {
          setDefaultOptions((prev) => [
            ...prev,
            {
              title: option?.title,
              id: option?.id,
              default_quantity: option?.default_quantity,
            },
          ]);
        }
      });
    });
  }, []);

  return (
    <div
      className={`flex flex-col ${
        find(additionalOption, (ao) => ao.option_id === id)
          ? ""
          : "invisible h-0"
      }`}
    >
      <select
        className="rounded-[7px] border-[0.75px] py-[7px] mt-[10px] px-[13px] text-sm h-[35px]"
        onChange={(e) => {
          if (e.target.value !== "0") {
            if (
              find(optionList, { id: parseInt(e.target.value), selected: true })
            ) {
              Swal.fire({
                icon: "error",
                title: "이미 선택한 옵션입니다.",
                confirmButtonText: "확인",
                willClose: () => {
                  e.target.value = "0";
                },
              });
            } else {
              const selectOption = find(optionList, {
                id: parseInt(e.target.value),
              });
              const prev = optionList.filter(
                (option) => option.id !== selectOption.id
              );
              setOptionList([
                ...prev,
                { ...selectOption, quantity: 1, selected: true },
              ]);
              e.target.value = "0";
            }
          }
        }}
      >
        <option value="0">{title}</option>
        {orderBy(
          optionList.filter(
            (option) =>
              option.option_id === id &&
              option.default_quantity < option.max_quantity
          ),
          ["position"]
        ).map((option, index) => {
          return (
            <option
              key={index}
              value={option?.id}
              disabled={!option?.is_active}
            >
              {option.title}
            </option>
          );
        })}
      </select>

      {optionList
        ?.filter((option) => option.option_id === id && option.selected)
        .map((optionItem) => {
          return (
            <OptionItem
              key={optionItem.id}
              {...optionItem}
              optionsPrice={optionsPrice}
              setOptionsPrice={setOptionsPrice}
            />
          );
        })}
    </div>
  );
};

const LectureOrderBody = ({
  id,
  sales_option,
  options,
  dnav_options,
  close,
  slug,
  main_option_price,
  is_only,
  image_url,
  title,
  has_book_attachment_url,
  orderableType,
  viewer_type,
  min_age,
  max_age,
}) => {
  const [finalPrice, setFinalPrice] = useState(main_option_price);
  const [optionList, setOptionList] = useState([]);
  const [childBirth, setChildBirth] = useState("");
  const [dnavOptionId, setDnavOptionId] = useState();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [additionalOption, setAdditionalOption] = useState([]);

  const initialValues = {
    orderable_type: orderableType,
    orderable_id: id,
    order_type: sales_option,
    price: finalPrice,
    slug: slug,
    options: optionList,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const orderParams = {
        order: {
          ...initialValues,
          options: filter(optionList, (o) => o.is_active === true).map(
            (option) => {
              return {
                option_list_id: option.id,
                quantity: option.quantity,
                ...option,
              };
            }
          ),
          child_birth: childBirth,
          dnav_option_id: dnavOptionId,
        },
      };

      postOrderOption(orderParams)
        .then((response) => {
          location.href = `/orders/${response?.data?.order_id}`;
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "주문 중 오류가 발생했습니다.",
            text: "계속해서 오류가 발생할 경우 고객센터로 문의해주세요.",
            confirmButtonText: "확인",
          });
        });
    },
  });

  useEffect(() => {
    setFinalPrice(
      main_option_price +
        sumBy(
          optionList.filter((option) => option.selected),
          (option) => option?.quantity * option?.amount
        )
    );
  }, [optionList]);

  const [isAgeSelect, setIsAgeSelect] = useState(false);
  const [isDnavOptionSelect, setIsDnavOptionSelect] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);

  useEffect(() => {
    if (["dnav", "etc"].includes(viewer_type) && !!max_age && !!min_age) {
      if (!!dnav_options && dnav_options?.length !== 0) {
        setIsPurchase(isAgeSelect && isDnavOptionSelect);
      } else {
        setIsPurchase(isAgeSelect);
      }
    } else {
      setIsPurchase(true);
    }
  }, [isAgeSelect, isDnavOptionSelect]);

  return (
    <OrderBodyContext.Provider
      value={{
        mainOptionPrice: main_option_price,
        optionList: optionList,
        setOptionList: setOptionList,
        finalPrice: finalPrice,
        setFinalPrice: setFinalPrice,
        isOnly: is_only,
        setIsAgeSelect: setIsAgeSelect,
        setIsDnavOptionSelect: setIsDnavOptionSelect,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <img
              src={closeSm}
              alt="닫기"
              className="w-[15px] object-contain cursor-pointer ml-auto"
              onClick={close}
            />
          </div>

          {!is_only && (
            <div className="flex flex-col">
              <span className="text-sm font-bold mt-[25px]">클래스 정보</span>
              <div className="flex items-center mt-2">
                <img
                  src={image_url}
                  alt="구매강의썸네일"
                  className="aspect-[570/657] object-contain mr-[15px] shrink-0 w-[60px]"
                />
                <span className="web:text-lg tablet:text-lg mobile:text-sm font-medium break-all">
                  {title}
                </span>
              </div>

              {!!dnav_options && dnav_options?.length !== 0 && (
                <div className="flex flex-col mt-4">
                  <div className="text-sm font-bold">반 선택</div>
                  <select
                    defaultValue=""
                    className="mt-2 border py-2 px-4 border-red"
                    onChange={(e) => {
                      setIsDnavOptionSelect(true);
                      setDnavOptionId(e.target.value);
                    }}
                  >
                    <option value="" disabled hidden>
                      신청할 반을 선택해주세요.
                    </option>
                    {dnav_options.map((dnav_option) => (
                      <option
                        key={`dnav_option_${dnav_option.id}`}
                        disabled={!dnav_option.is_purchase}
                        value={dnav_option.id}
                      >
                        {dnav_option.title}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {["dnav", "etc"].includes(viewer_type) &&
                !!max_age &&
                !!min_age && (
                  <div className="flex flex-col mt-4">
                    <div className="text-sm font-bold">나이 선택</div>
                    <select
                      defaultValue=""
                      className="mt-2 border py-2 px-4 border-red"
                      onChange={(e) => {
                        setIsAgeSelect(true);
                        setChildBirth(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        자녀의 나이를 선택해주세요
                      </option>
                      {id === 74 && <option value="유아">유아</option>}
                      {range(min_age, max_age + 1).map((age) => (
                        <option key={age} value={changeToSchoolAgeDnav(age)}>
                          {changeToSchoolAgeDnav(age)}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

              {isPurchase && (
                <div className="flex items-center mt-4 w-full">
                  <div className="flex flex-col w-full">
                    {defaultOptions?.length !== 0 && (
                      <div className="flex flex-col mt-2">
                        <span className="text-sm font-bold">
                          기본 포함 상품
                        </span>
                        <div className="w-full grid grid-cols-2 gap-[2px] text-sm mb-1 mt-[2px]">
                          <span>배송품 이름</span>
                          <span className="flex justify-center items-center">
                            배송품 개수
                          </span>
                        </div>
                        {defaultOptions?.map((defaultOption) => (
                          <div
                            key={defaultOption?.id}
                            className="w-full grid grid-cols-2 gap-2 text-sm border-t border-t-gray200 py-[2px]"
                          >
                            <span>{defaultOption?.title}</span>
                            <span className="flex justify-center items-center font-[700]">
                              {defaultOption?.default_quantity}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {has_book_attachment_url && options?.length === 0 && (
                <span className="text-sm font-normal text-gray400 mt-[17px]">
                  교재는 구매 후 마이페이지에서 다운로드하여 이용 부탁드립니다.
                </span>
              )}
            </div>
          )}

          {isPurchase && (
            <>
              {options?.length !== 0 &&
                !is_only &&
                additionalOption.length !== 0 && (
                  <span className="text-sm font-bold mt-[25px]">
                    추가 선택 옵션
                  </span>
                )}
              {options?.map((lectureOption) => {
                return (
                  <SelectOption
                    key={lectureOption?.id}
                    slug={slug}
                    {...lectureOption}
                    defaultOptions={defaultOptions}
                    setDefaultOptions={setDefaultOptions}
                    viewer_type={viewer_type}
                    additionalOption={additionalOption}
                    setAdditionalOption={setAdditionalOption}
                  />
                );
              })}
            </>
          )}

          <div className="flex items-center justify-between mt-[13px]">
            <span className="text-sm font-bold">총 상품 금액</span>
            <span className="font-bold text-blue-base">
              {addComma(Number(finalPrice))} 원
            </span>
          </div>

          <button
            id="checkout_btn"
            type="submit"
            disabled={finalPrice === 0 || !isPurchase ? true : false}
            className={`text-white rounded-[9px] justify-center items-center py-[11px] mt-[14px] w-full ${
              finalPrice === 0 || !isPurchase ? "bg-gray200" : "bg-blue-base"
            }`}
          >
            {finalPrice === 0 || !isPurchase
              ? "필수옵션을 선택해주세요"
              : "구매하기"}
          </button>
        </div>
      </form>
    </OrderBodyContext.Provider>
  );
};

export default LectureOrderBody;
