import useDeviceCheck from "../../hooks/useDeviceCheck";
import { css } from "@emotion/react";
import blurImg from "../../assets/images/blur_img.png";
import liveOn from "../../assets/icons/live_red.svg";

const contentStyleLg = css`
  .highlight-after::after {
    content: "BETA";
    position: absolute;
    bottom: 29px;
    left: 126px;
    font-size: 12px;
    font-style: italic;
    color: #00edc2;
    font-weight: 600;
  }

  .contentStyle {
    @media (min-width: 1201px) {
      padding: 0 82px 0 52px;
    }
  }
`;

const contentStyleSm = css`
  .highlight-after::after {
    content: "BETA";
    position: absolute;
    bottom: 17px;
    left: 98px;
    font-size: 12px;
    font-style: italic;
    color: #00edc2;
    font-weight: 600;
  }
`;
const TopVideo = ({ promotion_video_url }) => {
  const deviceCheck = useDeviceCheck();

  const ContentLg = () => {
    return (
      <div
        css={contentStyleLg}
        className="w-full max-w-[1200px] bg-black h-[356px] mx-auto flex justify-between py-[26px]"
      >
        <div className="flex justify-between w-full mx-auto my-0 default_margin contentStyle">
          <div className="flex flex-col w-full max-w-[306px] shrink-0 mt-[59px]">
            <img
              src={liveOn}
              alt="온라이브"
              className="w-[87px] h-[30px] mb-[48px]"
            />
            <span className="relative text-[25px] font-bold text-white highlight-after">
              부모학교 LIVE
            </span>
            <span className="text-[35px] font-bold text-white">
              입학을 환영합니다!
            </span>
          </div>

          <div className="w-full max-w-[634px] max-h-[348px] relative">
            <img src={blurImg} alt="blurImg" className="w-full h-full" />
            <iframe
              src={promotion_video_url}
              className="w-[634px] h-[356px] absolute top-[6px] left-[49px] rounded-[15px]"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const ContentSm = () => {
    return (
      <div className="relative">
        <div css={contentStyleSm} className="w-screen bg-black">
          <div className="flex justify-between default_margin px-[15px] pt-[20px] max-w-[640px] mb-0">
            <div className="flex flex-col">
              <span className="relative text-xl font-bold text-white highlight-after">
                부모학교 LIVE
              </span>
              <span className="text-xl font-bold text-white">
                입학을 환영합니다!
              </span>
            </div>
            <img
              src={liveOn}
              alt="온라이브"
              className="w-[61px] h-[15px] ml-auto mt-[5px]"
            />
          </div>
          <div
            className="w-full web:h-[163px] tablet:h-[197px] mobile:h-[197px] bg-cover"
            css={css`
              background-image: url(${blurImg});
            `}
          ></div>
        </div>

        <div className="mx-auto h-auto absolute left-0 right-0 top-[109px] w-screen rounded-[15px]">
          <iframe
            title="교육방법비디오"
            src={promotion_video_url}
            className="w-full h-[50vw] max-h-[210px] rounded-[15px]"
            css={css`
              .vp-video-wrapper {
                border-radius: 15px;
              }
            `}
          ></iframe>
        </div>
      </div>
    );
  };

  return deviceCheck === "web" ? <ContentLg /> : <ContentSm />;
};

export default TopVideo;
