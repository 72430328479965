import { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import { css } from "@emotion/react";
import StarRankGroup from "../shared/StarRankGroup";
import closeIcon from "../../assets/icons/close.svg";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { ModalContext } from "../../pages/Reviews";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { reviewModalStyle } from "../../style";

const ReviewModal = () => {
  const contextValue = useContext(ModalContext);
  const { selectReview, setIsModalOpen, closeModal, isModalOpen } =
    contextValue;
  const [review, setReview] = useState(null);

  useEffect(() => {
    setReview(selectReview);
  }, [selectReview]);

  const writerStyle = css`
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      font-size: 18.5px;
      line-height: 18.5px;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      font-size: 14px;
      line-height: 18.5px;
    }
  `;

  const textStyle = css`
    word-break: break-all;
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      font-size: 18.5px;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      font-size: 14px;
    }
  `;

  const closeBtnStyle = css`
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      width: 18px;
      height: 18px;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      width: 18px;
      height: 18px;
    }
  `;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isModalOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        setIsModalOpen(false);
      }}
      style={reviewModalStyle}
    >
      <div className="flex flex-col">
        <div className="flex items-center bg-white">
          <span className="mr-4 font-bold" css={writerStyle}>
            {review?.user?.nickname || review?.user?.name}
          </span>

          <StarRankGroup
            value={review?.rating || 1}
            webSize="20"
            mobileSize="14"
            gapRatio="3.4"
          />

          <button
            className="ml-auto"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <img src={closeIcon} alt="닫기버튼" css={closeBtnStyle} />
          </button>
        </div>
        <span className="mt-2 mb-4" css={textStyle}>
          {review?.body}
        </span>
        <div className="grid grid-cols-3 gap-2 mb-5">
          {review?.images?.map((image, index) => {
            return (
              <Zoom key={`리뷰더보기${index}`}>
                <img
                  src={image}
                  alt={`리뷰더보기${index}`}
                  css={css`
                    max-height: 100px;
                  `}
                />
              </Zoom>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;
