import TopVideo from "./TopVideo";
import Title from "./Title";
import Notice from "./Notice";
import TeacherIntroduce from "./TeacherIntroduce";
import ClassIntroduce from "./ClassIntroduce";
import Curriculum from "./Curriculum";
import FaqAboutClass from "./FaqAboutClass";
import FooterSticky from "./FooterSticky";
import EventBanner from "./EventBanner";
import Content from "./Content";

export {
  Content,
  TopVideo,
  Title,
  Notice,
  TeacherIntroduce,
  ClassIntroduce,
  Curriculum,
  FaqAboutClass,
  FooterSticky,
  EventBanner,
};
