import { useState, useEffect } from "react";
import { getAssignmentOnlyView } from "../../api";
import Zoom from "react-medium-image-zoom";
import moment from "moment";

import defaultProfile from "../../assets/icons/default_profile.svg";
import adminCrown from "../../assets/icons/admin_crown.svg";
import Swal from "sweetalert2";

const SseobolggaAssignments = (props) => {
  const [assignment, setAssignment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    getAssignmentOnlyView({
      userId: urlParams.get("user_id"),
      assignmentId: props.assignmentId,
    })
      .then((response) => {
        setAssignment(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "에러가 발생했습니다.",
          text: "문제가 지속될 경우 관리자에게 문의해주세요.",
          confirmButtonText: "확인",
          willClose: () => {
            window.location.href = "/";
          },
        });
      });
  }, []);

  return (
    !isLoading && (
      <div className="max-w-[450px] min-w-[360px] px-[20px] py-[20px] mx-auto">
        <div className="font-bold truncate pb-2 border-b border-b-gray200">
          미션
        </div>
        <div className="border border-gray100 rounded-[10px] flex flex-col py-[22px] px-[15px] mt-2">
          <span className="font-bold mb-2">{assignment?.mission?.title}</span>
          <Zoom>
            <div
              dangerouslySetInnerHTML={{ __html: assignment?.mission?.content }}
            ></div>
          </Zoom>
        </div>

        <div className="mt-4 mb-2 font-bold">결과물</div>

        <div className="rounded-[10px] border border-gray100 py-[20px] w-full">
          <div className="flex items-center px-[15px] border-b border-gray100 pb-[20px]">
            <div className="font-bold truncate">{assignment?.title}</div>
            <div className="text-xs ml-auto text-gray400">
              {moment(assignment?.created_at).format("YY-MM-DD")}
            </div>
          </div>

          <div className="pt-[20px] px-[15px]">
            <div
              dangerouslySetInnerHTML={{ __html: assignment?.content }}
            ></div>
          </div>
        </div>

        {assignment?.comments?.length > 0 && (
          <div className="mt-4 font-bold">피드백</div>
        )}

        <div className="mt-2">
          {assignment?.comments?.map((comment) => {
            return (
              <div
                className="flex flex-col py-2 mt-2 border-t border-gray200"
                key={`comment_${comment?.id}`}
              >
                <div className="flex items-center justify-between">
                  <img
                    src={comment?.user?.avatar?.thumb?.url || defaultProfile}
                    alt="글쓴이프로필"
                    className="rounded-full object-fit w-[40px] h-[40px] mr-[15px] flex-shrink-0 overflow-hidden"
                  />
                  <div className="flex flex-col mr-auto text-xs justify-evenly">
                    <div className="flex">
                      <span className="font-bold">
                        {comment?.user?.nickname || comment?.user?.name}
                      </span>
                      {comment?.is_manager && (
                        <img
                          src={adminCrown}
                          alt="선생님왕관"
                          className="w-[15px] h-[15px] ml-1"
                        />
                      )}
                    </div>
                    <span className="font-medium text-gray400 mt-[3px]">
                      {moment(comment?.created_at).format("YY-MM-DD")}
                    </span>
                  </div>
                </div>

                <span className="mt-2 text-sm font-medium whitespace-pre-wrap">
                  {comment?.body}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default SseobolggaAssignments;
