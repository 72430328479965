import childrenTogether from "../../assets/images/children_together.png";

const LecturesNone = () => {
  return (
    <div className="max-w-[1200px] my-[23px] w-full">
      <div className="rounded-[5px] flex items-center justify-center flex-col text-gray500 web:mx-auto h-[528px] py-[112px] border border-gray100 ">
        <img
          src={childrenTogether}
          alt="클래스없을때"
          className="max-w-[203px] h-auto mb-[28px]"
        />

        <span>원데이클래스 준비중입니다.</span>
      </div>
    </div>
  );
};

export default LecturesNone;
