import React, { useState } from "react";
import GenderChart from "./lectures/GenderChart";
import AgeChart from "./lectures/AgeChart";

import { useFormik } from "formik";
import * as Yup from "yup";

import { find } from "lodash";

function LectureStatistics({ lectures }) {
  const formik = useFormik({
    initialValues: {
      lectureId: null,
      percent: null,
    },
    validationSchema: Yup.object().shape({
      percent: Yup.number().max(100).min(0),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const selectLecture = find(lectures, (lecture) => {
        return lecture.id == values?.lectureId;
      });

      setTargetQuery({
        selectLecture,
        percent: values?.percent,
      });
      setSubmitting(true);
    },
  });
  const [targetQuery, setTargetQuery] = useState({
    selectLecture: null,
    percent: null,
  });

  return (
    <div className="mx-12">
      <h3 className="mb-2 text-xl font-bold text-black">강좌 선택하기</h3>
      <div className="flex mb-4">
        <select
          className="h-6 mr-4 border border-black"
          name="lectureId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">없음</option>
          {lectures?.map((lecture) => {
            return (
              <option value={lecture?.id} key={lecture?.id}>
                {lecture?.title}
              </option>
            );
          })}
        </select>

        <input
          type="number"
          placeholder="%수강율"
          name="percent"
          className="h-6 p-2 mr-4 border border-black w-28"
          onChange={formik.handleChange}
          value={formik.values.email}
          min={0}
          max={100}
        />
        <button
          className="w-12 font-normal rounded-sm"
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || !formik.isValid}
        >
          제출
        </button>
      </div>
      <div className="grid gap-4 web:grid-cols-3">
        <GenderChart targetQuery={targetQuery} />
        <AgeChart targetQuery={targetQuery} />
      </div>
    </div>
  );
}

export default LectureStatistics;
