import watchingQnaImg from "../../assets/images/watchingQnaImg.png";
import closeSm from "../../assets/icons/close_sm.svg";
import { useRef } from "react";
import { useEffect } from "react";

export const QuestionPlayer = ({ onClose }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.blur();
  }, []);

  return (
    <div className="flex flex-col">
      <button
        className="w-[40px] h-[40px] flex items-center justify-center ml-auto"
        onClick={onClose}
        ref={buttonRef}
      >
        <img src={closeSm} alt="이벤트상단배너" className="w-[11px] h-[11px]" />
      </button>

      <span className="mb-2 text-lg font-bold">
        강의 영상이 자꾸 끊기는데, 어떻게 해야 하죠?
      </span>
      <span>
        영상이 끊겨서 답답하셨을 거예요, 불편을 끼쳐드려 죄송합니다 😭
      </span>
      <span>아래 방법으로 우선 조치해보시기 바랍니다.</span>
      <span className="mt-2">1. 네트워크 환경을 확인해주세요</span>
      <span>인터넷 속도가 느리거나 불안정할 경우 영상이 끊길 수 있습니다.</span>
      <span className="mt-2">2. 크롬 브라우저로 바꿔보세요.</span>
      <span>
        스몰빅의 영상은 크롬 브라우저에서 가장 잘 재생되므로, 끊김 현상이 있다면
        크롬에서 스몰빅클래스에 접속하신 뒤 다시 재생해보세요.
      </span>
      <span className="mt-2">3. 영상 화질을 조정해보세요.</span>
      <span>
        간혹 저희 영상의 높은 화질이 몇몇 컴퓨터 모델에서 제대로 작동하지 못해서
        끊기는 경우가 있습니다. 이 경우 아래와 같은 방법으로 화질을
        조정해보세요.
      </span>
      <span>
        맨 위에 있는 화질일수록 고화질입니다 (가장 위에 있는 4K가 가장
        고화질이며 그 밑으로 갈수록 화질이 낮아집니다).
      </span>
      <span className="mb-2">
        낮은 화질로 재설정했을 때, 끊김 현상이 해결되는 경우가 있습니다.
      </span>

      <img src={watchingQnaImg} alt="화질조정방법" />
    </div>
  );
};
