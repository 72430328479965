const VideoComponent = ({ video }) => {
  return (
    <video
      width="100%"
      height="100%"
      className="rounded-[18px]"
      controls
      autoPlay
      loop
      muted
      playsInline
      controlsList="nodownload"
    >
      <source src={video} type="video/mp4" />
    </video>
  );
};

export const SseobolggaVideoTab = ({
  mainImg,
  videoUrl,
  subImg1,
  subImg2,
  btn,
  inversion,
  name,
}) => {
  return (
    <div
      id={name}
      className={`flex flex-col items-center w-full web:pb-[120px] tablet:pb-[60px] mobile:pb-[60px] ${
        inversion ? "bg-[#F3F3F3]" : "bg-white"
      }`}
    >
      <div className="flex flex-col w-full max-w-[650px] mx-auto px-[20px]">
        <img src={mainImg} className="w-full" alt="메인이미지" />
        <img src={subImg1} className="w-full" alt="특징1" />
        <div
          className={`web:px-[20px] tablet:px-[10px] mobile:px-[10px] ${
            inversion ? "bg-white" : "bg-[#F3F3F3]"
          }`}
        >
          <VideoComponent video={videoUrl} />
        </div>
        <img
          src={subImg2}
          className="w-full web:mb-[120px] tablet:mb-[60px] mobile:mb-[60px]"
          alt="특징2와3"
        />
        {btn}
      </div>
    </div>
  );
};
