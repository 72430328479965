import closeIcon from "../../assets/icons/close.svg";
import useRemainTimer from "../../hooks/useRemainTimer";
import { isEmpty } from "lodash";

const CouponDetail = ({ closeCoupon, coupon, couponStatus }) => {
  const remainTime = useRemainTimer(coupon?.coupon_end_at);

  return (
    <div className="flex flex-col oveflow-hidden">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-[22px]">
          <span className="text-lg font-bold">쿠폰 자세히 보기</span>
          <img
            src={closeIcon}
            alt="닫기"
            className="w-[13px] object-contain cursor-pointer"
            onClick={closeCoupon}
          />
        </div>

        <span className="text-xl font-bold web:text-2xl tablet:text-2xl mobile:text-xl">
          {coupon?.coupon_title}
        </span>
        <span className="font-medium font-sm text-gray400 my-[3px]">
          {coupon?.coupon_detail_title}
        </span>
        {couponStatus === "able" ? (
          <span className="text-xs font-bold text-red400">{remainTime}</span>
        ) : (
          <span className="text-xs font-bold opacity-30">
            {coupon?.used_at === null ? "기한 만료" : "사용 완료"}
          </span>
        )}

        <div className="border-b border-gray100 web:mt-[27px] tablet:mt-[27px] mobile:mt-[22px]"></div>
      </div>
      <div className="pt-[25px] flex flex-col overflow-auto max-h-[300px]">
        {coupon?.is_coupon_all ? (
          <span className="font-bold text-black web:text-sm tablet:text-sm mobile:text-xs px-2 py-1 rounded-[5px] bg-gray100 w-fit">
            전체 클래스 사용 가능
          </span>
        ) : (
          !isEmpty(coupon?.lectures) && (
            <>
              <span className="text-gray500 web:text-sm tablet:text-sm mobile:text-xs mb-[7px]">
                쿠폰 사용 가능한 클래스
              </span>
              <div className="flex flex-col">
                {coupon?.lectures?.map((lecture) => {
                  return (
                    <span
                      className="text-xs text-black font-regular bg-gray100 px-2 py-1 rounded-[5px] w-fit"
                      key={lecture?.id}
                    >
                      -{lecture?.title}
                    </span>
                  );
                })}
              </div>
            </>
          )
        )}

        {!isEmpty(coupon?.live_lectures) && (
          <>
            <span className="text-gray500 web:text-sm tablet:text-sm mobile:text-xs mb-[7px] mt-[23px]">
              쿠폰 사용 가능한 라이브 클래스
            </span>
            <div className="flex flex-col">
              {coupon?.live_lectures?.map((live_lecture) => {
                return (
                  <span
                    className="text-xs font-regular text-gray400"
                    key={live_lecture?.id}
                  >
                    -{live_lecture?.title}
                  </span>
                );
              })}
            </div>
          </>
        )}
        <span className="web:text-sm tablet:text-sm mobile:text-xs text-gray400 mb-[7px] mt-[23px]">
          유의사항
        </span>
        <span className="text-xs font-regular text-gray300">
          -사용 기한 만료 후에는 해당 쿠폰을 사용 하실 수 없습니다.
          <br />
          -본 쿠폰은 다른 할인 쿠폰과 중복 사용이 불가합니다. <br />
          -같은 종류의 쿠폰은 한 강의에 한 장만 사용할 수 있습니다.
          <br />
          -써볼까?! 클래스에는 쿠폰 사용이 불가능 합니다.
          <br />
          -패키지 클래스에는 쿠폰 사용이 불가능 합니다. <br />
        </span>
      </div>
    </div>
  );
};

export default CouponDetail;
