import {
  TopVideo,
  Title,
  Notice,
  TeacherIntroduce,
  ClassIntroduce,
  Curriculum,
  FaqAboutClass,
} from "./index";
import Review from "../../components/showReview/Review";
import { isEmpty } from "lodash";
import { Link, Element } from "react-scroll";
import { MOBILE_MAX_WIDTH, WEB_MAX_WIDTH } from "../../constant";
import { css } from "@emotion/react";
import { useRef, useState, useEffect, useContext } from "react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { LayoutContext } from "../Layout";

const Content = ({ specialEvent, setIsModalOpen, setSelectReview }) => {
  const deviceCheck = useDeviceCheck();

  const { navHeight, eventBannerState, eventBannerHeight } =
    useContext(LayoutContext);

  const specialEventTabRef = useRef(null);
  const [specialEventTabHeight, setSpecialEventTabHeight] = useState(0);

  useEffect(() => {
    setSpecialEventTabHeight(specialEventTabRef.current.offsetHeight);
  }, [deviceCheck]);

  const tabNavStyle = css`
    z-index: 3;
    &::-webkit-scrollbar {
      display: none;
    }

    a + a {
      margin-left: 27px;
    }

    @media (max-width: ${WEB_MAX_WIDTH - 1}px) {
      padding: 0 15px;
    }

    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      top: ${eventBannerState
        ? eventBannerHeight + navHeight - 1
        : navHeight - 1}px;
    }
  `;

  const navProps = {
    activeClass: "!text-black font-bold",
    spy: true,
    smooth: true,
    offset:
      deviceCheck === "mobile"
        ? -(eventBannerState
            ? specialEventTabHeight + navHeight + eventBannerHeight
            : specialEventTabHeight + navHeight)
        : -(specialEventTabHeight + 10),
    duration: 500,
    className:
      "web:text-xl tablet:text-sm mobile:text-sm text-gray200 my-auto cursor-pointer whitespace-nowrap",
  };

  return (
    <>
      {/* 최상단 동영상 */}
      {!isEmpty(specialEvent?.promotion_video_url) && (
        <TopVideo {...specialEvent} />
      )}

      {/* 상단 제목 섹션 */}
      <Title {...specialEvent} />

      {/* 라이브클래스 소개& 공지사항 */}
      <Notice {...specialEvent} />

      {/* 메뉴탭 */}
      <nav
        className="sticky bg-white h-[42px] w-full web:top-0 tablet:top-0"
        css={tabNavStyle}
        ref={specialEventTabRef}
      >
        <div className="max-w-[1200px] mx-auto w-full h-full flex overflow-auto">
          {!isEmpty(specialEvent?.content_image) && (
            <Link to="tab1" {...navProps}>
              클래스 소개
            </Link>
          )}
          <Link to="tab2" {...navProps}>
            리뷰
          </Link>
          {!isEmpty(specialEvent?.live_lectures) && (
            <Link to="tab3" {...navProps}>
              커리큘럼
            </Link>
          )}
          {!isEmpty(specialEvent?.lecture_teachers) && (
            <Link to="tab4" {...navProps}>
              강사진 소개
            </Link>
          )}
          {!isEmpty(specialEvent?.event_faqs) && (
            <Link to="tab5" {...navProps}>
              FAQ
            </Link>
          )}
        </div>
      </nav>

      {/* 클래스 소개 */}
      <Element name="tab1">
        <ClassIntroduce {...specialEvent} />
      </Element>

      {/* 리뷰 */}
      <Element
        name="tab2"
        className="web:max-w-[1024px] tablet:max-w-[640px] mobile:max-w-[640px] mx-auto default_margin"
      >
        <div className="font-bold web:text-2xl web:mt-[142px] web:mb-[2px] tablet:mt-[43px] mobile:mt-[43px]">
          수강생들의 후기
        </div>
        <Review
          targetId={specialEvent?.id}
          targetType="SpecialEvent"
          setIsModalOpen={setIsModalOpen}
          setSelectReview={setSelectReview}
        />
      </Element>

      {/* 커리큘럼 */}
      <Element name="tab3">
        <Curriculum {...specialEvent} />
      </Element>

      {/* 강사진 소개 */}
      <Element name="tab4">
        <TeacherIntroduce {...specialEvent} />
      </Element>

      {/* FAQ궁금해요! */}
      <Element name="tab5">
        <FaqAboutClass {...specialEvent} />
      </Element>
    </>
  );
};

export default Content;
