import { useState } from "react";
import lessonDownload from "../../assets/icons/lesson_download.svg";
import { css } from "@emotion/react";
import { color } from "../../tailwindStyle";

const pgBarParent = css`
  background-color: ${color.gray100};
  &::before {
    max-height: 1.25rem;
    padding-left: 0.5rem;
    position: absolute;
    color: #535353;
  }
`;

const DownloadProgressBar = ({ total, loaded }) => {
  const pgBar = css`
    max-height: 1.25rem;
    height: 100%;
    color: white;
    background-color: ${color.yellow600};
    width: ${(loaded / total) * 100}%;
  `;

  return (
    <div
      className="h-5 text-xs leading-5 rounded w-full mx-2 mt-[1px]"
      css={pgBarParent}
      // data-result={placeholder}
    >
      <div className="rounded pgBar" css={pgBar}></div>
    </div>
  );
};

const DownloadComponent = ({ textbook }) => {
  const [progressValue, setProgressValue] = useState({
    total: 0,
    loaded: 0,
  });

  const onClickDownload = (textbook) => {
    const xmlHttp = new XMLHttpRequest();

    xmlHttp.onprogress = (e) => {
      setProgressValue({ total: e.total, loaded: e.loaded });
    };

    xmlHttp.open("GET", textbook?.download_link, true);

    xmlHttp.responseType = "blob";
    xmlHttp.onload = function (e) {
      const blob = xmlHttp.response;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${textbook?.title}-교재.pdf`;
      link.click();
    };
    xmlHttp.send();
  };

  return (
    <div
      onClick={(e) => {
        if (
          !(
            progressValue?.total > 0 &&
            progressValue?.loaded > 0 &&
            progressValue?.total !== progressValue?.loaded
          )
        ) {
          onClickDownload(textbook);
        }
      }}
      key={textbook?.id}
      className="w-full flex items-center justify-between mb-[18px] border-b border-gray200 pb-[17px] cursor-pointer"
    >
      <div className="truncate mr-4">
        <span className="font-bold shrink-0">{textbook?.title}</span>
      </div>
      {progressValue?.total > 0 &&
        progressValue?.loaded > 0 &&
        progressValue?.total !== progressValue?.loaded && (
          <DownloadProgressBar
            total={progressValue?.total}
            loaded={progressValue?.loaded}
          />
        )}
      <img src={lessonDownload} alt="교재다운" className="w-[20px]" />
    </div>
  );
};

export default DownloadComponent;
