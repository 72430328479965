import { Show } from "../components/board/";
import Layout from "../components/Layout";

const Board = (props) => {
  return (
    <Layout>
      <Show boardId={props?.boardId} boardType={props?.boardType} />
    </Layout>
  );
};

export default Board;
