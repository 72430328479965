import iziToast from "izitoast";
import moment from "moment";
import MyLectureCardNone from "../mypageLectures/MyLectureCardNone";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LiveLectures = ({ liveLectures }) => {
  const BeforeLiveBtn = () => {
    return (
      <button
        className="flex mt-auto justify-center items-center rounded-[7px] font-bold py-2 text-sm w-full text-white bg-red400"
        disabled
      >
        라이브 예정입니다
      </button>
    );
  };

  const OnLiveBtn = ({ liveLecture }) => {
    return (
      <a
        href={liveLecture?.live_link}
        className="flex mt-auto justify-center items-center rounded-[7px] font-bold py-2 text-sm w-full text-white bg-red400"
      >
        라이브 입장하기
      </a>
    );
  };

  const FinishedLiveBtn = () => {
    return (
      <button
        className="flex mt-auto justify-center items-center rounded-[7px] font-bold py-2 text-sm w-full bg-gray400 text-white"
        disabled
      >
        종료된 라이브 입니다
      </button>
    );
  };

  const ReplayAbleBtn = ({ liveLecture }) => {
    return (
      <a
        href={liveLecture?.replay_link}
        className="flex mt-auto justify-center items-center rounded-[7px] font-bold py-2 text-sm w-full text-white bg-red400"
      >
        강연 다시보기
      </a>
    );
  };

  const ReplayReadyBtn = () => {
    return (
      <button
        onClick={() => {
          iziToast.warning({
            message:
              "1~2시간 이내로 ‘다시보기’ 영상이 제공될 예정입니다. 조금만 기다려주세요!",
            position: "topCenter",
          });
        }}
        className="flex mt-auto justify-center items-center rounded-[7px] font-bold py-2 text-sm w-full text-white bg-red400"
      >
        강연 다시보기
      </button>
    );
  };

  const MainText = ({ liveLecture }) => {
    return (
      <>
        <span className="font-medium text-sm mt-[13px]">
          {liveLecture?.status === "before_live" &&
            `라이브 일정: ${moment(liveLecture?.start_at)
              .locale("ko")
              .format("MM월 DD일 (ddd) LT")}`}

          {liveLecture?.status === "on_live" &&
            `라이브 30분 전부터 입장 가능합니다.`}

          {["replay_able", "replay_ready"].includes(liveLecture?.status) &&
            `${moment(liveLecture?.start_at)
              .add(7, "days")
              .locale("ko")
              .format("YY.MM.DD (ddd)")} 까지 다시보기 시청 가능`}

          {liveLecture?.status === "finished_live" &&
            `시청해 주셔서 감사합니다.`}
        </span>

        <span className="my-2 text-xs font-medium text-gray400">
          {["before_live", "on_live"].includes(liveLecture?.status) &&
            `${" "}
        ${moment(liveLecture?.start_at)
          .subtract(30, "minutes")
          .format("YY.MM.DD HH:mm")}${" "}
        부터 입장 가능`}
        </span>
      </>
    );
  };

  const MyLiveLectureCard = ({ liveLecture }) => {
    return (
      <div className="border-[2px] border-gray100 rounded-lg p-[15px] flex flex-col">
        <LazyLoadImage src={liveLecture?.image_url} effect="blur" />
        <span className="font-bold text-sm mt-[13px] truncate">
          {liveLecture?.special_event_title}
        </span>
        <span className="font-bold truncate">{liveLecture?.title}</span>
        <MainText liveLecture={liveLecture} />

        {liveLecture?.status === "before_live" && <BeforeLiveBtn />}
        {liveLecture?.status === "on_live" && (
          <OnLiveBtn liveLecture={liveLecture} />
        )}
        {liveLecture?.status === "finished_live" && (
          <FinishedLiveBtn liveLecture={liveLecture} />
        )}
        {/* 강연다시보기활성화 */}
        {liveLecture?.status === "replay_able" && (
          <ReplayAbleBtn liveLecture={liveLecture} />
        )}
        {/* 강연다시보기준비중 */}
        {liveLecture?.status === "replay_ready" && (
          <ReplayReadyBtn liveLecture={liveLecture} />
        )}
      </div>
    );
  };

  return liveLectures?.length !== 0 ? (
    <div className="grid gap-3 web:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1">
      {liveLectures?.map((liveLecture) => {
        return (
          <MyLiveLectureCard
            key={`수강중인라이브${liveLecture?.id}`}
            liveLecture={liveLecture}
          />
        );
      })}
    </div>
  ) : (
    <MyLectureCardNone />
  );
};

export default LiveLectures;
