import { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";
import ReactPlayer from "react-player";

import {
  getCalledQuestions,
  getCheckWatching,
  getColdCalls,
  getSyncUserLecture,
  putWatching,
  postEncrypt,
} from "../../api";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import useDeviceCheck from "../../hooks/useDeviceCheck";

import coldCallRight from "../../assets/icons/coldcall_right.svg";
import coldCallWrong from "../../assets/icons/coldcall_wrong.svg";
import coldCallQuestion from "../../assets/icons/coldcall_question.svg";

export const VideoPlayer = ({
  lessonDetail,
  isColdCall,
  setSelectedVideoId,
}) => {
  const deviceCheck = useDeviceCheck();
  const { currentUser } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();
  const lessonId = lessonDetail?.id;
  const [coldCalls, setColdCalls] = useState(null);
  const prevTimeRef = useRef(0);

  const syncUserLecture = () => {
    if (!!lessonId) {
      getSyncUserLecture({ lessonId: lessonId });
    }
  };

  useEffect(() => {
    if (!!lessonId) {
      getColdCalls({ lessonId: lessonDetail?.id }).then((response) => {
        setColdCalls(
          response.data.map((coldCall) => {
            return { ...coldCall, isActive: false };
          })
        );
      });
    }
  }, [lessonId]);

  //Player 관련 옵션
  const playerRef = useRef(null);
  const [playerOption, setPlayerOption] = useState({
    url: lessonDetail?.video_url,
    volume: null,
    playing: false,
    speed: 1,
    played: 0,
    loaded: 0,
    controls: true,
    width: "100%",
    height: "100%",
    progressInterval: 1000,
  });

  useEffect(() => {
    if (!!lessonDetail && !!coldCalls) {
      setPlayerOption({
        ...playerOption,
      });
    }
  }, [lessonDetail, coldCalls]);

  const videoPlay = () => {
    setPlayerOption({ ...playerOption, playing: true });
  };

  const videoPause = () => {
    setPlayerOption({ ...playerOption, playing: false });
  };

  const openShortQuestion = (cq, useAhoyYes) => {
    setColdCalls(
      coldCalls.map((coldCall) => {
        let transColdCall = coldCall;
        if (coldCall.id === cq.id) {
          transColdCall.isActive = true;
        }
        return transColdCall;
      })
    );
    Swal.fire({
      text: cq.body,
      footer: !_.isEmpty(cq.hint) ? cq.hint : false,
      input: "text",
      icon: "question",
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
      iconHtml: `<img src=${coldCallQuestion} />`,
      customClass: {
        popup: "!py-2 !min-w-[325px]",
        closeButton: "!top-[12px] !right-[6px]",
        icon: "!border-white !w-[90px] !h-[84px]",
        content: "!text-sm !font-medium",
        confirmButton:
          "!w-full !text-white !flex !items-center !justify-center !bg-black !rounded-[4px] !font-bold !text-sm",
      },
      confirmButtonText: "정답 제출하기",
      inputValidator: (value) => {
        if (!value) {
          return "정답을 입력해주세요";
        } else {
          getCalledQuestions({ id: cq?.id, value: value }).then((response) => {
            const [isCorrect, answer] = response?.data;

            if (isCorrect) {
              Swal.fire({
                iconHtml: `<img src=${coldCallRight} />`,
                customClass: {
                  popup: "!py-2 !min-w-[325px]",
                  closeButton: "!top-[12px] !right-[6px]",
                  icon: "!border-white !w-[90px] !h-[84px]",
                  content: "!text-sm !font-medium",
                  confirmButton:
                    "!w-full !text-white !flex !items-center !justify-center !bg-black !rounded-[4px] !font-bold !text-sm",
                },
                title: `<span class="!text-sm">${cq?.correct_answer_alert}</span>`,
                showCloseButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: "확인",
              }).then((result) => {
                result.isConfirmed && videoPlay();
                gtag("event", "answered", {
                  answer: value,
                  isCorrect: true,
                  called_question_id: cq?.id,
                  user_id: currentUser?.id,
                });
              });
            } else {
              Swal.fire({
                iconHtml: `<img src=${coldCallWrong} />`,
                customClass: {
                  popup: "!py-2 !min-w-[325px]",
                  closeButton: "!top-[12px] !right-[6px]",
                  icon: "!border-white !w-[90px] !h-[84px]",
                  confirmButton:
                    "!w-full !text-white !flex !items-center !justify-center !bg-black !rounded-[4px] !font-bold !text-sm",
                },
                html: `<span class="!text-xs">${cq?.wrong_answer_alert}</span>`,
                showCloseButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: "확인",
              }).then((result) => {
                gtag("event", "answered", {
                  answer: value,
                  isCorrect: false,
                  called_question_id: cq?.id,
                  user_id: currentUser?.id,
                });
                result.isConfirmed && videoPlay();
              });
            }
          });
        }
      },
    });
  };

  const openOptionalQuestion = (cq, useAhoyYes = true) => {
    setColdCalls(
      coldCalls.map((coldCall) => {
        let transColdCall = coldCall;
        if (coldCall.id === cq.id) {
          transColdCall.isActive = true;
        }
        return transColdCall;
      })
    );
    Swal.fire({
      text: cq.body,
      footer: !_.isEmpty(cq.hint) ? cq.hint : false,
      input: "select",
      inputOptions: _.reduce(
        cq.called_answers,
        (result, answer) => {
          result[answer.body] = answer.body;
          return result;
        },
        {}
      ),
      iconHtml: `<img src=${coldCallQuestion} />`,
      customClass: {
        popup: "!py-2 !min-w-[325px]",
        closeButton: "!top-[12px] !right-[6px]",
        icon: "!border-white !w-[90px] !h-[84px]",
        content: "!text-sm !font-medium",
        confirmButton:
          "!w-full !text-white !flex !items-center !justify-center !bg-black !rounded-[4px] !font-bold !text-sm",
      },
      showCloseButton: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonText: "정답 제출하기",
      inputValidator: (value) => {
        if (!value) {
          return "정답을 선택해주세요";
        } else {
          getCalledQuestions({ id: cq?.id, value: value }).then((response) => {
            const [isCorrect, answer] = response?.data;
            if (useAhoyYes) {
              let headerParams = {};
              Auth.currentSession()
                .then((cognitoToken) => {
                  const jwtToken = cognitoToken.getAccessToken().getJwtToken();
                  headerParams = { Authorization: `Bearer ${jwtToken}` };
                })
                .catch(() => {
                  headerParams = {};
                })
                .finally(() => {
                  ahoy.configure({ headers: headerParams });
                  ahoy.writeDownAnswer(cq.id, value, isCorrect);
                });
            }
            if (isCorrect) {
              Swal.fire({
                iconHtml: `<img src=${coldCallRight} />`,
                customClass: {
                  popup: "!py-2 !min-w-[325px]",
                  closeButton: "!top-[12px] !right-[6px]",
                  icon: "!border-white !w-[90px] !h-[84px]",
                  content: "!text-sm !font-medium",
                  confirmButton:
                    "!w-full !text-white !flex !items-center !justify-center !bg-black !rounded-[4px] !font-bold !text-sm",
                },
                title: `<span class="!text-sm">${cq?.correct_answer_alert}</span>`,
                showCloseButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: "확인",
              }).then((result) => {
                result.isConfirmed && videoPlay();
                gtag("event", "answered", {
                  answer: value,
                  isCorrect: true,
                  called_question_id: cq?.id,
                  user_id: currentUser?.id,
                });
              });
            } else {
              Swal.fire({
                iconHtml: `<img src=${coldCallWrong} />`,
                customClass: {
                  popup: "!py-2 !min-w-[325px]",
                  closeButton: "!top-[12px] !right-[6px]",
                  icon: "!border-white !w-[90px] !h-[84px]",
                  confirmButton:
                    "!w-full !text-white !flex !items-center !justify-center !bg-black !rounded-[4px] !font-bold !text-sm",
                },
                html: `<span class="!text-xs">${cq?.wrong_answer_alert}</span>`,
                showCloseButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                confirmButtonText: "확인",
              }).then((result) => {
                result.isConfirmed && videoPlay();
                gtag("event", "answered", {
                  answer: value,
                  isCorrect: false,
                  called_question_id: cq?.id,
                  user_id: currentUser?.id,
                });
              });
            }
          });
        }
      },
    });
  };

  const handleProgress = (event) => {
    //어디까지 들었는지 체크
    const currentTime = playerRef.current.getCurrentTime();

    if (prevTimeRef.current + 600 < currentTime) {
      //watching lesson gtag
      gtag("event", "watching_lesson", {
        current_position: playerRef.current?.getCurrentTime(),
        lecture_id: lessonDetail?.lecture_id,
        lesson_id: lessonDetail?.id,
        user_id: currentUser?.id,
      });

      putWatching({ lessonId: lessonId, params: { time: currentTime } });

      prevTimeRef.current = currentTime;
    }

    if (isColdCall && !Swal.isVisible()) {
      coldCalls?.map((coldCall) => {
        if (
          coldCall.isActive == false &&
          event?.playedSeconds * 1000 >= coldCall?.time_at &&
          event?.playedSeconds * 1000 - coldCall?.time_at < 1000
        ) {
          if (!!document.fullscreenElement) {
            document.exitFullscreen();
          }

          switch (coldCall?.status) {
            case "short":
              videoPause();
              openShortQuestion(coldCall, true);
              break;
            case "optional":
              videoPause();
              openOptionalQuestion(coldCall, true);
              break;
            default:
              throw new Error("Unhandled CalledQuestion Status");
          }
        }
      });
    }
  };

  const checkWatching = () => {
    if (lessonId) {
      getCheckWatching({ lessonId: lessonId }).then((response) => {
        if (!response?.data) {
          let headerParams = {};
          Auth.currentSession()
            .then((cognitoToken) => {
              const jwtToken = cognitoToken.getAccessToken().getJwtToken();
              headerParams = { Authorization: `Bearer ${jwtToken}` };
            })
            .catch(() => {
              headerParams = {};
            })
            .finally(() => {
              ahoy.configure({ headers: headerParams });
              ahoy.watchingLesson(lessonId, playerRef.current.getCurrentTime());
            });
        }
      });
    }
  };

  const handleReady = () => {
    const lectureId = lessonDetail?.lecture_id;

    if (Number(lectureId) == 23) {
      if (deviceCheck === "mobile" || deviceCheck === "tablet") {
        Swal.fire({
          title:
            "본 클래스의 코딩 실습은 PC 사용을 권장드립니다. <br> 모바일, 태블릿에서는 <br>일부 기능이 원활하게 작동하지 않습니다.",
          icon: "warning",
        });
      }
    } else {
      getCheckWatching({ lessonId: lessonId }).then((response) => {
        const time_unit = response?.data?.time_unit;
        if (time_unit && time_unit.time !== 0) {
          playerRef.current.seekTo((time_unit?.time).toFixed());
        }
      });
    }
  };

  const handlePause = () => {
    videoPause();
    //pause lesson gtag
    gtag("event", "pause_lesson", {
      lesson_id: lessonDetail?.id,
      lecture_id: lessonDetail?.lecture_id,
      user_id: currentUser?.id,
      current_position: playerRef.current.getCurrentTime(),
    });
  };

  const handleEnded = () => {
    if (lessonDetail?.mode === "internal_video_external_button") {
      Swal.fire({
        title: "다음 수업 보러가기",
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: '수업 다시 보기 <i class="fas fa-undo-alt"></i>',
        confirmButtonText:
          '미션해결하러 가기 Go! <i class="fas fa-caret-right"></i>',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = lessonDetail?.matching_link;
        } else {
          playerRef.current.seekTo(0);
        }
      });
    } else {
      if (
        !!lessonDetail?.next_lesson?.id &&
        lessonDetail?.next_lesson?.is_authority
      ) {
        Swal.fire({
          title: "다음 수업 보러가기",
          icon: "info",
          reverseButtons: true,
          showDenyButton: isUserSignedIn ? true : false,
          showCancelButton: true,
          cancelButtonText: '수업 다시 보기 <i class="fas fa-undo-alt"></i>',
          denyButtonText: "강의 리뷰 쓰기",
          showDenyButton: !(
            lessonDetail.lecture_id === 60 || lessonDetail.lecture_id === 61
          ),
          customClass: "lesson_end_modal",
          confirmButtonText:
            '다음 수업 보기 <i class="fas fa-caret-right"></i>',
        }).then((result) => {
          if (result.isConfirmed) {
            location.href = `/lessons/${lessonDetail?.next_lesson?.id}`;
          } else if (result.isDenied) {
            gtag("event", "click_end_lesson_review_button", {
              lecture_id: lessonDetail?.lecture_id,
              lesson_id: lessonDetail?.id,
              user_id: currentUser?.id,
            });
            postEncrypt({ targetValue: lessonDetail?.lecture_id }).then(
              (response) => {
                location.href = `/reviews/new?reviewable_id=${response?.data}&reviewable_type=Lecture`;
              }
            );
          } else {
            playerRef.current.seekTo(0);
          }
        });
      }

      if (
        !lessonDetail?.next_lesson?.id &&
        !lessonDetail?.next_lesson?.is_authority
      ) {
        if (
          //써볼까?!
          lessonDetail?.lecture_id === 55 ||
          lessonDetail?.lecture_id === 56 ||
          lessonDetail?.lecture_id === 57 ||
          lessonDetail?.lecture_id === 58
        ) {
          Swal.fire({
            title: "수업은 만족스러우셨나요?",
            icon: "info",
            reverseButtons: true,
            showDenyButton: isUserSignedIn ? true : false,
            showCancelButton: true,
            cancelButtonText: "닫기",
            denyButtonText: "본 강의 보러가기",
            showDenyButton: !(
              lessonDetail?.lecture_id === 60 || lessonDetail?.lecture_id === 61
            ),
            confirmButtonText: '수업 다시 보기 <i class="fas fa-undo-alt"></i>',
          }).then((result) => {
            if (result.isConfirmed) {
              location.href = `/lessons/${lessonDetail?.next_lesson?.id}`;
            } else if (result.isDenied) {
              switch (lessonDetail?.lecture_id) {
                case 55:
                  location.href = "/lectures/10";
                  break;
                case 56:
                  location.href = "/lectures/53";
                  break;
                case 57:
                  location.href = "/lectures/51";
                  break;
                case 58:
                  location.href = "/lectures/8";
                  break;
                default:
                  location.href = "/";
                  break;
              }
            } else {
              playerRef.current.seekTo(0);
            }
          });
        } else {
          Swal.fire({
            title: "수업은 만족스러우셨나요?",
            icon: "info",
            reverseButtons: true,
            showDenyButton: isUserSignedIn ? true : false,
            showCancelButton: true,
            cancelButtonText: "닫기",
            denyButtonText: "강의 리뷰 쓰기",
            showDenyButton: !(
              lessonDetail.lecture_id === 60 || lessonDetail.lecture_id === 61
            ),
            confirmButtonText: '수업 다시 보기 <i class="fas fa-undo-alt"></i>',
          }).then((result) => {
            if (result.isConfirmed) {
              location.href = `/lessons/${lessonDetail?.next_lesson?.id}`;
            } else if (result.isDenied) {
              gtag("event", "click_end_lesson_review_button", {
                lecture_id: lessonDetail?.lecture_id,
                lesson_id: lessonDetail?.id,
                user_id: currentUser?.id,
              });
              postEncrypt({ targetValue: lessonDetail?.lecture_id }).then(
                (response) => {
                  location.href = `/reviews/new?reviewable_id=${response?.data}&reviewable_type=Lecture`;
                }
              );
            } else {
              playerRef.current.seekTo(0);
            }
          });
        }
      }
    }

    gtag("event", "end_lesson", {
      current_position: playerRef.current?.getCurrentTime(),
      lecture_id: lessonDetail?.lecture_id,
      lesson_id: lessonDetail?.id,
      user_id: currentUser?.id,
    });
  };

  const handleStart = () => {
    checkWatching();
    setSelectedVideoId(lessonId);
    syncUserLecture();
  };

  const handlePlay = (data) => {
    videoPlay();
    gtag("event", "play_lesson", {
      current_position: playerRef.current.getCurrentTime(),
      lecture_id: lessonDetail?.lecture_id,
      lesson_id: lessonDetail?.id,
      user_id: currentUser?.id,
    });
  };

  return (
    <>
      {lessonDetail && !!coldCalls && isUserSignedIn && (
        <div
          className="relative mt-2 pb-[56.25%]"
          css={css`
            .react-player iframe {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <ReactPlayer
            {...playerOption}
            ref={playerRef}
            className={"absolute top-0 left-0 react-player"}
            onProgress={handleProgress}
            onReady={handleReady}
            onStart={handleStart}
            onEnded={handleEnded}
            onPlay={handlePlay}
            onPause={handlePause}
            playsinline={true}
          />
        </div>
      )}
    </>
  );
};
