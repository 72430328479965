import colon from "../../assets/icons/colon.svg";
import { useDiffTime } from "../../hooks/useDiffTime";

export const RemainTime = () => {
  const { days, hours, minutes, seconds } = useDiffTime({
    endTimeDate: new Date("2023-01-11T00:00:00+09:00"),
  });

  return (
    <div className="web:max-w-[430px] tablet:max-w-[265px] mobile:max-w-[265px] mx-auto flex justify-between w-full web:mt-[24px] tablet:mt-[15px] mobile:mt-[15px]">
      <div className="relative">
        <div className="web:w-[85px] web:h-[94px] tablet:w-[52px] tablet:h-[58px] mobile:w-[52px] mobile:h-[58px] w-full h-full flex items-center justify-center rounded-[5px] text-white bg-[#333333] web:text-[49px] tablet:text-[24px] mobile:text-[24px]">
          {days}
        </div>
        <span className="text-white web:text-[25px] tablet:text-[15px] mobile:text-[15px] web:mt-[10px] tablet:mt-[5px] mobile:mt-[5px] absolute web:left-[31px] tablet:left-[20px] mobile:left-[20px]">
          일
        </span>
      </div>

      <img src={colon} alt="colon1" />

      <div className="relative">
        <div className="web:w-[85px] web:h-[94px] tablet:w-[52px] tablet:h-[58px] mobile:w-[52px] mobile:h-[58px] w-full h-full flex items-center justify-center rounded-[5px] text-white bg-[#333333] web:text-[49px] tablet:text-[24px] mobile:text-[24px]">
          {hours}
        </div>
        <span className="text-white web:text-[25px] tablet:text-[15px] mobile:text-[15px] web:mt-[10px] tablet:mt-[5px] mobile:mt-[5px] absolute web:left-[31px] tablet:left-[20px] mobile:left-[20px]">
          시
        </span>
      </div>
      <img src={colon} alt="colon2" />

      <div className="relative">
        <div className="web:w-[85px] web:h-[94px] tablet:w-[52px] tablet:h-[58px] mobile:w-[52px] mobile:h-[58px] w-full h-full flex items-center justify-center rounded-[5px] text-white bg-[#333333] web:text-[49px] tablet:text-[24px] mobile:text-[24px]">
          {minutes}
        </div>
        <span className="text-white web:text-[25px] tablet:text-[15px] mobile:text-[15px] web:mt-[10px] tablet:mt-[5px] mobile:mt-[5px] absolute web:left-[31px] tablet:left-[20px] mobile:left-[20px]">
          분
        </span>
      </div>
      <img src={colon} alt="colon3" />

      <div className="relative">
        <div className="web:w-[85px] web:h-[94px] tablet:w-[52px] tablet:h-[58px] mobile:w-[52px] mobile:h-[58px] w-full h-full flex items-center justify-center rounded-[5px] text-white bg-[#333333] web:text-[49px] tablet:text-[24px] mobile:text-[24px]">
          {seconds}
        </div>
        <span className="text-white web:text-[25px] tablet:text-[15px] mobile:text-[15px] web:mt-[10px] tablet:mt-[5px] mobile:mt-[5px] absolute web:left-[31px] tablet:left-[20px] mobile:left-[20px]">
          초
        </span>
      </div>
    </div>
  );
};
