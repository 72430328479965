import useDeviceCheck from "../../hooks/useDeviceCheck";
import NavMobile from "./NavMobile";
import NavWeb from "./NavWeb";

import { useScrollDirection } from "../../hooks/useScrollDirection";

const NavBar = () => {
  const deviceCheck = useDeviceCheck();
  const { scrollDirection } = useScrollDirection();
  return (
    <>
      {deviceCheck !== "mobile" ? (
        <NavWeb />
      ) : scrollDirection !== "down" ? (
        <NavMobile />
      ) : null}
    </>
  );
};

export default NavBar;
