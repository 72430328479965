import { UsersTitle } from "./UsersTitle";
import { UsersInput } from "./UsersInput";
import { UsersParent } from "./UsersParent";
import { UsersInputLabel } from "./UsersInputLabel";
import { KakaoButton } from "./KakaoButton";
import { NaverButton } from "./NaverButton";
import { Auth } from "aws-amplify";
import { checkPasswordAndCreateCognitoId } from "../../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { returnSessionFunc } from "../../utils";
import { trim } from "lodash";

export const Signin = () => {
  const handleLogin = ({ email, password, setSubmitting }) => {
    // 1. cognito 로그인 시도
    Auth.signIn({ username: email, password: password })
      // 1-1 cognito 로그인 성공
      .then(() => {
        returnSessionFunc();
      })
      // 1-2 cognito 로그인 실패
      .catch(() => {
        // 2-1 비밀번호와 아이디가 유효한지 (일치하는지) 백엔드 확인
        checkPasswordAndCreateCognitoId({ email: email, password: password })
          // 3-1 백엔드에 아이디와 비밀번호가 존재, 유효 하므로 cognito에 유저 정보 생성
          .then(() => {
            Auth.signIn({ username: email, password: password })
              // 4-1 cognito 계정 생성 성공
              .then(() => {
                returnSessionFunc();
              })
              // 4-2 알 수 없는 이유로 cognito 계정 생성 실패
              .catch(() => {
                setSubmitting(false);

                Swal.fire({
                  icon: "error",
                  title: "잠시 후 다시 시도해주세요.",
                  text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
                  confirmButtonText: "확인",
                });
              });
          })
          // 3-2 백엔드에 있는 정보와 사용자가 입력한 email, password가 일치하지 않음.
          .catch((error) => {
            setSubmitting(false);
            Swal.fire({
              title: error?.response?.data?.message,
              confirmButtonText: "확인",
            });
          });
      });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("이메일 형식으로 입력해주세요")
      .required("이메일을 입력해주세요"),
    password: Yup.string().required("비밀번호를 입력해주세요"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      handleLogin({
        email: trim(values.email),
        password: values.password,
        setSubmitting: setSubmitting,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <UsersParent>
        <UsersTitle text="로그인" />

        <UsersInputLabel inputId="email" labelName="아이디" />
        <UsersInput
          inputPlaceHolder="example@naver.com"
          inputId="email"
          inputType="text"
          onChange={formik.handleChange}
        />
        {formik.errors.email && formik.touched.email && (
          <span className="text-red400 mt-[6px] text-xs">
            {formik.errors.email}
          </span>
        )}
        <div className="mt-[20px]">
          <UsersInputLabel labelName="비밀번호" inputId="password" />
          <UsersInput
            inputId="password"
            inputType="password"
            onChange={formik.handleChange}
          />
        </div>
        {formik.errors.password && formik.touched.password && (
          <span className="text-red400 mt-[6px] text-xs">
            {formik.errors.password}
          </span>
        )}
        <button
          type="submit"
          className="w-full mt-[30px] bg-black font-bold h-[45px] shadow text-white flex items-center justify-center rounded-[5px]"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? "로그인 중..." : "로그인"}
        </button>

        <div className="flex mx-auto text-sm font-medium text-gray500 items-center mt-[20px] mb-[24px]">
          <a className="ml-auto" href="/users/find_email">
            <span>아이디 찾기</span>
          </a>
          <span className="mx-[26px]">|</span>
          <a href="/users/find_password">
            <span>비밀번호 찾기</span>
          </a>
          <span className="mx-[26px]">|</span>
          <a className="mr-auto" href="/users/sign_up">
            <span>회원가입</span>
          </a>
        </div>

        <NaverButton />
        <KakaoButton />
      </UsersParent>
    </form>
  );
};
