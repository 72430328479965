import { UsersTitle } from "./UsersTitle";
import { UsersParent } from "./UsersParent";
import { postComparisonEmailAndPw } from "../../api";
import AuthPhoneNumber from "./AuthPhoneNumber";
import { useEffect, useState } from "react";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { UsersInputLabel } from "./UsersInputLabel";
import { UsersInput } from "./UsersInput";
import Swal from "sweetalert2";
import { trim } from "lodash";

export const FindPw = () => {
  const { isUserSignedIn } = useUserSignedIn();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState(null);
  const urlEmail = new URLSearchParams(window.location.search).get("email");

  useEffect(() => {
    if (isUserSignedIn) location.href = "/";
  }, [isUserSignedIn]);

  useEffect(() => {
    if (!!urlEmail) {
      setEmail(urlEmail);
    }
  }, [urlEmail]);

  const [encryptedKey, setEncryptedKey] = useState(null);

  const handleFindPw = () => {
    setIsSubmitting(true);
    if (!!email) {
      postComparisonEmailAndPw({
        email: trim(email),
        user_encrypted_key: encryptedKey,
      })
        .then(() => {
          location.href = `/users/change_password?user_encrypted_key=${encryptedKey}&email=${email}`;
        })
        .catch((error) => {
          switch (error.response.data.message) {
            case "user_does_not_exist":
              Swal.fire({
                icon: "error",
                text: "일치하는 사용자가 없습니다.",
              });
              break;
            case "not_matching":
              Swal.fire({
                icon: "error",
                text: "인증하신 번호에 일치하는 이메일이 아닙니다.",
              });
              break;
            default:
              Swal.fire({
                icon: "error",
                title: "잠시 후 다시 시도해주세요.",
                text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
                confirmButtonText: "확인",
              });
              break;
          }
          setIsSubmitting(false);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "이메일을 입력해주세요.",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <UsersParent>
      <UsersTitle text="비밀번호 찾기" />
      <div className="mb-[24px]">
        스몰빅클래스의 이메일과 전화번호를 입력해주세요.
      </div>
      <UsersInputLabel labelName="이메일" inputId="email" />
      <UsersInput
        inputType="email"
        inputId="email"
        onChange={(e) => setEmail(e.currentTarget.value)}
        value={email || ""}
        inputPlaceHolder="스몰빅클래스 계정의 이메일을 입력해주세요."
      />
      <div className="flex flex-col">
        <AuthPhoneNumber setEncryptedKey={setEncryptedKey} />
      </div>

      <button
        type="button"
        className="text-sm flex items-center justify-center text-white bg-black rounded-[5px] h-[45px] mt-8 shrink-0 w-full"
        disabled={!encryptedKey || !email || isSubmitting}
        onClick={handleFindPw}
      >
        {!email
          ? "이메일을 입력해주세요."
          : !encryptedKey
          ? "인증번호를 입력해주세요."
          : "비밀번호 재설정"}
      </button>
    </UsersParent>
  );
};
