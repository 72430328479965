import Layout from "../components/Layout";
import { AssignmentLists, EditContent } from "../components/assignments/index";
import useDeviceCheck from "../hooks/useDeviceCheck";
import { AssignmentsInfo } from "../components/assignments/AssignmentsInfo";
import { EditContentMobile } from "../components/assignments/EditContentMobile";

const AssignmentsEdit = (props) => {
  const deviceCheck = useDeviceCheck(null);

  return (
    <Layout>
      <div className="max-w-[1230px] px-[15px] mx-auto web:grid web:grid-cols-[43fr_57fr] web:gap-[28px]">
        <div className="flex flex-col w-full">
          <AssignmentsInfo lessonId={props?.lessonId} />
          {deviceCheck === "web" && (
            <AssignmentLists lessonId={props?.lessonId} />
          )}
        </div>
        {deviceCheck === "web" ? (
          <div className="flex flex-col w-full">
            <EditContent
              lessonId={props?.lessonId}
              assignmentId={props?.assignmentId}
            />
          </div>
        ) : (
          <EditContentMobile
            lessonId={props?.lessonId}
            assignmentId={props?.assignmentId}
          />
        )}
      </div>
    </Layout>
  );
};

export default AssignmentsEdit;
