import { useEffect } from "react";
import Layout from "../components/Layout";
import { Auth } from "aws-amplify";
import { Signin } from "../components/users/index";
import Swal from "sweetalert2";
import { useUserSignedIn } from "../hooks/useUserSignedIn";

const UsersSignin = () => {
  const { isUserSignedIn } = useUserSignedIn();

  const identifyLogin = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get("error");

    if (!!error) {
      switch (error) {
        case "alreadySignIn":
          window.history.replaceState(null, null, "/users/sign_in");
          Swal.fire({
            text: "이미 다른 방법으로 회원가입 된 계정이 있는 사용자입니다.",
            cancelButtonText: "확인",
          });
          break;
        default:
          window.history.replaceState(null, null, "/users/sign_in");
          Swal.fire({
            icon: "error",
            title: "잠시 후 다시 시도해주세요.",
            text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
            confirmButtonText: "확인",
          });
          break;
      }
    }
  };

  const returnBeforePage = () => {
    const returnUrl = sessionStorage.getItem("returnUrl");
    if (!!returnUrl) {
      sessionStorage.removeItem("returnUrl");
      location.href = returnUrl;
    } else {
      location.href = "/";
    }
  };

  useEffect(async () => {
    const currentSession = await Auth.currentSession();
    if (isUserSignedIn && !!currentSession) {
      returnBeforePage();
    }
  }, [isUserSignedIn]);

  useEffect(() => {
    identifyLogin();
  }, []);

  return (
    <Layout>
      <div className="max-w-[1200px] mx-auto">
        <Signin />
      </div>
    </Layout>
  );
};

export default UsersSignin;
