window.dataLayer =
  typeof window.dataLayer !== "undefined" && window.dataLayer instanceof Array
    ? window.dataLayer
    : [];
let videoLabels = [];
let lastP = [];
let _playerTitle = {};

const vimeoTrackingInit = () => {
  try {
    let player = document.getElementsByTagName("iframe");
    for (let i = 0; i < player.length; ++i) {
      let url = player[i].getAttribute("src");
      if (/player\.vimeo\.com\/video/.test(url)) {
        // vimeo player인지 확인
        // id 체크 후 없는 경우 추가
        if (!player[i].hasAttribute("id")) {
          player[i].setAttribute("id", "vimeo_id_" + i);
        }

        let urlUpdated = false;
        if (!/api=/.test(url)) {
          // api call 을 위해서 api 파라미터 추가;
          url = updateUrl(url, "api", 1);
          urlUpdated = true;
        }

        if (!/player_id=/.test(url)) {
          url = updateUrl(url, "player_id", player[i].getAttribute("id")); // player_id 가 없는 경우 추가
          urlUpdated = true;
        }
        if (urlUpdated) {
          // url이 업데이트 되면 player 배열도 업데이트
          player[i].contentWindow.location.replace(url);
        }
        videoLabels[player[i].getAttribute("id")] =
          player[i].getAttribute("src"); // player 별로 url 설정함
      }
    } // end of for

    // 이벤트리스너 추가
    if (window.addEventListener) {
      window.addEventListener("message", onMessageReceived, false);
    } else {
      // 이전버전 IE8, 오페라
      window.attachEvent("onmessage", onMessageReceived, false);
    }
  } catch (error) {
    // end of try
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - vimeoTrackingInit",
      error_message: error.message,
    });
  }
};

// url에 파라미터를 추가함
const updateUrl = (url, param, value) => {
  return url + (/\?/.test(url) ? "&" : "?") + param + "=" + value;
};

// Player로부터 메시지를 받아서 분기 처리
const onMessageReceived = (e) => {
  try {
    let data = e.data;
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    switch (data.event) {
      case "ready":
        onReady(data);
        break;
      case "play":
        if (typeof data.data !== "undefined") {
          // play중 시간을 선택하여 이동했을 때는 data.data가 없이 play 이벤트가 실행됨
          onPlay(data); // onPlay가 실행이 안 될 수 있음
        }
        break;
      case "pause":
        onPause(data);
        break;
      case "timeupdate":
        onPlayProgress(data);
        break;
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - onDataReceived(" + data.event + ")",
      error_message: error.message,
    });
  }
};

// player 로 메시지 전달
const post = (action, value) => {
  try {
    let data = { method: action, value: value };
    let player = document.getElementsByTagName("iframe");
    let url;
    let protocol;

    for (let i = 0; i < player.length; ++i) {
      if (!player[i].getAttribute("src")) continue; // src가 있는 플레이어가 아닐 경우 예외처리
      url = player[i].getAttribute("src").split("?")[0];
      if (/player\.vimeo\.com\/video/.test(url)) {
        protocol = url.split("//")[0];
        if (!protocol) {
          url = "https://" + url;
        }
        player[i].contentWindow.postMessage(data, url); // window 끼리 통신할 수 있게 함 여기선 player의 iframe
      }
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - post(" + action + ")",
      error_message: error.message,
    });
  }
};

const getLabel = (id) => {
  return videoLabels[id].split("?")[0].split("/").pop();
};

// JSONP
const getVimeoInfo = (id, callback) => {
  try {
    if (typeof id !== "undefined") {
      // 플레이어가 로드되기 전에 이 함수가 실행되는 경우 예외처리
      let url =
        "https://www.vimeo.com/api/oembed.json?url=https://vimeo.com/" +
        id +
        "&callback=vimeoCallback";
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      document.getElementsByTagName("body")[0].appendChild(script);
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - getVimeoInfo(" + id + ")",
      error_message: error.message,
    });
  }
};

const onReady = (data) => {
  try {
    if (typeof data.player_id !== "undefined") {
      //vimeo api 를 사용해서 데이터를 받아오는 작업 (video title 을 얻기 위해 필수로 수행되어야 함)
      //callback 은 밑에 작성
      getVimeoInfo(getLabel(data.player_id), vimeoCallback);
      post("addEventListener", "play");
      post("addEventListener", "pause");
      post("addEventListener", "playProgress");
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - onReady",
      error_message: error.message,
    });
  }
};

const onPlay = (data) => {
  try {
    if (typeof data.player_id !== "undefined") {
      const playerTitle = _playerTitle[getLabel(data.player_id)];

      window.dataLayer.push({
        event: "vimeo",
        event_action: "play",
        video_percent: data.data.percent.toFixed(2) * 100,
        video_label: !!playerTitle ? playerTitle.toLowerCase() : "",
        video_id: getLabel(data.player_id),
      });
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - onPlay",
      error_message: error.message,
    });
  }
};

const onPause = (data) => {
  try {
    if (typeof data.player_id !== "undefined") {
      let t = data.data.percent.toFixed(2) * 100;
      const playerTitle = _playerTitle[getLabel(data.player_id)];
      if (t !== 100) {
        // 100퍼센트에서 플레이 후 종료되는것은 확인하지 않습니다.
        window.dataLayer.push({
          event: "vimeo",
          event_action: "pause",
          video_percent: t,
          video_label: !!playerTitle ? playerTitle.toLowerCase() : "",
          video_id: getLabel(data.player_id),
        });
      }
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - onPause",
      error_message: error.message,
    });
  }
};

const onPlayProgress = (data) => {
  try {
    if (typeof data.player_id !== "undefined") {
      let t =
        data.data.duration - data.data.seconds <= 1.5
          ? 1
          : (
              Math.floor((data.data.seconds / data.data.duration) * 4) / 4
            ).toFixed(2);
      if (!lastP[data.player_id] || t > lastP[data.player_id]) {
        // 기록된 최대 진행보다 더 진행된 경우
        lastP[data.player_id] = t;
        const playerTitle = _playerTitle[getLabel(data.player_id)];
        if (parseFloat(t) != 0) {
          window.dataLayer.push({
            event: "vimeo",
            event_action: "progress - " + t * 100 + "%",
            video_percent: t * 100,
            video_label: !!playerTitle ? playerTitle.toLowerCase() : "",
            video_id: getLabel(data.player_id),
          });
        }
      }
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - onPlayProgress",
      error_message: error.message,
    });
  }
};

const isVimeo = () => {
  for (
    var e = document.getElementsByTagName("iframe"), i = 0;
    i < e.length;
    i++
  ) {
    if (/^https?:\/\/player.vimeo.com/.test(e[i].src)) {
      return true;
    }
  }
  return false;
};

// vimeo 동영상 정보 api callback 함수
window.vimeoCallback = (e) => {
  _playerTitle[e["video_id"]] = e["title"];
};

window.addEventListener("load", () => {
  try {
    if (isVimeo()) {
      vimeoTrackingInit();
    }
  } catch (error) {
    window.dataLayer.push({
      event: "error",
      error_name: "vimeo - vimeoAddEventListener",
      error_message: error.message,
    });
  }
});
