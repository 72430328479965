import useDeviceCheck from "../../hooks/useDeviceCheck";
import { EduTacticTitle } from "./SectionTitle";
import coldMobile from "../../assets/images/cold_mobile.png";
import coldWeb from "../../assets/images/cold_web.png";
import { css } from "@emotion/react";
import { gridSpanIEStyle } from "../../style";
import LazyLoad from "react-lazyload";
import VideoJs from "../shared/VideoJs";
import { useState } from "react";

const EduTactic = () => {
  const deviceCheck = useDeviceCheck();
  const [players, setPlayers] = useState([]);
  return (
    <div className="w-full default_margin web:mt-17 tablet:mt-17 mobile:mt-10">
      <EduTacticTitle />
      <div
        className="grid gap-4 web:grid-cols-2 tablet:grid-cols-2"
        css={gridSpanIEStyle}
      >
        <LazyLoad once>
          <VideoJs
            options={{
              autoplay: false,
              controls: true,
              responsive: true,
              fluid: true,
              sources: [
                {
                  src: "https://player.vimeo.com/video/557906533",
                  type: "video/vimeo",
                },
              ],
            }}
            setPlayers={setPlayers}
          />
        </LazyLoad>
        <div className="flex flex-col text-center justify-evenly web:text-xl tablet:text-xl mobile:row-start-1 mobile:mt-4">
          <span>
            스몰빅 클래스는 수강자가 강의를 보는 동안 <br />
            집중력을 유지할 수 있는 <br />
            <span className="font-bold">'콜드콜' 인터랙션 방식</span>을
            자랑합니다.
          </span>

          <img
            src={
              deviceCheck === "web" || deviceCheck === "tablet"
                ? coldWeb
                : coldMobile
            }
            alt="스몰빅강의방식소개텍스트"
            className="mx-auto my-4 web:w-[60%] tablet:w-[60%] mobile:w-[70%]"
          />
        </div>
      </div>
    </div>
  );
};

export default EduTactic;
