import moment from "moment/moment";
import useRemainTimer from "../../hooks/useRemainTimer";
import { slice, join } from "lodash";

export const RemainTime = ({ endTime }) => {
  const remainTime = useRemainTimer(moment(endTime), null);

  return (
    <div className="web:text-[25px] web:my-[25px] tablet:my-[15px] mobile:my-[15px] grid web:w-[390px] tablet:w-[268px] mobile:w-[268px] web:h-[60px] tablet:h-[42px] mobile:h-[42px] grid-cols-4 web:gap-[10px] tablet:gap-[5px] mobile:gap-[5px] mx-auto font-[700]">
      <div className="w-full h-full flex items-center justify-center bg-white rounded-[3px] ">
        00일
      </div>
      <div className="w-full h-full flex items-center justify-center bg-white rounded-[3px] ">
        {join(slice(remainTime, 0, 2), "")}시간
      </div>
      <div className="w-full h-full flex items-center justify-center bg-white rounded-[3px] ">
        {join(slice(remainTime, 3, 5), "")}분
      </div>
      <div className="w-full h-full flex items-center justify-center bg-white rounded-[3px] ">
        {join(slice(remainTime, 6, 8), "")}초
      </div>
    </div>
  );
};
