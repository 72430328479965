import { css } from "@emotion/react";
import { LectureTitle } from "../shared/Title";
import { useRef, useState, useEffect } from "react";
import { getCurriculums, getLessonTextbookDownload } from "../../api";
import { setReturnSession } from "../../utils";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const Curriculum = ({ lectureId, hasAuth }) => {
  const { isUserSignedIn } = useUserSignedIn();

  const detailPosition = useRef(0);

  const [curriculums, setCurriculums] = useState(null);
  useEffect(() => {
    if (!!lectureId) {
      getCurriculums({ lectureId }).then((response) => {
        setCurriculums(response?.data);
      });
    }
  }, [lectureId]);

  const indexStyle = css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      width: 30px !important;
      padding: 0 !important;
    }
  `;

  const onClickDownload = (lesson) => {
    getLessonTextbookDownload({ lessonId: lesson?.id }).then((response) => {
      const blob = new Blob([response.data]);
      const fileObjectUrl = window.URL.createObjectURL(blob);
      const fileType = response?.data?.type?.split("/")[1];
      const link = document.createElement("a");
      link.href = fileObjectUrl;
      link.style.display = "none";

      link.download = `${lesson.title}-교재.${fileType}`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(fileObjectUrl);
    });
  };

  const getLink = (lesson) => {
    if (isUserSignedIn) {
      if (lesson?.mode === "internal_download") {
        onClickDownload(lesson);
      } else {
        location.href = `/lessons/${lesson?.id}`;
      }
    } else {
      if (lesson?.mode === "internal_download") {
        return;
      } else {
        location.href = `/lessons/${lesson?.id}`;
      }
    }
  };

  return (
    <div>
      <LectureTitle text="커리큘럼" />
      {curriculums?.map((curriculum, index) => {
        if (index === 0) {
          detailPosition.current = 0;
        }
        return (
          <div
            key={`강의상세${curriculum?.id}`}
            className="flex flex-col pb-4 border-b border-b-gray100 mobile:text-sm"
          >
            <span className="mt-4 mb-2 font-bold">
              {curriculum?.chapter_title}
            </span>

            <div className="w-full mobile:flex mobile:flex-col web:justify-between tablet:justify-between">
              {curriculum?.lessons.map((lesson, index) => {
                detailPosition.current++;
                return (
                  <div
                    key={`강좌상세제목${index}`}
                    className="flex w-full web:justify-between tablet:justify-between mobile:flex-col"
                    style={{ marginTop: "2px" }}
                  >
                    {hasAuth ? (
                      <a
                        onClick={() => getLink(lesson)}
                        className="cursor-pointer"
                      >
                        <div className="flex items-center">
                          <span
                            className="bg-gray200 rounded-full text-white p-[12px] max-w-[24px] max-h-[24px] flex justify-center items-center mr-4"
                            css={indexStyle}
                          >
                            {detailPosition.current}
                          </span>
                          <span className="mr-auto leading-[24px] py-1">
                            {lesson?.title}
                          </span>
                        </div>
                      </a>
                    ) : (
                      <div className="flex items-center">
                        <span
                          className="bg-gray200 rounded-full text-white p-[12px] max-w-[24px] max-h-[24px] flex justify-center items-center mr-4"
                          css={indexStyle}
                        >
                          {detailPosition.current}
                        </span>
                        <span className="mr-auto leading-[24px] py-1">
                          {lesson?.title}
                        </span>
                      </div>
                    )}
                    {lesson?.is_free && !hasAuth && (
                      <a
                        className="class_free_class w-fit h-fit text-white rounded-[5px] bg-purple500 py-1 px-2 mobile:ml-[40x] mobile:mt-1 mobile:mb-2 cursor-pointer"
                        onClick={(e) => {
                          if (!isUserSignedIn) {
                            e.preventDefault();
                            if (lesson?.mode === "internal_download") {
                              setReturnSession();
                            } else {
                              setReturnSession(`/lessons/${lesson?.id}`);
                            }
                          } else {
                            getLink(lesson);
                          }
                        }}
                      >
                        무료체험 하기
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Curriculum;
