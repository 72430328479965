import { useState } from "react";
import { missionMobileTabBtnsStyle } from "../../style";
import { NewContent } from "./NewContent";
import { AssignmentLists } from "./AssignmentLists";

export const NewContentMobile = ({ lessonId }) => {
  const tab = new URLSearchParams(window.location.search);

  const [tabSelect, setTabSelect] = useState(tab.get("tab") || "sendMission"); // otherMission, sendMission

  const setPushState = (stateName) => {
    setTabSelect(stateName);
    window.history.replaceState(
      stateName,
      null,
      `/lessons/${lessonId}/assignments/new?tab=${stateName}`
    );
  };

  const TabBtn = ({ name, tabValue, tailwindStyle }) => {
    return (
      <div
        css={missionMobileTabBtnsStyle}
        className={`text-lg relative w-full cursor-pointer flex items-center justify-center ${
          tabSelect === tabValue
            ? "active font-bold text-black"
            : "font-medium text-gray400"
        } ${tailwindStyle}`}
        onClick={() => {
          setPushState(tabValue);
        }}
      >
        {name}
      </div>
    );
  };

  return (
    <div className="flex flex-col web:w-[354px] tablet:w-full tablet:min-w-[330px] mobile:min-w-[330px] mobile:w-full flex-shrink-0 mt-[9px]">
      <div className="grid grid-cols-2 gap- mt-[17px]">
        <TabBtn name="미션 도전" tabValue="sendMission" />
        <TabBtn name="미션 게시판" tabValue="otherMission" />
      </div>

      {tabSelect === "sendMission" && <NewContent lessonId={lessonId} />}
      {tabSelect === "otherMission" && <AssignmentLists lessonId={lessonId} />}
    </div>
  );
};
