import { useState, useRef, useReducer } from "react";
import { CSVReader } from "react-papaparse";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty, find, chunk } from "lodash";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

function Badge({ text, className, ...rest }) {
  return (
    <button
      type="button"
      className={`w-12 mx-2 py-1 font-normal rounded-sm ${className}`}
      {...rest}
    >
      {text}
    </button>
  );
}

// status => loading, fail, success
function reducer(state, action) {
  switch (action.type) {
    case "PARSING_DATAS": {
      const setViewDatas = action.setViewDatas;
      action.setFieldValue("importedDatas", action.standards);
      const result = action.standards.map((standard, index) => {
        return {
          standard: String(standard),
          index,
          user: {},
          status: "loading",
        };
      });
      setViewDatas(result.slice(0, action.limit));
      return [...result];
    }
    case "REMOVEALL": {
      action.setFieldValue("importedDatas", []);
      return [];
    }
    case "REMOVE": {
      let index = action.index;
      return [
        ...state.filter((obj) => {
          return index !== obj.index;
        }),
      ];
    }
    case "SET_DATAS": {
      const datas = action.datas;
      const standard = action.standard;

      const result = state;
      datas.forEach((data) => {
        const findData = find(state, ["standard", String(data.user[standard])]);

        findData["user"] = data.user;
        findData["status"] = data.result ? "success" : "fail";
        findData["message"] = data?.message;
      });
      return [...result];
    }
    default:
      return state;
  }
}

function ImportDatas() {
  const buttonRef = useRef(null);
  const DataActionSelectRef = useRef(null);
  const actionDataSelectRef = useRef(null);
  const [noticeMsg, setNoticeMsg] = useState(null);
  const [datas, dispatch] = useReducer(reducer, []);
  const [viewDatas, setViewDatas] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [loadingMsg, setLoadingMsg] = useState("");
  const dataActionLists = useRef([
    { name: "authority", displayName: "강의 권한 부여" },
    { name: "coupon", displayName: "쿠폰 추가 부여" },
    { name: "addAuthorityEndAt", displayName: "쿠폰 권한 월 추가" },
    {
      name: "addAuthorityEndAtFromCreatedAt",
      displayName: "쿠폰 권한 월 추가(시작일 기준)",
    },
  ]);
  const [actionDatas, setActionDatas] = useState([]);
  const [actionMessage, setActionMessage] = useState(null);

  const formik = useFormik({
    initialValues: {
      standard: null,
      rowNum: null,
      importedDatas: null,
      dataAction: null,
      actionData: null,
    },
    validationSchema: Yup.object().shape({
      standard: Yup.string().required(),
      rowNum: Yup.number().required().min(1),
      importedDatas: Yup.array().required().min(1),
      dataAction: Yup.string().required(),
      actionData: Yup.string().required(),
    }),
    onSubmit: ({
      standard,
      importedDatas,
      dataAction,
      actionData,
      ...rest
    }) => {
      let endPoint = "";
      let keyName = "";
      switch (dataAction) {
        case "authority": {
          endPoint = "/api/admin/import_datas/add_authority";
          keyName = "lecture_id";
          break;
        }
        case "coupon": {
          endPoint = "/api/admin/import_datas/add_coupon";
          keyName = "coupon_id";
          break;
        }
        case "addAuthorityEndAt": {
          endPoint = "/api/admin/import_datas/add_authority_end_at";
          keyName = "lecture_id";
          break;
        }
        case "addAuthorityEndAtFromCreatedAt": {
          endPoint =
            "/api/admin/import_datas/add_authority_end_at_from_created_at";
          keyName = "lecture_id";
          break;
        }
      }

      const chunkByimportedDatas = chunk(importedDatas, 10);
      const total = chunkByimportedDatas.length;
      let tryCount = 0;
      let successCount = 0;
      let failCount = 0;

      const sendDatas = (import_datas, index) => {
        const user_datas = import_datas[index];
        const paramsData = {
          standard,
          user_datas,
          [keyName]: actionData,
        };

        if (
          dataAction === "addAuthorityEndAt" ||
          dataAction === "addAuthorityEndAtFromCreatedAt"
        ) {
          paramsData["end_at"] = rest?.addAuthorityEndAtMonth;
        }

        axios({
          url: endPoint,
          method: "POST",
          headers: {
            "X-CSRF-Token":
              document.getElementsByTagName("meta")["csrf-token"].content,
          },
          data: paramsData,
        })
          .then((res) => {
            successCount++;
            dispatch({
              type: "SET_DATAS",
              datas: res.data,
              standard,
              setLoadingMsg,
            });
          })
          .catch((err) => {
            failCount++;
          })
          .finally(() => {
            tryCount++;
            setLoadingMsg(
              `${tryCount}/${total} [성공: ${successCount}, 실패: ${failCount}]`
            );
            if (import_datas.length - 1 > index) {
              sendDatas(import_datas, index + 1);
            }
          });
      };

      sendDatas(chunkByimportedDatas, 0);
    },
  });

  const standardEnum = () => {
    let value;
    switch (formik.values.standard) {
      case "phone":
        value = "전화번호";
        break;
      case "id":
        value = "ID";
        break;
      case "email":
        value = "EMAIL";
        break;
      default:
        value = "ID";
        break;
    }

    return value;
  };

  const checkedByStandard = (data) => {
    const { standard } = formik.values;
    let result;
    switch (standard) {
      case "phone":
        const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        result = regPhone.test(data);
        break;
      case "id":
        let parsedData = Number(data);
        result = Number.isInteger(parsedData) && parsedData != 0;
        break;
      case "email":
        result = true;
        break;
      default:
        result = true;
        break;
    }

    return result;
  };

  const getResultByStandard = (data) => {
    const { standard } = formik.values;
    let result;
    switch (standard) {
      case "phone":
        result = data.split("-").join("");
        break;
      case "id":
        result = Number(data);
        break;
      case "email":
        result = data;
        break;
      default:
        result = data;
        break;
    }
    return result;
  };

  const getActionDatas = async (dataAction) => {
    switch (dataAction) {
      case "authority": {
        const response = await axios({
          url: `/api/admin/import_datas/lectures`,
          method: "GET",
        });
        setActionDatas(response.data);
        setActionMessage("추가 정보에 부여하고 싶은 강의를 선택해주세요!");
        break;
      }
      case "coupon": {
        const response = await axios({
          url: `/api/admin/import_datas/coupons`,
          method: "GET",
        });
        setActionDatas(response.data);
        setActionMessage("추가 정보에 부여하고 싶은 쿠폰을 선택해주세요!");
        break;
      }
      case "addAuthorityEndAt": {
        const response = await axios({
          url: `/api/admin/import_datas/lectures`,
          method: "GET",
        });
        setActionDatas(response.data);
        setActionMessage("추가 정보에 부여하고 싶은 강의와 월을 선택해주세요!");
        break;
      }
      case "addAuthorityEndAtFromCreatedAt": {
        const response = await axios({
          url: `/api/admin/import_datas/lectures`,
          method: "GET",
        });
        setActionDatas(response.data);
        setActionMessage("추가 정보에 부여하고 싶은 강의와 월을 선택해주세요!");
        break;
      }
      default:
        break;
    }
  };

  const actionInputReset = () => {
    formik.setFieldValue("dataAction", "");
    DataActionSelectRef.current.value = "";

    formik.setFieldValue("actionData", "");
    actionDataSelectRef.current.value = "";
  };

  // CSVReader 관련 처리 시작
  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    const { rowNum } = formik.values;
    const parsedDatas = data.reduce((rst, obj) => {
      const result = obj.data[rowNum - 1];
      if (checkedByStandard(result)) {
        rst.push(getResultByStandard(result));
      }

      return rst;
    }, []);

    const allCount = data?.length || 0;
    const successCount = parsedDatas?.length || 0;

    setNoticeMsg(
      `총 ${allCount}개의 데이터 중 ${successCount}개의 데이터를 불러왔습니다! (${
        allCount - successCount
      }개 실패)`
    );

    dispatch({
      type: "PARSING_DATAS",
      standards: parsedDatas.sort((a, b) => {
        return b - a;
      }),
      setFieldValue: formik.setFieldValue,
      setViewDatas: setViewDatas,
      limit: limit,
    });
    actionInputReset();
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    dispatch({
      type: "REMOVEALL",
      setFieldValue: formik.setFieldValue,
    });
    actionInputReset();
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };
  // CSVReader 관련 처리 끝

  const nextDatas = () => {
    const total = datas.length;

    if (page * limit < total) {
      setViewDatas([
        ...viewDatas,
        ...datas.slice(page * limit, (page + 1) * limit),
      ]);
      setPage(page + 1);
    }
  };

  return (
    <div className="mx-12">
      <h3 className="mb-2 text-xl font-bold text-black">
        1. 데이터 IMPORT해서 사용자 불러오기
      </h3>
      <div className="flex items-end mb-4">
        <div className="flex flex-col">
          <label htmlFor="inputStandard">import 기준</label>
          <select
            className="h-6 mr-4 border border-black"
            name="standard"
            id="inputStandard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">기준을 선택해주세요.</option>
            <option value="id">ID</option>
            <option value="email">EMAIL</option>
            <option value="phone">
              전화번호 (00011112222 or 010-1111-2222)
            </option>
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="inputRowNum">열 기준 번호</label>
          <input
            id="inputRowNum"
            className="h-6 px-1 mr-4 border border-black"
            type="text"
            placeholder="해당 기준의 열 번호를 입력해주세요."
            name="rowNum"
            onChange={formik.handleChange}
          />
        </div>
        <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          config={{}}
          style={{}}
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <div className="flex">
              <Badge
                className={"text-white bg-green-400"}
                text="업로드"
                onClick={handleOpenDialog}
              />
              <div>{file && file.name}</div>
              <Badge
                className={"text-white bg-red-400"}
                text="제거"
                onClick={handleRemoveFile}
              />
            </div>
          )}
        </CSVReader>
      </div>

      {noticeMsg && (
        <span className="px-4 py-2 mt-2 text-lg text-white bg-red-500 border rounded-full">
          {noticeMsg}
        </span>
      )}

      <hr className="my-2" />

      <h3 className="mb-2 text-xl font-bold text-black">2. IMPORT 이후 행동</h3>
      {datas.length > 0 ? (
        <div>
          <div className="flex items-end mb-2">
            <div className="flex flex-col">
              <label htmlFor="inputDataAction">데이터 처리 방법</label>
              <select
                className="h-6 mr-4 border border-black"
                name="dataAction"
                id="inputDataAction"
                ref={DataActionSelectRef}
                onChange={(e) => {
                  formik.handleChange(e);
                  // actionData 값 초기화
                  formik.setFieldValue("actionData", "");
                  actionDataSelectRef.current.value = "";
                  // actionData 리스트 불러오기
                  getActionDatas(e.target.value);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="">처리 방법을 선택해주세요.</option>
                {dataActionLists.current.map((obj, index) => {
                  return (
                    <option key={index} value={obj.name}>
                      {obj.displayName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="inputActionData">{"추가 정보"}</label>
              <select
                className="h-6 mr-4 border border-black"
                ref={actionDataSelectRef}
                name="actionData"
                id="inputActionData"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">추가 정보를 입력해주세요.</option>
                {actionDatas.map((data, index) => {
                  return (
                    <option key={index} value={data?.id}>
                      {data?.title || data?.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {(formik?.values?.dataAction === "addAuthorityEndAt" ||
              formik?.values?.dataAction ===
                "addAuthorityEndAtFromCreatedAt") && (
              <div className="flex flex-col">
                <label htmlFor="inputActionData">{"개월 선택"}</label>
                <select
                  className="h-6 mr-4 border border-black"
                  name="addAuthorityEndAtMonth"
                  id="inputActionDataEndAtMonth"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">연장할 개월 수를 선택해주세요.</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                    (data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            )}

            <button
              className={`w-12 py-1 font-normal text-white ${
                !formik.dirty || !formik.isValid ? "bg-gray-500" : "bg-blue-500"
              } rounded-sm`}
              onClick={formik.handleSubmit}
              disabled={!formik.dirty || !formik.isValid}
            >
              시작!
            </button>
          </div>
          {!!actionMessage && (
            <p className="mb-1 text-xs text-red-700 underline underline-offset-1">
              {actionMessage}
            </p>
          )}
        </div>
      ) : (
        <h3 className="text-lg text-red-700 underline underline-offset-1">
          데이터를 IMPORT해서 0개 이상의 데이터를 불러와주세요.
        </h3>
      )}

      <div>{loadingMsg}</div>
      {/* 출력 데이터 */}
      <div>
        <div className="grid grid-cols-5">
          <div className="flex justify-center py-2 border">idx</div>
          <div className="flex justify-center py-2 border">
            {standardEnum()}
          </div>
          <div className="flex justify-center py-2 border">사용자</div>
          <div className="flex justify-center py-2 border">상태</div>
          <div className="flex justify-center py-2 border">action</div>
        </div>
        <InfiniteScroll
          hasMore={true}
          next={nextDatas}
          dataLength={viewDatas?.length}
        >
          {viewDatas?.map(({ standard, index, user, status, message }) => {
            return (
              <div className="grid grid-cols-5" key={index}>
                <div className="flex justify-center py-2 border">
                  {index + 1}
                </div>
                <div className="flex justify-center py-2 border">
                  {standard}
                </div>
                <div className="flex justify-center py-2 border">
                  {!isEmpty(user) ? (
                    <a
                      href={`/admin/users?q[id_eq]=${user?.id}`}
                      target="_blank"
                    >
                      {user?.name}
                    </a>
                  ) : (
                    "확인 불가"
                  )}
                </div>
                <div className="flex justify-center py-2 border">
                  <>
                    {status == "loading" ? (
                      <Badge
                        className={"text-black bg-gray-300"}
                        text="부여 전"
                      />
                    ) : status == "success" ? (
                      <Badge
                        className={"text-white bg-green-700"}
                        text="성공"
                      />
                    ) : (
                      status == "fail" && (
                        <>
                          <Badge
                            className={"text-white bg-red-700"}
                            text="실패"
                          />
                          <p>{message}</p>
                        </>
                      )
                    )}
                  </>
                </div>
                <div className="flex justify-center py-2 border">
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch({
                        type: "REMOVE",
                        index,
                      });
                    }}
                  >
                    삭제
                  </a>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
      <div className="grid gap-4 web:grid-cols-3"></div>
    </div>
  );
}

export default ImportDatas;
