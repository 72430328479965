import { css } from "@emotion/react";
import { useState } from "react";

import Notice from "./Notice";
import Event from "./Event";
import PinnedNotice from "./PinnedNotice";
import Faq from "./Faq";

const Content = () => {
  const boardType = new URLSearchParams(window.location.search);

  const [boardName, setBoardName] = useState(
    boardType.get("board_type") || "event"
  );

  const setPushState = (stateName) => {
    setBoardName(stateName);
    window.history.replaceState(
      stateName,
      null,
      `/boards?board_type=${stateName}`
    );
  };

  const GenerateContent = ({ contentName }) => {
    if (contentName === "notice") {
      return <Notice />;
    } else if (contentName === "event") {
      return <Event />;
    } else if (contentName === "faq") {
      return <Faq />;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="mx-auto min-w-[360px] max-w-[640px] px-[15px]">
        <span className="text-lg font-bold">새소식</span>
        {/* 탭 */}
        <div
          className="flex mt-[20px]"
          css={css`
            button + button {
              margin-left: 22px;
            }

            button.active {
              color: black !important;
            }

            button.active::after {
              position: absolute;
              content: "";
              bottom: -7px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: black;
              border: 1px solid black;
            }
          `}
        >
          <button
            onClick={() => {
              setPushState("event");
            }}
            className={`${
              boardName === "event" ? "active" : ""
            } text-sm text-gray400 relative font-bold`}
          >
            이벤트
          </button>
          <button
            onClick={() => {
              setPushState("notice");
            }}
            className={`${
              boardName === "notice" ? "active" : ""
            } text-sm text-gray400 relative font-bold`}
          >
            공지사항
          </button>

          {false && (
            <button
              onClick={() => {
                setPushState("faq");
              }}
              className={`${
                boardName === "faq" ? "active" : ""
              } text-sm text-gray400 relative font-bold`}
            >
              FAQ
            </button>
          )}
        </div>
        {/* 최상단중요한공지와이벤트 */}
        <PinnedNotice />

        {/* 공지사항, 이벤트, FAQ */}
        <GenerateContent contentName={boardName} />
      </div>
    </>
  );
};

export default Content;
