import { useState, useEffect, useContext } from "react";
import { css } from "@emotion/react";
import Sidebar from "react-sidebar";

import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { LayoutContext } from "../Layout";

import sidebarClose from "../../assets/icons/close_24.svg";
import menuSidebar from "../../assets/icons/menu_sidebar.svg";
import logoMain from "../../assets/images/nav_logo.png";
import defaultProfile from "../../assets/icons/default_profile.svg";
import activeRedDot from "../../assets/icons/active_red_dot.svg";
import linkArrowLight from "../../assets/icons/link_arrow_light.svg";
import sidebarClass from "../../assets/icons/sidebar_class.svg";
import sidebarCoupon from "../../assets/icons/sidebar_coupon.svg";
import sidebarEvent from "../../assets/icons/sidebar_event.svg";
import sidebarPayment from "../../assets/icons/sidebar_payment.svg";
import { setReturnSession } from "../../utils";

const SidebarButtons = () => {
  const buttons = [
    {
      position: 1,
      icon: sidebarClass,
      text: "나의 클래스",
      link: "/mypage/lectures_kids",
    },
    {
      position: 2,
      icon: sidebarPayment,
      text: "주문/배송",
      link: "/mypage/payment",
    },
    {
      position: 3,
      icon: sidebarCoupon,
      text: "쿠폰/수강권",
      link: "/mypage/coupon",
    },
    {
      position: 4,
      icon: sidebarEvent,
      text: "이벤트",
      link: "/boards?board_type=event",
    },
  ];

  return (
    <div className="grid grid-cols-4 mt-2">
      {buttons?.map((button, poistion) => {
        return (
          <a
            key={`버튼_${poistion}`}
            href={button?.link}
            className="h-[97px] flex flex-col justify-center items-center cursor-pointer"
          >
            <img
              src={button?.icon}
              alt={`${button?.text}_아이콘`}
              className="w-[43px] h-[43px]"
            />
            <span className="text-xs mt-1 font-[300]">{button?.text}</span>
          </a>
        );
      })}
    </div>
  );
};

const TextLinkButton = ({ text, link }) => {
  return (
    <a href={link} className="h-[45px] w-full flex items-center">
      <span className="text-[15px] font-[500]">{text}</span>
    </a>
  );
};

const NavMobile = () => {
  const { currentUser, isUserLoading } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { eventBannerHeight } = useContext(LayoutContext);

  const navMenus = [
    {
      id: "goHome",
      position: 1,
      name: "홈",
      link: "/",
      className: "w-[30px]",
      pointActive: false,
    },
    {
      id: "goDnav",
      position: 2,
      name: "D.Nav",
      link: "/lectures/118",
      className: "w-[64px]",
      pointActive: true,
    },
    {
      id: "goSnav",
      position: 3,
      name: "S.Nav",
      link: "/lectures/128",
      className: "w-[100px]",
      pointActive: true,
    },
    {
      id: "goDnav",
      position: 4,
      name: "마음 필담",
      link: "/lectures/129",
      className: "w-[100px]",
      pointActive: false,
    },
  ];

  //sidebar 열렸을때 스크롤 금지
  useEffect(() => {
    isSidebarOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isSidebarOpen]);

  const navSidebarStyle = {
    sidebar: {
      width: "360px",
      backgroundColor: "white",
      position: "fixed",
      zIndex: "19999999",
    },
    root: {
      position: "undefined",
      zIndex: "19999999",
    },
    content: {
      position: "undefined",
      top: "undefined",
      left: "undefined",
      right: "undefined",
      bottom: "undefined",
      zIndex: "19999999",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.75)",
      zIndex: "19999990",
    },
  };

  const mobileNavStyle = css`
    top: ${eventBannerHeight}px;
  `;

  const SidebarContent = () => {
    return (
      <div className="flex flex-col leading-[1.2]">
        <div className="flex flex-col px-4 pt-4 pb-2 border-b border-[#F5F5F5] border-b-[8px]">
          <img
            src={sidebarClose}
            alt="노란메뉴바"
            className="w-[24px] h-[24px] inline-block ml-auto cursor-pointer"
            onClick={() => setIsSidebarOpen(false)}
          />

          {!isUserLoading &&
            (!isUserSignedIn ? (
              <button
                type="button"
                onClick={() => setReturnSession()}
                className="all_login_bar mt-[34px] flex items-center"
              >
                <span className="font-[700] mr-1">로그인이 필요해요</span>
                <img src={linkArrowLight} alt="로그인이동버튼" />
              </button>
            ) : (
              <a href="/mypage/edit" className="flex items-center mt-[24px]">
                <img
                  src={currentUser?.avatar?.thumb?.url || defaultProfile}
                  alt="사용자프로필"
                  className="object-cover w-[40px] h-[40px] rounded-full mr-[10px]"
                />
                <span className="mr-1 font-[700]">
                  {currentUser?.nickname || currentUser?.name}
                </span>
                <img src={linkArrowLight} alt="로그인이동버튼" />
              </a>
            ))}

          <SidebarButtons />
        </div>
        <div className="flex flex-col px-4 py-5">
          <span className="text-xs font-[500] text-gray99 mb-[10px]">
            내 정보
          </span>
          <TextLinkButton text="나의 클래스" link="/mypage/lectures_kids" />
          <TextLinkButton text="주문/배송 정보" link="/mypage/payment" />
          <TextLinkButton text="쿠폰/수강권 정보" link="/mypage/coupon" />
          <span className="text-xs font-[500] text-gray99 mt-[20px] mb-[10px]">
            서비스
          </span>
          <TextLinkButton text="이벤트" link="/boards?board_type=event" />
          <TextLinkButton text="공지사항" link="/boards?board_type=notice" />

          <TextLinkButton
            text="고객센터"
            link="https://smallbigclass.channel.io/"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Sidebar
        sidebar={<SidebarContent />}
        open={isSidebarOpen}
        onSetOpen={setIsSidebarOpen}
        pullRight={true}
        touch={false}
        styles={navSidebarStyle}
      >
        <></>
      </Sidebar>

      <div className="fixed inset-x-0 z-[500]" css={mobileNavStyle}>
        <div id="nav" className="flex flex-col bg-white">
          <div className="w-full flex items-center justify-between px-4 h-[54px]">
            <img
              src={logoMain}
              alt="메인로고"
              className="cursor-pointer all_category max-w-[189px]"
              onClick={() => (location.href = "/")}
            />

            <img
              src={menuSidebar}
              alt="메뉴아이콘"
              className="w-[24px] h-[24px] inline-block cursor-pointer"
              onClick={() => {
                setIsSidebarOpen(true);
              }}
            />
          </div>

          <div
            className="flex items-center h-[42px] overflow-auto ml-4 space-x-[5.5px]"
            css={css`
              &::-webkit-scrollbar {
                width: 0 !important;
              }
            `}
          >
            {navMenus?.map((navMenu) => {
              return (
                <a
                  className={`all_category flex shrink-0 items-center justify-center font-[700] relative ${
                    navMenu?.className
                  } 
                  ${
                    location.pathname === "/"
                      ? navMenu?.link === "/"
                        ? "text-black"
                        : "text-[#999999]"
                      : location.pathname?.includes(navMenu?.link) &&
                        navMenu?.link !== "/"
                      ? "text-black"
                      : "text-[#999999]"
                  }
                  `}
                  href={navMenu?.link}
                  key={navMenu?.position}
                  id={navMenu?.id}
                >
                  <div className="relative">
                    <div className={`flex items-center`}>{navMenu?.name}</div>
                    {navMenu?.pointActive && (
                      <img
                        className="w-[5px] h-[5px] absolute top-[-2px] right-[-5px]"
                        src={activeRedDot}
                        alt={`붉은점_${navMenu?.position}`}
                      />
                    )}
                  </div>
                  {location.pathname === "/" ? (
                    navMenu?.link === "/" ? (
                      <div className="absolute border-b-[2px] bottom-[-8px] w-full border-black"></div>
                    ) : (
                      ""
                    )
                  ) : location.pathname?.includes(navMenu?.link) &&
                    navMenu?.link !== "/" ? (
                    <div className="absolute border-b-[2px] bottom-[-8px] w-full border-black"></div>
                  ) : (
                    ""
                  )}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavMobile;
