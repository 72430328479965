import { Editor } from "@tinymce/tinymce-react";
import { postAssignmentImage } from "../../api";

export const AssignmentEditor = ({ editorRef, formik, ...rest }) => {
  const readUrl = (files) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        // e: progress event;
        resolve(e);
      };
      reader.readAsDataURL(files[0]);
    });
  };

  return (
    <>
      <div className="flex items-center justify-between mb-1">
        <span className="text-sm text-gray-400">
          영상을 올리는 경우, 유튜브나 인스타에 영상 업로드 후 해당 영상의
          링크를 미션 내용에 첨부해주세요!
        </span>
      </div>
      <Editor
        apiKey={process.env.TINYMCE_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          language: "ko_KR",
          height: 668,
          menubar: false,
          statusbar: false,
          plugins:
            "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount autosize",
          toolbar:
            "custom_image_upload link formatselect bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify",
          content_css: false,
          image_title: true,
          automatic_uploads: true,
          images_upload_url: "/api/assets/images_upload",
          image_description: false,
          relative_urls: false,
          media_dimensions: false,
          media_alt_source: false,
          media_poster: false,
          image_caption: false,
          a11y_advanced_options: false,
          link_title: false,
          content_style: "body { font-family:Helvetica,Arial,sans-serif;}",
          setup: (editor) => {
            editor.ui.registry.addButton("custom_image_upload", {
              icon: "image",
              tooltip: "이미지 업로드",
              onAction: () => {
                editor.windowManager.open({
                  title: "이미지 업로드", // The dialog's title - displayed in the dialog header
                  body: {
                    type: "panel", // The root body type - a Panel or TabPanel
                    items: [
                      // A list of panel components
                      {
                        type: "dropzone", // component type
                        name: "imageInput", // identifier
                      },
                      {
                        type: "htmlpanel", // component type
                        html: "<div>이미지 프리뷰</div>",
                      },
                      {
                        type: "imagepreview", // component type
                        name: "preview", // identifier
                      },
                    ],
                  },
                  buttons: [
                    // A list of footer buttons
                    {
                      type: "submit",
                      text: "확인",
                    },
                  ],
                  onChange: (dialogApi, details) => {
                    const files = dialogApi.getData().imageInput;
                    readUrl(files).then((e) => {
                      dialogApi.setData({
                        preview: { url: e.target.result },
                      });
                    });
                  },
                  onSubmit: (dialogApi) => {
                    const files = dialogApi.getData().imageInput;
                    let data = new FormData();
                    data.append("file", files[0]);

                    postAssignmentImage(data).then((response) => {
                      const image = {
                        src: response.data.location,
                        width: "100%",
                      };

                      editor.insertContent(editor.dom.createHTML("img", image));
                    });

                    dialogApi.close();
                  },
                });
              },
            });
          },
        }}
        onEditorChange={(e) => {
          formik.setFieldValue("content", e);
        }}
        {...rest}
      />
    </>
  );
};
