import React from "react";
import { MypageTitle } from "../components/shared/Title";
import { Content } from "../components/mypagePassword/index";
import Aside from "../components/shared/MypageAside";
import Layout from "../components/Layout";
import { useUserSignedIn } from "../hooks/useUserSignedIn";
import { useEffect } from "react";

const MypagePassword = (props) => {
  const { isUserSignedIn } = useUserSignedIn();

  useEffect(() => {
    if (isUserSignedIn === null) {
      return;
    } else if (isUserSignedIn === false) {
      return (location.href = "/users/sign_in");
    }
  }, [isUserSignedIn]);

  return (
    <Layout>
      <div className="flex mypage_lectures default_margin mobile:flex-col h-[100%] max-w-[1200px]">
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>

        <div className="flex-grow w-full mobile:mt-5">
          <MypageTitle title="비밀번호 수정" />
          <Content />
        </div>
      </div>
    </Layout>
  );
};

export default MypagePassword;
