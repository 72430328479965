import { useState, useRef, useReducer } from "react";
import { CSVReader } from "react-papaparse";
import { useFormik } from "formik";
import { chunk, find, isNull } from "lodash";
import axios from "axios";
import * as Yup from "yup";

// status => loading, fail, success
function reducer(state, action) {
  switch (action.type) {
    case "PARSING_USERS":
      action.setFieldValue("userIds", action.userIds);
      const result = action.userIds.map((userId) => {
        return { userId, setCoupon: null, userName: null, message: null };
      });
      return [...result];
    case "REMOVE":
      action.setFieldValue("userIds", null);
      return [];
    case "SET_DATA":
      const resUsers = action.data;

      let result2 = state;
      resUsers.forEach((user) => {
        const findData = find(result2, ["userId", user.id]);

        findData["setCoupon"] = user.set_coupon;
        findData["userName"] = user.name;
        findData["message"] = user.message;
      });
      return [...result2];
    default:
      return state;
  }
}

function Badge({ text, className, ...rest }) {
  return (
    <button
      type="button"
      className={`w-12 mx-2 py-1 font-normal rounded-sm ${className}`}
      {...rest}
    >
      {text}
    </button>
  );
}

function MassiveCoupons({ couponList }) {
  const [coupons, setCoupons] = useState(couponList);
  const [users, dispatch] = useReducer(reducer, []);
  const buttonRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      userIds: null,
      couponId: null,
    },
    validationSchema: Yup.object().shape({
      couponId: Yup.number().required(),
      userIds: Yup.array().required(),
    }),
    onSubmit: ({ userIds, couponId }) => {
      const chunkByUserIds = chunk(userIds, 10);
      chunkByUserIds.forEach(async (user_ids) => {
        const response = await axios({
          url: `/admin/coupons/${couponId}/set_massive_coupons`,
          method: "PATCH",
          headers: {
            "X-CSRF-Token":
              document.getElementsByTagName("meta")["csrf-token"].content,
          },
          data: {
            user_ids,
          },
        });

        dispatch({
          type: "SET_DATA",
          data: response.data,
        });
      });
    },
  });

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    const parsedUserIds = data.reduce((rst, obj) => {
      const result = Number(obj.data[0]);
      if (Number.isInteger(result) && result != 0) {
        rst.push(result);
      }
      return rst;
    }, []);

    dispatch({
      type: "PARSING_USERS",
      userIds: parsedUserIds.sort((a, b) => {
        return b - a;
      }),
      setFieldValue: formik.setFieldValue,
    });
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log("---------------------------");
    console.log(err);
    console.log("---------------------------");
  };

  const handleOnRemoveFile = (data) => {
    dispatch({
      type: "REMOVE",
      setFieldValue: formik.setFieldValue,
    });
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <div className="mx-12">
      <h3 className="mb-2 text-xl font-bold text-black">쿠폰 선택하기</h3>
      <div className="flex items-center mb-4">
        <select
          className="h-6 mr-4 border border-black"
          name="couponId"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">없음</option>
          {coupons?.map((coupon) => {
            return (
              <option value={coupon?.id} key={coupon?.id}>
                {coupon?.title}
              </option>
            );
          })}
        </select>
        <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          noDrag
          config={{}}
          style={{}}
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <div className="flex">
              <Badge
                className={"text-white bg-green-400"}
                text="업로드"
                onClick={handleOpenDialog}
              />
              <div>{file && file.name}</div>
              <Badge
                className={"text-white bg-red-400"}
                text="제거"
                onClick={handleRemoveFile}
              />
            </div>
          )}
        </CSVReader>
        <button
          className={`w-12 py-1 font-normal text-white ${
            !formik.dirty || !formik.isValid ? "bg-gray-500" : "bg-blue-500"
          } rounded-sm`}
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || !formik.isValid}
        >
          제출
        </button>
      </div>
      <div className="flex justify-center">
        <table className="w-1/2 text-center table-auto index_table index">
          <thead>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">이름</th>
              <th className="text-center">상태</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(({ userId, userName, setCoupon, message }) => {
              return (
                <tr key={userId}>
                  <td>{userId}</td>
                  <td>
                    {!!userName ? (
                      <a href={`/admin/users/${userId}`} target="_blank">
                        {userName}
                      </a>
                    ) : (
                      "확인 불가"
                    )}{" "}
                  </td>
                  <td>
                    {setCoupon == null ? (
                      <Badge
                        className={"text-black bg-gray-300"}
                        text="부여 전"
                      />
                    ) : setCoupon ? (
                      <Badge
                        className={"text-white bg-green-700"}
                        text="성공"
                      />
                    ) : (
                      <>
                        <Badge
                          className={"text-white bg-red-700"}
                          text="실패"
                        />
                        <p>{message}</p>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="grid gap-4 web:grid-cols-3"></div>
    </div>
  );
}

export default MassiveCoupons;
