import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { css } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { KitClassTitle } from "./SectionTitle";
import Swal from "sweetalert2";
import LazyLoad from "react-lazyload";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { gridSpanIEStyle } from "../../style";
import VideoJs from "../shared/VideoJs";
import { useState } from "react";
import { useEffect } from "react";
import { getKitLectures } from "../../api";
import { color } from "../../tailwindStyle";

SwiperCore.use([Pagination]);

const tagStyle = css`
  padding: 0.25rem 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 1.5rem;
  background-color: #fe9d1f;
  font-size: clamp(5px, 3.194vw, 16px);
  font-weight: bold;
  @media (min-width: ${MOBILE_MAX_WIDTH}) {
    border: 2px solid #454545;
    font-weight: normal !important;
  }
`;

const kitClassSwiper = css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    padding-bottom: 0px !important;
    .swiper-pagination {
      display: none;
    }
  }

  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    padding-bottom: 85px;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin: 0 7px;
      background-color: #d8c890;
      opacity: 1 !important;
    }
    .swiper-pagination-bullet-active {
      background-color: ${color.gray800} !important;
    }
  }
`;

const TagElement = ({ text }) => {
  return <div css={tagStyle}>{text}</div>;
};

const KitClass = () => {
  const [players, setPlayers] = useState([]);
  const deviceCheck = useDeviceCheck();

  const [kitLectures, setKitLectures] = useState(null);
  useEffect(() => {
    getKitLectures().then((response) => {
      setKitLectures(response?.data);
    });
  }, []);

  return (
    <div className="pt-8 my-12 bg-[#ffeba5] web:mt-17 tablet:mt-17 mobile:mt-10 pb-9">
      <div className="w-full bg-[#ffeba5] default_margin">
        <KitClassTitle />
        <Swiper
          slidesPerView={
            deviceCheck === "web" || deviceCheck === "tablet" ? 1 : 1.15
          }
          spaceBetween={deviceCheck === "mobile" && 15}
          css={kitClassSwiper}
          pagination={
            (deviceCheck === "web" || deviceCheck === "tablet") && {
              clickable: true,
            }
          }
          onSlideChange={(swiper) => {
            players.map((player) => player.pause());
          }}
        >
          {kitLectures?.map((classList) => {
            return (
              <SwiperSlide key={classList?.id}>
                <div
                  className="grid text-gray800 web:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 web:gap-12 tablet:gap-12 mobile:gap-4"
                  css={gridSpanIEStyle}
                >
                  <LazyLoad once>
                    <div
                      css={css`
                        float: none;
                        clear: both;
                        width: 100%;
                        position: relative;
                        height: 0;
                        padding-bottom: 56.25%;
                      `}
                    >
                      <VideoJs
                        options={{
                          preload: "none",
                          autoplay: false,
                          controls: true,
                          responsive: true,
                          fluid: true,
                          sources: [
                            {
                              src: classList?.promotion_video_url,
                              type: "video/vimeo",
                            },
                          ],
                        }}
                        setPlayers={setPlayers}
                      />
                    </div>
                  </LazyLoad>
                  <div className="flex-col">
                    <a href={`/lectures/${classList?.id}`}>
                      <div
                        className="grid grid-cols-3 web:flex tablet:flex"
                        css={css`
                          white-space: nowrap;
                        `}
                      >
                        {classList?.ordered_tag_list?.map((tag, index) => {
                          return <TagElement text={tag} key={index} />;
                        })}
                      </div>
                      <div className="mt-2 mb-4 font-bold web:text-2xl tablet:text-2xl mobile:text-xl">
                        {classList?.title}
                      </div>
                      <div className="font-medium leading-normal web:text-lg tablet:text-base mobile:text-sm">
                        {classList?.short_desc}
                      </div>
                    </a>
                    <button
                      className="main_free_class items-center justify-center inline-block mt-4 text-white web:text-lg tablet:text-base mobile:text-sm rounded-3xl bg-yellow900"
                      css={css`
                        padding: 0.25rem 2rem;
                      `}
                      onClick={() => {
                        location.href = classList?.promotion_lesson_link;
                      }}
                    >
                      1강 무료체험 하기
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default KitClass;
