const FaqAboutClass = ({ event_faqs, precaution, refund_rule }) => {
  const FaqBox = ({ faq }) => {
    return (
      <div className="tablet:mt-[27px] mobile:mt-[27px]">
        <div className="flex tablet:flex-col mobile:flex-col web:items-center web:mt-[37px]">
          <span className="text-white font-bold web:text-[24px] web:mr-[37px]">
            Q {faq?.position}
          </span>
          <span className="text-white font-bold mt-[4px] web:text-[20px]">
            {faq?.title}
          </span>
        </div>
        <div className="web:font-medium rounded-[7px] web:px-[42px] web:py-[37px] tablet:py-[26px] tablet:px-[10px] mobile:py-[26px] mobile:px-[10px] tablet:text-sm mobile:text-sm font-medium bg-white mt-[11px]">
          <span dangerouslySetInnerHTML={{ __html: faq?.content }}></span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mt-[87px] web:py-[95px] web:w-full web:max-w-[1361px] tablet:w-screen mobile:w-screen bg-gray800 tablet:pt-[37px] tablet:pb-[62px] mobile:pt-[37px] mobile:pb-[62px] mx-auto">
        <div className="flex flex-col default_margin tablet:max-w-[640px] mobile:max-w-[640px] mx-auto">
          <span className="font-bold web:text-[26px] text-white">FAQ</span>
          <span className="font-bold web:text-[30px] text-white">
            라이브 클래스, 이런게 궁금해요.
          </span>

          {event_faqs?.map((faq) => {
            return <FaqBox key={faq?.id} faq={faq} />;
          })}
        </div>
      </div>
      <div className="web:py-[95px] web:w-full web:max-w-[1361px] tablet:w-screen mobile:w-screen web:bg-gray25 tablet:bg-gray100 mobile:bg-gray100 tablet:pt-[37px] tablet:pb-[62px] mobile:pt-[37px] mobile:pb-[62px] mx-auto text-gray700">
        <div className="flex flex-col default_margin tablet:max-w-[640px] mobile:max-w-[640px] mx-auto">
          <span className="web:text-[26px] font-bold">유의사항</span>
          <div className="font-medium tablet:text-xs mobile:text-xs web:mt-[47px] tablet:mt-[17px] mobile:mt-[17px]">
            <span dangerouslySetInnerHTML={{ __html: precaution }}></span>
          </div>
          <div className="flex items-center justify-between tablet:mt-[37px] mobile:mt-[37px] web:mt-[59px]">
            <span className="web:text-[26px] font-bold">환불 규정</span>
          </div>
          <span className="font-medium tablet:text-xs mobile:text-xs web:mt-[47px] tablet:mt-[17px] mobile:mt-[17px]">
            <span dangerouslySetInnerHTML={{ __html: refund_rule }}></span>
          </span>
        </div>
      </div>
    </>
  );
};

export default FaqAboutClass;
