import { useState, createContext } from "react";
import {
  GoBackBtn,
  RatingSection,
  ReviewAll,
  ReviewModal,
} from "../components/review/index";
import Layout from "../components/Layout";

export const ModalContext = createContext(null);

const Reviews = (props) => {
  const [metaRating, setMetaRating] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ratingAvg, setRatingAvg] = useState(null);
  const [selectReview, setSelectReview] = useState(null);

  return (
    <ModalContext.Provider
      value={{
        setSelectReview: setSelectReview,
        selectReview: selectReview,
        isModalOpen: isModalOpen,
        setIsModalOpen: setIsModalOpen,
      }}
    >
      <Layout>
        <div className="flex flex-col mx-auto max-w-[640px] w-[91.8vw]">
          <GoBackBtn lectureId={props?.targetId} />
          <span className="text-lg mt-[31.6px] mb-[17.6px] font-bold">
            별점 평균
          </span>

          <RatingSection
            metaRating={metaRating}
            ratingAvg={ratingAvg}
            targetId={props?.targetId}
            targetType={props?.targetType}
          />

          {/* <LectureTitle text="베스트 리뷰" />
			        <ReviewSwiper selectReview={selectReview}/> */}

          <ReviewAll
            targetId={props?.targetId}
            targetType={props?.targetType}
            setRatingAvg={setRatingAvg}
            setMetaRating={setMetaRating}
          />
        </div>
      </Layout>
      <ReviewModal />
    </ModalContext.Provider>
  );
};

export default Reviews;
