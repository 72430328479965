import { css } from "@emotion/react";
import { RankSwiperTitle } from "./SectionTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import LazyLoad from "react-lazyload";
import { times } from "lodash";

import top1 from "../../assets/icons/rank/top1.svg";
import top2 from "../../assets/icons/rank/top2.svg";
import top3 from "../../assets/icons/rank/top3.svg";
import top4 from "../../assets/icons/rank/top4.svg";
import top5 from "../../assets/icons/rank/top5.svg";
import starFull from "../../assets/icons/star_rank/starFull.svg";
import starHalf from "../../assets/icons/star_rank/starHalf.svg";
import starEmpty from "../../assets/icons/star_rank/starEmpty.svg";
import { MOBILE_MAX_WIDTH, TABELT_MAX_WIDTH } from "../../constant";
import { changeToSchoolAge } from "../../utils";
import { useState, useEffect } from "react";
import { getRatedLectures } from "../../api";

const RankImg = ({ src, index, min_age, max_age }) => {
  let url;
  if (index === 0) {
    url = top1;
  } else if (index === 1) {
    url = top2;
  } else if (index === 2) {
    url = top3;
  } else if (index === 3) {
    url = top4;
  } else if (index === 4) {
    url = top5;
  } else {
    url = null;
  }

  const rankImgParentStyle = css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      max-height: 271px;
    }

    position: relative;
    &::after {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        display: none;
      }
      position: absolute;
      background: url(${url});
      width: 15%;
      left: 75%;
      top: 0;
      content: "";
      height: 100%;
      background-repeat: no-repeat;
    }
  `;

  const rankChildStyle = css`
    &::before {
      z-index: -1;
      position: absolute;
      content: "";
      background-color: black;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      left: 50%;
      transform: translate(-50%);
    }
  `;

  return (
    <div css={rankImgParentStyle}>
      <img src={src} alt={`랭킹${index}`} className="rounded-[10px]" />
      <div
        className="absolute text-white bottom-[11px] right-[6px] z-[3] px-1 web:text-sm tablet:text-xs mobile:text-xs"
        css={rankChildStyle}
      >
        {changeToSchoolAge(min_age)} ~ {changeToSchoolAge(max_age)}
      </div>
    </div>
  );
};

const StarRankGroup = ({
  value,
  webSize,
  mobileSize,
  widthRatio,
  gapRatio,
}) => {
  //빈별 => 0, 반별 => 1, 풀별 => 2;
  const starStyle = css`
    @media (min-width: ${TABELT_MAX_WIDTH}px) {
      width: ${webSize}px;
    }
    @media (max-width: ${TABELT_MAX_WIDTH - 1}px) and (min-width: ${MOBILE_MAX_WIDTH}px) {
      width: ${(parseInt(webSize) + parseInt(mobileSize)) / 2 - 2}px;
    }
    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      width: ${mobileSize}px;
    }

    height: auto;
  `;

  const starGroupStyle = css`
    width: ${widthRatio};
    .starImg + .starImg {
      margin-left: ${gapRatio};
    }
  `;

  const mappingStar = (value) => {
    const doubleRoundValue = Math.ceil(value * 2) / 2;
    const fullStar = doubleRoundValue / 1;
    let halfStar;
    if (doubleRoundValue % 1 === 0.5) {
      halfStar = 1;
    } else {
      halfStar = 0;
    }
    const emptyStar = 5 - fullStar - halfStar;

    return (
      <>
        {times(fullStar).map((e, i) => {
          return (
            <img
              src={starFull}
              css={starStyle}
              className="starImg"
              key={`starfull${i}`}
            />
          );
        })}
        {times(halfStar).map((e, i) => {
          return (
            <img
              src={starHalf}
              css={starStyle}
              className="starImg"
              key={`halfFull${i}`}
            />
          );
        })}
        {times(emptyStar).map((e, i) => {
          return (
            <img
              src={starEmpty}
              css={starStyle}
              className="starImg"
              key={`emptyfull${i}`}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="flex" css={starGroupStyle}>
      {mappingStar(value)}
    </div>
  );
};

const RankSwiper = () => {
  let deviceCheck = useDeviceCheck();

  const [ranks, setRanks] = useState(null);
  useEffect(() => {
    getRatedLectures().then((response) => {
      setRanks(response?.data);
    });
  }, []);

  return (
    <div className="w-full default_margin web:mt-17 tablet:mt-17 mobile:mt-10">
      <RankSwiperTitle />
      <Swiper
        spaceBetween={20}
        slidesPerView={
          deviceCheck === "web" || deviceCheck === "tablet" ? 5 : 2
        }
      >
        {ranks?.map((rank, index) => {
          return (
            <SwiperSlide key={`star${rank?.id}`}>
              <div
                className="flex flex-col cursor-pointer"
                onClick={() => (location.href = `/lectures/${rank?.id}`)}
              >
                <LazyLoad height={270} once>
                  <RankImg
                    src={rank?.main_square_image_url}
                    index={index}
                    min_age={rank?.min_age}
                    max_age={rank?.max_age}
                  />
                </LazyLoad>

                <div className="flex items-center justify-between my-[1vh]">
                  <StarRankGroup
                    value={rank?.avg_rating || 1}
                    webSize="20"
                    mobileSize="16"
                    widthRatio="70%"
                    gapRatio="6px"
                  />
                  <div className="font-medium web:text-lg tablet:text-lg">
                    {rank?.avg_rating.toFixed(1)}
                  </div>
                </div>

                <div className="inline-block web:text-lg tablet:text-lg">
                  {rank?.title}
                </div>
                <div className="text-gray500 web:text-lg tablet:text-lg mobile:text-sm">
                  {rank?.teacher_name}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default RankSwiper;
