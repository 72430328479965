import { css } from "@emotion/react";
import Label from "./Label";
import starFull from "../../assets/icons/star_full.svg";
import { forEach } from "lodash";
import { changeToSchoolAge } from "../../utils";

const LectureCard = ({ lecture }) => {
  const CalRating = () => {
    if (lecture?.reviews?.total_count === 0) {
      return "리뷰가 없습니다";
    } else {
      let rating = 0;
      forEach(lecture?.reviews?.rating_count, (key, value) => {
        rating += key * parseInt(value);
      });
      return (rating / lecture?.reviews?.total_count).toFixed(1);
    }
  };

  return (
    <div className="flex flex-col">
      {lecture?.object_type === "futureVod" &&
        (lecture?.coming_text !== "" ? (
          <span className="text-purple500 tablet:text-sm mobile:text-sm mb-[10px] font-medium">
            {lecture?.coming_text}
          </span>
        ) : (
          <div className="tablet:text-sm mobile:text-sm mb-[10px] font-medium">
            &nbsp;&nbsp;&nbsp;
          </div>
        ))}

      <button
        disabled={lecture?.object_type === "futureVod" ? true : false}
        type="button"
        onClick={() =>
          (location.href = `/${lecture?.lecture_type}/${lecture?.id}`)
        }
        className="relative text-left"
      >
        <div className="rounded-[5px] flex flex-col">
          <div className="relative">
            <img
              src={lecture?.img_url}
              className={`w-full object-cover aspect-[570/657]`}
              alt="강의썸네일"
            />

            {lecture?.object_type === "lecturesKids" && (
              <div
                className="absolute text-white bottom-[11px] right-[6px] z-[3] px-1 web:text-sm tablet:text-xs mobile:text-xs"
                css={css`
                  &::before {
                    z-index: -1;
                    position: absolute;
                    content: "";
                    background-color: black;
                    opacity: 0.6;
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                    left: 50%;
                    transform: translate(-50%);
                  }
                `}
              >
                {changeToSchoolAge(lecture?.min_age)} ~{" "}
                {changeToSchoolAge(lecture?.max_age)}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            {lecture?.object_type !== "futureVod" && (
              <div className="flex items-center web:text-sm tablet:text-xs mobile:text-xs mt-[5px]">
                {lecture?.object_type === "lecturesKids" && (
                  <Label
                    isFinished={lecture?.is_finish}
                    status={lecture?.status}
                    objectType={lecture?.object_type}
                  />
                )}
                {lecture?.reviews?.total_count > 0 && (
                  <>
                    <img
                      src={starFull}
                      alt="강의별점"
                      className="web:w-[13px] web:h-[13px] tablet:w-[11px] tablet:h-[11px] mobile:w-[11px] mobile:h-[11px]"
                    />
                    <span className="mx-1">{CalRating()}</span>
                    <span>
                      {lecture?.reviews?.total_count !== 0
                        ? `(${lecture?.reviews?.total_count})`
                        : ""}
                    </span>
                  </>
                )}
              </div>
            )}

            <span className="font-bold truncate web:text-sm tablet:text-xs mobile:text-xs mt-[5px]">
              {lecture?.teacher_name}
            </span>
            <span className="mb-[5px] font-bold truncate web:text-sm tablet:text-xs mobile:text-xs text-yellow800">
              {lecture?.ordered_tag_list}
            </span>
            <span
              className="font-medium line-ellipsis tablet:text-sm mobile:text-sm"
              css={css`
                -webkit-line-clamp: 2;
              `}
            >
              {lecture?.title}
            </span>
          </div>
        </div>
        {lecture?.object_type !== "lecturesKids" && (
          <div className="absolute top-[7px] left-[8px]">
            <Label isFinished={lecture?.is_finish} status={lecture?.status} />
          </div>
        )}
      </button>
    </div>
  );
};

export default LectureCard;
