import ProgressBar from "./ProgressBar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import moment from "moment";
import { getLessonDownloads } from "../../api";
import Popup from "reactjs-popup";
import closeSm from "../../assets/icons/close_sm.svg";

import { useState } from "react";
import DownloadComponent from "../shared/DownloadComponent";

const MyLectureCard = ({ lecture }) => {
  const start_at = moment(lecture?.start_at).format("YYYY.MM.DD");
  const end_at = moment(lecture?.end_at).format("YYYY.MM.DD");
  const [textbooks, setTextbooks] = useState(null);

  const hasDelivery = lecture?.has_delivery;
  const hasDownload = lecture?.has_download;

  return (
    <>
      <div className="flex flex-col bg-white border-2 rounded-lg card border-gray100 p-3 h-fit">
        <div
          className="grid grid-cols-1 cursor-pointer"
          onClick={() =>
            (location.href = `/lessons/${lecture?.current_lesson?.id}`)
          }
        >
          <LazyLoadImage src={lecture?.image?.url} effect="blur" />
          <div className="flex flex-col">
            <span className="w-full overflow-hidden font-bold truncate mt-[13px]">
              {lecture?.title}
            </span>
            <div className="px-2 mobile:px-3 text-xs rounded-[4px] text-gray400 bg-gray100 w-fit my-[13px]">
              마지막으로 들은 수업
            </div>
            <div className="mb-1 text-sm font-bold truncate card_content__lecture-name text-gray400">
              {lecture?.current_lesson?.title}
            </div>
            {lecture?.id === 40 || lecture?.id === 45 ? (
              <div className="h-5 mb-1 text-xs leading-5 truncate text-gray500">
                해당 강의는 진행률을 확인할 수 없습니다.
              </div>
            ) : (
              <ProgressBar
                value={lecture?.class_progress}
                placeholderText={`클래스 진행률 ${lecture?.class_progress}%`}
              />
            )}

            {lecture?.has_mission ? (
              <ProgressBar
                value={lecture?.mission_progress}
                placeholderText={`미션 진행률 ${lecture?.mission_progress}%`}
              />
            ) : (
              <div className="h-5 my-[2px] text-xs leading-5 text-gray700">
                미션이 없는 강의 입니다.
              </div>
            )}
            <div className="mt-[7px] text-xs text-gray400">
              이용기간 : {start_at}~{end_at}
            </div>
          </div>
        </div>

        {/* 버튼모음 */}
        <div className="flex flex-col">
          {hasDelivery ? (
            <div>
              <a
                href={`/lectures/${lecture?.id}`}
                className="text-white w-full font-bold rounded-[7px] bg-yellow600 py-2 flex justify-center items-center web:mt-[18px] tablet:mt-[15px] mobile:mt-[15px] lecture_btn text-sm"
              >
                배송품 주문하기
              </a>
            </div>
          ) : (
            <div className="text-white w-full font-bold rounded-[7px] bg-gray500 py-2 flex justify-center items-center web:mt-[18px] tablet:mt-[15px] mobile:mt-[15px] lecture_btn text-sm">
              추가 배송품이 없습니다.
            </div>
          )}

          {hasDownload ? (
            <Popup
              trigger={
                <button
                  type="button"
                  className="lecture_btn text-yellow600 w-full font-bold rounded-[7px] border border-yellow600 py-2 flex justify-center items-center text-sm mt-1"
                >
                  강의 자료 다운
                </button>
              }
              onOpen={() => {
                getLessonDownloads({ lectureId: lecture?.id }).then(
                  (response) => {
                    setTextbooks(response?.data);
                  }
                );
              }}
              modal
              nested
              overlayStyle={{
                background: "rgba(0, 0, 0, 0.75)",
              }}
              lockScroll
            >
              {(close) => (
                <div className="flex flex-col pt-[26px] pb-2 px-[35px] bg-white max-w-[500px] web:w-[500px] tablet:w-[90vw] mobile:w-[90vw] rounded-[12px] overflow-auto max-h-[427px]">
                  <div className="flex justify-between w-full mb-[27px]">
                    <span className="text-lg font-bold">강의 자료 다운</span>
                    <button type="button" onClick={close}>
                      <img
                        src={closeSm}
                        alt="닫기"
                        className="w-[13px] h-[13px]"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col">
                    {!!textbooks &&
                      textbooks?.length > 0 &&
                      textbooks?.map((textbook) => {
                        return (
                          <DownloadComponent
                            key={textbook?.id}
                            textbook={textbook}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
            </Popup>
          ) : (
            <div className="text-white w-full font-bold rounded-[7px] bg-gray500 py-2 flex justify-center items-center lecture_btn text-sm mt-1">
              강의 자료가 없습니다.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyLectureCard;
