import arrowDownSharp from "../../assets/icons/board/arrow_down_sharp.svg";
import arrowUpSharp from "../../assets/icons/board/arrow_up_sharp.svg";
import { useState } from "react";
import { css } from "@emotion/react";

const FaqAccordion = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  const transitionActive = css`
    transition: all 0.2s ease-out;
  `;

  return (
    <div className="item">
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={
          isOpen
            ? "border-b border-b-gray100 h-[58px] w-full pl-[21px] pr-[24px] flex items-center justify-between py-[10px]"
            : "h-[58px] w-full pl-[21px] pr-[24px] flex items-center justify-between py-[10px]"
        }
      >
        <span className="text-sm font-medium">{faq?.title}</span>
        <img
          src={isOpen ? arrowUpSharp : arrowDownSharp}
          alt="FAQ여닫기"
          className="w-[10px]"
        />
      </div>
      <div
        css={transitionActive}
        className={
          isOpen
            ? "transitionActive px-[19px] pt-[30px] pb-[28px] h-auto"
            : "transitionActive h-0 overflow-hidden"
        }
      >
        {faq?.content}
      </div>
    </div>
  );
};

export default FaqAccordion;
