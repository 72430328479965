import React, { createContext, useState, useEffect, useCallback } from "react";
import { css } from "@emotion/react";
import Drawer from "react-bottom-drawer";

import useDeviceCheck from "../hooks/useDeviceCheck";
import { MOBILE_MAX_WIDTH, TABELT_MAX_WIDTH, WEB_MAX_WIDTH } from "../constant";
import {
  Content,
  DrawerContent,
  SideBar,
  FooterMobile,
  ContentRenewal,
} from "../components/lectureShow/index";
import ReviewShow from "../components/showReview/ReviewShow";
import Layout from "../components/Layout";
import { getHasPurchased, getLecture } from "../api";
import { useUserSignedIn } from "../hooks/useUserSignedIn";
import LectureOrderBody from "../components/lectureShow/LectureOrderBody";

export const DrawerContext = createContext(null);

const lectureShowParentStyle = css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    display: flex;
    div:nth-of-type(1) {
      max-width: 891px;
    }
  }

  @media (min-width: ${TABELT_MAX_WIDTH}px) and (max-width: ${WEB_MAX_WIDTH -
    1}px) {
    padding: 0 15px;
  }

  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    display: grid;
    grid-template-columns: 74.25% 23.91%;
    gap: 29px;
    max-width: ${WEB_MAX_WIDTH}px;
    min-width: 350px;
    margin: 0 auto;
  }
`;

const Show = (props) => {
  const { isUserSignedIn } = useUserSignedIn();
  const [isLoading, setIsLoading] = useState(true);

  const deviceCheck = useDeviceCheck();
  //review 모달 컨트롤 state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectReview, setSelectReview] = useState(null);

  //공유하기 Drawer에 관련된 State
  const [isVisible, setIsVisible] = useState(false);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const openDrawer = useCallback(() => setIsVisible(true), []);

  //액션시트 Drawer에 관련된 State
  const [isSheetVisible, setIsSheetVisible] = useState(false);
  const closeSheetDrawer = useCallback(() => setIsSheetVisible(false), []);
  const openSheetDrawer = useCallback(() => setIsSheetVisible(true), []);
  const [lecture, setLecture] = useState(null);
  useEffect(() => {
    getLecture({
      lectureId: props?.lectureId,
      query: {
        slug: props?.slug,
      },
    }).then((response) => {
      setLecture(response?.data);
      setIsLoading(false);
    });
  }, []);

  //modal 열렸을때 스크롤 금지
  useEffect(() => {
    isModalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isModalOpen]);

  useEffect(() => {
    if (!!lecture) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_name: lecture?.title,
              item_id: lecture.id,
              price: Number(lecture?.price),
              item_brand: null,
              item_category: null,
              item_categroy2: null,
              item_category3: null,
              currency: "KRW",
            },
          ],
        },
      });
    }
  }, [lecture]);

  const [hasPurchased, setHasPurchased] = useState(null);
  useEffect(() => {
    if (!!props?.lectureId) {
      if (isUserSignedIn) {
        getHasPurchased({ lectureId: props?.lectureId }).then((response) => {
          setHasPurchased(response?.data);
        });
      }
    }
  }, [props?.lectureId, isUserSignedIn]);

  return (
    !isLoading && (
      <>
        <DrawerContext.Provider
          value={{
            openDrawer: openDrawer,
            closeDrawer: closeDrawer,
            openSheetDrawer: openSheetDrawer,
            closeSheetDrawer: closeSheetDrawer,
          }}
        >
          <Layout>
            <hr className="w-full" />
            <div css={lectureShowParentStyle} className="tablet:px-[15px]">
              {lecture?.content_files?.length === 0 ? (
                <Content
                  lecture={lecture}
                  hasPurchased={hasPurchased}
                  slug={props.slug}
                  setSelectReview={setSelectReview}
                  setIsModalOpen={setIsModalOpen}
                />
              ) : (
                <ContentRenewal lecture={lecture} />
              )}

              {(deviceCheck === "web" || deviceCheck === "tablet") && (
                <SideBar
                  lecture={lecture}
                  slug={props.slug}
                  hasPurchased={hasPurchased}
                />
              )}
            </div>
            {deviceCheck === "mobile" && (
              <FooterMobile lecture={lecture} hasPurchased={hasPurchased} />
            )}
          </Layout>

          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
          >
            <DrawerContent lecture={lecture} />
          </Drawer>

          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeSheetDrawer}
            isVisible={isSheetVisible}
            className="lectureActionSheetStyle"
          >
            <LectureOrderBody
              {...lecture}
              close={closeSheetDrawer}
              slug={props.slug}
              orderableType="Lecture"
            />
          </Drawer>
        </DrawerContext.Provider>

        <ReviewShow
          selectReview={selectReview}
          modalIsOpen={isModalOpen}
          setModalIsOpen={setIsModalOpen}
        />
      </>
    )
  );
};

export default Show;
