import { css } from "@emotion/react";
import { lectureDefaultMargin } from "../../style";
import sharedIcon from "../../assets/icons/sharing.svg";
import { useContext } from "react";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { DrawerContext } from "../../pages/LectureShow";
import CautionWeb from "./CautionWeb";
import CautionMobile from "./CautionMobile";
import { color } from "../../tailwindStyle";

const buttonFreeLecture = css`
  color: white;
  background-color: ${color["blue-base"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0 13px;
  line-height: 2.11;
  white-space: nowrap;
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    width: 120px;
    height: auto;
  }

  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 14px;
    margin-left: 1rem;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    font-size: 12.5px;
  }
`;

const CautionAndTitle = (props) => {
  const { deviceCheck, tag_lists, title, free_link, hasAuth } = props;

  const drawerValue = useContext(DrawerContext);

  return (
    <div css={lectureDefaultMargin}>
      <div className="flex mt-5 mobile:items-center mobile:justify-center">
        {tag_lists.map((text, index) => {
          return (
            <span
              className="text-[13px] text-gray800 flex items-center justify-center rounded-[12px] border border-gray800 mr-2 px-[13px] whitespace-nowrap"
              key={index}
            >
              {text}
            </span>
          );
        })}
        {deviceCheck === "mobile" && (
          <button
            id="mobileExternalSharing"
            className="w-5 ml-auto"
            onClick={drawerValue.openDrawer}
          >
            <img src={sharedIcon} alt="공유버튼웹" />
          </button>
        )}
      </div>
      {/* 타이틀 */}
      <div className="flex items-center mt-4">
        <span className="text-2xl font-bold text-gray800">{title}</span>
        {/* 1강 무료체험 비활성화 */}
        {(deviceCheck === "web" || deviceCheck === "tablet") &&
          !!free_link &&
          !hasAuth && (
            <button css={buttonFreeLecture}>
              <a href={free_link} className="class_free_class">
                1강 무료체험 하기
              </a>
            </button>
          )}
      </div>

      {deviceCheck === "web" || deviceCheck === "tablet" ? (
        <CautionWeb {...props} />
      ) : (
        <CautionMobile {...props} />
      )}
    </div>
  );
};

export default CautionAndTitle;
