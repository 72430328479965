import { useState, useEffect, createContext, useCallback } from "react";
import { css } from "@emotion/react";

import { getPackageInfo, postOrderOption } from "../../api";
import { useDiffTime } from "../../hooks/useDiffTime";
import Layout from "../../components/Layout";
import { Content } from "../../components/cooingklePackage/Content";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { addComma, setReturnSession } from "../../utils";

export const DrawerContext = createContext(null);

const handleSubmit = (packageInfo) => {
  const orderParams = {
    order: {
      orderable_type: "Package",
      orderable_id: 3,
      order_type: "delivery",
      price: packageInfo?.main_option_price, //임시
      slug: packageInfo?.slug,
      options: !!packageInfo?.option_lists
        ? packageInfo?.option_lists.map((option_list) => {
            return {
              option_list_id: option_list.id,
              quantity: 0,
              ...option_list,
            };
          })
        : null,
    },
  };

  if (packageInfo?.has_purchased) {
    //이미 구입했으면
    Swal.fire({
      icon: "warning",
      title: "이미 구입한 상품입니다.",
    });
  } else {
    //구입하지 않았으면
    postOrderOption(orderParams)
      .then((response) => {
        location.href = `/orders/${response?.data?.order_id}`;
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "오류가 발생했습니다.",
          text: "다시 시도해주세요.",
        });
      });
  }
};

const GoOrderBtn = ({ packageInfo, isLoading, isDochimom }) => {
  const { isUserSignedIn } = useUserSignedIn();
  const { duration, days, hours, minutes, seconds } = useDiffTime({
    endTimeDate: isDochimom
      ? new Date(2023, 1, 3, 23, 59, 59)
      : packageInfo?.price_end_at,
    trigger: packageInfo,
  });

  return isUserSignedIn
    ? !isLoading && (
        <div
          // onClick={() => handleSubmit(packageInfo)}
          type="button"
          id="cooingkle_package-cta_bottom"
          className="w-full fixed bottom-0 max-w-[650px] w-full h-[81px] flex flex-col items-center justify-center bg-[#FFFFFF]"
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
          `}
        >
          <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
            {/* <div>
              월{" "}
              {addComma(
                Math.ceil(Number(packageInfo?.main_option_price / 12 / 100)) *
                  100
              )}
              원에 수강신청하기 {`>`}
            </div> */}
            <div>구매 불가능한 상품입니다</div>
          </div>
        </div>
      )
    : !isLoading && (
        <div
          // onClick={() => setReturnSession()}
          type="button"
          id="cooingkle_package-cta_bottom"
          className="w-full fixed bottom-0 max-w-[650px] w-full h-[81px] flex flex-col items-center justify-center bg-[#FFFFFF]"
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
          `}
        >
          <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
            {/* 월{" "}
            {addComma(
              Math.ceil(Number(packageInfo?.main_option_price / 12 / 100)) * 100
            )}
            원에 수강신청하기 {`>`} */}
            <div>구매 불가능한 상품입니다</div>
          </div>
        </div>
      );
};
const CooingklePackage = (props) => {
  const [packageInfo, setPackageInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //액션시트 Drawer에 관련된 State
  const [isSheetVisible, setIsSheetVisible] = useState(false);
  const closeSheetDrawer = useCallback(() => setIsSheetVisible(false), []);
  const openSheetDrawer = useCallback(() => setIsSheetVisible(true), []);

  useEffect(() => {
    getPackageInfo({ packageClassId: 3, slug: props?.slug }).then(
      (response) => {
        setPackageInfo(response?.data);
        setIsLoading(false);
      }
    );
  }, []);

  const [isDochimom, setIsDochimom] = useState(
    props?.slug?.includes("dochimom") ? true : false
  );

  return (
    <DrawerContext.Provider
      value={{
        openSheetDrawer: openSheetDrawer,
        closeSheetDrawer: closeSheetDrawer,
      }}
    >
      <Layout>
        <Content
          handleSubmit={handleSubmit}
          packageInfo={packageInfo}
          isDochimom={isDochimom}
        />
        <GoOrderBtn
          packageInfo={packageInfo}
          isLoading={isLoading}
          isDochimom={isDochimom}
        />
      </Layout>
    </DrawerContext.Provider>
  );
};

export default CooingklePackage;
