import { UsersInput } from "./UsersInput";
import { UsersInputLabel } from "./UsersInputLabel";
import { UsersParent } from "./UsersParent";
import { UsersTitle } from "./UsersTitle";
import { useFormik } from "formik";
import { useState, useEffect, useRef } from "react";
import iziToast from "izitoast";
import confirmSuccess from "../../assets/icons/confirm_success";
import { AmplifySignIn } from "../../hooks/useAmplifyhook";
import arrowRGray500 from "../../assets/icons/arrow_right_gray500";
import UserPolicy from "../shared/UserPolicy";
import { StyledPopup } from "../shared/StyledPopup";
import PrivatePolicy from "../shared/PrivatePolicy";
import Drawer from "react-bottom-drawer";
import { useCallback } from "react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import {
  getAhoyAuthenticateUser,
  getEmailExist,
  getPhoneConfirmCheck,
  postPhoneNumber,
  postSignup,
} from "../../api";
import * as Yup from "yup";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import Swal from "sweetalert2";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import useRemainTimer from "../../hooks/useRemainTimer";
import moment from "moment";
import { keys, trim } from "lodash";
import * as awsAllowedCountries from "../../awsAllowedCountries.json";
import { useContext } from "react";
import { LayoutContext } from "../Layout";

export const Signup = ({ identify }) => {
  const { isUserSignedIn } = useUserSignedIn();

  const [userType, setUserType] = useState("parents");

  const deviceCheck = useDeviceCheck();
  const [phoneConfirm, setPhoneConfirm] = useState("");
  const [isPhoneConfirm, setIsPhoneConfirm] = useState(false);

  const [isReceiveInputOpen, setIsReceiveInputOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [country, setCountry] = useState(null);

  const [clearTrigger, setClearTrigger] = useState(null);
  const remainTime = useRemainTimer(moment().add(3, "minutes"), clearTrigger);

  const emailRef = useRef(null);

  const signUpValidationSchema = Yup.object().shape({
    username: Yup.string()
      .max(6, "이름은 6글자를 초과 할 수 없습니다.")
      .required("이름은 필수 입니다."),
    phoneConfirmId: Yup.number().required("번호 인증은 필수 입니다"),
    email: Yup.string()
      .trim()
      .email("이메일 형식이 아닙니다.")
      .required("이메일은 필수 입니다."),
    isEmailConfirm: Yup.bool().isTrue("이미 존재하는 이메일입니다."),
    pw: Yup.string()
      .min(6, "6글자 이상으로 입력해주세요.")
      .required("비밀번호는 필수입니다."),
    pwConfirm: Yup.string()
      .oneOf(
        [Yup.ref("pw")],
        "비밀번호가 일치하지 않습니다. 다시 확인해주세요."
      )
      .min(6, "6글자 이상으로 입력해주세요.")
      .required("비밀번호 확인을 해주셔야 합니다."),
    isOver14: Yup.bool().isTrue("만 14세 이상만 가입 가능합니다."),
    isPolicy: Yup.bool().isTrue("필수 항목을 체크해주셔야 합니다."),
    isPrivate: Yup.bool().isTrue("필수 항목을 체크해주셔야 합니다."),
  });

  useEffect(() => {
    if (isUserSignedIn) location.href = "/";
  }, [isUserSignedIn]);

  const signupFormik = useFormik({
    initialValues: {
      isValid: "false",
      username: "",
      phoneConfirmId: "",
      email: identify?.response?.info?.email || "",
      isEmailConfirm: null,
      pw: "",
      pwConfirm: "",
      isPolicy: false,
      isPrivate: false,
      isOver14: false,
      isOptional: false,
    },
    enableReinitialize: true,
    validationSchema: signUpValidationSchema,

    onSubmit: (values, { setSubmitting }) => {
      if (values.isValid) {
        postSignup({
          name: values.username,
          style: "parents",
          email: trim(values.email),
          isMarketing: values.isOptional,
          phoneConfirmId: values.phoneConfirmId,
          countryCode: "+" + country,
          password: values.pw,
          identifyId: identify?.id,
        })
          .then(() => {
            signUpEvent();
            AmplifySignIn({
              email: values.email,
              password: values.pw,
            })
              .then(() => {
                getAhoyAuthenticateUser();
                window.location.href = "/users/more_info";
              })
              .catch(() => {
                setSubmitting(false);
                Swal.fire({
                  icon: "error",
                  title: "잠시 후 다시 시도해주세요.",
                  text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
                  confirmButtonText: "확인",
                });
              });
          })
          .catch(() => {
            setSubmitting(false);
          });
      } else {
        Swal.fire({
          icon: "error",
          text: "잘못된 형식의 번호입니다.",
          confirmButtonText: "확인",
        });
      }
    },
  });

  useEffect(() => {
    if (!!identify?.response?.info?.email) {
      emailRef.current.blur();
    }
  }, [identify]);

  const { navHeight } = useContext(LayoutContext);

  useEffect(() => {
    if (
      !signupFormik.isValid &&
      signupFormik.submitCount !== 0 &&
      signupFormik.isSubmitting
    ) {
      const errorsArray = keys(signupFormik?.errors);
      for (let i = 0; i < errorsArray.length; i++) {
        switch (errorsArray[i]) {
          case "isEmailConfirm":
            document.getElementById("email")?.focus();
            window.scrollTo(
              0,
              document.getElementById("email")?.getBoundingClientRect().top +
                window.pageYOffset -
                navHeight -
                30
            );
            break;
          case "phoneConfirmId":
            document.getElementById("phoneNumber")?.focus();
            window.scrollTo(
              0,
              document.getElementById("phoneNumber")?.getBoundingClientRect()
                .top +
                window.pageYOffset -
                navHeight -
                30
            );
            break;
          case "isPolicy":
            continue;
          case "isPrivate":
            continue;
          default:
            document.getElementById(errorsArray[i])?.focus();
            window.scrollTo(
              0,
              document.getElementById(errorsArray[i])?.getBoundingClientRect()
                .top +
                window.pageYOffset -
                navHeight -
                30
            );
            break;
        }
      }
    }
  }, [
    signupFormik.submitCount,
    signupFormik.isValid,
    signupFormik.errors,
    signupFormik.isSubmitting,
  ]);

  const handleSendNumber = () => {
    if (signupFormik.values.isValid) {
      if (!!phoneNumber) {
        postPhoneNumber({
          phoneNumber: phoneNumber,
          countryCode: "+" + country,
        })
          .then((response) => {
            setClearTrigger((prev) => !prev);
            if (response?.data?.message === "development") {
              iziToast.warning({
                title: "임시인증번호-개발서버입니다",
                message: response?.data?.code,
                position: "topCenter",
              });
            }
            setIsReceiveInputOpen(true);
          })
          .catch((error) => {
            switch (error.response.data.message) {
              case "phone_user_exist":
                Swal.fire({
                  text: "해당 핸드폰 번호로 이미 가입된 계정이 있습니다. 아이디 찾기를 통해 가입된 계정을 확인해주세요.",
                  confirmButtonText: "아이디 찾기",
                  showDenyButton: true,
                  denyButtonText: "닫기",
                }).then((result) => {
                  if (result.isConfirmed) {
                    location.href = "/users/find_email";
                  }
                });
                break;
              case "send_failed":
                Swal.fire({
                  icon: "error",
                  text: "문자 전송을 실패했습니다.",
                  confirmButtonText: "확인",
                });
                break;
              default:
                Swal.fire({
                  icon: "error",
                  title: "잠시 후 다시 시도해주세요.",
                  text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
                  confirmButtonText: "확인",
                });
                break;
            }
          });
      } else {
        Swal.fire({
          title: "정확한 휴대폰 번호를 입력해주세요.",
          confirmButtonText: "확인",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "잘못된 형식의 번호입니다.",
      });
    }
  };

  const [isUserPolicyDrawerOpen, setIsUserPolicyDrawerOpen] = useState(false);
  const [isPrivatePolicyDrawerOpen, setIsPrivatePolicyDrawerOpen] =
    useState(false);
  const onUserPolicyClose = useCallback(() => {
    setIsUserPolicyDrawerOpen(false);
  }, []);

  const onPrivatePolicyClose = useCallback(() => {
    setIsPrivatePolicyDrawerOpen(false);
  });

  const handlePhoneConfirm = () => {
    getPhoneConfirmCheck({
      phoneNumber: phoneNumber,
      code: phoneConfirm,
    })
      .then((response) => {
        signupFormik.setFieldValue("phoneConfirmId", response?.data?.data?.id);
        setIsReceiveInputOpen(false);
        setIsPhoneConfirm(true);
      })
      .catch(() => {
        Swal.fire({
          title:
            "인증하려는 핸드폰 번호와 전송된 인증번호를 정확히 입력해주세요.",
          confirmButtonText: "확인",
        });
      });
  };

  const [isEmailConfirm, setIsEmailConfirm] = useState(null); //boolean 값이지만 초기값을 null로 놓아야 validation 글귀가 나타나지 않음.
  const handleEmailBlur = () => {
    getEmailExist({ email: signupFormik.values.email }).then((response) => {
      if (response?.data?.email_exist) {
        setIsEmailConfirm(false);
        signupFormik.setFieldValue("isEmailConfirm", false);
      } else {
        setIsEmailConfirm(true);
        signupFormik.setFieldValue("isEmailConfirm", true);
      }
    });
  };

  //체크 박스에 따라서 모두 동의하기 체크 박스가 체크되거나 풀림
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (
      signupFormik.values.isOver14 &&
      signupFormik.values.isPrivate &&
      signupFormik.values.isPolicy &&
      signupFormik.values.isOptional
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [
    signupFormik.values.isOver14,
    signupFormik.values.isPrivate,
    signupFormik.values.isPolicy,
    signupFormik.values.isOptional,
  ]);

  const handleCheckAll = (e) => {
    if (e) {
      signupFormik.setFieldValue("isOver14", true);
      signupFormik.setFieldValue("isPolicy", true);
      signupFormik.setFieldValue("isPrivate", true);
      signupFormik.setFieldValue("isOptional", true);
    } else {
      signupFormik.setFieldValue("isOver14", false);
      signupFormik.setFieldValue("isPolicy", false);
      signupFormik.setFieldValue("isPrivate", false);
      signupFormik.setFieldValue("isOptional", false);
    }
  };

  return (
    <>
      <UsersParent>
        <form onSubmit={signupFormik.handleSubmit} className="w-full">
          <UsersTitle text="회원가입" />

          <div className="mt-[18px]">
            <UsersInputLabel labelName="이름" inputId="username" />
            <UsersInput
              labelName="이름"
              inputPlaceHolder="이름을 입력해주세요."
              inputId="username"
              inputType="text"
              value={signupFormik.values.username}
              onChange={signupFormik.handleChange}
            />

            {signupFormik.errors.username && signupFormik.touched.username && (
              <span className="text-red400 mt-[6px] text-xs">
                {signupFormik.errors.username}
              </span>
            )}
          </div>
          <div className="mt-[18px]">
            <UsersInputLabel labelName="핸드폰 번호" inputId="phoneNumber" />

            <div className="flex items-center mt-[6px]">
              <div className="relative flex items-center w-full">
                <IntlTelInput
                  containerClassName="intl-tel-input w-full"
                  inputClassName="border-gray200 border-[0.5px] h-[45px] shrink-0 w-full rounded-[5px] mr-2 px-[12px]"
                  defaultCountry="kr"
                  fieldName="phoneNumber"
                  fieldId="phoneNumber"
                  value={phoneNumber || ""}
                  preferredCountries={["kr", "us"]}
                  formatOnInit
                  format
                  onPhoneNumberChange={(
                    isValid,
                    value,
                    selectedCountryData,
                    fullNumber,
                    extension
                  ) => {
                    signupFormik.setFieldValue("isValid", isValid);
                    setCountry(selectedCountryData.dialCode);
                    setPhoneNumber(value);
                  }}
                  onPhoneNumberBlur={() => {
                    signupFormik.setFieldTouched("phoneNumber", true);
                  }}
                  updateFlagOnDefaultCountryChange={true}
                  onSelectFlag={(
                    currentNumber,
                    selectedCountryData,
                    fullNumber,
                    isValid
                  ) => {
                    if (
                      // awsAllowedCountries?.codes.includes(
                      //   selectedCountryData.dialCode
                      // ) -> 해외 사용자 임시로 막음.
                      selectedCountryData.dialCode === "82"
                    ) {
                      signupFormik.setFieldValue("isValid", isValid);
                      setCountry(selectedCountryData.dialCode);
                    } else {
                      setPhoneNumber("");
                      signupFormik.setFieldValue("isValid", false);
                      Swal.fire({
                        // icon: "error",
                        // text: "지원하지 않는 국가입니다. 채널톡을 통해 관리자에게 연락주세요.",
                        icon: "error",
                        title: "불편을 드려 죄송합니다.",
                        text: "해외 거주 등의 사유로 인해 휴대폰 번호 인증이 어려우신 경우 채널톡으로 문의 부탁드립니다. 빠른 시일 내에 해외 사용자도 가능하도록 업데이트 하겠습니다.",
                        confirmButtonText: "확인",
                      });
                    }
                  }}
                />

                {isPhoneConfirm && (
                  <img
                    src={confirmSuccess}
                    alt="인증성공"
                    className="absolute top-[13px] right-[16px]"
                  />
                )}
              </div>

              {!isPhoneConfirm && (
                <button
                  className="text-sm flex items-center justify-center text-white bg-black rounded-[5px] h-[45px] ml-[5px] w-[106px] shrink-0"
                  type="button"
                  onClick={handleSendNumber}
                >
                  인증 번호 받기
                </button>
              )}
            </div>

            {signupFormik.errors.phoneConfirmId &&
              signupFormik.touched.phoneConfirmId && (
                <span className="text-red400 mt-[6px] text-xs">
                  {signupFormik.errors.phoneConfirmId}
                </span>
              )}
          </div>

          {isReceiveInputOpen && (
            <div className="mt-[18px]">
              <UsersInputLabel
                labelName="인증번호"
                inputId="phonenumber_receive"
              />
              <div className="flex items-center w-full">
                <UsersInput
                  inputPlaceHolder="문자메세지로 받은 번호를 입력하세요"
                  inputId="phonenumber_receive"
                  inputType="number"
                  value={phoneConfirm}
                  onChange={(e) => setPhoneConfirm(e.currentTarget.value)}
                />
                <button
                  className="text-sm flex items-center justify-center text-white bg-black rounded-[5px] h-[45px] ml-[5px] mt-[6px] shrink-0 w-[72px]"
                  type="button"
                  onClick={handlePhoneConfirm}
                >
                  확인
                </button>
              </div>

              <div className="flex justify-end mt-2 text-red400">
                {remainTime}
              </div>
            </div>
          )}

          <div className="mt-[18px]">
            <label className="font-medium" htmlFor="email">
              아이디 (이메일)
            </label>
            <UsersInput
              inputId="email"
              inputType="email"
              value={signupFormik.values.email}
              onChange={signupFormik.handleChange}
              onBlur={handleEmailBlur}
              inputRef={emailRef}
            />

            <div className="flex flex-col">
              {signupFormik.errors.email && signupFormik.touched.email && (
                <span className="text-red400 mt-[6px] text-xs">
                  {signupFormik.errors.email}
                </span>
              )}
              {isEmailConfirm == false && signupFormik.touched.email && (
                <span className="text-red400 mt-[6px] text-xs">
                  이미 존재하는 이메일입니다.
                </span>
              )}
            </div>
          </div>
          <div className="mt-[18px]">
            <UsersInputLabel inputId="pw" labelName="비밀번호" />
            <UsersInput
              inputId="pw"
              inputType="password"
              value={signupFormik.values.pw}
              onChange={signupFormik.handleChange}
              blur={signupFormik.handleBlur}
            />

            {signupFormik.errors.pw && signupFormik.touched.pw && (
              <span className="text-red400 mt-[6px] text-xs">
                {signupFormik.errors.pw}
              </span>
            )}
          </div>
          <div className="mt-[18px]">
            <label className="font-medium" htmlFor="pw_confirm">
              비밀번호 확인
            </label>
            <UsersInput
              inputId="pwConfirm"
              inputType="password"
              value={signupFormik.values.pwConfirm}
              onChange={signupFormik.handleChange}
              onBlur={signupFormik.handleBlur}
            />

            {signupFormik.errors.pwConfirm &&
              signupFormik.touched.pwConfirm && (
                <span className="text-xs text-red400 mt-[6px]">
                  {signupFormik.errors.pwConfirm}
                </span>
              )}
          </div>

          <div className="mt-[26px]">
            스몰빅 클래스 서비스 약관에 동의해주세요.
          </div>
          <div className="flex items-center mt-[17px]">
            <input
              type="checkbox"
              id="agree_all"
              className="mr-[6px]"
              onChange={(e) => {
                handleCheckAll(e.currentTarget.checked);
                setCheckAll(e.currentTarget.checked);
              }}
              checked={checkAll}
            />
            <label htmlFor="agree_all" className="text-sm font-bold">
              모두 동의합니다
            </label>
          </div>
          <div className="flex mt-[5px]">
            <input type="checkbox" className="invisible w-[13px]" />
            <span className="text-xs text-gray500">
              전체 동의는 필수 및 선택 정보에 대한 동의도 포함되어 있으며,
              개별적으로도 동의를 선택하실 수 있습니다. 선택항목에 대한 동의를
              거부하시는 경우에도 서비스는 이용이 가능합니다.
            </span>
          </div>
          <div className="border-[0.75px] border-b border-gray100 my-[18px]"></div>

          <div className="flex flex-col">
            <div
              className={`flex items-center ${
                signupFormik.errors.isOver14 && signupFormik.touched.isOver14
                  ? ""
                  : "mb-[15px]"
              }`}
            >
              <input
                type="checkbox"
                name="isOver14"
                className="mr-[6px]"
                onChange={signupFormik.handleChange}
                value={signupFormik.values.isOver14}
                checked={signupFormik.values.isOver14}
                id="isOver14"
              />
              <label htmlFor="isOver14" className="text-sm">
                [필수] 만 14세 이상입니다.
              </label>
            </div>
            {signupFormik.errors.isOver14 && signupFormik.touched.isOver14 && (
              <span className="text-xs text-red400 mt-[6px] mb-[6px]">
                {signupFormik.errors.isOver14}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <div
              className={`flex items-center justify-between ${
                signupFormik.errors.isPolicy && signupFormik.touched.isPolicy
                  ? ""
                  : "mb-[15px]"
              }`}
            >
              <input
                type="checkbox"
                name="isPolicy"
                className="mr-[6px]"
                onChange={signupFormik.handleChange}
                value={signupFormik.values.isPolicy}
                checked={signupFormik.values.isPolicy}
                id="isPolicy"
              />
              <label htmlFor="isPolicy" className="text-sm">
                [필수] 스몰빅클래스 이용약관 동의
              </label>

              {deviceCheck === "web" ? (
                <StyledPopup
                  trigger={
                    <button
                      type="button"
                      className="flex justify-end w-10 ml-auto"
                    >
                      <img src={arrowRGray500} alt="팝업" />
                    </button>
                  }
                  content={<UserPolicy />}
                />
              ) : (
                <button
                  type="button"
                  className="flex justify-end w-10 ml-auto"
                  onClick={() => setIsUserPolicyDrawerOpen(true)}
                >
                  <img src={arrowRGray500} alt="팝업" />
                </button>
              )}
            </div>
            {signupFormik.errors.isPolicy && signupFormik.touched.isPolicy && (
              <span className="text-xs text-red400 mt-[6px] mb-[6px]">
                {signupFormik.errors.isPolicy}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <div
              className={`flex items-center justify-between ${
                signupFormik.errors.isPolicy && signupFormik.touched.isPolicy
                  ? ""
                  : "mb-[15px]"
              }`}
            >
              <input
                type="checkbox"
                name="isPrivate"
                className="mr-[6px]"
                onChange={signupFormik.handleChange}
                value={signupFormik.values.isPrivate}
                checked={signupFormik.values.isPrivate}
                id="isPrivate"
              />
              <label htmlFor="isPrivate" className="text-sm">
                [필수] 개인정보 수집 및 이용 동의
              </label>

              {deviceCheck === "web" ? (
                <StyledPopup
                  trigger={
                    <button
                      type="button"
                      className="flex justify-end w-10 ml-auto"
                    >
                      <img src={arrowRGray500} alt="팝업" />
                    </button>
                  }
                  content={<PrivatePolicy />}
                />
              ) : (
                <button
                  type="button"
                  className="flex justify-end w-10 ml-auto"
                  onClick={() => setIsPrivatePolicyDrawerOpen(true)}
                >
                  <img src={arrowRGray500} alt="팝업" />
                </button>
              )}
            </div>

            {signupFormik.errors.isPrivate &&
              signupFormik.touched.isPrivate && (
                <span className="text-xs text-red400 mt-[6px] mb-[6px]">
                  {signupFormik.errors.isPrivate}
                </span>
              )}
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="isOptional"
              className="mr-[6px]"
              onChange={signupFormik.handleChange}
              value={signupFormik.values.isOptional}
              checked={signupFormik.values.isOptional}
              id="isOptional"
            />
            <label htmlFor="isOptional" className="text-sm">
              [선택] 클래스 오픈 및 이벤트, 할인 소식 알림 동의
            </label>
          </div>

          <button
            type="submit"
            disabled={signupFormik.isSubmitting}
            className="flex items-center justify-center text-white bg-black h-[45px] rounded-[5px] mt-4 w-full"
          >
            {signupFormik.isSubmitting ? "가입 중..." : "가입하기"}
          </button>
        </form>
      </UsersParent>
      <Drawer isVisible={isUserPolicyDrawerOpen} onClose={onUserPolicyClose}>
        <UserPolicy onClose={onUserPolicyClose} />
      </Drawer>
      <Drawer
        isVisible={isPrivatePolicyDrawerOpen}
        onClose={onPrivatePolicyClose}
      >
        <PrivatePolicy onClose={onPrivatePolicyClose} />
      </Drawer>
    </>
  );
};
