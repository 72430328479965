import LectureCard from "../shared/LectureCard";
import { useAxios } from "use-axios-client";
import { orderBy } from "lodash";
import { useState, useEffect } from "react";

const Content = ({ lectures }) => {
  const [activeLectures, setActiveLectures] = useState();
  const [futureVods, setFutureVods] = useState();
  const [finishedLives, setFinishedLives] = useState();

  useEffect(() => {
    initActiveLectures();
    initFutureVods();
    initFinishedLives();
  }, [lectures]);

  const initialLecture = ({ lecture, object_type, lecture_type }) => {
    return {
      id: lecture?.id,
      title: lecture?.title,
      reviews: lecture?.reviews,
      teacher_name: lecture?.teacher_name,
      ordered_tag_list: lecture?.ordered_tag_list,
      object_type: object_type,
      lecture_type: lecture_type,
      status: null,
      coming_text: null,
      img_url: null,
      is_finish: null,
      is_active: null,
      min_age: null,
      max_age: null,
    };
  };

  const initActiveLectures = () => {
    const lecturesFilter = _.filter(lectures?.lectures, (lecture) => {
      return lecture?.is_active;
    }).map((lecture) => {
      return {
        ...initialLecture({
          lecture: lecture,
          object_type: "activeLectures",
          lecture_type: "lectures",
        }),
        img_url: lecture?.image?.url,
        coming_text: lecture?.coming_text,
        is_active: lecture?.is_active,
        status: lecture?.status,
      };
    });
    const specialEventsFilter = _.filter(
      lectures?.special_events,
      (specialEvent) => {
        return !specialEvent?.is_finish;
      }
    )?.map((specialEvent) => {
      return {
        ...initialLecture({
          lecture: specialEvent,
          object_type: "activeLectures",
          lecture_type: "special_events",
        }),
        img_url: specialEvent?.image?.image?.url,
        coming_text: null,
        is_finish: specialEvent?.is_finish,
        status: "live",
      };
    });

    const joinArray = [...lecturesFilter, ...specialEventsFilter];
    return setActiveLectures(orderBy(joinArray, ["created_at"]));
  };

  const initFutureVods = () => {
    const lecturesFilter = _.filter(lectures?.lectures, (lecture) => {
      return !lecture?.is_active;
    })?.map((lecture) => {
      return {
        ...initialLecture({
          lecture: lecture,
          object_type: "futureVod",
          lecture_type: "lectures",
        }),
        img_url: lecture?.image?.url,
        coming_text: lecture?.coming_text,
        is_active: lecture?.is_active,
        status: lecture?.status,
      };
    });

    return setFutureVods([...lecturesFilter]);
  };

  const initFinishedLives = () => {
    const specialEventsFilter = _.filter(
      lectures?.special_events,
      (specialEvent) => {
        return specialEvent?.is_finish;
      }
    ).map((specialEvent) => {
      return {
        ...initialLecture({
          lecture: specialEvent,
          object_type: "finishedLives",
          lecture_type: "special_events",
        }),
        img_url: specialEvent?.image?.image?.url,
        coming_text: null,
        is_finish: specialEvent?.is_finish,
        status: "live",
      };
    });

    return setFinishedLives([...specialEventsFilter]);
  };

  return (
    <div className="max-w-[1200px] mx-auto flex flex-col default_margin">
      <span className="font-bold web:text-2xl tablet:text-lg mobile:text-lg web:mb-[25px] tablet:mb-[10px] mobile:mb-[10px]">
        부모 클래스
      </span>

      {/* 모집중 */}
      {activeLectures?.length !== 0 && (
        <div className="flex flex-col mb-[66px]">
          <span className="font-bold web:text-xl text-red500">모집중</span>
          <span className="font-bold web:text-xl">
            성장하는 부모님들을 위한 부모학교 <br />
            지금 바로 수강신청 하세요!
          </span>

          <div className="mt-[22px] grid web:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 web:gap-x-[57px] tablet:gap-x-[16px] mobile:gap-x-[16px] web:gap-y-[50px] tablet:gap-y-[30px] mobile:gap-y-[30px]">
            {activeLectures?.map((activeLecture) => {
              return (
                <LectureCard lecture={activeLecture} key={activeLecture?.id} />
              );
            })}
          </div>
        </div>
      )}

      {/* VOD출시예정 */}
      {futureVods?.length !== 0 && (
        <div className="flex flex-col mb-[66px]">
          <span className="font-bold web:text-xl text-purple500">
            VOD 출시예정
          </span>
          <span className="font-bold web:text-xl">
            알찬 강의 커리큘럼으로 곧 찾아뵙겠습니다!
          </span>

          <div className="mt-[22px] grid web:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 web:gap-x-[57px] tablet:gap-x-[16px] mobile:gap-x-[16px] web:gap-y-[50px] tablet:gap-y-[30px] mobile:gap-y-[30px]">
            {futureVods?.map((futureVod) => {
              return <LectureCard lecture={futureVod} key={futureVod?.id} />;
            })}
          </div>
        </div>
      )}

      {/* 종료된 LIVE */}
      {finishedLives?.length !== 0 && (
        <div className="flex flex-col">
          <span className="font-bold web:text-xl text-gray400">
            종료된 LIVE
          </span>
          <span className="font-bold web:text-xl">
            알찬 강의 커리큘럼으로 곧 찾아뵙겠습니다!
          </span>

          <div className="mt-[22px] grid web:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 web:gap-x-[57px] tablet:gap-x-[16px] mobile:gap-x-[16px] web:gap-y-[50px] tablet:gap-y-[30px] mobile:gap-y-[30px]">
            {finishedLives?.map((finishedLive) => {
              return (
                <LectureCard lecture={finishedLive} key={finishedLive?.id} />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
