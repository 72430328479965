import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
const ReviewLecture = ({ titleRef, titleText, titleImg }) => {
  const [titleHeight, setTitleHeight] = useState(0);

  const deviceCheck = useDeviceCheck();

  useEffect(() => {
    setTitleHeight(titleRef.current.offsetHeight);
  }, [titleRef]);

  return (
    <div
      className="flex items-center justify-center py-[33px] mx-[15px] border-b border-gray100"
      css={css`
        margin-top: ${deviceCheck === "mobile" ? 0 : titleHeight}px;
      `}
    >
      <img src={titleImg} alt="강의" className="w-[119px] aspect-auto mr-[21px]" />
      <div className="font-medium">{titleText}</div>
    </div>
  );
};

export default ReviewLecture;