import arrowRgray from "../../assets/icons/arrow_right_gray500.svg";
import { useState, useEffect } from "react";
import { getMissionHistories, patchFeedbackStatus } from "../../api";
import moment from "moment";
import { Pagination } from "antd";
import { pageBtnStyle } from "../shared/Pagination";
import { timeForToday } from "../../utils";
import Swal from "sweetalert2";

export const Content = () => {
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const [missions, setMissions] = useState(null); //[]

  useEffect(() => {
    getMissionHistories({ page: currentPage, per: 10 }).then((response) => {
      setMissions(response?.data);
    });
  }, []);

  const setPushState = (page) => {
    window.history.replaceState(
      page,
      null,
      `${location.pathname}?page=${page}`
    );
  };

  const handleClickPageBtn = (page, pageSize) => {
    setPushState(page);
    setCurrentPage(page);
    getMissionHistories({ page: page, per: pageSize }).then((response) => {
      setMissions(response?.data);
    });
  };

  const handleConfirmMission = (missionId) => {
    Swal.fire({
      title: "과제를 확인 처리하시겠습니까?",
      text: "확인 처리된 과제는 이 페이지에 더 이상 뜨지 않습니다.",
      confirmButtonText: "확인 처리하기",
      showCancelButton: true,
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        //post action
        patchFeedbackStatus({ missionId: missionId }).then(() => {
          getMissionHistories({ page: currentPage, per: 10 }).then(
            (response) => {
              setMissions(response?.data);
            }
          );
        });
      }
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <div className="grid grid-cols-[2fr_5fr_5fr_1fr] border-b border-b-gray200 pb-2 mb-2 mr-2 w-full">
          <div className="flex justify-center items-center font-bold text-lg">
            아이디
          </div>
          <div className="flex justify-center items-center font-bold text-lg">
            시간
          </div>
          <div className="flex justify-center items-center font-bold text-lg">
            강좌명
          </div>
          <div className="flex justify-center items-center font-bold text-lg">
            미션명
          </div>
          <div className="flex justify-center items-center font-bold text-lg"></div>
        </div>
        <div className="shrink-0 flex items-center justify-center invisible border-black px-2 mb-2">
          과제 확인처리
        </div>
      </div>

      {!!missions?.assignments &&
        missions?.assignments?.map((mission) => {
          return (
            <div className="flex justify-center items-center mb-2 ">
              <a
                href={mission?.link}
                key={mission?.id}
                className="grid grid-cols-[1fr_2fr_5fr_4fr_1fr] border-b border-b-gray200 gap-2 pb-2 w-full mr-2"
              >
                <div className="flex flex-col truncate">{mission?.id}</div>
                <div className="flex flex-col justify-center items-center">
                  {timeForToday(mission?.created_at)}
                </div>
                <div className="flex items-center truncate">
                  {mission?.lecture_title}
                </div>
                <div className="flex items-center truncate">
                  {mission?.mission_title}
                </div>
                <div className="flex justify-center items-center">
                  <img src={arrowRgray} alt="이동" />
                </div>
              </a>
              <button
                type="button"
                className="shrink-0 flex items-center justify-center border rounded-[5px] border-black px-2 hover:text-white hover:bg-black mb-2"
                onClick={() => handleConfirmMission(mission?.id)}
              >
                과제 확인처리
              </button>
            </div>
          );
        })}

      {!missions?.assignments && <div>피드백이 없는 미션이 없습니다.</div>}

      <div
        className="flex flex-col items-center justify-center mt-4"
        css={pageBtnStyle}
      >
        <Pagination
          total={missions?.meta?.total_count}
          pageSize={10}
          showSizeChanger={false}
          onChange={handleClickPageBtn}
          current={currentPage}
          defaultPageSize={10}
        />
      </div>
    </div>
  );
};
