import Layout from "../components/Layout";
import { Signup } from "../components/users/index";

const UsersSignup = ({ identify }) => {
  return (
    <Layout>
      <div className="max-w-[1200px] mx-auto">
        <Signup identify={identify} />
      </div>
    </Layout>
  );
};

export default UsersSignup;
