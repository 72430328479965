import { useState, createContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { css } from "@emotion/react";

import NavBar from "../components/shared/NavBar";
import Footer from "../components/shared/Footer";
import { MOBILE_MAX_WIDTH } from "../constant";
import { getTopBanner } from "../api";
import { EventBanner } from "./EventBanner";
import useDeviceCheck from "../hooks/useDeviceCheck";
import { useScrollDirection } from "../hooks/useScrollDirection";

export const LayoutContext = createContext(null);

Sentry.init({
  dsn: "https://a16c9c752fdc4c0da6e096ea8f6d924a@o1367008.ingest.sentry.io/6668268",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV || "development",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const Layout = (props) => {
  const [banners, setBanners] = useState(null); // []
  const device = useDeviceCheck();

  useEffect(() => {
    getTopBanner().then((response) => {
      setBanners(response?.data);
    });
  }, []);
  //쿠키 코드
  const [navHeight, setNavHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(325);
  const [eventBannerHeight, setEventBannerHeight] = useState(0);
  const [cookies, setCookie] = useCookies(["isEventBannerOpen"]);
  const [eventBannerState, setEventBannerState] = useState(
    cookies["isEventBannerOpen"] === "false" ? false : true
  );

  const { scrollDirection } = useScrollDirection();

  useEffect(() => {
    if (device === "mobile") {
      if (scrollDirection === "down") {
        setNavHeight(0);
      } else {
        setNavHeight(96);
      }
    } else {
      setNavHeight(120);
    }
  }, [device, scrollDirection]);

  useEffect(() => {
    if (banners?.length === 0) {
      setEventBannerState(false);
    } else {
      if (cookies["isEventBannerOpen"] === "false") {
        setEventBannerState(false);
      } else {
        setEventBannerState(true);
      }
    }
  }, [banners]);

  const contentStyle = css`
    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      padding-top: ${eventBannerHeight + navHeight}px;
    }

    min-height: calc(100vh - ${footerHeight}px - ${navHeight}px);
  `;

  useEffect(() => {
    if (banners?.length > 0 && eventBannerState) {
      setEventBannerHeight(52);
    } else {
      setEventBannerHeight(0);
    }
  }, [eventBannerState, banners]);

  return (
    <LayoutContext.Provider
      value={{
        navHeight: navHeight,
        setNavHeight: setNavHeight,
        eventBannerState: eventBannerState,
        setEventBannerState: setEventBannerState,
        eventBannerHeight: eventBannerHeight,
        banners: banners,
      }}
    >
      {eventBannerState && scrollDirection !== "down" && (
        <div className="mobile:fixed mobile:top-0 mobile:z-[500]">
          <EventBanner banners={banners} />
        </div>
      )}
      <NavBar />

      <div css={contentStyle}>{props.children}</div>

      <Footer />
    </LayoutContext.Provider>
  );
};
export default Layout;
