import { css } from "@emotion/react";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

const leftTextStyle = css`
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 30px;
    .muchSmaller {
      font-size: 18px;
    }
  }

  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    font-size: 24px;
    .muchSmaller {
      font-size: 16px;
    }
  }
`;

const rightTextStyle = css`
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    font-size: 18px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    font-size: 16px;
  }
`;

const WatchingLecturesTitleL = () => {
  const { currentUser } = useGetCurrentUser();
  return (
    <div className="flex flex-col font-bold text-gray800" css={leftTextStyle}>
      <div className="flex">
        <span className="text-[#00a3cc] mobile:truncate mobile:max-w-[180px]">
          {currentUser?.name}
        </span>
        <span>님이&nbsp;</span>
      </div>
      <span>수강 중인 클래스</span>
    </div>
  );
};

const OriginalClassTitleL = () => {
  return (
    <div className="flex flex-col text-gray800" css={leftTextStyle}>
      <span className="muchSmaller">스몰빅클래스에서 직접 기획하고 제작한</span>
      <span className="font-bold">스몰빅 오리지널 클래스</span>
    </div>
  );
};

const RankSwiperTitleL = () => {
  return (
    <div className="flex flex-col text-gray800" css={leftTextStyle}>
      <span className="font-bold">SMALLBIG TOP 5 CLASS</span>
    </div>
  );
};

const KitClassTitleL = () => {
  return (
    <div className="flex flex-col text-gray800" css={leftTextStyle}>
      <span className="font-bold muchSmaller">준비물까지 보내드리는</span>
      <span className="font-bold">스몰빅 키트 클래스</span>
    </div>
  );
};

const OpenClassTitleL = () => {
  return (
    <div className="flex flex-col text-gray800" css={leftTextStyle}>
      <span className="font-bold">오픈 예정 클래스 소개</span>
    </div>
  );
};

const EduTacticTitleL = () => {
  return (
    <div className="flex flex-col text-gray800" css={leftTextStyle}>
      <span className="muchSmaller">집중력을 잃지 않으면서 공부하는</span>
      <span className="font-bold">소통하는 강의 방식</span>
    </div>
  );
};

const TitleR = ({ redirectUrl }) => {
  return (
    <a
      href={redirectUrl}
      className="flex items-center justify-center text-gray500"
      css={rightTextStyle}
    >
      <span className="mobile:hidden">전체보기</span>
      <i className="ml-2 fas fa-chevron-right"></i>
    </a>
  );
};

const KitTitleR = ({ redirectUrl }) => {
  return (
    <a
      href={redirectUrl}
      className="flex items-center justify-center text-gray800"
      css={rightTextStyle}
    >
      <span className="mobile:hidden">전체보기</span>
      <i className="ml-2 fas fa-chevron-right"></i>
    </a>
  );
};

export const WatchingLecturesTitle = () => {
  return (
    <div className="flex justify-between web:mb-4 tablet:mb-4 mobile:mb-2">
      <WatchingLecturesTitleL />
      <TitleR redirectUrl="mypage/lectures_kids" />
    </div>
  );
};

export const OriginalClassTitle = () => {
  return (
    <div className="flex justify-between web:mb-4 tablet:mb-4 mobile:mb-2">
      <OriginalClassTitleL />
      <TitleR redirectUrl="/lectures/kids" />
    </div>
  );
};

export const RankSwiperTitle = () => {
  return (
    <div className="flex justify-between web:mb-4 tablet:mb-4 mobile:mb-2">
      <RankSwiperTitleL />
    </div>
  );
};

export const KitClassTitle = () => {
  return (
    <div className="flex justify-between web:mb-4 tablet:mb-4 mobile:mb-2">
      <KitClassTitleL />
      <KitTitleR redirectUrl="/lectures/kids" />
    </div>
  );
};

export const EduTacticTitle = () => {
  return (
    <div className="flex justify-between web:mb-4 tablet:mb-4 mobile:mb-2">
      <EduTacticTitleL />
    </div>
  );
};
