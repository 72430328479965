import { css } from "@emotion/react";

const TeacherIntroduce = ({ lecture_teachers, teachers_text }) => {
  const TeacherCard = ({ teacher, indexNum }) => {
    return (
      <div className="flex flex-col web:mt-[29px] tablet:mt-[17px] mobile:mt-[17px]">
        <span className="web:text-2xl tablet:text-[20px] mobile:text-[20px] font-bold web:px-[24px]">
          {indexNum}회차
        </span>
        <div className="relative web:mt-[26px] tablet:mt-[14px] mobile:mt-[14px] flex web:py-[42px] tablet:py-[16px] mobile:py-[16px] bg-gray25 web:px-[24px] tablet:px-[15px] mobile:px-[15px]">
          <div
            css={css`
              background-image: url(${teacher?.image.url});
            `}
            className="shrink-0 web:w-[243px] web:h-[243px] tablet:w-[94px] tablet:h-[94px] mobile:w-[94px] mobile:h-[94px] web:mr-[31px] tablet:mr-[10px] mobile:mr-[10px] bg-contain bg-no-repeat"
          ></div>
          <div className="flex flex-col">
            <span className="font-bold web:text-xl tablet:text-sm mobile:text-sm">
              {teacher?.name}
            </span>
            <span
              className="web:mt-[44px] tablet:mt-[15px] mobile:mt-[15px] mobile:text-xs web:font-medium mobile:font-normal"
              dangerouslySetInnerHTML={{ __html: teacher?.desc }}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="default_margin flex flex-col web:max-w-[1024px] tablet:max-w-[640px] mobile:max-w-[640px] web:mt-[121px] tablet:mt-[46px] mobile:mt-[46px]">
      <span className="font-bold web:text-2xl">강사진 소개</span>
      <div className="font-bold web:text-[28px] tablet:text-xl mobile:text-xl mt-2">
        <span dangerouslySetInnerHTML={{ __html: teachers_text }}></span>
      </div>

      <div className="flex flex-col">
        {lecture_teachers?.map((teacher, index) => {
          return (
            <TeacherCard
              teacher={teacher}
              indexNum={index + 1}
              key={`강사진${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TeacherIntroduce;
