import { ChangePw } from "../components/users/index";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import Layout from "../components/Layout";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useUserSignedIn } from "../hooks/useUserSignedIn";

const UsersChangePw = () => {
  const { currentUser } = useGetCurrentUser();
  const { isUserSignedIn } = useUserSignedIn();

  const returnBeforePage = () => {
    const returnUrl = sessionStorage.getItem("returnUrl");
    if (!!returnUrl) {
      sessionStorage.removeItem("returnUrl");
      location.href = returnUrl;
    } else {
      location.href = "/";
    }
  };

  useEffect(async () => {
    const currentSession = await Auth.currentSession();
    if (isUserSignedIn && !!currentSession) {
      returnBeforePage();
    }
  }, [currentUser]);

  return (
    <Layout>
      <div className="max-w-[1200px] mx-auto">
        <ChangePw />
      </div>
    </Layout>
  );
};

export default UsersChangePw;
