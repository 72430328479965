import { useFormik } from "formik";
import Rating from "react-rating";
import starFull from "../../assets/icons/star_full";
import starEmpty from "../../assets/icons/star_empty";
import close from "../../assets/icons/close.svg";
import Swal from "sweetalert2";
import { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import iziToast from "izitoast";
import * as Yup from "yup";
import { remove } from "lodash";
import { MOBILE_MAX_WIDTH } from "../../constant";
import { color } from "../../tailwindStyle";
import { postReviews } from "../../api";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

const reviewInputStyle = css`
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  height: 90px;
  width: 100%;
  padding: 15px 13px;
  font-size: 14px;
  resize: none;
`;

const reviewAddImg = css`
  border-radius: 10px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 0 36px;
    height: 41px;
    margin-right: 0.75rem;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    height: 30px;
    width: fit-content;
    padding: 0 1rem;
    font-size: 12px;
    margin-right: 0.25rem;
  }
`;

const ratingInputGroup = css`
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  padding: 0 0.5rem;
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    height: 41px;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    height: 30px;
  }
`;

const reviewStarStyle = css`
  @media (min-width: ${MOBILE_MAX_WIDTH}px) {
    width: 21px;
    height: 21px;
    margin: 0 0.25rem;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
    width: 15px;
    height: 15px;
    margin: 0 0.1rem;
  }
`;

const onlyImgTextStyle = css`
  font-size: 11px;
  color: red;
`;

const ReviewForm = ({ targetId, getReviews, targetType }) => {
  const deviceCheck = useDeviceCheck();
  const reviewImgInput = useRef(null);
  const [imgFiles, setImgFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const [countReviewBody, setCountReviewBody] = useState(0);
  const reviewBodyRef = useRef(null);

  const { isUserSignedIn } = useUserSignedIn();

  const formik = useFormik({
    initialValues: {
      "image[]": [],
      body: "",
      rating: 0,
    },

    validationSchema: Yup.object({
      body: Yup.string()
        .required()
        .min(30, "30자 이상 필수 입력입니다")
        .max(2000, "2000자를 넘길 수 없습니다")
        .trim(),
      rating: Yup.number().required().min(1).max(5),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsUploading(true);
      const formData = new FormData();

      imgFiles.forEach((file) => {
        formData.append("review[images][]", file);
      });

      formData.append("review[body]", values.body);
      formData.append("review[rating]", values.rating);

      if (isUserSignedIn) {
        postReviews({
          data: formData,
          targetId: targetId,
          targetType: targetType,
        })
          .then(() => {
            setIsUploading(false);
            iziToast.success({
              title: "OK",
              message: "리뷰가 성공적으로 등록되었습니다",
              timeout: 1000,
            });
            resetForm();
            setImgFiles([]);
            getReviews();
          })
          .catch(() => {
            setIsUploading(false);
            Swal.fire("일시적인 문제가 생겼습니다. 다시 한 번 시도해주세요.");
          });
      } else {
        Swal.fire({
          icon: "info",
          text: "로그인 이후 리뷰를 작성해주세요!",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "로그인하러 이동",
          cancelButtonText: "취소",
        }).then((result) => {
          setIsUploading(false);
          if (result.isConfirmed) {
            location.href = "/users/sign_in";
          }
        });
      }
    },
  });

  useEffect(() => {
    setCountReviewBody(reviewBodyRef.current?.value.length);
  }, [formik.values.body]);

  const reviewSubmit = css`
    border-radius: 10px;
    background-color: ${color.navy900};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    @media (min-width: ${MOBILE_MAX_WIDTH}px) {
      height: 41px;
      padding: 0 36px;
    }
    @media (max-width: ${MOBILE_MAX_WIDTH - 1}px) {
      height: 30px;
      width: fit-content;
      padding: 0 1rem;
      font-size: 12px;
    }
  `;

  return (
    <>
      <form>
        <textarea
          id="body"
          name="body"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.body}
          css={reviewInputStyle}
          placeholder="리뷰를 남겨주세요"
          ref={reviewBodyRef}
        />

        <div className="flex justify-between">
          <input
            id="file"
            name="file"
            type="file"
            className="hidden"
            multiple
            ref={reviewImgInput}
            onChange={(event) => {
              const newFiles = event.currentTarget.files;

              if (imgFiles.length + newFiles.length > 3) {
                Swal.fire("3개를 넘는 이미지를 넣을 수 없습니다");
              } else {
                let tempFiles = [...imgFiles];
                for (let i = 0; i < newFiles.length; i++) {
                  tempFiles.push(newFiles[i]);
                }
                setImgFiles(tempFiles);
              }
            }}
          />

          <div
            css={reviewAddImg}
            onClick={() => reviewImgInput.current.click()}
          >
            사진 추가
          </div>

          <div css={ratingInputGroup}>
            <Rating
              emptySymbol={<img src={starEmpty} css={reviewStarStyle} />}
              fullSymbol={<img src={starFull} css={reviewStarStyle} />}
              initialRating={formik.values.rating}
              onChange={(value) => {
                formik.setFieldValue("rating", value);
              }}
            />
          </div>

          {deviceCheck !== "mobile" && (
            <span className="ml-auto mr-4 text-xs text-gray300">
              {countReviewBody}/2000
            </span>
          )}

          <button
            type="submit"
            css={reviewSubmit}
            onClick={(e) => {
              e.preventDefault();
              if (!isUploading) {
                if (formik.isValid && formik.dirty) {
                  formik.handleSubmit();
                } else {
                  if (!formik.dirty) {
                    iziToast.warning({
                      message: "글과 별점은 반드시 입력해주셔야 합니다.",
                      position: "topCenter",
                      timeout: 1500,
                    });
                  } else {
                    formik.errors.body &&
                      iziToast.warning({
                        message: "30자 이상 필수 입력입니다.",
                        position: "topCenter",
                        timeout: 1500,
                      });
                    formik.errors.rating &&
                      iziToast.warning({
                        message: "별점도 선택해주세요.",
                        position: "topCenter",
                        timeout: 1500,
                      });
                  }
                }
              }
            }}
          >
            리뷰 등록하기
          </button>
        </div>

        <div
          className="flex"
          css={css`
            div.reviewImgParent + div.reviewImgParent {
              margin-left: 10px;
            }
            margin-top: 0.5rem;
          `}
        >
          {imgFiles?.map((file, index) => {
            return (
              <div className="flex reviewImgParent" key={index}>
                <div
                  className="bg-center bg-no-repeat bg-cover"
                  css={css`
                    background-image: url(${URL.createObjectURL(file)});
                    width: 50px;
                    height: 50px;
                  `}
                ></div>

                <img
                  onClick={() => {
                    setImgFiles(
                      remove(imgFiles, (e) => {
                        return e !== file;
                      })
                    );
                  }}
                  src={close}
                  css={css`
                    width: 10px;
                    height: 10px;
                    cursor: pointer;
                  `}
                  alt={`리뷰이미지삭제버튼${index}`}
                />
              </div>
            );
          })}
        </div>
        <div css={onlyImgTextStyle}>사진만 첨부 할 수 있습니다.</div>
      </form>
    </>
  );
};

export default ReviewForm;
