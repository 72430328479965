import moment from "moment";
import { getPackageInfo, getTamgeulPrice, postOrderOption } from "../../api";
import { useState, useEffect } from "react";
import { css } from "@emotion/react";

import { useDiffTime } from "../../hooks/useDiffTime";
import { Content } from "../../components/tamgeulAllkill";
import Layout from "../../components/Layout";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import { addComma, setReturnSession } from "../../utils";

const handleSubmit = (packageInfo) => {
  const orderParams = {
    order: {
      orderable_type: "Package",
      orderable_id: 2,
      order_type: "delivery",
      price: packageInfo?.main_option_price, //임시
      slug: packageInfo?.slug,
      options: !!packageInfo?.option_lists
        ? packageInfo?.option_lists.map((option_list) => {
            return {
              option_list_id: option_list.id,
              quantity: 0,
              ...option_list,
            };
          })
        : null,
    },
  };

  if (packageInfo?.has_purchased) {
    //이미 구입했으면
    Swal.fire({
      icon: "warning",
      title: "이미 구입한 상품입니다.",
    });
  } else {
    //구입하지 않았으면
    postOrderOption(orderParams)
      .then((response) => {
        location.href = `/orders/${response?.data?.order_id}`;
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "오류가 발생했습니다.",
          text: "다시 시도해주세요.",
        });
      });
  }
};

const GoOrderBtn = ({ packageInfo, isLoading, isDochimom }) => {
  const { isUserSignedIn } = useUserSignedIn();
  const { days, hours, minutes, seconds } = useDiffTime({
    endTimeDate: isDochimom
      ? new Date(2023, 1, 3, 23, 59, 59)
      : packageInfo?.price_end_at,
    trigger: packageInfo,
  });

  return isUserSignedIn
    ? !isLoading && (
        <button
          onClick={() => handleSubmit(packageInfo)}
          type="button"
          id="tamgeulAllkill-cta_bottom"
          className={`${
            isDochimom
              ? "w-full fixed bottom-0 max-w-[650px] w-full h-[81px] flex flex-col items-center justify-center bg-[#FFFFFF]"
              : "text-white bg-black w-full fixed bottom-0 max-w-[650px] w-full h-[81px] flex flex-col items-center justify-center"
          }`}
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
          `}
        >
          {isDochimom ? (
            <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
              사회&과학 글쓰기 수강신청하기
            </div>
          ) : (
            <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
              모든 혜택 받고 {Math.round(Number(packageInfo?.discount_rate))}%{" "}
              {addComma(Number(packageInfo?.main_option_price))}원에 구매하기
            </div>
          )}
        </button>
      )
    : !isLoading && (
        <button
          onClick={() => setReturnSession()}
          type="button"
          id="tamgeulAllkill-cta_bottom"
          className={`${
            isDochimom
              ? "w-full fixed bottom-0 max-w-[650px] w-full h-[81px] flex flex-col items-center justify-center bg-[#FFFFFF]"
              : "text-white bg-black w-full fixed bottom-0 max-w-[650px] w-full h-[81px] flex flex-col items-center justify-center"
          }`}
          css={css`
            left: 50%;
            transform: translate(-50%, 0%);
          `}
        >
          {isDochimom && (
            <div className="bg-black text-white text-sm flex items-center justify-center h-[29px] w-full text-sm">
              🚨 도치맘 판매 까지 {days}일 {hours}시간 {minutes}분 {seconds}초
              🚨
            </div>
          )}
          {isDochimom ? (
            <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
              사회&과학 글쓰기 수강신청하기
            </div>
          ) : (
            <div className="rounded-[3px] flex items-center justify-center h-[52px] font-bold w-full text-lg">
              모든 혜택 받고 {Math.ceil(Number(packageInfo?.discount_rate))}%{" "}
              {addComma(Number(packageInfo?.main_option_price))}원에 구매하기
            </div>
          )}
        </button>
      );
};

const TamgeulAllkill = (props) => {
  const [packageInfo, setPackageInfo] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPackageInfo({ packageClassId: 2, slug: props?.slug }).then(
      (response) => {
        setPackageInfo(response?.data);
        setIsLoading(false);
      }
    );
  }, []);

  const [isDochimom, setIsDochimom] = useState(
    props?.slug?.includes("dochimom") ? true : false
  );

  return (
    <Layout>
      <Content
        packageInfo={packageInfo}
        handleSubmit={handleSubmit}
        slug={props?.slug}
        isDochimom={isDochimom}
      />

      <GoOrderBtn
        isLoading={isLoading}
        packageInfo={packageInfo}
        slug={props?.slug}
        isDochimom={isDochimom}
      />
    </Layout>
  );
};

export default TamgeulAllkill;
