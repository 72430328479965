import { useContext, useState, useRef } from "react";
import moment from "moment";
import replyEmpty from "../../assets/icons/reply_empty.svg";
import { ReplyInputForm } from "./ReplyInputForm";
import defaultProfile from "../../assets/icons/default_profile.svg";
import Swal from "sweetalert2";
import adminCrown from "../../assets/icons/admin_crown.svg";
import { color } from "../../tailwindStyle";
import { QnasContext } from "../../pages/Lessons";
import { filter } from "lodash";
import { deleteReply } from "../../api";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

const ReplyReply = ({ comment, commentLengthId }) => {
  const [hidden, setHidden] = useState(false);
  const { currentUser } = useGetCurrentUser();
  const { qnas, setQnas } = useContext(QnasContext);

  const doHidden = () => {
    Swal.fire({
      title: "댓글을 삭제하시겠어요?",
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: "삭제",
      denyButtonText: "취소",
      confirmButtonColor: color["red500"],
      denyButtonColor: color["blue500"],
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReply({ replyId: comment?.id }).then(() => {
          const transQnas = qnas.reduce((result, qna) => {
            if (qna.comments.length > 0) {
              const filteredQComments = qna.comments.filter((qComment) => {
                return qComment.id != comment.id;
              });

              return [...result, { ...qna, comments: filteredQComments }];
            } else {
              if (qna.id != comment?.id) {
                return [...result, qna];
              } else {
                return [...result];
              }
            }
          }, []);
          setQnas(transQnas);

          Swal.fire("삭제되었습니다");
        });
      }
    });
  };

  return (
    <div className={`flex flex-col mt-4 pl-5 ${hidden ? "hidden" : ""}`}>
      <div className="flex items-center justify-between">
        <img
          src={comment?.user?.avatar?.thumb?.url || defaultProfile}
          alt="글쓴이프로필"
          className="rounded-full object-fit w-[40px] h-[40px] mr-[15px] flex-shrink-0 overflow-hidden"
        />
        <div className="flex flex-col mr-auto text-xs justify-evenly">
          <div className="flex">
            <span className="font-bold">
              {comment?.user?.nickname || comment?.user?.name}
            </span>
            {comment?.is_manager && (
              <img
                src={adminCrown}
                alt="선생님왕관"
                className="w-[15px] h-[15px] ml-1 overflow-hidden"
              />
            )}
          </div>
          <span className="font-medium gray400">
            {moment(comment?.created_at).format("YY-MM-DD")}
          </span>
        </div>
        {currentUser?.id === comment?.user?.id && (
          <span
            className="cursor-pointer text-gray400 web:text-sm tablet:text-xs mobile:text-xs"
            onClick={doHidden}
          >
            삭제
          </span>
        )}
      </div>

      <div className="mt-2 text-sm font-medium whitespace-pre-wrap">
        {comment?.body}
      </div>
    </div>
  );
};

export const ReplyMaster = ({ qna }) => {
  const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);

  const { currentUser } = useGetCurrentUser();

  const { qnas, setQnas } = useContext(QnasContext);

  const doHidden = () => {
    Swal.fire({
      title: "댓글을 삭제하시겠어요?",
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: "삭제",
      denyButtonText: "취소",
      confirmButtonColor: color["red500"],
      denyButtonColor: color["blue500"],
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReply({ replyId: qna?.id }).then(() => {
          setQnas([
            ...filter(qnas, (q) => {
              return q.id !== qna.id;
            }),
          ]);
          Swal.fire("삭제되었습니다");
        });
      }
    });
  };

  const closeReplyForm = () => {
    return setIsReplyFormOpen(false);
  };

  return (
    <div className="flex flex-col pt-4 pb-4 overflow-auto web:border-b web:border-gray100">
      <div className="flex flex-col" key={qna?.id}>
        <div className="flex items-center justify-between">
          <img
            src={qna?.user?.avatar?.thumb?.url || defaultProfile}
            alt="글쓴이프로필"
            className="rounded-full object-fit w-[40px] h-[40px] mr-[15px] flex-shrink-0 overflow-hidden"
          />
          <div className="flex flex-col mr-auto text-xs justify-evenly">
            <div className="flex">
              <span className="font-bold">
                {qna?.user?.nickname || qna?.user?.name}
              </span>
              {qna?.is_manager && (
                <img
                  src={adminCrown}
                  alt="선생님왕관"
                  className="w-[15px] h-[15px] ml-1"
                />
              )}
            </div>
            <span className="font-medium text-gray400 mt-[3px]">
              {moment(qna?.created_at).format("YY-MM-DD")}
            </span>
          </div>
          <div className="flex items-center text-gray400 web:text-sm tablet:text-xs mobile:text-xs">
            <img src={replyEmpty} alt="댓글" />
            <span className="ml-[6px] mr-4" id={`commentsLength_${qna?.id}`}>
              {qna?.comments?.length}
            </span>
            <button
              type="button"
              className="font-medium"
              onClick={() => {
                setIsReplyFormOpen(!isReplyFormOpen);
              }}
            >
              답글 달기
            </button>
            {qna?.user?.id === currentUser?.id && (
              <span
                className="ml-4 cursor-pointer text-gray400 web:text-sm tablet:text-xs mobile:text-xs"
                onClick={doHidden}
              >
                삭제
              </span>
            )}
          </div>
        </div>

        <span className="mt-2 text-sm font-medium whitespace-pre-wrap">
          {qna?.body}
        </span>

        {isReplyFormOpen && (
          <div className="mt-4">
            <ReplyInputForm
              commentable_id={qna?.id}
              commentable_type="Comment"
              closeReplyForm={closeReplyForm}
            />
          </div>
        )}

        {qna?.comments?.map((comment) => {
          return (
            <ReplyReply
              key={comment?.id}
              comment={comment}
              commentLengthId={`commentsLength_${qna?.id}`}
            />
          );
        })}
      </div>
    </div>
  );
};
