import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import Popup from "reactjs-popup";

import { color } from "../../tailwindStyle";
import { LessonLists } from "./LessonLists";
import closeSm from "../../assets/icons/close_sm.svg";
import { TextbookLists } from "./TextbookLists";
import { AssignmentLists } from "./AssignmentLists";
import { Reply } from "./Reply";
import { ReplyInputForm } from "./ReplyInputForm";
import { QnaTitle } from "./QnaTitle";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import { getLessonDownloads, getLessonTextbookDownload } from "../../api";
import DownloadComponent from "../shared/DownloadComponent";

import congratu from "../../assets/icons/congratu.svg";

const LessonsSidebar = ({ lessonDetail, qnas, lessonId, selectedVideoId }) => {
  const tab = new URLSearchParams(window.location.search);

  const deviceCheck = useDeviceCheck();

  const initTab = () => {
    let validTabs = [];

    if (deviceCheck === "mobile") {
      validTabs = ["lesson", "assignment", "textbook", "replay"];

      if (!!tab.get("tab") && validTabs.includes(tab.get("tab"))) {
        return tab.get("tab");
      } else {
        return "lesson";
      }
    } else {
      validTabs = ["lesson", "assignment"];
      if (!!tab.get("tab") && validTabs.includes(tab.get("tab"))) {
        return tab.get("tab");
      } else {
        return "lesson";
      }
    }
  };

  const [tabSelect, setTabSelect] = useState(initTab()); //lesson, textbook, assignment, reply

  const setPushState = (stateName) => {
    setTabSelect(stateName);
    window.history.replaceState(
      stateName,
      null,
      `/lessons/${lessonDetail?.id}?tab=${stateName}`
    );
  };

  const TabBtn = ({ name, tabValue, tailwindStyle }) => {
    return (
      <div
        css={tabBtnsStyle}
        className={`text-lg relative w-fit cursor-pointer ${
          tabSelect === tabValue
            ? "active font-bold text-black"
            : "font-medium text-gray400"
        } ${tailwindStyle}`}
        onClick={() => {
          setPushState(tabValue);
        }}
      >
        {name}
      </div>
    );
  };

  const [textbooks, setTextbooks] = useState(null);

  useEffect(() => {
    if (!!lessonDetail?.lecture_id) {
      getLessonDownloads({ lectureId: lessonDetail?.lecture_id }).then(
        (response) => {
          setTextbooks(response?.data);
        }
      );
    }
  }, [lessonDetail]);

  const tabBtnsStyle = css`
    &.active {
      color: black !important;
    }

    &.active::after {
      position: absolute;
      content: "";
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 5.5px;
      border-radius: 3.5px;
      background-color: ${color.yellow600};
      border: 1px solid ${color.yellow600};
    }
  `;

  const onClickDownload = (textbook) => {
    getLessonTextbookDownload({ lessonId: textbook?.id }).then((response) => {
      const blob = new Blob([response.data]);
      const fileObjectUrl = window.URL.createObjectURL(blob);
      const fileType = response?.data?.type?.split("/")[1];
      const link = document.createElement("a");
      link.href = fileObjectUrl;
      link.style.display = "none";

      link.download = `${textbook.title}-교재.${fileType}`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(fileObjectUrl);
    });
  };

  return (
    <div className="flex flex-col web:w-[354px] tablet:w-full tablet:min-w-[330px] mobile:min-w-[330px] mobile:w-full flex-shrink-0">
      <span className="text-xs">&nbsp;&nbsp;&nbsp;</span>
      <div className="flex items-center justify-between">
        <TabBtn name="강의 목록" tabValue="lesson" />
        {lessonDetail?.is_mission && (
          <TabBtn
            name="미션"
            tabValue="assignment"
            tailwindStyle="web:ml-[28px] web:mr-auto"
          />
        )}

        <TabBtn name="교재" tabValue="textbook" tailwindStyle="web:hidden" />
        <TabBtn name="Q&A" tabValue="reply" tailwindStyle="web:hidden" />

        <Popup
          trigger={
            <button className="tablet:hidden mobile:hidden text-white bg-yellow700 font-bold text-sm flex items-center justify-center p-[6px] rounded-[5px]">
              강의 자료 다운
            </button>
          }
          modal
          nested
          overlayStyle={{
            background: "rgba(0, 0, 0, 0.75)",
          }}
          lockScroll
        >
          {(close) => (
            <div className="flex flex-col pt-[26px] pb-2 px-[35px] bg-white max-w-[500px] w-[50vw] rounded-[12px] overflow-auto max-h-[427px]">
              <div className="flex justify-between w-full mb-[27px]">
                <span className="text-lg font-bold">강의 자료 다운</span>
                <button onClick={close}>
                  <img src={closeSm} alt="닫기" className="w-[13px] h-[13px]" />
                </button>
              </div>
              {!!textbooks && textbooks?.length > 0 ? (
                <div className="flex flex-col">
                  {!!textbooks &&
                    textbooks?.length > 0 &&
                    textbooks?.map((textbook) => {
                      return (
                        <DownloadComponent
                          key={textbook?.id}
                          textbook={textbook}
                        />
                      );
                    })}
                </div>
              ) : (
                <span className="mb-4">자료가 없는 강의입니다</span>
              )}
            </div>
          )}
        </Popup>
      </div>
      {tabSelect === "reply" && (
        <>
          <QnaTitle
            qnasCount={qnas?.length}
            titleName="선생님 질문있어요!"
            titleContent={`<span className="font-bold">선생님 질문있어요</span> <br />
              <span>
                이곳은 학생들이 강의 내용에 대해 질문하는 공간입니다. 그 외의
                댓글은 삭제될 수 있습니다. <br /> <br /> 수강 신청, 강의 이용
                방법 등이 궁금하신가요? <br /> 화면 오른쪽 아래 채널톡을 눌러
                문의해주세요!
              </span>`}
          />
          <ReplyInputForm
            commentable_type="Lesson"
            commentable_id={lessonDetail?.id}
          />
        </>
      )}

      <div
        id="lessonSidebar"
        className="rounded-[5px] border border-gray100 flex flex-col py-[26px] px-[18px] web:mt-[11px] tablet:mt-[22px] mobile:mt-[22px] w-full web:overflow-auto web:max-h-[662px]"
      >
        {tabSelect === "lesson" && (
          <LessonLists
            lectureId={lessonDetail?.lecture_id}
            lessonId={lessonDetail?.id}
            selectedVideoId={selectedVideoId}
            lessonMode={lessonDetail?.mode}
          />
        )}
        {tabSelect === "textbook" &&
          (textbooks?.length !== 0 ? (
            <TextbookLists textbooks={textbooks} lessonId={lessonId} />
          ) : (
            <span>자료가 없는 강의입니다</span>
          ))}
        {tabSelect === "assignment" && (
          <AssignmentLists
            lessonId={lessonId}
            lectureId={lessonDetail?.lecture_id}
          />
        )}
        {tabSelect === "reply" &&
          (qnas?.length !== 0 ? (
            <Reply qnas={qnas} replyTitle="다른 학생들 질문 보기" />
          ) : (
            <div className="flex flex-col items-center justify-center text-sm font-medium text-gray300 mt-[45px] mb-[32px]">
              <img
                src={congratu}
                alt="미션없음"
                className="w-[39px] mb-[28px]"
              />
              <span>아직 질문을 작성한 친구가 없습니다!</span>
              <span>첫번째로 질문을 작성해주세요!</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LessonsSidebar;
