import { css } from "@emotion/react";
import Swal from "sweetalert2";

const ReviewNewTitle = ({ titleRef }) => {
  return (
    <div
      css={css`
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
      `}
      className="py-[15px] w-full bg-white fixed top-0 z-[1] max-w-[640px]"
      ref={titleRef}
    >
      <div className="flex justify-between items-center mx-[15px]">
        <span className="font-medium">리뷰를 작성해 주세요!</span>
      </div>
    </div>
  );
};

export default ReviewNewTitle;
