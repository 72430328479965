import { getNicePayCheck } from "../components/api";

const startPurchaseEvent = (price, items = []) => {
  fbq("track", "InitiateCheckout");
};

const trialEvent = (price, ltv = 0.0) => {
  fbq("track", "StartTrial", {
    value: price,
    currency: "KRW",
    predicted_ltv: ltv,
  });
};

const gtagPurchase = ({ paymentId }) => {
  getNicePayCheck({ paymentId: paymentId }).then((response) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: "KRW",
        value: Number(response?.data?.value),
        shipping: response?.data?.shipping_fee,
        transaction_id: response?.data?.transaction_id,
        coupon: response?.data?.coupon,
        items: response?.data?.items,
      },
    });
  });
};

const checkNicePurchaseEvent = (paymentId) =>
  new Promise((resolve, reject) => {
    $.ajax({
      method: "GET",
      url: `/api/nice_payments/${paymentId}/check`,
      success: (res) => {
        try {
          kPixel.purchase({
            total_quantity: "1",
            total_price: res?.value,
            currency: res?.currency,
            products: [
              {
                id: res?.items[0]?.item_id,
                name: res?.items[0]?.item_name,
              },
            ],
          });
          fbq("track", "Purchase", {
            value: res?.value,
            currency: res?.currency,
          });

          var _nasa = {};
          window.wcs_add = { wa: "s_18b486de454c" };
          wcs.inflow("smallbigclass.com");
          _nasa["cnv"] = wcs.cnv("1", Number(res?.value));
          wcs_do(_nasa);
          var _nasa = {};
          gtagPurchase({ paymentId: paymentId });

          gtag("event", "timing_complete", {
            event_callback: function () {
              resolve();
            },
          });
        } catch (error) {
          console.error(error);
          reject(error);
        }
      },
      error: (err) => {
        reject(err);
      },
    });
  });

const signUpEvent = () => {
  kPixel.completeRegistration();
  gtag("event", "login");
  fbq("track", "Lead");
};

const viewContent = (lectureId) => {
  $.ajax({
    method: "GET",
    url: `/api/lectures/${lectureId}/gtag_items`,
    success: (res) => {
      try {
        const items = res;

        kPixel.viewContent({
          id: lectureId,
        });

        fbq("track", "ViewContent", {
          item: items[0],
        });
      } catch (error) {
        console.error(error);
      }
    },
  });
};

const watchingLesson = ({
  current_position,
  lecture_id,
  lesson_id,
  user_id,
}) => {
  gtag("event", "watching_lesson", {
    current_position,
    time: Date.now(),
    lesson_id,
    lecture_id,
    user_id,
  });
};

const playLesson = ({ current_position, lecture_id, lesson_id, user_id }) => {
  gtag("event", "play_lesson", {
    current_position,
    time: Date.now(),
    lesson_id,
    lecture_id,
    user_id,
  });
};

const pauseLesson = ({ current_position, lecture_id, lesson_id, user_id }) => {
  gtag("event", "pause_lesson", {
    current_position,
    time: Date.now(),
    lesson_id,
    lecture_id,
    user_id,
  });
};

global.startPurchaseEvent = startPurchaseEvent;
global.trialEvent = trialEvent;
global.checkNicePurchaseEvent = checkNicePurchaseEvent;
global.signUpEvent = signUpEvent;
global.viewContent = viewContent;
global.watchingLesson = watchingLesson;
global.playLesson = playLesson;
global.pauseLesson = pauseLesson;
