import replyEmpty from "../../assets/icons/reply_empty.svg";
import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import congratu from "../../assets/icons/congratu.svg";
import { color } from "../../tailwindStyle";
import Zoom from "react-medium-image-zoom";
import { getAssignments, getMission } from "../../api";
import { find } from "lodash";

export const AssignmentLists = ({ lessonId }) => {
  const [mission, setMission] = useState(null);
  const [myMission, setMyMission] = useState(null);

  useEffect(() => {
    getMission({ lessonId: lessonId }).then((response) => {
      setMission(response?.data);
    });
  }, []);

  const [assignments, setAssignments] = useState(null);
  useEffect(() => {
    getAssignments({ lessonId: lessonId }).then((response) => {
      setAssignments(response?.data?.assignments);
    });
  }, []);

  const assignmentStyle = css`
    .assignmentStyle:last-child {
      border-bottom: 0 solid black;
    }

    .assignmentStyle {
      border-bottom: 1px solid ${color["gray200"]};
    }
  `;

  const loadOtherMissionPage = (assignment) => {
    if (assignment?.is_writer || assignment?.is_auth) {
      //is_writer는 작성자인지 체크, is_auth는 선생님같이 관리자 권한을 가지고 있는 사람
      return () => (window.location.href = `/assignments/${assignment?.id}`);
    } else {
      if (assignment?.is_private) {
        return () =>
          Swal.fire({
            title: "비공개 미션 입니다",
            confirmButtonText: "확인",
          });
      } else {
        return () => (window.location.href = `/assignments/${assignment?.id}`);
      }
    }
  };

  useEffect(() => {
    const m = find(assignments, (assignment) => {
      return assignment?.is_writer === true;
    })
    setMyMission(m);
  }, [assignments])

  return (
    <div className="flex flex-col" css={assignmentStyle}>
      <span className="font-bold">{mission?.title}</span>
      <Zoom>
        <div dangerouslySetInnerHTML={{ __html: mission?.content }}></div>
      </Zoom>

      {!!myMission ? (
        <a
          href={`/assignments/${myMission?.id}`}
          className="flex justify-center items-center text-white bg-black rounded-[5px] py-[11px] font-bold w-full mt-[14px] cursor-pointer"
        >
          내가 한 미션 보러가기
        </a>) : (
        <a
          href={`/lessons/${lessonId}/assignments/new`}
          className="flex justify-center items-center text-white bg-black rounded-[5px] py-[11px] font-bold w-full mt-[14px] cursor-pointer"
        >
          미션 하러가기
        </a>
      )}

      <span className="font-bold mt-[38px]">미션 게시판</span>

      {assignments?.length !== 0 ? (
        assignments?.map((assignment) => {
          return (
            <div
              key={assignment?.id}
              onClick={loadOtherMissionPage(assignment)}
              className={`border-gray200 pt-[8px] pb-[10px] flex items-center justify-between assignmentStyle cursor-pointer ${assignment?.is_writer ? "" : "other_mission_view"
                }`}
            >
              <div className="flex flex-col max-w-[78%]">
                <div className="flex items-center w-full">
                  <span className="text-sm truncate">{assignment?.title}</span>
                  {/* 비공개분기 */}
                  {assignment?.is_private && (
                    <div className="text-white bg-gray200 text-[10px] flex items-center justify-center px-1 rounded-full text-sm whitespace-nowrap flex-shrink-0 ml-2">
                      비공개
                    </div>
                  )}
                  {/* 내가 작성한 미션 분기 */}
                  {assignment?.is_writer && (
                    <div className="text-white bg-yellow900 text-[10px] flex items-center justify-center px-1 rounded-full text-sm whitespace-nowrap flex-shrink-0 ml-2">
                      내 미션
                    </div>
                  )}
                </div>
                <span className="text-xs font-medium text-gray500">
                  {assignment?.nickname || assignment?.name}
                </span>
              </div>
              <div className="flex shrink-0">
                <img src={replyEmpty} alt="댓글이없을때" className="w-[15px]" />
                <span className="ml-1 text-gray300">
                  {assignment?.comments_count}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex flex-col items-center justify-center text-sm font-medium text-gray300 mt-[45px] mb-[32px]">
          <img src={congratu} alt="미션없음" className="w-[39px] mb-[28px]" />
          <span>아직 미션을 작성한 친구가 없습니다!</span>
          <span>첫번째로 미션을 작성해주세요!</span>
        </div>
      )}
    </div>
  );
};
