import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import {
  WatchingLectures,
  OriginalClass,
  RankSwiper,
  KitClass,
} from "../components/home/index";
import BannerSwiper from "../components/shared/BannerSwiper";
import Layout from "../components/Layout";
import { getMainBanners } from "../api";
import { useUserSignedIn } from "../hooks/useUserSignedIn";

const Home = () => {
  const [banners, setBanners] = useState(null);

  const { isUserSignedIn } = useUserSignedIn();

  useEffect(() => {
    getMainBanners().then((response) => {
      setBanners(response?.data);
    });
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("error_message")) {
      Swal.fire({
        icon: "error",
        title: sessionStorage.getItem("error_message"),
        confirmButtonText: "확인",
        willClose: () => {
          sessionStorage.removeItem("error_message");
        },
      });
    }
  }, []);

  return (
    <Layout>
      {/* 메인배너 react */}
      <div className="main_roll_banner">
        <BannerSwiper banners={banners} />
      </div>

      {/* 수강중인 클래스 */}
      {isUserSignedIn && <WatchingLectures />}

      {/* 오리지널 클래스 */}
      <OriginalClass />

      {/* 1위부터 5위 랭크 */}
      <RankSwiper />

      {/* 키트 클래스 */}
      <KitClass />

      {/* 소통하는 강의 방식 */}
      {/* <EduTactic /> */}
    </Layout>
  );
};

export default Home;
