import { css } from "@emotion/react";
import TextTruncate from "react-text-truncate";
import StarRankGroup from "../shared/StarRankGroup";

const ReviewSwiperContent = ({ review, setIsModalOpen, setSelectReview }) => {
  return (
    <div
      className="pt-[16px] pb-[20px] px-[18px] rounded-[10px] border border-gray200 flex flex-col justify-between h-[231px] cursor-pointer break-words"
      onClick={() => {
        setSelectReview(review);
        setIsModalOpen(true);
      }}
    >
      <div className="flex items-center mb-2">
        <span className="truncate whitespace-nowrap min-w-[3vw] max-w-[140px] text-sm font-bold mr-2 tablet:w-[25%] tablet:overflow-hidden">
          {review?.user?.nickname || review?.user?.name}
        </span>
        <StarRankGroup
          value={review?.rating}
          webSize="16"
          mobileSize="14"
          gapRatio="3.4px"
        />
      </div>
      <TextTruncate
        line={review.thumb_images.length === 0 ? 8 : 4}
        element="span"
        truncateText="…"
        containerClassName="text-sm text-gray500 mb-auto"
        text={review?.body}
        textTruncateChild={
          <span className="underline text-blue300">더보기</span>
        }
      />

      {review?.thumb_images?.length !== 0 && (
        <div className="grid grid-cols-3 gap-2 h-[76px]">
          {review?.thumb_images?.map((image, index) => {
            return (
              <div
                key={index}
                css={css`
                  background-image: url(${image});
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  width: 100%;
                  height: 100%;
                `}
              ></div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ReviewSwiperContent;
