import ahoy from "ahoy.js";
import { Auth } from "aws-amplify";

global.ahoy = ahoy;

const getBearerToken = async () => {
  let headerParams = {};
  try {
    const cognitoToken = await Auth.currentSession();

    const jwtToken = cognitoToken.getAccessToken().getJwtToken();
    headerParams = { Authorization: `Bearer ${jwtToken}` };
  } catch {
    headerParams = {};
  }
  return headerParams;
};

ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  headers: getBearerToken(),
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60, // 2 years
});

ahoy.watchingLesson = (lesson_id, time) => {
  ahoy.track("watching", {
    lesson_id,
    status: "start",
    time,
  });
};

ahoy.finishingLesson = (lesson_id) => {
  gtag("event", "finishing_lesson", {
    status: "finish",
    lesson_id,
  });

  ahoy.track("watching", {
    status: "finish",
    lesson_id,
  });
};

ahoy.writeDownAnswer = (called_question_id, answer, isCorrect) => {
  gtag("event", "answered", {
    answer,
    called_question_id,
    isCorrect,
  });

  ahoy.track("answered", {
    answer,
    called_question_id,
    isCorrect,
  });
};
