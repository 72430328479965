import { css } from "@emotion/react";

import useDeviceCheck from "../../hooks/useDeviceCheck";

import logoFooter from "../../assets/icons/logo_footer.svg";
import youtubeFooter from "../../assets/icons/youtube_footer.svg";
import instaFooter from "../../assets/icons/insta_footer.svg";

const Footer = () => {
  const device = useDeviceCheck();
  return device === "web" ? (
    <div className="flex flex-col max-w-[1230px] px-[15px] mx-auto pt-[60px] pb-[80px] text-[#BBBBBB] w-full leading-[1.2]">
      <img src={logoFooter} alt="푸터로고" className="max-w-[204px]" />

      <div className="mt-[30px] flex items-center justify-between">
        <div
          className="flex items-center"
          css={css`
            a {
              margin: 0 15px;
            }

            a:first-of-type {
              margin-left: 0;
            }
          `}
        >
          <a
            className="text-[15px] font-[700]"
            href="/boards?board_type=notice"
          >
            공지사항
          </a>
          <a className="text-[15px] font-[700]" href="/boards?board_type=event">
            이벤트
          </a>
          <a
            className="text-[15px] font-[700]"
            href="/privacy_policy"
            target="_blank"
          >
            개인정보처리방침
          </a>
          <a
            className="text-[15px] font-[700]"
            href="/terms_and_conditions"
            target="_blank"
          >
            이용약관
          </a>
          <a
            className="text-[15px] font-[700]"
            href="https://smallbigclass.channel.io"
          >
            온라인 문의
          </a>
          <a
            className="text-[15px] font-[700]"
            href="/cancellation_policies"
            target="_blank"
          >
            환불규정
          </a>
        </div>
        <div className="flex ml-auto">
          <a href="https://www.youtube.com/@smallbigclass" className="mr-2">
            <img
              src={youtubeFooter}
              alt="유투브이동"
              className="w-[46px] h-[46px]"
            />
          </a>
          <a href="https://www.instagram.com/smallbigclass/">
            <img
              src={instaFooter}
              alt="인스타이동"
              className="w-[46px] h-[46px]"
            />
          </a>
        </div>
      </div>
      <div className="mt-[30px] text-[13px] ">
        (주)스몰빅클래스 | 대표 조승우
      </div>
      <div className="mt-[5px] flex flex-col leading-[20px] font-[300] text-[13px]">
        <span>
          서울특별시 관악구 남부순환로 1793, 9층(백광빌딩), 충청남도 아산시
          배방읍 희망로 46번길 45-11. 3층 306호(충남콘텐츠 기업지원센터)
        </span>
        <span>
          선생님 지원/교육기관 및 기업 제휴 : smallbig_md@smallbigclass.com
        </span>
        <span>
          사업자등록번호 : 824-86-02150 | 통신판매업신고 : 제
          2023-충남아산-1504호
        </span>
      </div>
    </div>
  ) : (
    <div className="flex flex-col max-w-[1230px] px-[15px] mx-auto py-[50px] text-[#BBBBBB] w-full leading-[1.2]">
      <div className="flex items-center justify-between">
        <img src={logoFooter} alt="푸터로고" className="max-w-[146px]" />
        <div className="flex ml-auto">
          <a href="https://www.youtube.com/@smallbigclass" className="mr-2">
            <img
              src={youtubeFooter}
              alt="유투브이동"
              className="w-[30px] h-[30px]"
            />
          </a>
          <a href="https://www.instagram.com/smallbigclass/">
            <img
              src={instaFooter}
              alt="인스타이동"
              className="w-[30px] h-[30px]"
            />
          </a>
        </div>
      </div>

      <div
        className="mt-[24px] flex flex-col font-[700] text-[13px]"
        css={css`
          a {
            margin: 0 10px;
          }

          a:first-of-type {
            margin-left: 0;
          }
        `}
      >
        <div className="flex">
          <a href="/boards?board_type=notice">공지사항</a>
          <a href="/boards?board_type=event">이벤트</a>
          <a href="/privacy_policy" target="_blank">
            개인정보처리방침
          </a>
          <a href="/terms_and_conditions" target="_blank">
            이용약관
          </a>
        </div>
        <div className="flex mt-[15px]">
          <a href="https://smallbigclass.channel.io">온라인 문의</a>
          <a href="/cancellation_policies" target="_blank">
            환불규정
          </a>
        </div>
      </div>
      <div className="mt-[30px] text-[13px]">
        (주)스몰빅클래스 | 대표 조승우
      </div>
      <div className="mt-[5px] flex flex-col leading-[15px] font-[300] text-[11px]">
        <span>
          서울특별시 관악구 남부순환로 1793, 9층(백광빌딩), 충청남도 아산시
          배방읍 희망로 46번길 45-11. 3층 306호(충남콘텐츠 기업지원센터)
        </span>
        <span>
          선생님 지원/교육기관 및 기업 제휴 : smallbig_md@smallbigclass.com
        </span>
        <span>
          사업자등록번호 : 824-86-02150 | 통신판매업신고 : 제
          2023-충남아산-1504호
        </span>
      </div>
    </div>
  );
};

export default Footer;
