import Swal from "sweetalert2";
import { profileEditInput } from "../../style";
import { deleteChildInfo } from "../../api";

const ChildCard = ({ register, child, index, remove, getValues, errors }) => {
  const ChildrenNicknameInput = ({ register, index }) => {
    return (
      <input
        {...register(`user.children_attributes.${index}.nickname`, {
          required: true,
        })}
        type="text"
        css={profileEditInput}
        defaultValue={getValues(`user.children_attributes.${index}.nickname`)}
      />
    );
  };

  const ChildrenBirthInput = ({ register, index }) => {
    return (
      <input
        {...register(`user.children_attributes.${index}.birthday`, {
          required: true,
        })}
        type="date"
        css={profileEditInput}
        defaultValue={getValues(`user.children_attributes.${index}.birthday`)}
      />
    );
  };

  const ChildrenGenderFemaleInput = ({ register, index }) => {
    return (
      <input
        type="radio"
        id={`user_children_attributes_${index}_female`}
        defaultValue="female"
        {...register(`user.children_attributes.${index}.gender`)}
      />
    );
  };

  const ChildrenGenderMaleInput = ({ register, index }) => {
    return (
      <input
        type="radio"
        id={`user_children_attributes_${index}_male`}
        defaultValue="male"
        {...register(`user.children_attributes.${index}.gender`)}
      />
    );
  };

  const ChildrenGenderNoneInput = ({ register, index }) => {
    return (
      <input
        type="radio"
        id={`user_children_attributes_${index}_none`}
        defaultValue="none"
        {...register(`user.children_attributes.${index}.gender`)}
      />
    );
  };

  function handleRemove(child, index) {
    if (child.isSave) {
      Swal.fire({
        title: "자녀 정보를 삭제하시겠습니까?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteChildInfo({ childId: child?.id }).then(() => {
            iziToast.success({
              message: "자녀 정보 삭제되었습니다.",
              position: "topCenter",
              timeout: 1000,
              onOpening: () => {
                remove(index);
              },
            });
          });
        }
      });
    } else {
      Swal.fire({
        title: "자녀 정보를 삭제하시겠습니까?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          remove(index);
        }
      });
    }
  }

  return (
    <>
      <div className="flex items-center justify-between mt-[45px]">
        <span className="text-lg font-bold">자녀 {index + 1}</span>
        <button
          className="text-sm text-gray400"
          type="button"
          onClick={() => handleRemove(child, index)}
        >
          삭제하기
        </button>
      </div>
      <div>
        <label className="font-sans font-medium text-sm mt-[25px] inline-block mb-[10px] ml-2">
          닉네임
        </label>

        <ChildrenNicknameInput register={register} index={index} />

        {errors?.user?.children_attributes[index]?.nickname && (
          <p className="text-xs text-red-500">이름을 입력해주세요.</p>
        )}

        <label
          htmlFor={`user_children_attributes_${index}_birthday`}
          className="font-sans font-medium text-sm mobile:mr-4 mt-[18px] inline-block mb-[10px] ml-2"
        >
          생년 월일
        </label>
        <ChildrenBirthInput register={register} index={index} />

        {errors?.user?.children_attributes[index]?.birthday && (
          <p className="text-xs text-red-500">생일을 입력해주세요.</p>
        )}

        <label
          htmlFor="input_gender"
          className="ml-2 text-sm font-medium mt-[25px] inline-block mb-[10px]"
        >
          성별
        </label>

        <div className="flex flex-col w-full ml-2" id="input_gender">
          <div className="flex items-center mt-auto">
            <ChildrenGenderFemaleInput register={register} index={index} />
            <label
              className="ml-1 font-sans text-xs"
              htmlFor={`user_children_attributes_${index}_female`}
            >
              여자
            </label>
          </div>
          <div className="flex items-center mt-1">
            <ChildrenGenderMaleInput register={register} index={index} />
            <label
              className="ml-1 font-sans text-xs"
              htmlFor={`user_children_attributes_${index}_male`}
            >
              남자
            </label>
          </div>
          <div className="flex items-center mt-1">
            <ChildrenGenderNoneInput register={register} index={index} />
            <label
              className="ml-1 font-sans text-xs"
              htmlFor={`user_children_attributes_${index}_none`}
            >
              선택 안함
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChildCard;
