import GoBackBtn from "./GoBackBtn";
import RatingBar from "./RatingBar";
import RatingSection from "./RatingSection";
import ReviewAll from "./ReviewAll";
import ReviewModal from "./ReviewModal";
import ReviewSwiper from "./ReviewSwiper";

export {
  GoBackBtn,
  RatingBar,
  RatingSection,
  ReviewAll,
  ReviewModal,
  ReviewSwiper,
};
