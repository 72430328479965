import { useEffect } from "react";
import { css } from "@emotion/react";
import { postKakaoLinkUp } from "../../api";
import Swal from "sweetalert2";

const loaderStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const KakaoLinkUpLoading = ({ code }) => {
  useEffect(() => {
    postKakaoLinkUp({ code: code })
      .then(() => {
        location.href = "/mypage/edit";
      })
      .catch((error) => {
        switch (error.response.data.message) {
          case "already_exist":
            Swal.fire({
              icon: "error",
              text: "SNS계정과 연동된 아이디가 이미 존재합니다.",
              confirmButtonText: "마이페이지로 돌아가기",
              willClose: () => {
                location.href = "/mypage/edit";
              },
            });
            break;
          default:
            Swal.fire({
              icon: "error",
              title: "잠시 후 다시 시도해주세요.",
              text: "문제가 계속 발생 할 경우 채널톡을 통해 관리자에게 문의해주세요.",
              confirmButtonText: "확인",
              willClose: () => {
                location.href = "/mypage/edit";
              },
            });
            break;
        }
      });
  }, []);

  return (
    <div css={loaderStyle}>
      <div className="lds-dual-ring"></div>
      <p>연동 진행 중입니다.</p>
      <p>새로 고침하시지 마시고 잠시 기다려주세요.</p>
    </div>
  );
};

export default KakaoLinkUpLoading;
