import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import moment from "moment";
import { getLessonDownloads } from "../../api";
import Popup from "reactjs-popup";
import closeSm from "../../assets/icons/close_sm.svg";

import { useState } from "react";
import DownloadComponent from "../shared/DownloadComponent";

const MyDnavLectureCard = ({ lecture }) => {
  const [textbooks, setTextbooks] = useState(null);

  const hasDelivery = lecture?.has_delivery;
  const hasDownload = lecture?.has_download;

  return (
    <>
      <div className="flex flex-col p-3 bg-white border-2 rounded-lg card border-gray100 h-fit">
        <div
          className="grid grid-cols-1 cursor-pointer"
          onClick={() => (location.href = `https://class.dnav.co.kr`)}
        >
          <LazyLoadImage src={lecture?.image_url} effect="blur" />
          <div className="flex flex-col">
            <span className="w-full overflow-hidden font-bold mt-[13px]">
              {lecture?.title}
            </span>
          </div>
        </div>

        {/* 버튼모음 */}
        <div className="flex flex-col">
          <div>
            <a
              href={`https://class.dnav.co.kr`}
              className="text-black w-full font-bold rounded-[7px] bg-[#45EB0B] py-2 flex justify-center items-center web:mt-[18px] tablet:mt-[15px] mobile:mt-[15px] lecture_btn text-sm"
            >
              디냅 사이트로 이동
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDnavLectureCard;
