import { Swiper, SwiperSlide } from "swiper/react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import LazyLoad from "react-lazyload";
import { WatchingLecturesTitle } from "./SectionTitle";
import { getWatchingLectures } from "../../api";
import { useState, useEffect } from "react";

const WatchingLectures = () => {
  const deviceCheck = useDeviceCheck();
  const [watchingLectures, setWatchingLectures] = useState(null);
  useEffect(() => {
    getWatchingLectures({ page: 1, per: 5 }).then((response) => {
      setWatchingLectures(response?.data);
    });
  }, []);

  return (
    <>
      {watchingLectures?.length !== 0 && (
        <div className="w-full default_margin web:mt-17 tablet:mt-17 mobile:mt-10">
          <WatchingLecturesTitle />
          <Swiper
            spaceBetween={15}
            slidesPerView={
              deviceCheck === "web" || deviceCheck === "tablet" ? 3 : 1.15
            }
          >
            {watchingLectures?.map((lecture) => {
              return (
                <SwiperSlide
                  key={lecture?.id}
                  onClick={() => {
                    location.href = `/lessons/${lecture?.current_lesson?.id}`;
                  }}
                >
                  <div className="flex flex-col cursor-pointer">
                    <LazyLoad height={220} once>
                      <img
                        className="aspect-[390/219] object-contain"
                        src={
                          lecture?.current_lesson_thumb || lecture?.image?.url
                        }
                        alt={`수강중인클래스${lecture?.id}`}
                      />
                    </LazyLoad>

                    <span className="font-bold truncate web:mt-6 tablet:mt-6 mobile:mt-3 web:text-2xl tablet:text-2xl mobile:text-xl">
                      {lecture?.title}
                    </span>
                    <span className="truncate text-gray45 mobile:hidden">
                      {lecture?.teacher?.name}
                    </span>
                    <span className="truncate web:text-sm tablet:text-sm mobile:text-sm text-gray500">
                      {lecture?.current_lesson?.title}
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default WatchingLectures;
