import defaultProfile from "../../assets/icons/default_profile.svg";
import trashIcon from "../../assets/icons/trash_icon.svg";
import Lightbox from "react-image-lightbox";
import StarRankGroup from "../shared/StarRankGroup";
import moment from "moment";
import { useState } from "react";
import { css } from "@emotion/react";
import "./Review.scss";
import { deleteReview } from "../../api";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

const CommentImgs = ({ review }) => {
  const { currentUser } = useGetCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    // 개별댓글
    <div className="w-full break-all flex border-b border-gray100 pb-[20px] mb-[20px] flex-wrap flex-col singleCard">
      <div className="flex mb-5">
        <img
          src={review?.user?.avatar?.url || defaultProfile}
          alt="기본프로필"
          className="w-[38.5px] h-[38.5px] rounded-full bg-cover bg-center overflow-hidden"
        />
        <div className="flex-col flex ml-[13.3px] mr-auto justify-evenly">
          <div className="flex text-sm">
            <span className="mr-[19.5px] font-bold">
              {review?.user?.nickname || review?.user?.name}
            </span>
            <span className="text-gray200">
              {moment(review?.created_at).format("YYYY-MM-DD")}
            </span>
          </div>
          <StarRankGroup
            value={review?.rating}
            webSize="15"
            mobileSize="15"
            gapRatio="2px"
          />
        </div>
        {currentUser?.id === review?.user?.id && (
          <img
            src={trashIcon}
            alt="댓글삭제버튼"
            onClick={() => {
              deleteReview({ reviewId: review?.id }).then(() => {
                window.location.reload();
              });
            }}
            className="w-[3.2vw] h-auto my-auto max-w-[14.4px] cursor-pointer"
          />
        )}
      </div>

      <span
        className="text-sm leading-[24px] whitespace-pre-wrap"
        css={css`
          word-break: break-word;
        `}
      >
        {review?.body}
      </span>

      <div
        className="flex"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {review?.thumb_images?.map((image, index) => {
          return (
            <div
              key={`전체리뷰${review.id}_${index}`}
              className="mt-[21px]"
              css={css`
                width: 77px;
                height: 77px;
                background-image: url(${image});
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 1rem;
              `}
            ></div>
          );
        })}
      </div>

      {isOpen && (
        <div>
          <Lightbox
            mainSrc={review?.images[photoIndex]}
            nextSrc={review?.images[(photoIndex + 1) % review?.images.length]}
            prevSrc={
              review?.images[
                (photoIndex + review?.images.length - 1) % review?.images.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + review?.images.length - 1) % review?.images.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % review?.images.length)
            }
          />
        </div>
      )}
    </div>
  );
};

export default CommentImgs;
