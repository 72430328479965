import Drawer from "react-bottom-drawer";
import { useState, useCallback, createContext } from "react";

import Layout from "../../components/Layout";
import { Content, OrderContent } from "../../components/sseobolgga";

export const DrawerContext = createContext(null);

const Sseobolgga = () => {
  //공유하기 Drawer에 관련된 State
  const [isVisible, setIsVisible] = useState(false);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const openDrawer = useCallback(() => setIsVisible(true), []);

  return (
    <div className="pb-[52px]">
      <Layout>
        <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
          <Content />
          <Drawer
            duration={250}
            hideScrollbars={true}
            onClose={closeDrawer}
            isVisible={isVisible}
            className="sseobolggaActionSheetStyle"
          >
            <OrderContent />
          </Drawer>
        </DrawerContext.Provider>
      </Layout>
    </div>
  );
};

export default Sseobolgga;
