import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { forEach, isEmpty } from "lodash";
import Swal from "sweetalert2";
import axios from "axios";

const LectureRanking = ({ lectures }) => {
  const [spreadLectures, setSpreadLectures] = useState(
    lectures?.map((lecture) => ({ ...lecture, selected: false }))
  );

  const formik = useFormik({
    initialValues: {
      rankingList: [
        { rank: 1, lectureId: null },
        { rank: 2, lectureId: null },
        { rank: 3, lectureId: null },
        { rank: 4, lectureId: null },
        { rank: 5, lectureId: null },
      ],
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values?.rankingList.find((ranking) => isEmpty(ranking.lectureId))) {
        Swal.fire("5개 강의를 모두 선택해주세요");
      } else {
        Swal.fire({
          title: "설정 완료하시겠습니까?",
          confirmButtonText: "설정",
          showCancelButton: true,
          cancelButtonText: "취소",
          showCloseButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .post("/api/lecture_ranking", {
                ranking_list: values.rankingList,
              })
              .then((response) => {
                window.location.href = "/admin/lectures";
              });
          }
        });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    forEach(formik.values.rankingList, (ranking) => {
      if (!!ranking?.lectureId) {
        forEach(spreadLectures, (spreadLecture, index) => {
          if (spreadLecture?.id == ranking.lectureId) {
            return setSpreadLectures([
              ...spreadLectures.slice(0, index),
              { ...spreadLecture, selected: true },
              ...spreadLectures.slice(index + 1),
            ]);
          }
        });
      }
    });
  }, [formik.values]);

  return (
    <form className="mx-12" onSubmit={formik.handleSubmit}>
      <h3 className="mb-2 text-xl font-bold text-black">강좌 선택하기</h3>
      <div className="mb-4">
        {formik.values.rankingList?.map((rankingInfo, index) => (
          <div className="my-4" key={`select${index}`}>
            <div>{`${rankingInfo.rank}위`}</div>
            <select
              className="mr-4 border border-black"
              name={`rankingList.${index}.lectureId`}
              onChange={formik.handleChange}
            >
              <option value="">없음</option>
              {spreadLectures?.map((lecture) => {
                return (
                  <option
                    value={lecture?.id}
                    key={lecture?.id}
                    disabled={lecture?.selected}
                  >
                    {lecture?.title}
                  </option>
                );
              })}
            </select>
          </div>
        ))}
      </div>
      <button
        type="submit"
        className="w-16 font-normal rounded-sm bg-blue300 text-white py-2"
      >
        제출
      </button>
    </form>
  );
};

export default LectureRanking;
