import { css } from "@emotion/react";
const NowLoading = ({ mt }) => {
  const nowLoadingStyle = css`
    margin-top: ${mt}px;
  `;
  return (
    <div className="default_margin" css={nowLoadingStyle}>
      NOW LOADING...
    </div>
  );
};

export default NowLoading;
