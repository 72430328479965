import labelHot from "../../assets/icons/label_hot.svg";
import labelLive from "../../assets/icons/label_live.svg";
import labelNew from "../../assets/icons/label_new.svg";
import labelVod from "../../assets/icons/label_vod.svg";
import labelLiveFinished from "../../assets/icons/label_live_finished.svg";

const Label = ({ status, isFinished, objectType }) => {
  let lableElements = {
    img: "",
  };

  if (status === "live") {
    lableElements = {
      img: isFinished ? labelLiveFinished : labelLive,
    };
  }

  if (status === "vod") {
    lableElements = {
      img: labelVod,
    };
  }

  if (status === "hot") {
    lableElements = {
      img: labelHot,
    };
  }

  if (status === "new") {
    lableElements = {
      img: labelNew,
    };
  }

  return (
    status !== "none" && (
      <img
        src={lableElements?.img}
        alt="강의라벨"
        className={`web:w-[50px] tablet:w-[36px] mobile:w-[36px] rounded-[3px] ${
          status !== "none" && objectType === "lecturesKids" ? "mr-[6px]" : ""
        } ${lableElements?.style}`}
      />
    )
  );
};

export default Label;
