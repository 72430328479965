import { MypageTitle } from "../components/shared/Title";
import Aside from "../components/shared/MypageAside";
import Layout from "../components/Layout";
import { useState, useEffect } from "react";
import { getMySseobolgga } from "../api";
import {
  MyLectureCardNone,
  MyLectureCardExist,
} from "../components/mypageLectures/index";

const MypageSseobolgga = (props) => {
  const [myLectures, setMyLectures] = useState(null);

  useEffect(() => {
    getMySseobolgga().then((response) => {
      setMyLectures(response?.data);
    });
  }, []);

  return (
    <Layout>
      <div className="flex mypage_lectures default_margin mobile:flex-col max-w-[1200px] h-full">
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>

        <div className="flex-grow w-full mobile:mt-5">
          <MypageTitle title="써볼까?!" />
          {myLectures &&
            (myLectures?.length !== 0 ? (
              <MyLectureCardExist value={myLectures} />
            ) : (
              <MyLectureCardNone />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default MypageSseobolgga;
