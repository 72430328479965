import { useState, useEffect } from "react";

import { getWatchingLectures } from "../../api";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";

import commentSuccess from "../../assets/icons/comment_success.svg";

const ReviewNewSuccess = ({ viewerType, lectureType, lectureId }) => {
  const { isUserSignedIn } = useUserSignedIn();
  const [isWatchingLessonExist, setIsWatchingLessonExist] = useState(false);
  const [watchingLessonId, setWatchingLessonId] = useState(null);
  useEffect(() => {
    if (isUserSignedIn) {
      getWatchingLectures().then((response) => {
        response?.data?.map((watchingLecture) => {
          if (watchingLecture?.id === Number(lectureId)) {
            setIsWatchingLessonExist(true);
            setWatchingLessonId(watchingLecture?.current_lesson?.id);
          }
        });
      });
    }
  }, [isUserSignedIn, lectureId]);

  return (
    <div className="flex flex-col">
      <img
        src={commentSuccess}
        alt="작성해주셔서감사합니다"
        className="mx-auto w-[127px] mt-[102px]"
      />
      <span className="mx-[50px] font-medium mt-[53px] text-center">
        소중한 리뷰 남겨주셔서 감사합니다. 작성해주신 내용은 항상 귀 기울여 듣고
        더욱 발전된 클래스로 찾아뵙겠습니다!
      </span>
      <a
        href={`/reviews?reviewable_id=${lectureId}&reviewable_type=${lectureType}`}
        className="text-yellow600 border border-yellow600 rounded-[5px] font-bold flex justify-center items-center h-[45px] mx-[15px] mt-[86px]"
      >
        내가 쓴 리뷰 보러가기
      </a>
      <a
        href={`/lectures/${viewerType}s`}
        className="text-white bg-yellow600 flex justify-center items-center rounded-[5px] h-[45px] font-bold mx-[15px] mt-[10px]"
      >
        다른 클래스 구경하기
      </a>
      {isWatchingLessonExist && (
        <a
          href={`/lessons/${watchingLessonId}`}
          className="text-white bg-yellow600 flex justify-center items-center rounded-[5px] h-[45px] font-bold mx-[15px] mt-[10px]"
        >
          보던 강의로 돌아가기
        </a>
      )}
    </div>
  );
};

export default ReviewNewSuccess;
