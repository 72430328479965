import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import moment from "moment";
import { orderBy } from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "antd";

import { pageBtnStyle } from "../shared/Pagination";
import {
  getNoticeDetail,
  getEventDetail,
  postBoardReply,
  getBoardReplies,
} from "../../api";
import { resizeIframes, setReturnSession } from "../../utils";
import { useUserSignedIn } from "../../hooks/useUserSignedIn";
import Reply from "./Reply";

import checkBoxClick from "../../assets/icons/checkbox_click.svg";
import checkBoxNotClick from "../../assets/icons/checkbox_not_click.svg";
import useDeviceCheck from "../../hooks/useDeviceCheck";

const Show = ({ boardId, boardType }) => {
  const [board, setBoard] = useState(null);
  const { isUserSignedIn } = useUserSignedIn();
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(window.location.search).get("page")) || 1
  );

  useEffect(() => {
    if (boardType === "notice") {
      getNoticeDetail({ params: boardId }).then((response) => {
        setBoard(response?.data);
      });
    } else if (boardType === "event") {
      getEventDetail({ params: boardId }).then((response) => {
        setBoard(response?.data);
      });
    } else {
      return null;
    }
  }, []);

  const setPushState = (page) => {
    window.history.replaceState(
      page,
      null,
      `${location.pathname}?page=${page}`
    );
  };

  const GenerateTabBtn = ({ tabName }) => {
    const [name, setName] = useState("");

    useEffect(() => {
      if (tabName === "notice") {
        setName("공지사항");
      } else if (tabName === "event") {
        setName("이벤트");
      } else if (tabName === "faq") {
        setName("FAQ");
      } else {
        null;
      }
    }, []);

    return (
      <button
        onClick={() => {
          location.href = `/boards?board_type=${tabName}`;
        }}
        className={`${
          boardType === tabName ? "active" : ""
        } text-sm text-gray400 font-bold relative tab_item`}
      >
        {name}
      </button>
    );
  };

  useEffect(() => {
    resizeIframes("note-video-clip");
  }, [board]);

  const [comments, setComments] = useState(null);
  const [commentInputs, setCommentInputs] = useState({
    id: null,
    type: "",
  });

  const handleGetBoardReplies = () => {
    getBoardReplies({ boardId: boardId, page: currentPage, per: 6 }).then(
      (response) => {
        setComments(response?.data);
      }
    );
  };

  //댓글 로드
  useEffect(() => {
    handleGetBoardReplies();
  }, []);

  const handleClickPageBtn = (page, pageSize) => {
    setPushState(page);
    setCurrentPage(page);

    getBoardReplies({ boardId: boardId, page: page, per: pageSize }).then(
      (response) => {
        setComments(response?.data);
      }
    );
  };

  const handleReplyInputOpen = (targetId, targetType) => {
    isUserSignedIn
      ? setCommentInputs({ id: targetId, type: targetType })
      : Swal.fire({
          title: "로그인이 필요한 서비스입니다.",
          icon: "warning",
          confirmButtonText: "로그인하러 가기",
          showCancelButton: true,
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            setReturnSession();
          }
        });
  };

  return (
    <>
      <div className="mx-auto min-w-[360px] max-w-[640px] px-[15px]">
        <span className="text-lg font-bold">새소식</span>
        {/* 탭 */}

        <div
          className="flex mt-[20px]"
          css={css`
            .tab_item + .tab_item {
              margin-left: 22px;
            }

            .tab_item.active {
              color: black !important;
            }

            .tab_item.active::after {
              position: absolute;
              content: "";
              bottom: -7px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: black;
              border: 1px solid black;
            }
          `}
        >
          <GenerateTabBtn tabName="event" />
          <GenerateTabBtn tabName="notice" />
          {false && <GenerateTabBtn tabName="faq" />}
        </div>

        {/* 컨텐트 */}
        <div className="text-sm mt-[50px] mobile:mt-[34px]">
          <div className="text-[#B2B2B2]">
            {moment(board?.created_at).format("YYYY-MM-DD")}
          </div>

          {board?.is_over && (
            <div className="text-white bg-gray200 rounded-[8px] text-xs px-2 font-medium inline-block mb-[7px]">
              종료
            </div>
          )}
          <div className="font-bold text-xl mobile:font-lg mt-[20px] mobile:mt-[10px]">
            {board?.title}
          </div>

          <div
            className="pt-[20px] w-full "
            css={css`
              table {
                width: 100% !important;
              }
            `}
          >
            <div dangerouslySetInnerHTML={{ __html: board?.content }}></div>
          </div>
        </div>

        {board?.is_comment && (
          <>
            {/* 댓글 쓰는 영역 */}
            <div className="flex flex-col border-b border-black pb-[15px] mt-[30px]">
              <div className="flex items-center justify-between">
                <span className="text-lg font-[700]">
                  ✏️ 댓글(
                  {comments?.meta?.sumby_total_count})
                </span>

                {!(
                  commentInputs?.id === boardId &&
                  commentInputs?.type === "Board"
                ) && (
                  <button
                    type="button"
                    onClick={() => handleReplyInputOpen(boardId, "Board")}
                    className="text-[13px] font-[700] px-[12px] h-fit w-fit flex items-center justify-center rounded-[15px] border border-black pt-[5px] pb-[3px] mobile:pb-[4px]"
                  >
                    댓글쓰기
                  </button>
                )}
              </div>

              {commentInputs?.id === boardId &&
                commentInputs?.type === "Board" && (
                  <Formik
                    initialValues={{
                      content: "",
                      isSecret: false,
                    }}
                    validationSchema={Yup.object().shape({
                      content: Yup.string().required(),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      postBoardReply({
                        boardId: boardId,
                        content: values.content,
                        isSecret: values.isSecret,
                      })
                        .then((res) => {
                          if (!!res) {
                            Swal.fire({
                              icon: "success",
                              title: "댓글이 등록되었습니다.",
                              confirmButtonText: "확인",
                              willClose: () => {
                                handleGetBoardReplies();
                                setCommentInputs(null);
                                resetForm();
                              },
                            });
                          }
                        })
                        .catch(() => {
                          Swal.fire({
                            icon: "error",
                            title: "댓글 작성에 실패했습니다.",
                            text: "문제가 반복될 경우 관리자에게 문의해주세요",
                            confirmButtonText: "확인",
                          });
                        });
                    }}
                  >
                    <Form>
                      <div className="flex flex-col">
                        <Field
                          name="content"
                          as="textarea"
                          type="text"
                          placeholder="댓글을 입력해주세요."
                          className="p-[20px] bg-[#FAFAFA] h-[120px] overflow-auto mt-[20px] mobile:text-[15px] rounded-[10px] focus:outline-none"
                        ></Field>

                        <div className="flex items-center mt-[15px] mobile:mt-[10px]">
                          <label
                            htmlFor="isSecret"
                            className="text-gray99 ml-[6px] text-[13px] font-[700] flex items-center pt-[1px] cursor-pointer"
                            css={css`
                              .isSecret {
                                display: none;
                                position: relative;
                                z-index: -9999;
                              }

                              .hiddenCheckbox {
                                display: block;
                                width: 18px;
                                height: 18px;
                                background: url(${checkBoxNotClick});
                              }

                              .isSecret:checked + .hiddenCheckbox {
                                width: 18px;
                                height: 18px;
                                background: url(${checkBoxClick});
                              }
                            `}
                          >
                            <Field
                              name="isSecret"
                              type="checkbox"
                              id="isSecret"
                              className="isSecret h-[18px] w-[18px] rounded-[3px]"
                            ></Field>
                            <span className="mr-2 hiddenCheckbox"></span>
                            비밀글 쓰기
                          </label>
                          <button
                            type="button"
                            onClick={() => setCommentInputs(null)}
                            className="rounded-[15px] bg-[#ECECEC] text-gray99 w-fit flex items-center justify-center font-[700] text-[13px] px-[12px] pt-[5px] pb-[3px] ml-auto mr-4"
                          >
                            취소
                          </button>
                          <button
                            type="submit"
                            className="rounded-[15px] bg-black text-white w-fit flex items-center justify-center font-[700] text-[13px] px-[12px] pt-[5px] pb-[3px]"
                          >
                            댓글달기
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                )}
            </div>

            {/* 댓글 리스트 */}

            {comments?.comments?.length > 0 &&
              orderBy(comments?.comments, "id", "desc")?.map((comment) => {
                return (
                  <Reply
                    key={comment?.id}
                    comment={comment}
                    handleGetBoardReplies={handleGetBoardReplies}
                    setCommentInputs={setCommentInputs}
                    commentInputs={commentInputs}
                  />
                );
              })}

            <div
              className="flex flex-col items-center justify-center mt-4"
              css={pageBtnStyle}
            >
              <Pagination
                total={comments?.meta?.total_count}
                pageSize={6}
                showSizeChanger={false}
                onChange={handleClickPageBtn}
                current={currentPage}
                defaultPageSize={6}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Show;
