import { useCookies } from "react-cookie";
import { useContext } from "react";
import { LayoutContext } from "./Layout";
import { css } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";

import closeSm from "../assets/icons/close_sm.svg";
import { CloseSm } from "./shared/CloseSm";

import { EventBannerTimer } from "./shared/EventBannerTimer";

SwiperCore.use([Autoplay, EffectFade]);

export const EventBanner = ({ banners }) => {
  const { setEventBannerState: setEventBannerState } =
    useContext(LayoutContext);

  const [cookies, setCookie] = useCookies();
  const tomorrow = new Date();
  tomorrow.setFullYear(
    tomorrow.getFullYear(),
    tomorrow.getMonth(),
    tomorrow.getDate() + 1
  );

  const closeEventBanner = () => {
    setCookie("isEventBannerOpen", "false", {
      path: "/",
      expires: tomorrow,
    });

    setEventBannerState(false);
  };

  return (
    <Swiper
      modules={[Autoplay, EffectFade]}
      effect="fade"
      autoplay={{
        delay: 5000,
      }}
    >
      {banners?.map((banner) =>
        !!banner?.end_at ? (
          <SwiperSlide key={`banner_${banner.id}`}>
            <EventBannerTimer banner={banner} />
          </SwiperSlide>
        ) : (
          <SwiperSlide key={`banner_${banner.id}`}>
            <div
              className="w-screen h-[52px] flex items-center px-[15px]"
              css={css`
                background-image: url(${banner?.background_image?.url});
                background-position: center;
                @media (min-width: 1200px) {
                  background-size: contain;
                }
                @media (max-width: 1199px) {
                  background-size: cover;
                }
              `}
            >
              <div className="flex justify-between items-center mx-auto w-full max-h-[52px]">
                <div className="ml-4 w-full h-full max-w-[18px] max-h-[18px]">
                  <img
                    src={closeSm}
                    alt="가상영역"
                    className="w-full h-full max-w-[18px] max-h-[18px] min-w-[14px] min-h-[14px] invisible"
                  />
                </div>
                <a href={banner?.link} className="mx-auto w-full max-h-[44px]">
                  <img
                    src={banner?.text_image?.url}
                    className="mx-auto w-full max-h-[44px] py-1 object-contain"
                    alt={banner?.title}
                    id={`event_banner_${banner?.title}`}
                  />
                </a>
                <div
                  className="ml-4 w-full h-full max-w-[18px] max-h-[18px] cursor-pointer"
                  onClick={closeEventBanner}
                >
                  <CloseSm color="#FFFFFF" />
                </div>
              </div>
            </div>
          </SwiperSlide>
        )
      )}
    </Swiper>
  );
};
