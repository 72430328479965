import { useState, useRef } from "react";
import React from "react";
import ReactPlayer from "react-player";
import videoPlaying from "../assets/icons/videoPlaying.png";

const LectureVideo = ({ videoUrl, style, title, lectureId, isStartBtn }) => {
  const lectureVideoRef = useRef(null);

  const [playerOption, setPlayerOption] = useState({
    url: videoUrl,
    volume: null,
    playing: false,
    speed: 1,
    played: 0,
    loaded: 0,
    controls: true,
    width: "100%",
    height: "auto",
    progressInterval: 1000,
  });

  return (
    <div className={`relative ${style}`}>
      <ReactPlayer
        ref={lectureVideoRef}
        {...playerOption}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        playsinline
        muted
        onStart={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "video_start",
            ecommerce: {
              video_id: title,
              currentSeconds: lectureVideoRef.current.getCurrentTime(),
              lecture_id: lectureId,
            },
          });
        }}
        onPlay={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "video_play",
            ecommerce: {
              video_id: title,
              currentSeconds: lectureVideoRef.current.getCurrentTime(),
              lecture_id: lectureId,
            },
          });
        }}
        onPause={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "video_pause",
            ecommerce: {
              video_id: title,
              currentSeconds: lectureVideoRef.current.getCurrentTime(),
              lecture_id: lectureId,
            },
          });
        }}
        onEnded={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "video_end",
            ecommerce: {
              video_id: title,
              currentSeconds: lectureVideoRef.current.getCurrentTime(),
              lecture_id: lectureId,
            },
          });
        }}
      />
      {isStartBtn && !playerOption?.playing && (
        <img
          onClick={() => {
            setPlayerOption({ ...playerOption, playing: true });
          }}
          src={videoPlaying}
          className="absolute top-[50%] right-[50%] w-[100px] h-[100px] mr-[-55px] mt-[-60px] cursor-pointer bg-gray-200 rounded-full opacity-90 hover:opacity-100"
        />
      )}
    </div>
  );
};

export default React.memo(LectureVideo);
