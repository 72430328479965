import { css } from "@emotion/react";
import FaqAccordion from "./FaqAccordion";
import { color } from "../../tailwindStyle";
import { useState } from "react";
import { useEffect } from "react";
import { getFaqs } from "../../api";

const faqStyle = css`
  .item {
    border: 1px solid ${color.gray100};
  }

  .item:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .item:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const Faq = () => {
  const [faqs, setFaqs] = useState(null);
  useEffect(() => {
    getFaqs().then((response) => {
      setFaqs(response?.data);
    });
  }, []);

  return (
    <div className="mt-5 " css={faqStyle}>
      {faqs?.map((faq) => {
        return <FaqAccordion key={faq.id} faq={faq} />;
      })}
    </div>
  );
};

export default Faq;
