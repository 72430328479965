import congratu from "../../assets/icons/congratu.svg";

const EmptyContentCard = ({ text, classNames }) => {
  return (
    <div
      className={`rounded-[12px] border border-gray100 flex flex-col justify-center items-center ${classNames}`}
    >
      <img src={congratu} alt="내리뷰없음" className="w-[50px] h-[49px]" />
      <span className="text-sm mt-[29px] font-medium text-gray200">{text}</span>
    </div>
  );
};

export default EmptyContentCard;
