import Layout from "../components/Layout";
import { Content } from "../components/onedayLectures/index";

const OnedayLectures = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default OnedayLectures;
