import { useRef, useEffect } from "react";
import closeIcon from "../../assets/icons/close.svg";

const PrivatePolicy = ({ onClose }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    titleRef.current.scrollIntoView();
  }, []);

  return (
    <div ref={titleRef} id="titleRef" className="px-4">
      <div className="flex items-center justify-between">
        <div className="my-2 text-lg font-bold">
          스몰빅클래스 개인정보 처리방침
        </div>
      </div>

      <div className="my-3 text-sm">
        <div className="break-words break-all ">
          {`주식회사 스몰빅클래스(이하 “회사”)은 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』(이하 “정보통신망법”),
          『개인정보 보호법』 등 관련 법령상의 개인정보 보호규정을 준수하며,정보통신망법에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다.
          본 개인정보처리방침은 회사가 제공하는 웹/모바일웹/앱 서비스에 적용되며 다음과 같은 내용을 포함하고 있습니다.`}
          <br />
          <br />
          <div className="break-words break-all pl-3 ">
            <ol className="list-inside list-decimal">
              <li className="break-words break-all ">
                이용자의 권리 및 행사방법
              </li>
              <li className="break-words break-all ">이용자의 의무</li>
              <li className="break-words break-all ">
                자동 수집되는 개인정보 및 거부에 관한 사항
              </li>
              <li className="break-words break-all ">
                개인정보 수집항목 및 이용 목적
              </li>
              <li className="break-words break-all ">개인정보 제3자 제공</li>
              <li className="break-words break-all ">개인정보 처리위탁</li>
              <li className="break-words break-all ">
                개인정보 보유 및 이용기간
              </li>
              <li className="break-words break-all ">
                개인정보 파기절차 및 방법
              </li>
              <li className="break-words break-all ">
                개인정보의 보호조치에 관한 사항
              </li>
              <li className="break-words break-all ">
                개인정보 보호책임자 연락처 및 이용자 고충 처리
              </li>
              <li className="break-words break-all ">
                개인정보처리방침 고지의무
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="text-lg">1. 이용자의 권리 및 행사방법</h4>
        <div className="break-words break-all ">
          &#9312;
          {`이용자는 언제든지 정보주체로서의 권리(등록되어 있는 본인의 개인정보를 열람,
          정정, 동의 철회, 삭제)를 행사할 수 있으며, 아래 사항에 대한 열람,
          제공, 정정, 동의 철회, 삭제(탈퇴), 처리 정지,
          이의제기 등을 요구할 수 있습니다.`}
          <br />
          <ol className="break-words break-all pl-3 ">
            <li className="break-words break-all ">{`1) 회사가 보유하고 있는 이용자의 개인정보`}</li>
            <li className="break-words break-all ">
              {`2) 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 현황`}
            </li>
            <li className="break-words break-all ">
              {`3) 이용자가 개인정보 수집·이용·제공 등의 동의를 한 현황`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- 조회 및 수정 : 로그인 후 마이페이지 > 프로필 수정`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- 삭제 및 회원탈퇴 : 고객센터(채널톡으로 문의)`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- 동의 철회(이벤트 정보수신) :  고객센터(채널톡으로 문의)`}
            </li>
            <li className="break-words break-all ">
              {`4) 회사는 관련된 상담 및 문의처리를 위해 별도의 고객센터를 운영하고 있으며, 개인정보 보호책임자에게 이메일 또는 전화로 연락하시면 지체없이 조치하겠습니다.`}
            </li>
          </ol>
          &#9313; 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우,
          완료하기 전까지 개인정보를 이용 또는 제공하지 않습니다.
          <br />
          &nbsp; 또한 잘못된 개인정보를 제3자에게
          이미 제공한 경우에는 정정 처리결과를 제3자에게
          지체없이 통지하여 정정이 이루어지도록 하겠습니다.
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">2. 이용자의 의무</h4>
        <div className="break-words break-all ">
          이용자는 자신의 개인정보를 보호할 의무가 있으며,
          회사의 고의 또는 과실 등 귀책사유 없이 ID/비밀번호 공유,
          로그인 상태에서 자리를 비우는 등 이용자의 부주의로 인하여 발생하는 문제에 대해서는 회사가 책임지지 않습니다.
          <br />
          <br />
          &#9312; 이용자는 개인정보를 최신의 상태로 유지해야 하며,
          부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.
          <br />
          &#9313;
          이용자의 ID/비밀번호는 원칙적으로 본인만 사용하여야 하며 제3자에게
          이를 양도하거나 대여할 수 없습니다.
          타인의 개인정보를 도용한 회원가입 또는 ID 등을 도용하여 구매한 경우,
          회원자격 상실 및 관계 법령에 따라 처벌될 수 있습니다.
          <br />
          &#9314;
          이용자의 회사의 서비스를 이용한 후 반드시 계정을 로그아웃하고 웹 브라우저 프로그램을 종료해야 합니다.
          <br />
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">
          3. 자동 수집되는 개인정보 및 거부에 관한 사항
        </h4>
        <div className="break-words break-all ">
          회사는 이용자 맞춤서비스 등을 제공하기 위하여 쿠키(Cookie)를 설치 및 운영 합니다.
          쿠키의 사용 목적과 거부에 관한 사항은 다음과 같습니다.
          <br />
          <br />
          &#9312; 쿠키란 :
          쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로 이용자의 컴퓨터에 저장되어 운영됩니다.
          <br />
          &#9313; 쿠키의 사용 목적 : 이용자의 접속 빈도나 방문 시간 등의 분석,
          이용 형태 및 관심분야 파악, 자취 추적, 각종 이벤트 참여 정도,
          방문 횟수 파악 등을 타겟 마케팅 등 개인별 맞춤 서비스를 제공하기 위해 쿠키를 사용 합니다.
          <br />
          &#9314; 쿠키의 설치·운영 및 거부
          <br />
          <ol className="pl-3">
            <li className="break-words break-all ">
              {`1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저별 옵션 선택을 통해 모든 쿠키를 허용 또는 거부 하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 쿠키 설치 허용여부를 지정하는 방법은 다음과 같습니다.`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- Internet Explorer
              : 도구 메뉴 > 인터넷 옵션 > 개인정보 탭 > 개인정보처리 수준 설정`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- Chrome
              : 설정 메뉴 > 고급 설정 표시 선택 > 개인정보-콘텐츠 설정 > 쿠키 수준 설정`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- Firefox
              : 옵션 메뉴 > 개인정보 > 방문기록 -사용자 정의 설정 > 쿠키 수준 설정`}
            </li>
            <li className="break-words break-all pl-3 ">
              {`- Safari
              : 환경설정 메뉴 > 개인정보 탭 > 쿠키 및 웹 사이트 데이터 수준 설정`}
            </li>
            <li className="break-words break-all ">
              {`2) 단, 쿠키의 저장을 거부하였을 경우에는 로그인이 필요한 서비스 등 일부 서비스 이용에 어려움이 있을 수 있습니다.`}
            </li>
          </ol>
        </div>

        <div className="my-6 text-sm">
          <h4 className="my-2 text-lg">4. 개인정보 수집항목 및 이용 목적</h4>
          <div className="break-words break-all ">
            ① 회사는 서비스 제공을 위한 최소한의 범위 내에서 이용자의 동의 하에 개인정보를 수집하며, 이용자가 필요한 최소한의 개인정보 이외의 개인정보를 제공하지 아니한다는 이유로 해당 서비스 제공을 거부하지 않습니다. 또한 수집한 모든 개인정보는 고지한 목적 범위 내에서만 사용되며 회사에서 제공하는 서비스 유형에 따라 다음과 같이 개인정보를 수집 및 이용하고 있습니다.
            <br />
            ② 주식회사 스몰빅클래스는 만 14세 미만 아동에 대해 개인정보를 수집할
            때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의
            개인정보를 수집합니다. 또한, 주식회사 스몰빅클래스의 홍보를 위해
            아동의 개인정보를 수집할 경우에는 법정대리인으로부터 별도의 동의를
            얻습니다.
            <br />
            <table className="table-auto border-collapse border">
              <thead>
                <tr className="border font-bold">
                  <th className="border">수집방법</th>
                  <th className="border">수집방법</th>
                  <th className="border">수집 및 이용목적</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border text-center">회원가입</td>
                  <td className="border">{`[필수] 이름, 이메일주소, 휴대폰번호, 비밀번호 ※만 14세 미만 아동의 경우 법정대리인 정보(성명, 생년월일, 휴대폰번호)`}</td>
                  <td className="border">
                    본인확인 및 가입의사 확인, 상담 및 문의 처리, 공지사항 전달
                    및 불만처리 등 원활한 의사소통 경로 확보
                  </td>
                </tr>
                <tr className="border">
                  <td className="border text-center">SNS계정 가입</td>
                  <td className="border">
                    {`[필수 - 카카오] 카카오계정 이메일, 이름`}
                    <br />
                    {`[필수 - 네이버] 네이버계정 이메일, 이름 프로필사진`}
                    <br />
                    {`[필수 - 구글] 구글계정 이메일, 이름, 휴대폰번호, 프로필사진`}
                    <br />
                    {`[필수 - 애플] 애플계정 이메일`}
                  </td>
                  <td className="border">SNS계정을 이용한 회원가입</td>
                </tr>
                <tr>
                  <td className="border text-center">회원정보 수정</td>
                  <td className="border">{`[선택] 프로필 사진, 닉네임 (학생), 닉네임(학부모), 출생년도 (학생)`}</td>
                  <td className="border">
                    이용자 식별 및 이용자간 관계 형성, 커뮤니티 활성화, 과제
                    피드백
                  </td>
                </tr>
                <tr>
                  <td className="border text-center">구매 시</td>
                  <td className="border">{`[필수] 수취인정보(이름, 휴대폰번호, 주소), 현금영수증 발행 정보(휴대폰번호)`}</td>
                  <td className="border">
                    주문/결제 및 상품 배송(교환, 반품) 서비스, 현금영수증 발급
                  </td>
                </tr>
                <tr>
                  <td className="border text-center">이벤트 참여 시</td>
                  <td className="border">{`[필수] 배송정보:수취인 이름, 전화번호, 우편번호, 연락처, 가입ID`}</td>
                  <td className="border">이벤트 당첨자 경품 제공</td>
                </tr>
              </tbody>
            </table>
            <br />
            1) ‘회원’이라 함은 회사의 사이트에 접속하여 본 약관에 동의하고
            회사와 이용계약을 체결하여 회원가입의 승낙을 받은 개인 또는 단체로서
            회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 ‘학습자’
            본인 또는 친권자 또는 법정대리인(이하 ‘친권자 또는 법정대리인’을
            ‘보호자’라 합니다)을 말합니다.
            <br />
            2) ‘학습자’라 함은 회사와 이용계약을 체결한 본인 또는 ‘회원’의 자녀
            또는 법적 보호를 받는 자로서 디냅 서비스를 이용하여 회사가 제공하는
            학습 콘텐츠 서비스를 지속적으로 사용하는 자를 말합니다.
            <br />
            <br />
            <h3 className="font-bold">
              (서비스 이용에 따른 자동 수집 및 생성 정보)
            </h3>
            <table className="table-auto border-collapse border">
              <thead>
                <tr className="border font-bold">
                  <th className="border">수집항목</th>
                  <th className="border">수집 및 이용목적</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border">
                    <li>쿠키, IP, 방문일시, 서비스 이용기록</li>
                    <li>{`기기정보(device_id, OS종류 및 버전, 디바이스 종류, 광고식별자`}</li>
                    <li>{`앱 이용시 추가 모바일 기기정보(UUID, 푸쉬토큰)`}</li>
                  </td>
                  <td className="border">
                    서비스 이용 통계, 접속관리, 이용자별 사용환경 제공, 활동정보
                    파악, 이벤트 및 프로모션 통계 확인, 맞춤형 정보 제공
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            &#9313; 회사는 다음과 같은 방법으로 개인정보를 수집할 수 있으며
            개인식별이 가능한 개인정보를 수집하는 경우에는 이용자로부터 동의를
            받습니다. 이용자가 동의버튼을 클릭하거나 회원정보 수정 등을 통해
            추가로 수집하는 개인정보를 기재한 후 저장할 경우 개인정보 수집에
            동의한 것으로 봅니다.
            <ol className="pl-3">
              <li className="break-words break-all ">{`1) 홈페이지, 서면, 팩스, 전화, 고객센터 문의하기, 이벤트 응모`}</li>
              <li className="break-words break-all ">{`2) 생성정보 수집 툴을 통한 자동 수집`}</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">
          5-1. 만 14세 미만 아동의 개인정보 처리에 관한 사항
        </h4>
        <div className="break-words break-all ">
          개인정보처리자가 만 14세 미만 아동의 개인정보를 처리하기 위해 법
          제22조제6항에 따른 법정대리인의 동의를 받기 위하여 아동으로부터
          수집하는 법정대리인의 개인정보(이름, 연락처 등)에 대해 기재하여야 함.
          <br />
          <br />① 주식회사 스몰빅클래스는 만 14세 미만 아동에 대해 개인정보를
          수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의
          개인정보를 수집합니다. • 필수항목 : 법정 대리인의 성명, 관계, 연락처{" "}
          <br /> ② 또한, 수상실적 홍보, 입학 홍보 등 주식회사 스몰빅클래스의
          홍보를 위해 아동의 개인정보를 수집할 경우에는 법정대리인으로부터
          별도의 동의를 얻습니다. <br /> ③ 주식회사 스몰빅클래스는 만 14세 미만
          아동의 개인정보를 수집할 때에는 아동에게 법정대리인의 성명 등 최소한의
          정보를 요구할 수 있으며, 다음 중 하나의 방법으로 적법한 법정대리인이
          동의하였는지를 확인합니다.
          <br />• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
          표시하도록 하고 개인정보처리자가 그 동의 표시를 확인했음을
          법정대리인의 휴대전화 문자메시지로 알리는 방법
          <br /> • 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
          표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를
          확인하는 방법
          <br /> • 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
          표시하도록 하고 법정대리인의 신용카드·직불카드 등의 카드정보를
          제공받는 방법
          <br /> • 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편
          또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인
          후 제출하도록 하는 방법
          <br /> • 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터
          동의의 의사표시가 적힌 전자우편을 전송받는 방법
          <br /> • 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나
          인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화
          통화를 통하여 동의를 얻는 방법
          <br /> • 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을
          알리고 동의의 의사표시를 확인하는 방법
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">
          5-2. 법정대리인의 권리·의무 및 행사방법 및 정보 주체 안내
        </h4>
        <div className="break-words break-all ">
          ① 정보주체는 주식회사 스몰빅클래스에 대해 언제든지 개인정보
          열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다. ※ 만 14세
          미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접 해야 하며,
          만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여
          미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할
          수도 있습니다. <br /> ② 권리 행사는 주식회사 스몰빅클래스에 대해
          「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편 등을
          통하여 하실 수 있으며, 주식회사 스몰빅클래스은(는) 이에 대해 지체없이
          조치하겠습니다.
          <br />③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한
          고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
          <br />④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조
          제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.{" "}
          <br />⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가
          수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.{" "}
          <br />⑥ 주식회사 스몰빅클래스의 정보주체 권리에 따른 열람의 요구,
          정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나
          정당한 대리인인지를 확인합니다.
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">6. 개인정보 제3자 제공</h4>
        <div className="break-words break-all ">
          &#9312; 회사는 『4. 개인정보 수집 및 이용목적』에서 고지한 범위
          내에서만 개인정보를 이용하며, 원칙적으로 이용자의 개인정보를 제3자에게
          제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          <br />
          <br />
          <div className="pl-3">
            {`1) 이용자가 거래 이행을 위해 제3자 제공에 동의하는 경우, 서비스 제공
            및 배송, 본인확인 등을 위하여 필요한 최소한의 개인정보만을 서비스
            제공 업체에게 제공할 수 있습니다.`}
            <div className="flex">
              <table className="grow table-auto border-collapse border">
                <thead>
                  <tr className="border font-bold">
                    <th className="border">제공받는 자</th>
                    <th className="border">제공목적</th>
                    <th className="border">제공항목</th>
                    <th className="border">보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td className="border">교재 및 책 배송업체</td>
                    <td className="border">
                      주문상품의 배송, 고객상담 및 불만처리
                    </td>
                    <td className="border">{`수취인 정보(이름, 휴대폰번호, 주소)`}</td>
                    <td className="border">구매확정 후 1개월</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            *키트 및 교재 배송업체 : &nbsp;
            <a href="https://docs.google.com/spreadsheets/d/1fb3wJjAcHPpHebBmU41IOgQGh34x2GwQ4E7JrK_uLQw/edit?usp=sharing">
              {`업체 상세 리스트(구글 스프레드시트) 확인 하러 가기`}
            </a>
            <br />
            <br />
            {`2) 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의
            요구가 있는 경우`}
            <br />
            {`3) 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는
            형태로 광고주, 협력사, 연구단체 등에 제공하는 경우`}
            <br />
          </div>
          &#9313;{" "}
          {`이용자는 개인정보 제3자 제공에 대해 동의하지 않을 수 있고
          언제든지 제3자 제공 동의를 철회할 수 있습니다. 다만, 제3자 제공에
          기반한 관련된 일부 서비스의 이용이 제한될 수 있습니다(회원가입
          서비스는 이용하실 수 있습니다)`}
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">7. 개인정보 처리위탁</h4>
        <div className="break-words break-all ">
          회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며
          관계법령에 따라 위탁 처리되는 개인정보가 안전하게 관리될 수 있도록
          필요한 조치를 취하고 있습니다. 또한 위탁 처리하는 개인정보는 서비스
          제공에 필요한 최소한의 범위에 국한됩니다.
        </div>
        <h5 className="my-2 text-base">&#60; 국내 처리위탁 &#62;</h5>
        <div className="flex">
          <table className="grow table-auto border-collapse border">
            <thead>
              <tr className="border font-bold">
                <th className="border">수탁업체</th>
                <th className="border">위탁업무</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td className="border">엔플러스</td>
                <td className="border">SMS 발송</td>
              </tr>
              <tr className="text-center">
                <td className="border">즐거운</td>
                <td className="border">이벤트 쿠폰 발송</td>
              </tr>
              <tr className="text-center">
                <td className="border">(주)알리는사람들</td>
                <td className="border">SMS 발송</td>
              </tr>
              <tr className="text-center">
                <td className="border">(주)채널코퍼레이션</td>
                <td className="border">채널톡 시스템 운영</td>
              </tr>
              <tr className="text-center">
                <td className="border">Amazon Web Services Inc.</td>
                <td className="border">서비스 제공을 위한 인프라 관리</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">8. 개인정보 보유 및 이용기간</h4>
        <div className="break-words break-all ">
          &#9312; 회사는 이용자의 개인정보를 고지 및 동의받은 사항에 따라
          수집·이용 목적이 달성되기 전 또는 이용자의 탈퇴 요청이 있기 전까지
          해당 정보를 보유합니다. 다만, 아래의 사유로 인하여 보관이 필요한 경우
          외부와 차단된 별도의 DB 또는 테이블에 분리 보관 됩니다.
          <br />
          <ol className="pl-3">
            <li className="break-words break-all ">{`1) 온라인/모바일 서비스 제공을 위해 수집한 회원가입 정보 : 회원탈퇴 시 까지`}</li>
            <li className="break-words break-all ">{`2) 법령에 의한 개인정보 보유`}</li>
          </ol>
          <div className="flex">
            <table className="grow table-auto border-collapse border">
              <thead>
                <tr className="border font-bold">
                  <th className="border">관련 법률</th>
                  <th className="border">보유항목</th>
                  <th className="border">보유기간</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td className="border">통신비밀보호법</td>
                  <td className="border">
                    인터넷 로그기록자료, 접속지 추적자료
                  </td>
                  <td className="border">3개월</td>
                </tr>
                <tr>
                  <td rowSpan={4} className="border">
                    전자상거래 등에서의 소비자보호에 관한 법률
                  </td>
                  <td className="border">표시·광고에 관한 기록</td>
                  <td className="border">6개월</td>
                </tr>
                <tr>
                  <td className="border">계약 또는 청약철회에 관한 기록</td>
                  <td className="border">5년</td>
                </tr>
                <tr>
                  <td className="border">
                    대금결제 및 재화등의 공급에 관한 기록
                  </td>
                  <td className="border">5년</td>
                </tr>
                <tr>
                  <td className="border">
                    소비자의 불만 또는 분쟁처리에 관한 기록
                  </td>
                  <td className="border">3년</td>
                </tr>
                <tr>
                  <td className="border">부가가치세법</td>
                  <td className="border">
                    부가가치세의 과세표준과 세액의 신고자료 등
                  </td>
                  <td className="border">5년</td>
                </tr>
              </tbody>
            </table>
          </div>
          &#9313; 개인정보 유효기간제(휴면계정 정책)
          <br />
          <ol className="pl-3">
            <li className="break-words break-all ">{`1) 1년 이상 서비스 이용기록이 없는 이용자의 회원가입정보는 정보통신망법 제29조에 따라 일반 이용자의 회원가입정보와 분 리하여 별도로 저장 및 관리 됩니다.`}</li>
            <li className="break-words break-all ">{`2) 회사는 휴면계정으로 전환되는 시점 최소 30일 이전, 해당 내용에 대해 이메일 등을 통해 이용자에게 사전 통지합니다.`}</li>
            <li className="break-words break-all ">{`3) 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용·제공하지 않습니다.`}</li>
            <li className="break-words break-all ">{`4) 주문 및 결제, CS정보는 관련 법령에 의한 개인정보 보유기간 동안 보관 후 삭제됩니다.`}</li>
          </ol>
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">9. 개인정보 파기절차 및 방법</h4>
        <div className="break-words break-all ">
          &#9312; 파기절차 및 기한 : 이용자의 개인정보는 원칙적으로 개인정보
          수집 및 이용목적이 달성되면 지체없이 파기합니다. 다만, 『7. 개인정보
          보유 및 이용기간』에서 명시한 다른 법령에 의해 보관해야 하는 경우
          별도의 DB에 옮겨져 내부 규정 및 관련 법령을 준수하여 일정기간 동안
          안전하게 보관된 후 지체없이 파기됩니다. 이때 DB로 옮겨진 개인정보는
          법률에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다.
          <br />
          <br />
          &#9313; 파기방법
          <br />
          <ol className="pl-3">
            <li className="break-words break-all ">
              {`1) 전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을
              사용하여 완전하게 삭제합니다.`}
            </li>
            <li className="break-words break-all ">
              {`2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기합니다.`}
            </li>
          </ol>
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">10. 개인정보의 보호조치에 관한 사항</h4>
        <div className="break-words break-all ">
          회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난,
          유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 『정보통신망법』,
          『개인정보보호법』 등 정보통신서비스 제공자가 준수하여야 할 관련
          법령에 따라 기술적·관리적 보호대책을 강구하고 있습니다.
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">
          11. 개인정보 보호책임자 및 이용자 고충처리
        </h4>
        <div className="break-words break-all ">
          &#9312; 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련
          민원, 불만처리 등에 관한 사항을 개인정보 보호책임자 및 고객센터로
          문의하실 수 있고, 회사는 이용자의 문의에 신속하고 성실하게
          답변하겠습니다.
          <div className="flex">
            <table className="grow table-auto border-collapse border">
              <thead>
                <tr className="border font-bold">
                  <th className="border">개인정보 보호책임자</th>
                  <th className="border">제공목적</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td className="border">
                    이름 : 조승우 대표
                    <br />
                    이메일 : insight_cho@smallbigclass.com
                  </td>
                  <td className="border">
                    연락처 : 1566-2766
                    <br />
                    이메일 : smallbigclass@smallbigclass.com
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          &#9313; 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
          기관에 문의하시기 바랍니다.
          <br />
          <ol>
            <li className="break-words break-all ">
              {`1) 개인정보침해신고센터 :`}
              <a href="http://privacy.kisa.or.kr">
                http://privacy.kisa.or.kr &nbsp;
              </a>
              - 국번없이 118
            </li>
            <li className="break-words break-all ">
              {`2) 대검찰청 사이버범죄수사단 :`}
              <a href="http://www.spo.go.kr">http://www.spo.go.kr</a> - 국번없이
              1301
            </li>
            <li className="break-words break-all ">
              {`3) 경찰청 사이버안전국 :`}
              <a href="http://cyberbureau.police.go.kr">
                http://cyberbureau.police.go.kr
              </a>
              &nbsp;- 국번없이 182
            </li>
          </ol>
        </div>
      </div>

      <div className="my-6 text-sm">
        <h4 className="my-2 text-lg">12. 개인정보 처리방침 고지 의무</h4>
        <div className="break-words break-all ">
          이 개인정보 처리방침은 시행일로부터 적용되며, 관련 법령 및 회사
          정책변경 등에 따라 변경 내용의 추가, 삭제 및 정정사항이 있는 경우에는
          홈페이지 또는 이메일을 통해 사전 공지하겠습니다.
          <br />
          <br />
          <ol className="list-inside list-disc pl-3">
            <li className="break-words break-all ">
              시행일자 : 2023년 03월 24일
            </li>
            <li className="break-words break-all ">
              개인정보 처리방침 버전 번호 : ver 2.0
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivatePolicy;
