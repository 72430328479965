import lessonOk from "../../assets/icons/lesson_ok.svg";
import lessonYet from "../../assets/icons/lesson_yet.svg";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { filter, includes } from "lodash";
import { getLectureLessons, getHasPurchased } from "../../api";

const lessonCurriculumStyle = css`
  .curriculum:last-of-type {
    border-bottom: 0px;
  }

  .curriculum:first-of-type {
    margin-top: 0px;
  }
`;

export const LessonLists = ({
  lectureId,
  lessonId,
  selectedVideoId,
  lessonMode,
}) => {
  const [lectureLessons, setLectureLessons] = useState(null);

  useEffect(() => {
    if (!!lectureId) {
      getLectureLessons({ lectureId: lectureId }).then((response) => {
        setLectureLessons(response?.data);
      });
    }
  }, [lectureId]);

  //현재 강의에 대한 권한 여부 체크
  const [isAuthLecture, setIsAuthLecture] = useState(false);
  useEffect(() => {
    if (!!lectureId) {
      getHasPurchased({ lectureId: lectureId }).then((response) => {
        setIsAuthLecture(response?.data?.has_authority);
      });
    }
  }, [lectureId]);

  useEffect(() => {
    const lessonSidebar = document.getElementById("lessonSidebar");
    const currentLesson = document.getElementById(`lesson_${lessonId}`);
    lessonSidebar.scrollTo(
      0,
      currentLesson?.offsetTop - lessonSidebar?.offsetTop - 80
    );
  }, [lectureLessons]);

  return (
    <div css={lessonCurriculumStyle}>
      {lessonMode !== "external" &&
        lectureLessons?.map((chapter) => {
          return (
            chapter?.lessons?.length !== 0 && (
              <div
                key={`chapter_${chapter?.id}`}
                id={`chapter_${chapter?.id}`}
                className="curriculum mt-[15px] border-b border-b-gray100"
              >
                <div className="flex flex-col pb-[15px]">
                  <span
                    className={`line-ellipsis ${
                      includes(
                        chapter?.lessons,
                        filter(chapter?.lessons, (lesson) => {
                          return lesson?.id === lessonId;
                        })[0]
                      )
                        ? "font-bold"
                        : "font-medium text-gray600"
                    }`}
                    css={css`
                      -webkit-line-clamp: 2;
                    `}
                  >
                    {chapter?.title}
                  </span>

                  {chapter?.lessons?.map((lesson) => {
                    return isAuthLecture || lesson?.is_free ? (
                      <a
                        id={`lesson_${lesson?.id}`}
                        href={`/lessons/${lesson?.id}`}
                        key={`강의${lesson?.id}`}
                        className={`flex items-center justify-between text-sm mt-[6px]`}
                      >
                        <div className="flex items-center">
                          {/* 들었냐 안들었냐 유무로 달라짐 */}
                          {lesson?.is_view || selectedVideoId === lesson?.id ? (
                            <img
                              src={lessonOk}
                              alt="들었음"
                              className="w-[18px] mr-2"
                            />
                          ) : (
                            <img
                              src={lessonYet}
                              alt="안들었음"
                              className="w-[18px] mr-2"
                            />
                          )}

                          <span
                            className={`mr-4 ${
                              lessonId === lesson?.id
                                ? "font-bold"
                                : "text-gray600"
                            }`}
                          >
                            {lesson?.title}
                          </span>
                        </div>

                        {/* 무료 공개 강의 라면? */}
                        {lesson?.is_free && !isAuthLecture &&  (
                          <div className="text-gray800 font-medium shrink-0 h-fit text-[10px] w-fit px-[5px] rounded-[8px] bg-yellow200 ml-auto">
                            무료 공개
                          </div>
                        )}

                        {/* 과제가 있다면? */}
                        {lesson?.is_mission && (
                          <div
                            className={`text-white font-bold shrink-0 h-fit text-[10px] w-fit px-[8px] rounded-[10px] bg-yellow700 ${
                              lesson?.is_free ? "ml-2" : "ml-auto"
                            }`}
                          >
                            미션
                          </div>
                        )}
                      </a>
                    ) : (
                      <div
                        id={`lesson_${lesson?.id}`}
                        key={`강의${lesson?.id}`}
                        className={`flex items-center justify-between text-sm mt-[6px]`}
                      >
                        <div className="flex items-center">
                          {/* 들었냐 안들었냐 유무로 달라짐 */}
                          {lesson?.is_view || selectedVideoId === lesson?.id ? (
                            <img
                              src={lessonOk}
                              alt="들었음"
                              className="w-[18px] mr-2"
                            />
                          ) : (
                            <img
                              src={lessonYet}
                              alt="안들었음"
                              className="w-[18px] mr-2"
                            />
                          )}

                          <span
                            className={`mr-4 ${
                              lessonId === lesson?.id
                                ? "font-bold"
                                : "text-gray600"
                            }`}
                          >
                            {lesson?.title}
                          </span>
                        </div>

                        {/* 무료 공개 강의 라면? */}
                        {lesson?.is_free && (
                          <div className="text-gray800 font-medium shrink-0 h-fit text-[10px] w-fit px-[5px] rounded-[8px] bg-yellow200 ml-auto">
                            무료 공개
                          </div>
                        )}

                        {/* 과제가 있다면? */}
                        {lesson?.is_mission && (
                          <div
                            className={`text-white font-bold shrink-0 h-fit text-[10px] w-fit px-[8px] rounded-[10px] bg-yellow700 ${
                              lesson?.is_free ? "ml-2" : "ml-auto"
                            }`}
                          >
                            미션
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
    </div>
  );
};
