import { MypageTitle } from "../components/shared/Title";
import { Content } from "../components/mypageCoupon/index";
import Aside from "../components/shared/MypageAside";
import Layout from "../components/Layout";

const Coupon = (props) => {
  return (
    <Layout>
      <div className="flex mypage_lectures default_margin mobile:flex-col max-w-[1200px] h-full">
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>

        <div className="flex-grow w-full web:mt-[34px] tablet:mt-[34px] mobile:mt-[25px]">
          <MypageTitle title="쿠폰 / 수강권 등록" />
          <Content />
        </div>
      </div>
    </Layout>
  );
};

export default Coupon;
