import { setUtm } from "../utils";

const useKakaoShare = (data) => {
  const kakaoJsKey = process.env.KAKAO_JS_KEY;

  if (Kakao.isInitialized() === false) {
    Kakao.init(kakaoJsKey);
  }

  const utmObj = {
    utm_source: "externalsharing",
    utm_medium: "kakao",
    utm_campaign: "",
  };
  const url = setUtm(utmObj);

  return Kakao.Link.sendDefault({
    objectType: "text",
    text: data?.title,
    link: {
      webUrl: url,
      mobileWebUrl: url,
    },
  });
};

export default useKakaoShare;
