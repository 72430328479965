import { UsersInputLabel } from "./UsersInputLabel";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useRef } from "react";
import * as Yup from "yup";
import { deleteChildInfo, patchUser } from "../../api";
import iziToast from "izitoast";
import Swal from "sweetalert2";
import moment from "moment";
import arrowRLight from "../../assets/icons/arrow_right_light.svg";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { returnSessionFunc } from "../../utils";

export const MoreInfoForm = ({ page }) => {
  const { currentUser } = useGetCurrentUser();

  const arrayHelpersRef = useRef(null);

  const validationSchema = Yup.object().shape({
    nickname: Yup.string().max(8, "닉네임을 8글자 이내로 입력해주세요."),
    birthday: Yup.date()
      .min("1900-01-01", "생일이 너무 이전입니다.")
      .max(moment().format("YYYY-MM-DD"), "생일은 오늘이전으로 입력해주세요.")
      .typeError("생년월일을 정확히 입력해주세요."),
    gender: Yup.string("성별을 정확히 선택해주세요."),
    children: Yup.array().of(
      Yup.object().shape({
        nickname: Yup.string()
          .max(8, "닉네임을 8글자 이내로 입력해주세요.")
          .required("자녀의 닉네임은 필수입니다."),
        birthday: Yup.date()
          .min("1900-01-01", "생일이 너무 이전입니다.")
          .max(
            moment().format("YYYY-MM-DD"),
            "생일은 오늘이전으로 입력해주세요."
          )
          .typeError("생년월일을 정확히 입력해주세요."),
      })
    ),
  });

  return (
    <Formik
      className="flex flex-col"
      initialValues={{
        nickname: currentUser?.nickname || "",
        birthday: currentUser?.birthday || "",
        gender: currentUser?.gender || "none",
        children: currentUser?.children || [
          {
            nickname: "",
            birthday: "",
            gender: "none",
          },
        ],
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        patchUser({
          nickname: values?.nickname,
          birthday: values?.birthday,
          gender: values?.gender,
          children_attributes: values?.children,
        })
          .then(() => {
            if (page === "mypageEdit") {
              location.href = "/mypage/edit";
            } else {
              returnSessionFunc();
            }
          })
          .catch(() => {
            setSubmitting(false);
            iziToast.error({
              message: "에러가 발생했습니다.",
              position: "topCenter",
            });
          });
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <div className="flex flex-col">
              <UsersInputLabel labelName="닉네임" inputId="nickname" />

              <Field
                name="nickname"
                type="text"
                id="nickname"
                className="border-gray200 border-[0.5px] rounded-[5px] h-[45px] px-[15px] mt-[6px] w-full shrink-0"
              />

              <span className="mt-2 text-sm text-red400">
                <ErrorMessage name="nickname" />
              </span>

              <div className="mt-[7px]">
                <UsersInputLabel labelName="생일" inputId="birthday" />
              </div>
              <Field
                name="birthday"
                type="date"
                id="birthday"
                className="border-gray200 border-[0.5px] rounded-[5px] h-[45px] px-[15px] mt-[6px] w-full shrink-0 bg-white"
              />
              <span className="mt-2 text-sm text-red400">
                <ErrorMessage name="birthday" />
              </span>

              <div className="mt-[7px]">
                <UsersInputLabel labelName="성별" inputId="gender" />
              </div>
              <Field name="gender" type="select" id="gender">
                {({ field }) => {
                  return (
                    <select
                      {...field}
                      value={field.value || ""}
                      className="border-gray200 border-[0.5px] rounded-[5px] h-[45px] px-[15px] mt-[6px] w-full shrink-0 bg-white"
                    >
                      <option value="female">여자</option>
                      <option value="male">남자</option>
                      <option value="none">공개 안함</option>
                    </select>
                  );
                }}
              </Field>
              <span className="mt-2 text-sm text-red400">
                <ErrorMessage name="gender" />
              </span>
            </div>

            {page === "mypageEdit" && (
              <a href="/mypage/password_change">
                <div className="flex justify-between items-center my-[30px]">
                  <span className="text-lg font-bold">비밀번호 변경하기</span>
                  <img
                    className="h-[19px]"
                    src={arrowRLight}
                    alt="비밀번호변경이동"
                  />
                </div>
              </a>
            )}

            <div className="flex flex-col">
              {page === "mypageEdit" && (
                <div className="text-lg font-bold">자녀 정보</div>
              )}

              <FieldArray
                name="children"
                render={(arrayHelpers) => {
                  arrayHelpersRef.current = arrayHelpers;
                  return (
                    <div>
                      {values.children?.map((child, index) => {
                        return (
                          <div
                            key={`자녀_${index}`}
                            className="mt-[15px] border-[0.5px] border-gray200 rounded-[5px] py-5 px-[14px] flex flex-col"
                          >
                            {currentUser?.style === "parents" && (
                              <div className="flex justify-between mb-4">
                                <span className="font-bold">
                                  자녀 {index + 1}
                                </span>
                                <button
                                  type="button"
                                  className="text-sm font-medium text-gray400"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "삭제하시겠습니까?",
                                      showCancelButton: true,
                                      confirmButtonText: "삭제",
                                      cancelButtonText: "취소",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        // 삭제 API 호출
                                        deleteChildInfo({
                                          childId: child?.id,
                                        })
                                          .then(() => {
                                            arrayHelpers.remove(index);
                                            iziToast.success({
                                              message: "삭제되었습니다.",
                                              position: "topCenter",
                                            });
                                          })
                                          .catch((error) => {
                                            if (
                                              error.response.status === 404 // 프론트에서만 자녀정보 array가 있고 backend에는 저장된 array element가 없는 경우
                                            ) {
                                              arrayHelpers.remove(index);
                                            } else {
                                              iziToast.error({
                                                message: "에러가 발생했습니다.",
                                                position: "topCenter",
                                              });
                                            }
                                          });
                                      }
                                    });
                                  }}
                                >
                                  삭제하기
                                </button>
                              </div>
                            )}

                            <UsersInputLabel
                              labelName="이름(닉네임)"
                              inputId={`children.${index}.nickname`}
                            />

                            <Field
                              name={`children.${index}.nickname`}
                              type="text"
                              className="border-b-[0.75px] border-b-gray500 mt-2 outline-none focus:outline-none pb-[6px]"
                            />

                            <span className="mt-2 text-sm text-red400">
                              <ErrorMessage
                                name={`children.${index}.nickname`}
                              />
                            </span>

                            <span className="font-medium text-gray700 mt-[18px]">
                              생일
                            </span>

                            <Field
                              name={`children.${index}.birthday`}
                              type="date"
                              className="border-b-[0.75px] border-b-gray500 mt-2 outline-none focus:outline-none pb-[6px]"
                            />

                            <span className="font-medium text-gray700 mt-[18px]">
                              성별
                            </span>

                            <Field
                              name={`children.${index}.gender`}
                              className="border-b-[0.75px] border-b-gray500 mt-2 outline-none focus:outline-none pb-[6px]"
                            >
                              {({ field }) => {
                                return (
                                  <select
                                    {...field}
                                    value={field.value || ""}
                                    className="mt-2"
                                  >
                                    <option value="female">여자</option>
                                    <option value="male">남자</option>
                                    <option value="none">공개안함</option>
                                  </select>
                                );
                              }}
                            </Field>

                            <span className="mt-2 text-sm text-red400">
                              <ErrorMessage
                                name={`children.${index}.birthday`}
                              />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />

              <button
                type="button"
                onClick={() => {
                  arrayHelpersRef.current.push({
                    nickname: "",
                    birthday: moment().format("YYYY-MM-DD"),
                    gender: "none",
                  });
                }}
                className="h-[45px] mt-[26px] border-[0.5px] border-navy900 font-medium text-gray700 flex items-center justify-center rounded-[5px] w-full"
              >
                자녀 추가하기
              </button>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="font-bold h-[45px] mt-[9px] text-white bg-black flex items-center justify-center rounded-[5px] w-full"
            >
              {isSubmitting ? "저장 중..." : "저장하기"}
            </button>

            {page !== "mypageEdit" && (
              <button
                type="button"
                className="font-medium h-[45px] mt-[9px] text-gray700 flex items-center justify-center rounded-[5px] w-full"
                onClick={() => returnSessionFunc()}
              >
                다음에 입력하기
              </button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
