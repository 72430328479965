import { useEffect, useState } from "react";
import { MypageTitle } from "../components/shared/Title";
import { Content } from "../components/mypagePayment/index";
import Aside from "../components/shared/MypageAside";
import Layout from "../components/Layout";
import { getKgPayOrders, getNicePayOrders } from "../api";

const Payment = (props) => {
  const [kgPayOrders, setKgPayOrders] = useState(null);
  const [nicePayOrders, setNicePayOrders] = useState(null);

  useEffect(() => {
    getKgPayOrders().then((response) => {
      setKgPayOrders(response?.data);
    });

    getNicePayOrders().then((response) => {
      setNicePayOrders(response?.data);
    });
  }, []);

  return (
    <Layout>
      <div className="flex mypage_lectures default_margin mobile:flex-col h-full max-w-[1200px]">
        <div className="flex-0 web:mr-[58px] tablet:mr-[28px] mobile:hidden">
          <Aside pageName={props?.pageName} />
        </div>

        <div className="flex-grow w-full mobile:mt-5">
          <MypageTitle title="주문 / 배송 정보" />

          {kgPayOrders && nicePayOrders && (
            <Content kgPayOrders={kgPayOrders} nicePayOrders={nicePayOrders} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Payment;
