import rightArrowRed from "../../assets/icons/arrow_right_red.svg";
import { addComma } from "../../utils";
import moment from "moment";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import { postCancelOrder } from "../../api";

const OrderNicePayLg = ({ order }) => {
  const paymentMethodWord = (paymentMethod) => {
    return paymentMethod === "card" ? "카드 결제" : "무통장 입금";
  };

  return (
    <div className="mb-[26px] relative">
      <div className="flex items-center justify-between">
        <span className="text-lg font-bold">
          {moment(order?.created_at).format("YYYY.MM.DD")}
        </span>

        <a
          href={
            order?.status === "입금 대기"
              ? `/orders/${order?.id}/result`
              : `/mypage/orders/${order?.id}`
          }
          className="flex text-sm"
        >
          <span className="mr-[11px] text-red400">자세히 보기</span>
          <img src={rightArrowRed} alt="자세히보기아이콘" />
        </a>
      </div>

      <div className="border rounded-[5px] border-gray100 w-full py-[33px] px-[17px] flex mt-[14px]">
        <div
          className="w-[280px] h-[187px] mr-[26px] bg-contain bg-center bg-no-repeat"
          css={css`
            background-image: url(${order?.image_url});
          `}
        ></div>
        <div className="flex flex-col justify-between">
          <span className="text-lg font-bold">{order?.title}</span>
          <div className="flex text-sm">
            <div className="flex flex-col w-[95px]">
              <span className="font-medium text-gray400">결제 상태</span>
              <span className="mt-[5px] text-gray400 font-medium">
                결제 금액
              </span>
              <span className="mt-[5px] text-gray400 font-medium">
                결제 수단
              </span>

              {order?.status != "전액 취소" && order?.pay_method === "card" ? (
                //카드
                <div className="flex flex-col">
                  <span className="mt-[5px] text-gray400 font-medium">
                    카드 정보
                  </span>
                  <span className="mt-[5px] text-gray400 font-medium">
                    결제 정보
                  </span>
                </div>
              ) : //무통장 입금
              order?.status === "입금 대기" ? (
                <div className="flex flex-col">
                  <span className="mt-[5px] text-gray400 font-medium">
                    계좌 번호
                  </span>
                  <span className="mt-[5px] text-gray400 font-medium">
                    입금 마감일
                  </span>
                </div>
              ) : (
                <div className="flex flex-col">
                  <span className="mt-[5px] h-[20px]"></span>
                  <span className="mt-[5px] h-[20px]"></span>
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <span
                className={`font-bold ${
                  order?.status === "입금 대기" ? "text-red400" : ""
                }`}
              >
                {order?.status === "부분 취소"
                  ? "결제 완료 (부분 환불)"
                  : order?.status}
              </span>
              <span className="mt-[5px] font-medium">
                {addComma(order?.payment_balance_amount)} 원
              </span>
              <span className="mt-[5px] font-medium">
                {paymentMethodWord(order?.pay_method)}
              </span>
              {order?.status != "전액 취소" && order?.pay_method === "card" ? (
                //카드
                <div className="flex flex-col">
                  <span className="mt-[5px] font-medium">
                    {order?.card?.cardName} &nbsp; &nbsp; {order?.card?.cardNum}
                  </span>
                  <span className="mt-[5px] font-medium">
                    {order?.card?.cardQuota === 0
                      ? "일시불"
                      : `할부 ${order?.card?.cardQuota} 개월`}
                  </span>
                </div>
              ) : //무통장입금
              order?.status === "입금 대기" ? (
                <div className="flex flex-col">
                  <span className="mt-[5px] font-medium">
                    {order?.vbank?.vbankName} &nbsp; &nbsp;{" "}
                    {order?.vbank?.vbankNumber}
                  </span>

                  <span className="mt-[5px] font-medium">
                    {moment(order?.vbank?.vbankExpDate).format(
                      "YYYY.MM.DD HH:mm"
                    )}{" "}
                    분 까지
                  </span>
                </div>
              ) : (
                <div className="flex flex-col">
                  <span className="mt-[5px] h-[20px]"></span>
                  <span className="mt-[5px] h-[20px]"></span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {order?.cancel_available && (
        <button
          type="button"
          onClick={() => {
            Swal.fire({
              title: "주문을 취소하시겠습니까?",
              input: "text",
              inputPlaceholder: "취소 사유를 입력해주세요.",
              showCancelButton: true,
              confirmButtonText: "주문 취소",
              cancelButtonText: "돌아가기",
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading(),
              preConfirm: (reason) => {
                if (!reason) {
                  Swal.showValidationMessage(`취소 사유를 입력해주세요.`);
                } else {
                  return reason;
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                postCancelOrder({
                  orderId: order?.id,
                  reason: result.value,
                })
                  .then(() => {
                    Swal.fire({
                      icon: "success",
                      title: "주문이 취소되었습니다.",
                      showConfirmButton: true,
                      cancelButtonText: "확인",
                      willClose: () => location.reload(),
                    });
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: "error",
                      title: "주문 취소에 실패했습니다",
                      text: "반복될 경우 채널톡으로 문의주세요.",
                      showConfirmButton: true,
                      cancelButtonText: "확인",
                    });
                  });
              }
            });
          }}
          className="absolute bg-[#F5F5F5] rounded-[3px] w-[76px] h-[34px] flex items-center justify-center text-[13px] bottom-[20px] right-[20px] text-[#B2B2B2] font-[500]"
        >
          주문취소
        </button>
      )}
    </div>
  );
};

export default OrderNicePayLg;
