import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { isEmpty, sum } from "lodash";

Chart.register(ChartDataLabels);

function GenderChart({ targetQuery }) {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { selectLecture, percent } = targetQuery;

  const setGenderData = () => {
    setIsLoading(true);
    const url = `/api/lectures/${selectLecture?.id}/gender_data?percent=${percent}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data;
        setTotal(getTotalNumber(result));
        setData({
          labels: Object.keys(result),
          datasets: [
            {
              label: "# of Votes",
              data: Object.values(result),
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
              ],
            },
          ],
        });
      })
      .catch((err) => {});
  };

  const isDataCheck = () => {
    return isEmpty(data?.labels);
  };

  const getTotalNumber = (result) => {
    const totalAry = Object.values(result);
    const tempTotal = sum(totalAry);

    return tempTotal;
  };

  useEffect(() => {
    if (selectLecture !== null && percent !== null) {
      setGenderData();
    }
  }, [selectLecture, percent]);

  useEffect(() => {
    if (data !== null) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div className="p-4 bg-white border border-black">
      {selectLecture !== null && (
        <h2 className="mb-4 text-xl font-bold">
          {selectLecture?.title}
          <br /> {percent}% 이상 수강한 학생 데이터
          <br /> 총 {total} 명
        </h2>
      )}

      <h3 className="mb-4 font-bold text-l">강좌별 성별 분포도</h3>
      {isLoading ? (
        <p>로딩중입니다.</p>
      ) : selectLecture !== null ? (
        isDataCheck() ? (
          <p>적합한 데이터가 없습니다.</p>
        ) : (
          <Pie data={data} />
        )
      ) : (
        <p>강좌를 선택해주세요.</p>
      )}
    </div>
  );
}

export default GenderChart;
