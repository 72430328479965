import { useEffect, useState } from "react";
import checkCircleNot from "../../assets/icons/check/check_circle_not.svg";
import checkCircle from "../../assets/icons/check/check_circle.svg";
import checkSquareNot from "../../assets/icons/check/check_square_not.svg";
import checkSquare from "../../assets/icons/check/check_square.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import PrivatePolicy from "../shared/PrivatePolicy";

import { submitOnedayBoard } from "../../api";

const CheckboxLecture = ({ handleClick, checkLectures, id, title }) => {
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    if (checkLectures?.includes(id)) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
  }, [checkLectures]);

  return (
    <div className="flex mt-4" onClick={() => handleClick({ id: id })}>
      <img
        id={`checkbox_input_${id}`}
        src={isCheck ? checkSquare : checkSquareNot}
        alt={`lecture_check_${id}`}
        className="w-5 h-5 mr-[10px] cursor-pointer"
      />
      <span
        className={`leading-[19px] font-[500] cursor-pointer ${
          isCheck ? "text-black" : "text-[#B2B2B2]"
        }`}
      >
        {title}
      </span>
    </div>
  );
};

export const SubmitForm = ({ isOverDate, onedayLecture }) => {
  const [checkPrivate, setCheckPrivate] = useState(false);
  const [isOpenPrivatePolicy, setIsOpenPrivatePolicy] = useState(false);

  const formik = useFormik({
    initialValues: {
      parentName: "",
      parentPhone: "",
      ages: [],
      lectures: [],
    },

    validationSchema: yup.object({
      parentName: yup.string().required("필수 입력사항입니다."),
      parentPhone: yup
        .string()
        .min(11, "전화번호를 다시 확인해주세요. (ex. 01012345678)")
        .required("필수 입력사항입니다."),
    }),

    onSubmit: (values, { resetForm }) => {
      submitOnedayBoard({ id: onedayLecture.id, ...values })
        .then((res) => {
          resetForm();
          setCheckPrivate(false);
          setIsOpenPrivatePolicy(false);
          Swal.fire({
            title: "원데이클래스 신청이 완료되었습니다.",
            text: "클래스 당일에 줌 미팅 링크를 보내드리겠습니다.",
            icon: "success",
            confirmButtonText: "확인",
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "원데이클래스 신청에 문제가 생겼습니다.",
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    },
  });

  const handleCheckAge = ({ age: age }) => {
    if (formik.values.ages?.includes(age)) {
      formik?.setFieldValue(
        "ages",
        formik.values.ages.filter((grade) => grade !== age)
      );
      return;
    } else {
      formik?.setFieldValue("ages", [...formik.values.ages, age]);
    }
  };

  const handleCheckLecture = ({ id: id }) => {
    if (formik.values.lectures?.includes(id)) {
      formik?.setFieldValue(
        "lectures",
        formik.values.lectures.filter((lecture) => lecture !== id)
      );

      return;
    } else {
      formik?.setFieldValue("lectures", [...formik.values.lectures, id]);
    }
  };

  return (
    <div className="w-full bg-gray25">
      <div className="px-4 flex flex-col pt-[50px] pb-[30px] max-w-[650px] mx-auto mt-[30px]">
        <span className="font-bold text-[24px] mx-auto">
          원데이클래스 신청하기
        </span>

        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col bg-white rounded-[10px] px-5 py-[30px] mt-[50px] relative"
        >
          <label htmlFor="parentName" className="font-bold">
            1. 학부모 성함 <span className="text-[#FF0000] font-[500]">*</span>
          </label>

          <input
            id="parentName"
            name="parentName"
            value={formik.values.parentName}
            onChange={formik.handleChange}
            placeholder="학부모 성함을 입력해주세요."
            type="text"
            className="focus:outline-black mt-[10px] pt-[17px] pb-4 px-5 rounded-[10px] font-[500] border border-[#DDDDDD]"
          />

          {formik.errors.parentName && formik.touched.parentName && (
            <div className="text-[#FF0000] font-[500] mt-[10px] text-xs">
              {formik.errors.parentName}
            </div>
          )}

          <label htmlFor="parentPhone" className="font-bold mt-[30px]">
            2. 학부모 휴대전화번호{" "}
            <span className="text-[#FF0000] font-[500]">*</span>
          </label>

          <input
            id="parentPhone"
            name="parentPhone"
            value={formik.values.parentPhone}
            onChange={formik.handleChange}
            placeholder="‘-’ 없이 휴대전화번호를 입력해주세요."
            type="text"
            className="focus:outline-black mt-[10px] pt-[17px] pb-4 px-5 rounded-[10px] font-[500] border border-[#DDDDDD]"
          />

          {formik.errors.parentPhone && formik.touched.parentPhone && (
            <div className="text-[#FF0000] font-[500] mt-[10px] text-xs">
              {formik.errors.parentPhone}
            </div>
          )}

          <label className="font-bold mt-[30px]">
            3. 학생 학년 정보(중복 가능)
            <span className="text-[#FF0000] font-[500]">*</span>
          </label>

          <div className="grid grid-cols-3 gap-x-[6px] gap-y-[5px] mt-[10px]">
            <div
              onClick={() => handleCheckAge({ age: "초4" })}
              className={`font-[500] rounded-[10px] flex justify-center items-center h-[52px] cursor-pointer ${
                formik.values.ages.includes("초4")
                  ? "bg-white text-black border-black border"
                  : "bg-gray25 text-[#B2B2B2]"
              }`}
            >
              초4
            </div>
            <div
              onClick={() => handleCheckAge({ age: "초5" })}
              className={`font-[500] rounded-[10px] flex justify-center items-center h-[52px] cursor-pointer ${
                formik.values.ages.includes("초5")
                  ? "bg-white text-black border-black border"
                  : "bg-gray25 text-[#B2B2B2]"
              }`}
            >
              초5
            </div>
            <div
              onClick={() => handleCheckAge({ age: "초6" })}
              className={`font-[500] rounded-[10px] flex justify-center items-center h-[52px] cursor-pointer ${
                formik.values.ages.includes("초6")
                  ? "bg-white text-black border-black border"
                  : "bg-gray25 text-[#B2B2B2]"
              }`}
            >
              초6
            </div>
            <div
              onClick={() => handleCheckAge({ age: "중1" })}
              className={`font-[500] rounded-[10px] flex justify-center items-center h-[52px] cursor-pointer ${
                formik.values.ages.includes("중1")
                  ? "bg-white text-black border-black border"
                  : "bg-gray25 text-[#B2B2B2]"
              }`}
            >
              중1
            </div>
            <div
              onClick={() => handleCheckAge({ age: "중2" })}
              className={`font-[500] rounded-[10px] flex justify-center items-center h-[52px] cursor-pointer ${
                formik.values.ages.includes("중2")
                  ? "bg-white text-black border-black border"
                  : "bg-gray25 text-[#B2B2B2]"
              }`}
            >
              중2
            </div>
            <div
              onClick={() => handleCheckAge({ age: "중3" })}
              className={`font-[500] rounded-[10px] flex justify-center items-center h-[52px] cursor-pointer ${
                formik.values.ages.includes("중3")
                  ? "bg-white text-black border-black border"
                  : "bg-gray25 text-[#B2B2B2]"
              }`}
            >
              중3
            </div>
          </div>

          <div
            className="flex mt-[10px]"
            onClick={() => handleCheckAge({ age: "기타" })}
          >
            <img
              src={
                formik.values.ages.includes("기타")
                  ? checkSquare
                  : checkSquareNot
              }
              alt={`학년기타`}
              className="w-5 h-5 mr-[10px] cursor-pointer"
            />
            <span
              className={`leading-[19px] font-[500] cursor-pointer 
            ${
              formik.values.ages.includes("기타")
                ? "text-black"
                : "text-[#B2B2B2]"
            }
              `}
            >
              {`기타(다른 학년입니다.)`}
            </span>
          </div>

          {formik.errors.ages && formik.touched.ages && (
            <div className="text-[#FF0000] font-[500] mt-[10px] textxs">
              {formik.errors.ages}
            </div>
          )}

          {onedayLecture?.options?.length > 0 && (
            <label htmlFor="input_parent_phone" className="font-bold mt-[30px]">
              4. 신청 강의(중복가능)
              <span className="text-[#FF0000] font-[500]">*</span>
            </label>
          )}

          {/* 루프 돌아가야함 */}
          {onedayLecture?.options?.map((lecture, index) => {
            return (
              <CheckboxLecture
                handleClick={handleCheckLecture}
                checkLectures={formik.values.lectures}
                id={lecture?.id}
                title={lecture?.title}
                key={index}
              />
            );
          })}

          {formik.errors.lectures && formik.touched.lectures && (
            <div className="text-[#FF0000] font-[500] mt-[10px] text-xs">
              {formik.errors.lectures}
            </div>
          )}

          <div className="mt-[30px] flex items-center">
            <img
              src={checkPrivate ? checkCircle : checkCircleNot}
              alt="개인정보동의"
              className={`w-[24px] h-[24px] cursor-pointer shrink-0 mr-[10px] ${
                checkPrivate ? "text-black" : "text-[#B2B2B2]"
              }`}
              onClick={() => setCheckPrivate(!checkPrivate)}
            />
            <span
              className={`cursor-pointer ${
                checkPrivate ? "text-black" : "text-[#B2B2B2]"
              }`}
              onClick={() => setCheckPrivate(!checkPrivate)}
            >
              개인정보 수집, 이용, 제공 동의
            </span>
            <span
              className="underline ml-auto text-[#B2B2B2] cursor-pointer"
              onClick={() => setIsOpenPrivatePolicy(!isOpenPrivatePolicy)}
            >
              {isOpenPrivatePolicy ? "접기" : "보기"}
            </span>
          </div>

          {isOpenPrivatePolicy && (
            <div className="border border-[#DDDDDD] rounded-[5px] mt-[15px] h-[68px] overflow-auto">
              <PrivatePolicy />
            </div>
          )}

          <button
            type="submit"
            disabled={
              !(
                !!formik.values.ages.length > 0 &&
                !!formik.values.lectures.length > 0 &&
                onedayLecture?.options?.length > 0 &&
                !!formik.values.parentName &&
                !!formik.values.parentPhone &&
                checkPrivate
              )
            }
            className={`h-[52px] flex items-center justify-center text-white rounded-[5px] w-full mt-[30px] ${
              !(
                !!formik.values.ages.length > 0 &&
                !!formik.values.lectures.length > 0 &&
                onedayLecture?.options?.length > 0 &&
                !!formik.values.parentName &&
                !!formik.values.parentPhone &&
                checkPrivate
              )
                ? "bg-[#CCCCCC]"
                : "bg-black"
            }`}
          >
            신청하기
          </button>

          {isOverDate && (
            <div className="absolute rounded-[10px] h-full w-full bg-black opacity-60 top-0 left-0 flex justify-center items-center">
              <div className="flex items-center justify-center w-full m-auto text-lg font-bold text-white z-3">
                <div className="flex flex-col items-center justify-center">
                  <span>원데이 클래스 신청이</span>
                  <span>마감되었습니다.</span>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
