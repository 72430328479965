import { css } from "@emotion/react";
import { color } from "../../tailwindStyle";

const pgBarParent = css`
  background-color: ${color.gray100};
  &::before {
    content: attr(data-result);
    max-height: 1.25rem;
    padding-left: 0.5rem;
    position: absolute;
    color: #535353;
  }
`;

const ProgressBar = (props) => {
  const pgNumber = props?.value;
  const placeholder = props?.placeholderText;

  const pgBar = css`
    max-height: 1.25rem;
    height: 100%;
    color: white;
    background-color: ${color.yellow600};
    width: ${pgNumber}%;
  `;

  return (
    <div
      className="h-5 mb-1 text-xs leading-5 rounded"
      css={pgBarParent}
      data-result={placeholder}
    >
      <div className="rounded pgBar" css={pgBar}></div>
    </div>
  );
};

export default ProgressBar;
