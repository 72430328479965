import rightArrowRed from "../../assets/icons/arrow_right_red.svg";
import { addComma } from "../../utils";
import moment from "moment";
import { css } from "@emotion/react";

const KgPayCardLg = ({ card }) => {
  return (
    <div className="mb-[26px]">
      <div className="flex items-center justify-between">
        <span className="text-lg font-bold">
          {moment(card?.created_at).format("YYYY.MM.DD")}
        </span>
        <a href={`${card?.receipt_url}`} className="flex text-sm">
          <span className="mr-[11px] text-red400">영수증 보기</span>
          <img src={rightArrowRed} alt="자세히보기아이콘" />
        </a>
      </div>

      <div className="border rounded-[5px] border-gray100 w-full py-[33px] px-[17px] flex mt-[14px]">
        <div
          className="w-[280px] h-[187px] mr-[26px] bg-contain bg-center bg-no-repeat"
          css={css`
            background-image: url(${card?.lecture_image_url});
          `}
        ></div>
        <div className="flex flex-col justify-between">
          <span className="text-lg font-bold">{card?.lecture_title}</span>
          <div className="flex text-sm">
            <div className="flex flex-col w-[95px]">
              <span className="font-medium text-gray400">
                결제 상태
              </span>
              <span className="mt-[5px] text-gray400 font-medium">
                결제 금액
              </span>
              <span className="mt-[5px] text-gray400 font-medium">
                결제 수단
              </span>

              {card?.payment_card_name !== null ? (
                //카드
                <div className="flex flex-col">
                  <span className="mt-[5px] text-gray400 font-medium">
                    카드 정보
                  </span>
                  <span className="mt-[5px] text-gray400 font-medium">
                    결제 정보
                  </span>
                </div>
              ) : (
                <div className="flex flex-col">
                  <span className="mt-[5px] h-[20px]"></span>
                  <span className="mt-[5px] h-[20px]"></span>
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <span className="font-bold">{card?.payment_status}</span>
              <span className="mt-[5px] font-medium">
                {addComma(parseInt(card?.amount))} 원
              </span>
              <span className="mt-[5px] font-medium">카드 결제</span>

              <div className="flex flex-col">
                <span className="mt-[5px] font-medium">
                  {card?.payment_card_name}
                </span>
                <span className="mt-[5px] font-medium">
                  {card?.payment_installment_str}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KgPayCardLg;
