import { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Vbank } from "../components/order/index";
import { getOrderResult } from "../api";

const OrderVbank = (props) => {
  const [order, setOrder] = useState(null);
  const [orderable, setOrderable] = useState(null);

  useEffect(() => {
    orderInit();
  }, []);

  const orderInit = () => {
    getOrderResult({ orderId: props?.orderId }).then((response) => {
      setOrder(response?.data.order);
      setOrderable(response?.data.orderable);
    });
  };

  return (
    <Layout>
      <Vbank order={order} orderable={orderable} />
    </Layout>
  );
};

export default OrderVbank;
