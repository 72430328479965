import { useState, useEffect } from "react";
import useDeviceCheck from "../../hooks/useDeviceCheck";
import Zoom from "react-medium-image-zoom";
import { getLesson, getMission } from "../../api";

export const AssignmentsInfo = ({ lessonId }) => {
  const deviceCheck = useDeviceCheck(null);

  const [mission, setMission] = useState(null);
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    getLesson({ lessonId: lessonId }).then((response) => {
      setLesson(response?.data);
    });

    getMission({ lessonId: lessonId }).then((response) => {
      setMission(response?.data);
    });
  }, []);

  return (
    <div className="flex flex-col web:max-w-[510px]">
      <span className="font-medium tablet:text-sm mobile:text-sm">
        {lesson?.lecture_title}
      </span>
      <div className="flex items-center justify-between mb-[13px]">
        <span className="font-bold truncate web:text-xl">{lesson?.title}</span>
        {deviceCheck !== "web" && (
          <a
            href={`/lessons/${lessonId}?tab=assignment`}
            className="text-white whitespace-nowrap web:px-5 tablet:px-[7px] mobile:px-[7px] web:py-[6px] rounded-[5px] font-bold web:text-sm tablet:text-xs mobile:text-xs bg-yellow700 p-[6px]"
          >
            강의로 돌아가기
          </a>
        )}
      </div>
      <div className="border border-gray100 rounded-[10px] flex flex-col py-[22px] px-[29px]">
        <span className="font-bold">{mission?.title}</span>
        <Zoom>
          <div dangerouslySetInnerHTML={{ __html: mission?.content }}></div>
        </Zoom>
      </div>
    </div>
  );
};
